import { createGlobalStyle } from "styled-components";
import InterRegular from "./assets/fonts/Inter-Regular.ttf";
import InterMedium from "./assets/fonts/Inter-Medium.ttf";
import InterSemiBold from "./assets/fonts/Inter-SemiBold.ttf";
import NunitoSemiBold from "./assets/fonts/Nunito-SemiBold.ttf";
import NunitoRegular from "./assets/fonts/Nunito-Regular.ttf";
import MontserratRegular from "./assets/fonts/Montserrat-Regular.ttf";
import MontserratMedium from "./assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "./assets/fonts/Montserrat-SemiBold.ttf";
import ClearSansThin from "./assets/fonts/ClearSans-Thin.ttf";
import ClearSansRegular from "./assets/fonts/ClearSans-Regular.ttf";
import ClearSansMedium from "./assets/fonts/ClearSans-Medium.ttf";
import ClearSansMediumItalic from "./assets/fonts/ClearSans-MediumItalic.ttf";
import ClearSansLight from "./assets/fonts/ClearSans-Light.ttf";
import ClearSansItalic from "./assets/fonts/ClearSans-Italic.ttf";
import ClearSansBold from "./assets/fonts/ClearSans-Bold.ttf";
import ClearSansBoldItalic from "./assets/fonts/ClearSans-BoldItalic.ttf";


const Styles = createGlobalStyle`
 @font-face {
   font-family: 'ClearSans';
   font-weight: 400;
   src: url(${ClearSansRegular});
}
 @font-face {
   font-family: 'ClearSans';
   font-weight: 500;
   src: url(${ClearSansMedium});
}
@font-face {
   font-family: 'ClearSans';
   font-weight: 700;
   src: url(${ClearSansBold});
}

   *{
      margin:0;
      padding:0;
      box-sizing: border-box;
     :focus {
      outline: none;
   }
   body {
      font-family: 'ClearSans', sans-serif;;
      border: 0;
      outline: 0;
      overflow-x: hidden;
      color: #444;
      background-color: #f1f5f7;
      font-size: 16px;
      line-height: 24px;
      
   }
   ul{
      list-style: none;
   }
   a {
      text-decoration: none;
      outline: none;
      color: #EE6C4D;
      transition: color 0.2s ease-in;
      :hover,a.active {
         color: #98C1D8;
      }
   }
   h2{
      font-size: 24px;
      line-height: 38.4px;
       font-family: 'ClearSans', sans-serif;
       font-weight: 500;
   }
   h4{
      font-size: 20px;
      line-height: 32px;
       font-family: 'ClearSans', sans-serif;
       font-weight: 700;
   }
   h6{
      font-size: 18px;
      line-height: 26px;
       font-family: 'ClearSans', sans-serif;
       font-weight: 500;
   }
   h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
      color: #444;
   }
   .text-base {
      font-size: 15px;
      line-height: 22px;
   } 
   .text-sm {
      font-size: 12px;
      line-height: 20px;
   } 
   input, button {
      font-family: inherit;
      font-size: inherit;
      outline: none;
      background: transparent; 
   }
   button {
      cursor:pointer;
      transition: 0.2s ease-in;
      border: none;

   }
   button:hover{
      background-color: #EE6C4D;
   }
   
   .normalButton {
      color: white;
      background-color: #3D5A80;
   }
   .normalButton:hover {
      background-color: #EE6C4D;
   }
   .simplebar-scrollbar:before {
      background: #a2adb7;
   }
   .react-slidedown {
      transition-duration: 0.5s;
   }
   :fullscreen {
      background-color: #f1f5f7;
   }
   .form-check-input:checked {
      background-color: #EE6C4D;
      border-color: #EE6C4D;
   }
   .form-label {
      color: #444;
   }
   label {
      color: #444;
   }
   Form label {
      color: black;
   }
   Form input[type="radio"]:checked+label {
      color: #EE6C4D;
      font-weight: 700;
   }
   .section-title {
      color: #3D5A80;
      font-weight: 700;
      font-size: 1.4rem;
   }
   }`

export default Styles;
