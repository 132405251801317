import * as S from "./styles"

export let columnsForImpactTablePersonal = [{
  Header: 'Level of Impact',
  accessor: 'levelofimpact'
  },
  {
    Header: 'Area of Effect - Personal (Individuals)',
    accessor: 'aoeindividuals'
  }];

export let columnsForImpactTableInstitutional = [{
  Header: 'Level of Impact',
  accessor: 'levelofimpact'
  },
  {
    Header: 'Area of effect – Institutional',
    accessor: 'aoeinstitutional'
  }];


export let columnsForImpactTableInstitutionalRep = [{
    Header: 'Level of Impact',
    accessor: 'levelofimpact'
    },
    {
      Header: 'Area of effect – Institutional, reputation and brand (business/organizations)',
      accessor: 'aoeinstitutionalrep'
    }];
export let columnsForImpactTableInstitutionalLegal = [{
    Header: 'Level of Impact',
    accessor: 'levelofimpact'
    },
    {
      Header: 'Area of effect - Institutional, Legal(business/organizations)',
      accessor: 'aoeinstitutionallegal'
    }];
export let columnsForImpactTableSocietal = [{
    Header: 'Level of Impact',
    accessor: 'levelofimpact'
    },
    {
      Header: 'Area of effect – Societal',
      accessor: 'aoesocietal'
    }];
export let columnsForImpactTableEthical = [{
    Header: 'Level of Impact',
    accessor: 'levelofimpact'
    },
    {
      Header: 'Area of effect – Ethical',
      accessor: 'aoeethical'
    }];
export let columnsForImpactTableEconomical = [{
    Header: 'Level of Impact',
    accessor: 'levelofimpact'
    },
    {
      Header: 'Area of effect – Economical',
      accessor: 'aoeeconomical'
    }];
export let columnsForImpactTableTechnical = [{
    Header: 'Level of Impact',
    accessor: 'levelofimpact'
    },
    {
      Header: 'Area of effect – Technical',
      accessor: 'aoetechnical'
    }];

let lowImpactPersonal = <>
    <S.ListCircle>
      <li>Individuals may encounter a few minor inconveniences, which they will overcome without any problem 
        (time spent re-entering information, annoyances, irritations, targeted advertisement etc.).</li>
      
    </S.ListCircle></>
let mediumImpactPersonal = <>
  <S.ListCircle>
    <li>Individuals may encounter significant inconveniences, which they will be able to overcome despite a few difficulties 
      (extra costs, extra time spent waiting or following up on an issue, denial of access to business services, fear, lack of understanding, 
      stress, minor physical ailments, misrepresentation etc.).</li>
    
  </S.ListCircle></>
  let highImpactPersonal = <>
  <S.ListCircle>
    <li>Individuals may encounter significant consequences, which they should be able to overcome albeit with 
      serious difficulties (misappropriation of funds, blacklisting by financial institutions, property damage, 
      loss of employment, subpoena, worsening of health, identify theft, etc.).</li>
    
  </S.ListCircle>
  </>

  let veryHighImpactPersonal = <>
  <S.ListCircle>
    <li>Individuals which may encounter significant, or even irreversible consequences, which they may not overcome 
      (inability to work, long-term psychological or physical ailments, death, etc.).</li>
    
  </S.ListCircle>
  </>

  let lowImpactInstitutional = <>
    <S.ListCircle>
      <li>Organization might encounter minor issues, but none of them will affect it's operations.</li>
      
    </S.ListCircle>
  </>
  let mediumImpactInstitutional = <>
    <S.ListCircle>
      <li>Organization might face significant consequences (e.g. minor loss of trust, minor legal issues from 
        breach of contract or law), 
        which it will be able to overcome by making minor adjustments to operations or spend minor funds 
        (use of different tools, 
        minor updates in existing toolchain, updates in contracts with partners, personel training, supervisory procedure from 
        external institution, etc.)
      </li>
     
      
      
    </S.ListCircle>
  </>
  let highImpactInstitutional = <>
    <S.ListCircle>
    <li>Organizations may face significant consequences (loss of trust, major legal issues, loss of competitive advantage, etc.), 
        which it will probably be able to overcome by making major adjustments to operations and/or spending
        major funds (
          acquire expensive tools or develop new solutions, hire experts to oversee internal operations, 
          train a large part of the workforce, pay legal fines due to breach of contract or law etc.
        ) </li>
    </S.ListCircle>
  </>

  let veryHighImpactInstitutional = <>
    <S.ListCircle>
      <li>Organizations may encounter significant or irreversible consequences to the continuity of operations
         (serious damage to trust, loss of major funds, significant workforce reductions, loss of partnerships,
         pause of operations due to legal reasons, etc.).
      </li>
    </S.ListCircle>
  </>

let lowImpactSocietal = <>
  <S.ListCircle>
    <li>Minor inconveniences for society, which can be resolved without any problem ().</li>
    
  </S.ListCircle>
</>
let mediumImpactSocietal = 
<>
  <S.ListCircle>
    <li>Major consequences to society, which can be resolved although with some effort (
      distrust towards LEAs or ministries, smaller demonstrations, articles in newspapers, etc.).</li>
  </S.ListCircle>
</>
let highImpactSocietal = <>
  <S.ListCircle>
    <li>Major consequences to society, which may be resolved with some serious effort (
      worsening of international-diplomatic relations, large demonstrations,
      negative opinions in media for law enforcement agencies, major distrust by people towards institutions 
      and agencies that keep personal data for security reasons or research, loss of value for city areas,
       etc.).</li>
  </S.ListCircle>
</>
let veryHighImpactSocietal = <>
  <S.ListCircle>
    <li>Major or irreversible consequences to society, which can lead to unrest and instability (
      protests, public demonstrations, disruption of social peace and national security, 
      complete loss of trust to agencies and government)</li>
  </S.ListCircle>
</>

  let lowImpactEthical = 
    <S.ListCircle>
      <li>Minor inconveniences to minorities because of a misrepresentation in the datset.</li>
      <li>Data is being used for another purpose that poses no high threat (newsletters, targeted 
        advertisement etc.).</li>
    </S.ListCircle>;
  let mediumImpactEthical = <>
    <S.ListCircle>
      <li>Data is being used for other purposes that may affect an individual more than just in low risk area.</li>
    </S.ListCircle>;
  </>

  
  
  let lowImpactEconomical = 
    <S.ListCircle><li>Low risks usually do not cause economic loss. It could be that instead time is an issue, for example a procedure takes longer than expected and it is an inconvenience.</li></S.ListCircle>
  let lowImpactTechnical = 
    <S.ListCircle><li>If one part of the system is down and other works, it is not that big of a risk.</li>
      <li>There is not need to use extra money for technical issues, organization's own people can provide enough support.</li>
    </S.ListCircle>;
  
  let mediumImpactInstitutionalReputation = 
    <S.ListCircle><li>Organization may need to make major adjustments in operations to avoid loosing trust.</li>
      <li>Organization is not able to fulfil contracts or laws.</li>
      <li>Organization may fail to prove it's usefulness and have competetitors step in.</li>
    </S.ListCircle>;
  let mediumImpactInstitutionalLegal = 
    <S.ListCircle>
      <li>Minor legal issues that will affect the organization’s operations but will be overcome despite a few difficulties (minor legal fees etc).</li>
      <li>If there are requirements stemming from laws or contracts, it may cause an organization or LEA to have to pay a fine. In case a person within a company has done something to cause the issue, they may face consequences.</li>
    </S.ListCircle>;
  
  
  let mediumImpactEconomical = 
    <S.ListCircle><li>Individuals risk a loss of money for different reasons, for example if their data is openly available somewhere, they may not get a job and it affects them long term.</li>
      <li>Organization has to use extra funds to create better security systems or pay fines.</li>
    </S.ListCircle>;
  let mediumImpactTechnical = 
    <S.ListCircle><li>Difficulties in one area may affect other things, for example issues with data integrity may give false results.</li>
      <li>Organization may have to use external specialists.</li>
    </S.ListCircle>;
  
  let highImpactInstitutionalReputation = 
    <S.ListCircle>
      <li>Organizations might suffer minor damage to reputation or trust.</li>
      <li>Organizations might suffer loss of funding.</li>
      <li>Organization may have to fill claims or face smaller fines.</li>
      <li>Issues in one area affect other areas of the system and processes.</li>
    </S.ListCircle>;
  let highImpactInstitutionalLegal = 
    <S.ListCircle>
      <li>Organizations might suffer major legal penalties.</li>
      <li>Contracts may change, operations will be affected in a major way.</li>
      <li>Support from other institutions or organizations, partners etc may be pulled.</li>
    </S.ListCircle>;
  
  let highImpactEthical = 
    <S.ListCircle>
      <li>Data is used for other purposes, for example sold etc.</li>
    </S.ListCircle>
  let highImpactEconomical = 
    <S.ListCircle>
      <li>Bigger fines, claims etc for the organization.</li>
      <li>People face losing their money or actually suffer greatly economically.</li>
    </S.ListCircle>
  let highImpactTechnical = 
    <S.ListCircle>
      <li>Systems don't work properly.</li>
      <li>Wrong information is being used and processed.</li>
      <li>Organization has to use outside support, specialists or change something in technical aspects, create new systems etc.</li>
    </S.ListCircle>
  
  let veryHighImpactInstitutionalReputation = 
    <S.ListCircle>
      <li>Serious consequences to the business’ continuity of operations (business/organization might cease to exist).</li>
      <li>Serious damage to organization’s trust.</li>
      <li>Loss of major funds.</li>
      <li>Difficulties between partners.</li>
      <li>Organization or institution is unable to function.</li>
    </S.ListCircle>;
  let veryHighImpactInstitutionalLegal = 
    <S.ListCircle>
      <li>Organizations not able to meet legal or regulatory obligations.</li>
      <li>Organization commits crime.</li>
      <li>Organization faces major legal processing in  different areas.</li>
    </S.ListCircle>;
  
  let veryHighImpactEthical = 
    <S.ListCircle>
      <li>Data is used for other purposes that cause serius risks, peoples' identity can be taken over, which may cause everything from physical harm to loss of money, job etc.</li>
    </S.ListCircle>;
  let veryHighImpactEconomical = 
    <S.ListCircle>
      <li>The biggest fines, claims etc for the organization.</li>
      <li>People loose a lot of money and funding, they cannot get a job or get a loan.</li>
    </S.ListCircle>;
  let veryHighImpactTechnical = 
    <S.ListCircle>
      <li>Nothing works as it should, data is deleted or changed forever.</li>
      <li>Resolutions and decisions are made on wrong processes.</li>
      <li>Organization cannot handle technical issues on their own, finding new partners and aid is difficult and costly.</li>
    </S.ListCircle>;

  export let dataForImpactTablePersonal = [
    {"levelofimpact": 'Low', "aoeindividuals": lowImpactPersonal},
    {"levelofimpact": 'Medium', "aoeindividuals": mediumImpactPersonal},
    {"levelofimpact": 'High', "aoeindividuals": highImpactPersonal},
    {"levelofimpact": 'Very High', "aoeindividuals": veryHighImpactPersonal},
  ];
  export let dataForImpactTableInstitutional = [
    {"levelofimpact": 'Low', "aoeinstitutional": lowImpactInstitutional},
    {"levelofimpact": 'Medium', "aoeinstitutional": mediumImpactInstitutional},
    {"levelofimpact": 'High', "aoeinstitutional": highImpactInstitutional},
    {"levelofimpact": 'Very High', "aoeinstitutional": veryHighImpactInstitutional},
  ]
  /*
  export let dataForImpactTableInstitutionalReputation = [
    {"levelofimpact": 'Low', "aoeinstitutionalrep": lowImpactInstitutionalReputation},
    {"levelofimpact": 'Medium', "aoeinstitutionalrep": mediumImpactInstitutionalReputation},
    {"levelofimpact": 'High', "aoeinstitutionalrep": highImpactInstitutionalReputation},
    {"levelofimpact": 'Very High', "aoeinstitutionalrep": veryHighImpactInstitutionalReputation},
  ];
  export let dataForImpactTableInstitutionalLegal = [
    {"levelofimpact": 'Low', "aoeinstitutionallegal": lowImpactInstitutionalLegal},
    {"levelofimpact": 'Medium', "aoeinstitutionallegal": mediumImpactInstitutionalLegal},
    {"levelofimpact": 'High', "aoeinstitutionallegal": highImpactInstitutionalLegal},
    {"levelofimpact": 'Very High', "aoeinstitutionallegal": veryHighImpactInstitutionalLegal},
  ];*/
  export let dataForImpactTableSocietal = [
    {"levelofimpact": 'Low', "aoesocietal": lowImpactSocietal},
    {"levelofimpact": 'Medium', "aoesocietal": mediumImpactSocietal},
    {"levelofimpact": 'High', "aoesocietal": highImpactSocietal},
    {"levelofimpact": 'Very High', "aoesocietal": veryHighImpactSocietal},
  ];
  /*
  export let dataForImpactTableEthical = [
    {"levelofimpact": 'Low', "aoeethical": lowImpactEthical},
    {"levelofimpact": 'Medium', "aoeethical": mediumImpactEthical},
    {"levelofimpact": 'High', "aoeethical": highImpactEthical},
    {"levelofimpact": 'Very High', "aoeethical": veryHighImpactEthical},
  ];
  export let dataForImpactTableEconomical = [
    {"levelofimpact": 'Low', "aoeeconomical": lowImpactEconomical},
    {"levelofimpact": 'Medium', "aoeeconomical": mediumImpactEconomical},
    {"levelofimpact": 'High', "aoeeconomical": highImpactEconomical},
    {"levelofimpact": 'Very High', "aoeeconomical": veryHighImpactEconomical},
  ];
  export let dataForImpactTableTechnical = [
    {"levelofimpact": 'Low', "aoetechnical": lowImpactTechnical},
    {"levelofimpact": 'Medium', "aoetechnical": mediumImpactTechnical},
    {"levelofimpact": 'High', "aoetechnical": highImpactTechnical},
    {"levelofimpact": 'Very High', "aoetechnical": veryHighImpactTechnical},
  ];
  */
  export let columnsForImpactLevelFinal = [
    { Header: 'Impact Assessment', accessor: 'impactassessment'},
    { Header: 'Area of Effect - Personal', accessor: 'aoeindividuals'},
    { Header: 'Area of Effect - Institutional Reputation', accessor: 'aoeinstitutionrep'},
    { Header: 'Area of Effect - Institutional Legal', accessor: 'aoeinstitutionlegal'},
    { Header: 'Area of Effect - Societal', accessor: 'aoesocietal'},
    { Header: 'Area of Effect - Ethical', accessor: 'aoeethical'},
    { Header: 'Area of Effect - Economical', accessor: 'aoeeconomical'},
    { Header: 'Area of Effect - Technical', accessor: 'aoetechnical'},
  ];
