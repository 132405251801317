import React, {useState} from 'react';
import * as S from './styles';
import * as P from "./data.js"
import {Form } from 'react-bootstrap'
import {Text} from "@react-pdf/renderer";
import ArrowDown from "./assets/icons/arrow-down.png";

let questionHeaderStyle = {
  fontWeight: 'bold', 
  fontSize: '12px',
  marginTop: '8px',
}
let answerHeaderStyle = {
  fontWeight: 'bold', 
  fontSize: '12px',
}

export function AnswersForPDF (props) {
  let answeredQuestions = [];
  
  for (let [key, value] of Object.entries(props.object) ) {
    if (value.checked) {
      answeredQuestions.push(value);
    }
  }

  return (
    <>
      <Text style={props.headerStyle}>{props.headerText}</Text>
      {answeredQuestions.map((item,index)=> (
        <>
        <Text style={questionHeaderStyle}>Question: </Text>
        <Text style={props.questionStyle}>{item.question}</Text>

        <Text style={answerHeaderStyle}>Answer: </Text>
        <Text style={props.answerStyle}>{item.answer}</Text>
        
        <Text style={answerHeaderStyle}>Justification: </Text>
        <Text style={props.justificationStyle}>{item.comments}</Text>
        </>
      ))}
    </>
  )
}

export function Question (props) {

  const [activeExplanation, setActiveExplanation] = useState(false);
  const [activeExamples, setActiveExamples] = useState(false);

  return (
    <>
      <S.ListSquare>
        <li>{props.question}</li>
      </S.ListSquare>
      
      <S.CardContentExamples>
        <S.CardContentExamplesTitle onClick={() => (activeExplanation) ? setActiveExplanation(false) : setActiveExplanation(true)}>
          Explanation:   <S.SidebarLinkArrow 
              src={ArrowDown} 
              rotate= {(activeExplanation) ? '180deg' : '0'}
              />
        </S.CardContentExamplesTitle>
        {activeExplanation && <S.CardContentExamplesList>
          {props.explanationObject.map((item, index) => {
            return(<p>{item}</p>)
          })}
        </S.CardContentExamplesList>}
      </S.CardContentExamples>
      
      <S.CardContentExamples>
        <S.CardContentExamplesTitle onClick={() => (activeExamples) ? setActiveExamples(false) : setActiveExamples(true)}>
          Examples:<S.SidebarLinkArrow 
              src={ArrowDown} 
              rotate= {(activeExamples) ? '180deg' : '0'}
              />
        </S.CardContentExamplesTitle>
        {activeExamples && <S.CardContentExamplesList>
          {props.exampleObject.map((item, index) => {
            return(<li>{item}</li>)
          })}
        </S.CardContentExamplesList>}
      </S.CardContentExamples>
    </>
  ) 
}

export function Answer (props) {
  return(
    <Form style={{width:'100%'}}>
      <Form.Group className="mb-3" controlId={props.controlId}>
        <div key={props.key} className="mb-3">
          <Form.Check inline type="radio" value="Yes" onChange={(event) => props.onChangeThreatAnswer(props.section, props.subsection, true, false, event)} label="Yes" name="group1" id={props.yesId} defaultChecked={props.answer === 'Yes' ? true : false}/>
          <Form.Check inline type="radio" value="No" onChange={(event) => props.onChangeThreatAnswer(props.section, props.subsection, true, false, event)} label="No" name="group1" id={props.noId} defaultChecked={props.answer === 'No' ? true : false}/>
        </div>
      </Form.Group>
    </Form>
  )
}

export function Justification (props) {
  return (
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
        defaultValue={props.defaultValue} onChange={(event) => props.onChangeThreatAnswer(props.section, props.subsection, true, true, event)}></S.GeneralFormFieldTextArea>
    </S.GeneralFormField>
  )
}
//formThreatLevelTransferAnswerbestPracticesCheckedYes
export function RiskFactorQuestion (props) {
  return (<>
    <Question 
      question={props.question}
      explanationObject = {props.explanationObject}
      exampleObject = {props.exampleObject}
    />
    <S.CardSection>
      {<Answer
        controlId={"formThreat"+props.section + props.subsection + "Radio"}
        key = {"inlineThreat"+props.section + props.subsection + "Radio"}
        onChangeThreatAnswer= {props.onChangeThreatAnswer}
        section= {props.section}
        subsection= {props.subsection}
        answer={props.threatLevelsObject.answer}
        yesId={"formThreatLevel"+props.section+props.subsection+"CheckedYes"}
        noId={"formThreatLevel"+props.section+props.subsection+"CheckedNo"}
      />}  
    </S.CardSection>  
    <S.CardSection>
      {<Justification 
        defaultValue= {props.threatLevelsObject.comments}
        onChangeThreatAnswer= {props.onChangeThreatAnswer}
        section={props.section}
        subsection={props.subsection}
      />}
    </S.CardSection>
  </>)
}