import React from 'react';
import * as S from './styles.js'
import ScrollButton from './scrollToTopButton.js';
import { FaArrowRight } from "react-icons/fa";
import ScrollToTop from './scrollToTop.js';

import Figure1 from "./assets/images/Figure-1.png"
import Figure2 from "./assets/images/Figure-2.png"
import Figure3 from "./assets/images/Figure-3.png"
import Figure4 from "./assets/images/Figure-4.png"
import Figure5 from "./assets/images/Figure-5.png"
import Figure6 from "./assets/images/Figure-6.png"
import Figure7 from "./assets/images/Figure-7.png"

export default class DocumentationV1 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      documentationMenu: [
        {
          title: 'Introduction',
          id: '1',
          active: true,
          visited: true,
          enabled: true,
        },
        {
          id: '2',
          title: 'Usage and use cases',
          visited: false,
          active: false,
          enabled: true,
          subNav: [
            {
              id: '2.1',
              title: 'Step 1: Context Definition',
              visited: false,
              active: false,
              enabled: true,
            },
            {
              id: '2.2',
              title: 'Step 2: Impact Evaluation',
              visited: false,
              active: false,
              enabled: true,
            },
            {
              id: '2.3',
              title: 'Step 3: Threat Analysis',
              visited: false,
              active: false,
              enabled: true,
            },
            {
              id: '2.4',
              title: 'Step 4: Risk Evaluation',
              visited: false,
              active: false,
              enabled: true,
            },
            {
              id: '2e',
              title: 'Step 5: Mitigation & Control',
              visited: false,
              active: false,
              enabled: true,
            },
          ]
        },
        {
          title: 'Frequently Asked Questions',
          id: '3',
          active: false,
          visited: false,
          enabled: true,
        },
      ]
    }

    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  handleMenuClick(item) {

    let documentationMenu = this.state.documentationMenu;
    
    if (item.enabled) {
      documentationMenu.forEach((menuItem) => {
      
        if (menuItem.id === item.id) {
          menuItem.active = true;
        } else {
          menuItem.active = false;
        }
        menuItem.subNav && menuItem.subNav.forEach((subMenuItem) => {
      
          if (subMenuItem.id === item.id) {
            subMenuItem.active = true;
          } else {
            subMenuItem.active = false;
          }

          subMenuItem.subNav && subMenuItem.subNav.forEach((subsubMenuItem) => {
      
            if (subsubMenuItem.id === item.id) {
              subsubMenuItem.active = true;
            } else {
              subsubMenuItem.active = false;
            }
          })
        })
      });
      
    }
    
    
    this.setState({
      documentationMenu: documentationMenu
    });

  }

  render() {
    return(
      <>
      <S.PageWidth>
      
      <ScrollButton/>
      
        <S.Navigation><S.InnerVerticalMenu>
        <S.RiskSidebarTitle>Table of Contents</S.RiskSidebarTitle>
          {this.state.documentationMenu.map((item, index) => {
            return(
              <>
              
              <S.RiskSidebarMenuLinkDiv 
                active={item.active} 
                enabled={item.enabled}
                onClick={() => this.handleMenuClick(item)}>
              <S.Circle active={item.active} enabled={item.enabled}>{item.id}</S.Circle>
                {item.title}
              </S.RiskSidebarMenuLinkDiv>
              {item.subNav && item.enabled && 
                item.subNav.map((item, index) => {
                  
                  return(
                    <>
                    <S.RiskSidebarSubMenuLinkDiv 
                      active={item.active} 
                      enabled={item.enabled}
                      onClick={() => this.handleMenuClick(item)}>
                    <S.Square active={item.active} enabled={item.enabled}>{item.id}</S.Square>
                      {item.title}
                    </S.RiskSidebarSubMenuLinkDiv>
                    {item.subNav && item.enabled && 
                      item.subNav.map((subItem, index) => {
                        console.log('subNav.subNav')
                        return(
                          <S.RiskSidebarSubSubMenuLinkDiv 
                            active={subItem.active} 
                            enabled={subItem.enabled}
                            onClick={() => this.handleMenuClick(subItem)}>
                          <S.NewSquare active={subItem.active} enabled={subItem.enabled}>{subItem.id}</S.NewSquare>
                            {subItem.title}
                          </S.RiskSidebarSubSubMenuLinkDiv>
                          
                        )})
                    }
                    </>
                  )})
              }
              </>);
          })}
        </S.InnerVerticalMenu>
        
        
        
        </S.Navigation>

        <S.MainInnerContent >
          {/*Section 1: Definition and Context of the Sharing Operation*/}
          {this.state.documentationMenu[0].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop />
            <S.CardTitle><h4 className="section-title" >1. Introduction</h4></S.CardTitle>
            <p>The Risk Assessment tool is a web-based online tool that complements the methodology developed in T5.2 “Risk Assessment on accessing specific FCT research data”. It provides an evaluation of the potential risks when sharing real FCT data and it can be an invaluable tool to data providers that want to participate in data sharing operations.</p>
            <p>Using the Risk Assessment tool the data provider is made aware of potential threats and impacts to
individuals, their institutions and society. A set of mitigation actions are also proposed in order to reduce
risks. The risk assessment user can export the evaluation to pdf format and use it to discuss internally
possible courses of action before sharing the any FCT data.</p>
            <p>The assessment evaluates the following risks:</p>
            <ul>
              <li>Unauthorized access (loss of confidentiality from a data leak)</li>
              <li>The existence of bias in dataset</li>
              <li>Legal compliance with relevant legal frameworks (like GDPR)</li>
              <li>Data misuse (data are used to create potentially dangerous tools)</li>
            </ul>
            <p>Each risk can have an impact to several areas therefore the risk assessment tool evaluates the impact of the
            above risks to the following areas of effect:</p>
            <ul>
              <li>Individuals (Personal impact)</li>
              <li>Institutions (Impact to the data provider’s institution)</li>
              <li>Society (Impact to general society)</li>
            </ul>
            <p>The user is guided step-by-step through an evaluation process that examines the impact of these risks along
            with their probability of materializing.</p>
            </S.CardBody>
            </S.Card>
          </>}

          {this.state.documentationMenu[1].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop />
            <S.CardTitle><h4 className="section-title" >2. Usage and Use Cases</h4></S.CardTitle>
            <p>When the user visits the home page of the tool (https://lago.microlab.ntua.gr) there are three options
            available, “I am a data provider”, “I am a data requester”, “Demo” as shown in Figure 1.</p>
            <S.Images src={Figure1}></S.Images>
            <S.Caption>Figure 1: Homepage of the Risk Assessment Tool</S.Caption>
            <p>An explanation of the options is given below:</p>
            <S.ListSimpleUl>
              <S.ListSimpleLi>“I am a data provider”: this option is the default assessment for sharing FCT data. It is intended to help guide the data provider through the evaluation when sharing FCT data.</S.ListSimpleLi>
              <S.ListSimpleLi>“I am a data requester”: (not available yet) this option is intended for the data requester to assess their cybersecurity. This may be required because the risk assessment needs details regarding the environment that the data will be hosted.</S.ListSimpleLi>
              <S.ListSimpleLi>“Use Case 1: this option opens an already completed assessment where every question has been answered and it serves as a demonstration of the Risk Assessment Tool</S.ListSimpleLi>
              </S.ListSimpleUl>
            <p>After the user selects “I am a data provider” the assessment begins, and they are guided through the evaluation step-by-step. The evaluation consists of five steps as shown in Figure 2. </p>

            <S.Images src={Figure2}></S.Images>
            <S.Caption>Figure 2: Risk Assessment methodology steps</S.Caption>

            <p>The assessment is self-explanatory with descriptions and examples, so no extra information is needed to complete the assessment. However, in the next sections an overview of each section is given for reference.</p>
            </S.CardBody>
            </S.Card>
          </>}

          {this.state.documentationMenu[1].subNav[0].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop />

            <S.CardTitle><h4 className="section-title" >2.1. Step 1: Context Definition</h4></S.CardTitle>
            <p>This section asks the user to input a few general information regarding the context of the sharing operation, like the data types included, the parties involved etc, as shown in Figure 3.</p>
            
            <S.Images src={Figure3}></S.Images>
            <S.Caption>Figure 3: Step 1: Context Definition</S.Caption>

            </S.CardBody></S.Card>
            </>}
          
          {this.state.documentationMenu[1].subNav[1].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop />

            <S.CardTitle><h4 className="section-title" >2.2. Step 2: Impact Evaluation</h4></S.CardTitle>
            <p>This section guides the user in selecting the appropriate impact level for each risk and for each area of effect
(personal, institutional, societal). The user must select the impact level based on the specifics of the data
involved and the intended use, while consulting the impact tables presented in this section as shown in
Figure 4.</p>
            
            <S.Images src={Figure4}></S.Images>
            <S.Caption>Figure 4: Step 2: Impact Evaluation</S.Caption>

            </S.CardBody></S.Card>
          </>}

          {this.state.documentationMenu[1].subNav[2].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop />
            <S.CardTitle><h4 className="section-title" >2.3. Step 3: Threat Analysis</h4></S.CardTitle>
            <p>This section guides the user through a set of threats that can potentially make the risks materialize. The user
is asked a few questions for each threat category and is asked to provide an evaluation of the threat
occurrence probability for each category, as shown in Figure 5.</p>
            
            <S.Images src={Figure4}></S.Images>
            <S.Caption>Figure 5: Step 3 Threat Analysis</S.Caption>

            </S.CardBody></S.Card></>
          }
          {this.state.documentationMenu[1].subNav[3].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop />

            <S.CardTitle><h4 className="section-title" >2.4. Step 4: Risk Evaluation</h4></S.CardTitle>
            <p>This section presents the overall risk evaluation for each risk and area of effect as shown in Figure 6.</p>
            
            <S.Images src={Figure5}></S.Images>
            <S.Caption>Figure 6: Step 4 Risk Evaluation</S.Caption>

            </S.CardBody></S.Card></>
          }

          {this.state.documentationMenu[1].subNav[4].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop /> 

            <S.CardTitle><h4 className="section-title" >2.5. Step 5: Mitigation & Control</h4></S.CardTitle>
            <p>Finally, Step 5 presents a set of mitigation actions that can reduce the risks based on the previous
            evaluation, as shown in Figure 7.</p>
            
            <S.Images src={Figure5}></S.Images>
            <S.Caption>Figure 7: Step 5 Mitigation & Control</S.Caption>

            </S.CardBody></S.Card>
          </>}

          {this.state.documentationMenu[2].active && <>
            <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop /> 
              
              <S.CardTitle><h4 className="section-title" >3. FAQ & Troubleshooting</h4></S.CardTitle>
              <p>1) Data are lost when I refresh the page?</p>
              <S.ListSimpleUl>
                <S.ListSimpleLi>
                For privacy reasons, no data are saved at the server, so the assessment starts over if the
                page is refreshed. 
                If you wish to continue at a later time please export your work using the save/load functionality at the 
                bottom of the left sidebar. When you are ready you can import the same file and continue the assessmentn 
                from that point.

                </S.ListSimpleLi>
              </S.ListSimpleUl>

              <p>2) How can I save my session and resume later?</p>
              <S.ListSimpleUl>
                <S.ListSimpleLi>
                  At any point you can use the save/load functionality that you can find on the bottom of the menu at the side.
                  You can export your work to a JSON file and at a later time upload the same file and continue the assessment from
                  that point.
                </S.ListSimpleLi>
              </S.ListSimpleUl>

              <p>3) How can I save my session and resume later?</p>
              <S.ListSimpleUl>
                <S.ListSimpleLi>
                  You can click at the "Home" menu item on the top right menu. Please have in mind that this action will 
                  reset any progress to the assessment and you will have to start from the beginning. 
                </S.ListSimpleLi>
              </S.ListSimpleUl>
              </S.CardBody>
            </S.Card>
          </>}

        </S.MainInnerContent>

        </S.PageWidth>

    </>)
  }

}