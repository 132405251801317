// Dropdown.js 

import React from "react";

import Select from 'react-select';
import * as S from '../../styles'
import MySelect from "./dropMenu";

class DropdownSelect extends React.Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topics
    console.log(value);
    this.props.onChange(value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topics
    this.props.onBlur('attributes', true);
  };
  render() {
  return (
    <S.DropdownWrapper>
      {this.props.label && <S.StyledLabel>{this.props.label}</S.StyledLabel>}
        
        <Select
          ref={this.props.ref}
          id={this.props.ckey}
          options={this.props.options}
          isMulti={false}
          onChange={this.props.onChange}
          isClearable={this.props.isClearable}
          defaultValue={this.props.defaultValue}
          //onBlur={this.handleBlur}
          //value={this.props.value}
        />
        
      </S.DropdownWrapper>
  )}

}
export default DropdownSelect

export function Dropdown(props) {
  return (
    <S.MyDropdownWrapper
      action={props.action}
      onChange={props.onChange}
    >
      <S.StyledLabel htmlFor="services">
        {props.formLabel}
      </S.StyledLabel>
      <S.StyledSelect id="services" name="services" defaultValue={props.defaultValue}>
        {props.children}
      </S.StyledSelect>
      {/*<StyledButton type="submit" value={props.buttonText} />*/}
    </S.MyDropdownWrapper>
  );
}
export function DropdownHorizontal(props) {
  return (
    <S.DropDownHorizontalWrapper
      action={props.action}
      onChange={props.onChange}
    >
      <S.DropDownStyledLabel>
        {props.formLabel}
      </S.DropDownStyledLabel>
      <S.DropDownStyledSelect>
        {props.children}
      </S.DropDownStyledSelect>
      {/*<StyledButton type="submit" value={props.buttonText} />*/}
    </S.DropDownHorizontalWrapper>
  );
}
export function DropdownHorizontalWithButton(props) {
  return (
    <S.DropDownHorizontalWrapper
      action={props.action}
      onChange={props.onChange}
    >
      <S.DropDownStyledLabel htmlFor="services">
        {props.formLabel}
      </S.DropDownStyledLabel>
      <S.DropDownStyledSelect id="services" name="services">
        {props.children}
      </S.DropDownStyledSelect>
      {<S.StyledButtonPrimary type="submit">{props.buttonText} </S.StyledButtonPrimary>}
    </S.DropDownHorizontalWrapper>
  );
}

export function Option(props) {
  return (
    <S.StyledOption selected={props.selected}>
      {props.value}
    </S.StyledOption>
  );
}