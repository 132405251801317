
const darkThemeColors = {
  bodyBackgroundColor: '#F1F5F7',
  linkColorDark: '#8590A5',
  linkColorHoverDark: '#D7E6ED',
  linkColorActiveDark: '#F1F5F7',
  navigationBackground: '#252B3B',
  sidebarBackgroundColor: '#252B3B',
  menuItemHeader: '#A8AEB5',
  text: '#444',
  altText: '#343A40',
  primaryColor: '#5664d2',
}
const lightThemeColors = {
  bodyBackgroundColor: '#F1F5F7',
  linkColorDark: '#74788D',
  linkColorHoverDark: '#343A40',
  linkColorActiveDark: '#5664d2',
  navigationBackground: '#fff',
  sidebarBackgroundColor: 'fff',
  menuItemHeader: '#8590a5',
  text: '#444',
  altText: '#343A40',
  primaryColor: '#5664d2',
}
const lagoThemeColors = {
  bodyBackgroundColor: '#F1F5F7',
  linkColorDark: '#8590A5',
  linkColorHoverDark: '#D7E6ED',
  linkColorActiveDark: '#F1F5F7',
  navigationBackground: '#fff',
  sidebarBackgroundColor: '#F6F8FA',
  menuItemHeader: '#A8AEB5',
  text: '#444',
  altText: '#343A40',
  primaryColor: '#3D5A80',

  topNavBackgroundColor: '#324966',
}
export const defaultTheme = {
  //sidebar
  bodyBackgroundColor: darkThemeColors.bodyBackgroundColor,
  sidebarWidth: '262px',
  sidebarLinkColor: darkThemeColors.linkColorDark,
  sidebarLinkColorHover: darkThemeColors.linkColorHoverDark,
  sidebarLinkColorActive: darkThemeColors.linkColorActiveDark,
  sidebarLinkHeaderColor: darkThemeColors.navigationHeader,
  sidebarBackgroundColor: darkThemeColors.sidebarBackgroundColor,
  //top nav
  topNavBackgroundColor: darkThemeColors.navigationBackground,
  topNavIconColor: '#EAECEF',
  topNavSearchFieldBackgroundColor: 'rgba(241,245,247,.07)', 
  topNavSearchFieldColor: '#fff',
  topNavSearchPlaceholderColor: '#9A9CA4',
  topNavUserProfileColor: darkThemeColors.text,
  topNavUserAvatarBackgroundColor: '#f1f5f7',
  topNavDropDownColor: darkThemeColors.text,
  topNavDropDownBackgroundColor: '#fff',
  topNavDropDownHeaderColor: darkThemeColors.altText,
  topNavDropDownTextColor: darkThemeColors.altText,
  topNavDropDownDividerColor: '#eff2f7',
  topNavDropDownBorderColor: 'rgba(0,0,0,.15)',
  topNavDropDownBoxShadow: 'rgb(0 0 0 / 10%)',
  topNavDropDownIconBackgroundColor: darkThemeColors.linkColorDark,
  topNavDropDownIconColor: '#fff',
  //footer
  footerColor: '#74788d',
  footerBackgroundColor: '#fff',
  // breadcrumps
  breadcrumpLinkColor: darkThemeColors.text,
  // main content
  cardBackgroundColor: '#fff',
  mainContentTabColor: '#696D8C',
  mainContentTabColorActive: '#252B3B',
  // tables
  tableSettingsPanelColor: darkThemeColors.text,
  tableSettingsPanelBackgroundColor: '#fff',
  toggleSwitchBackgroundColor: '#fff',
  toggleSwitchBackgroundColorChecked: darkThemeColors.primaryColor,
  toggleSwitchBorderColor: 'rgba(0,0,0,.25)',
  toggleSwitchBackgroundColorBorderColorChecked: darkThemeColors.primaryColor,
  globalFilterInputColor: darkThemeColors.text,
  globalFilterBackgroundColor: '#fff',
  globalFilterBorderColor: '#ced4da',
  globalFilterInputColorFocus: darkThemeColors.text,
  globalFilterBackgroundColorFocus: '#fff',
  globalFilterBorderColorFocus: '#b1bbc4',
  tableResponsiveColor: darkThemeColors.text,
  tableResponsiveBorderColor: '#eff2f7',
  tableResponsiveStrippedColor: '#f2f2f2',
  tableColor: darkThemeColors.text,
  tableBorderColor: '#eff2f7',
  tableStickyHeaderBackgroundColor: '#fff',
  tableHeaderBackgroundColor: 'transparent',
  tableBoxShadowInsetColor: 'transparent',
  tableStrippedColor: '#f2f2f2',
  tableBackgroundColor: '#fff',
  tableUnreadColor: '#fff',
  tableUnreadBackgroundColor: darkThemeColors.linkColorDark,
  tableColumnFilterInputColor: darkThemeColors.text, 
  tableColumnFilterInputBorderColor: '#D5DBE2',
  tableColumnFilterInputBackgroundColor: '#fff',
  tableColumnFilterInputColorFocus: darkThemeColors.text, 
  tableColumnFilterInputBorderColorFocus: '#b1bbc4',
  tableColumnFilterInputBackgroundColorFocus: '#fff',
  paginationGoToPageColor: darkThemeColors.text,
  paginationGoToPageBackgroundColor: '#fff',
  paginationGoToPageBorderColor: '#D5DBE2',
  paginationGoToPageColorFocus: darkThemeColors.text,
  paginationGoToPageBackgroundColorFocus: '#fff',
  paginationGoToPageBorderColorFocus: '#b1bbc4',
  // buttons
  outlineButtonColor: darkThemeColors.text,
  outlineButtonBorderColor: darkThemeColors.primaryColor,
  primaryButtonColor: '#fff',
  primaryButtonBackgroundColor: darkThemeColors.primaryColor,
  primaryButtonBorderColor: darkThemeColors.primaryColor,
  lightButtonColor: '#000',
  lightButtonBackgroundColor: '#eff2f7',
  lightButtonBorderColor: '#eff2f7',
  greyButtonColor: '#fff',
  greyButtonBackgroundColor: '#74788d',
  greyButtonBorderColor: '#74788d',
  //login page
  loginImageBackgroundColor: '#fff',
  loginFormBackgroundColor: '#fff',
  loginHeaderSubTitleColor: '#74788d',
  loginIconColor: darkThemeColors.primaryColor,
  loginFormInputColor: darkThemeColors.text,
  loginFormInputBackgroundColor: '#fff',
  loginFormInputBorderColor: '#ced4da',
  loginButtonColor: '#fff',
  loginButtonBackgroundColor: darkThemeColors.primaryColor,
  loginButtonBorderColor: darkThemeColors.primaryColor,
  //links
  primaryLinkColor: darkThemeColors.primaryColor,
  //Checkboxes
  checkBoxBackgroundColor: '#fff',
  checkBoxBorderColor: '#ddd',
  checkBoxBackgroundColorChecked: darkThemeColors.linkColorDark,
  concentricCircleBackgroundColor: '#fff',
  concentricCircleBackgroundColorChecked: 'grey',
  //notifications red pin
  notificationBellRedPinBackgroundColor: '#ff3d60',
  //dropDown
  dropDownColor: darkThemeColors.text,
  dropDownBorderColor: '#ced4da',
  dropDownOptionColor: 'black',
  dropDownOptionColorSelected: 'lightgrey',
  //settings
  settingsGroupBorderColor: '#efefef'
}
export const lightTheme = {
  bodyBackgroundColor: lightThemeColors.bodyBackgroundColor,
  //sidebar
  sidebarWidth: '262px',
  sidebarLinkColor: lightThemeColors.linkColorDark,
  sidebarLinkColorHover: lightThemeColors.linkColorHoverDark,
  sidebarLinkColorActive: lightThemeColors.linkColorActiveDark,
  sidebarLinkHeaderColor: lightThemeColors.navigationHeader,
  sidebarBackgroundColor: lightThemeColors.sidebarBackgroundColor,
  //top nav
  topNavBackgroundColor: lightThemeColors.navigationBackground,
  topNavIconColor: '#636E75',
  topNavSearchFieldBackgroundColor: '#F1F5F7', 
  topNavSearchFieldColor: '#636E75',
  topNavSearchPlaceholderColor: '#74788D',
  topNavUserProfileColor: lightThemeColors.text,
  topNavUserAvatarBackgroundColor: '#f1f5f7',
  topNavDropDownColor: lightThemeColors.text,
  topNavDropDownBackgroundColor: '#fff',
  topNavDropDownHeaderColor: lightThemeColors.altText,
  topNavDropDownTextColor: lightThemeColors.altText,
  topNavDropDownDividerColor: '#eff2f7',
  topNavDropDownBorderColor: 'rgba(0,0,0,.15)',
  topNavDropDownBoxShadow: 'rgb(0 0 0 / 10%)',
  topNavDropDownIconBackgroundColor: lightThemeColors.linkColorDark,
  topNavDropDownIconColor: '#fff',
  //footer
  footerColor: '#74788d',
  footerBackgroundColor: '#fff',
  // breadcrumps
  breadcrumpLinkColor: lightThemeColors.text,
  // main content
  cardBackgroundColor: '#fff',
  mainContentTabColor: '#696D8C',
  mainContentTabColorActive: '#252B3B',
  // tables
  tableSettingsPanelColor: lightThemeColors.text,
  tableSettingsPanelBackgroundColor: '#fff',
  toggleSwitchBackgroundColor: '#fff',
  toggleSwitchBackgroundColorChecked: lightThemeColors.primaryColor,
  toggleSwitchBorderColor: 'rgba(0,0,0,.25)',
  toggleSwitchBackgroundColorBorderColorChecked: lightThemeColors.primaryColor,
  globalFilterInputColor: lightThemeColors.text,
  globalFilterBackgroundColor: '#fff',
  globalFilterBorderColor: '#ced4da',
  globalFilterInputColorFocus: lightThemeColors.text,
  globalFilterBackgroundColorFocus: '#fff',
  globalFilterBorderColorFocus: '#b1bbc4',
  tableResponsiveColor: lightThemeColors.text,
  tableResponsiveBorderColor: '#eff2f7',
  tableResponsiveStrippedColor: '#f2f2f2',
  tableColor: lightThemeColors.text,
  tableBorderColor: '#eff2f7',
  tableStickyHeaderBackgroundColor: '#fff',
  tableHeaderBackgroundColor: 'transparent',
  tableBoxShadowInsetColor: 'transparent',
  tableStrippedColor: '#f2f2f2',
  tableBackgroundColor: '#fff',
  tableUnreadColor: '#fff',
  tableUnreadBackgroundColor: lightThemeColors.linkColorDark,
  tableColumnFilterInputColor: lightThemeColors.text, 
  tableColumnFilterInputBorderColor: '#D5DBE2',
  tableColumnFilterInputBackgroundColor: '#fff',
  tableColumnFilterInputColorFocus: lightThemeColors.text, 
  tableColumnFilterInputBorderColorFocus: '#b1bbc4',
  tableColumnFilterInputBackgroundColorFocus: '#fff',
  paginationGoToPageColor: lightThemeColors.text,
  paginationGoToPageBackgroundColor: '#fff',
  paginationGoToPageBorderColor: '#D5DBE2',
  paginationGoToPageColorFocus: lightThemeColors.text,
  paginationGoToPageBackgroundColorFocus: '#fff',
  paginationGoToPageBorderColorFocus: '#b1bbc4',
  // buttons
  outlineButtonColor: lightThemeColors.text,
  outlineButtonBorderColor: lightThemeColors.primaryColor,
  primaryButtonColor: '#fff',
  primaryButtonBackgroundColor: lightThemeColors.primaryColor,
  primaryButtonBorderColor: lightThemeColors.primaryColor,
  lightButtonColor: '#000',
  lightButtonBackgroundColor: '#eff2f7',
  lightButtonBorderColor: '#eff2f7',
  greyButtonColor: '#fff',
  greyButtonBackgroundColor: '#74788d',
  greyButtonBorderColor: '#74788d',
  //login page
  loginImageBackgroundColor: '#fff',
  loginFormBackgroundColor: '#fff',
  loginHeaderSubTitleColor: '#74788d',
  loginIconColor: lightThemeColors.primaryColor,
  loginFormInputColor: lightThemeColors.text,
  loginFormInputBackgroundColor: '#fff',
  loginFormInputBorderColor: '#ced4da',
  loginButtonColor: '#fff',
  loginButtonBackgroundColor: lightThemeColors.primaryColor,
  loginButtonBorderColor: lightThemeColors.primaryColor,
  //links
  primaryLinkColor: lightThemeColors.primaryColor,
  //Checkboxes
  checkBoxBackgroundColor: '#fff',
  checkBoxBorderColor: '#ddd',
  checkBoxBackgroundColorChecked: lightThemeColors.linkColorDark,
  concentricCircleBackgroundColor: '#fff',
  concentricCircleBackgroundColorChecked: 'grey',
  //notifications red pin
  notificationBellRedPinBackgroundColor: '#ff3d60',
  //dropDown
  dropDownColor: lightThemeColors.text,
  dropDownBorderColor: '#ced4da',
  dropDownOptionColor: 'black',
  dropDownOptionColorSelected: 'lightgrey',
  //settings
  settingsGroupBorderColor: '#efefef'
}
export const lagoTheme = {
  bodyBackgroundColor: lagoThemeColors.bodyBackgroundColor,
  //sidebar
  sidebarWidth: '262px',
  sidebarLinkColor: lagoThemeColors.linkColorDark,
  sidebarLinkColorHover: lagoThemeColors.linkColorHoverDark,
  sidebarLinkColorActive: lagoThemeColors.linkColorActiveDark,
  sidebarBackgroundColor: lagoThemeColors.sidebarBackgroundColor,
  sidebarLinkHeaderColor: lagoThemeColors.navigationHeader,
  //top nav
  topNavBackgroundColor: lagoThemeColors.topNavBackgroundColor,
  topNavIconColor: '#636E75',
  topNavSearchFieldBackgroundColor: '#F1F5F7', 
  topNavSearchFieldColor: '#636E75',
  topNavSearchPlaceholderColor: '#74788D',
  topNavUserProfileColor: lagoThemeColors.text,
  topNavUserAvatarBackgroundColor: '#f1f5f7',
  topNavDropDownColor: lagoThemeColors.text,
  topNavDropDownBackgroundColor: '#fff',
  topNavDropDownHeaderColor: lagoThemeColors.altText,
  topNavDropDownTextColor: lagoThemeColors.altText,
  topNavDropDownDividerColor: '#eff2f7',
  topNavDropDownBorderColor: 'rgba(0,0,0,.15)',
  topNavDropDownBoxShadow: 'rgb(0 0 0 / 10%)',
  topNavDropDownIconBackgroundColor: lagoThemeColors.linkColorDark,
  topNavDropDownIconColor: '#fff',
  //footer
  footerColor: '#74788d',
  footerBackgroundColor: '#fff',
  // breadcrumps
  breadcrumpLinkColor: lagoThemeColors.text,
  // main content
  cardBackgroundColor: '#fff',
  titleCardBackgroundColor: '#3D5A80',
  mainContentTabColor: '#696D8C',
  mainContentTabColorActive: '#252B3B',
  // tables
  tableSettingsPanelColor: lagoThemeColors.text,
  tableSettingsPanelBackgroundColor: '#fff',
  toggleSwitchBackgroundColor: '#fff',
  toggleSwitchBackgroundColorChecked: lagoThemeColors.primaryColor,
  toggleSwitchBorderColor: 'rgba(0,0,0,.25)',
  toggleSwitchBackgroundColorBorderColorChecked: lagoThemeColors.primaryColor,
  globalFilterInputColor: lagoThemeColors.text,
  globalFilterBackgroundColor: '#fff',
  globalFilterBorderColor: '#ced4da',
  globalFilterInputColorFocus: lagoThemeColors.text,
  globalFilterBackgroundColorFocus: '#fff',
  globalFilterBorderColorFocus: '#b1bbc4',
  tableResponsiveColor: lagoThemeColors.text,
  tableResponsiveBorderColor: '#eff2f7',
  tableResponsiveStrippedColor: '#f2f2f2',
  tableColor: lagoThemeColors.text,
  tableBorderColor: '#eff2f7',
  tableStickyHeaderBackgroundColor: '#fff',
  tableHeaderBackgroundColor: 'transparent',
  tableBoxShadowInsetColor: 'transparent',
  tableStrippedColor: '#f2f2f2',
  tableBackgroundColor: '#fff',
  tableUnreadColor: '#fff',
  tableUnreadBackgroundColor: lagoThemeColors.linkColorDark,
  tableColumnFilterInputColor: lagoThemeColors.text, 
  tableColumnFilterInputBorderColor: '#D5DBE2',
  tableColumnFilterInputBackgroundColor: '#fff',
  tableColumnFilterInputColorFocus: lagoThemeColors.text, 
  tableColumnFilterInputBorderColorFocus: '#b1bbc4',
  tableColumnFilterInputBackgroundColorFocus: '#fff',
  paginationGoToPageColor: lagoThemeColors.text,
  paginationGoToPageBackgroundColor: '#fff',
  paginationGoToPageBorderColor: '#D5DBE2',
  paginationGoToPageColorFocus: lagoThemeColors.text,
  paginationGoToPageBackgroundColorFocus: '#fff',
  paginationGoToPageBorderColorFocus: '#b1bbc4',
  // buttons
  outlineButtonColor: lagoThemeColors.text,
  outlineButtonBorderColor: lagoThemeColors.primaryColor,
  primaryButtonColor: '#fff',
  primaryButtonBackgroundColor: lagoThemeColors.primaryColor,
  primaryButtonBorderColor: lagoThemeColors.primaryColor,
  lightButtonColor: '#000',
  lightButtonBackgroundColor: '#eff2f7',
  lightButtonBorderColor: '#eff2f7',
  greyButtonColor: '#fff',
  greyButtonBackgroundColor: '#74788d',
  greyButtonBorderColor: '#74788d',
  //login page
  loginImageBackgroundColor: '#fff',
  loginFormBackgroundColor: '#fff',
  loginHeaderSubTitleColor: '#74788d',
  loginIconColor: lagoThemeColors.primaryColor,
  loginFormInputColor: lagoThemeColors.text,
  loginFormInputBackgroundColor: '#fff',
  loginFormInputBorderColor: '#ced4da',
  loginButtonColor: '#fff',
  loginButtonBackgroundColor: lagoThemeColors.primaryColor,
  loginButtonBorderColor: lagoThemeColors.primaryColor,
  //links
  primaryLinkColor: lagoThemeColors.primaryColor,
  //Checkboxes
  checkBoxBackgroundColor: '#fff',
  checkBoxBorderColor: '#ddd',
  checkBoxBackgroundColorChecked: lagoThemeColors.linkColorDark,
  concentricCircleBackgroundColor: '#fff',
  concentricCircleBackgroundColorChecked: 'grey',
  //notifications red pin
  notificationBellRedPinBackgroundColor: '#ff3d60',
  //dropDown
  dropDownColor: lagoThemeColors.text,
  dropDownBorderColor: '#ced4da',
  dropDownOptionColor: 'black',
  dropDownOptionColorSelected: 'lightgrey',
  //settings
  settingsGroupBorderColor: '#efefef'
}