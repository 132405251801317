import { saveAs } from 'file-saver';
import { PDFDownloadLink, pdf, Document, Page, Text, View, StyleSheet} from "@react-pdf/renderer";
import ReactPDF from '@react-pdf/renderer';
import React from 'react';
import { JsonToTable } from "react-json-to-table";
import axios from 'axios';
import * as S from './styles'
import MyDocument from './document.js'



import MySelect from "./components/ntua/dropMenu";
import DropdownSelect, { Dropdown, Option } from "./components/ntua/dropdown";

import styled from 'styled-components'
import { useTable } from 'react-table'
import HighRiskEvaluationExample from "./assets/images/final-risk-evaluation-example.png";
import MediumEvaluationExample from "./assets/images/final-medium-risk-evaluation-example.png";
import LowLowEvaluation from "./assets/images/low-low.jpg";
import LowMediumEvaluation from "./assets/images/low-medium.jpg";
import LowHighEvaluation from "./assets/images/low-high.jpg";
import MediumLowEvaluation from "./assets/images/medium-low.jpg";
import MediumMediumEvaluation from "./assets/images/medium-medium.jpg";
import MediumHighEvaluation from "./assets/images/medium-high.jpg";
import HighLowEvaluation from "./assets/images/high-low.jpg";
import HighMediumEvaluation from "./assets/images/high-medium.jpg";
import HighHighEvaluation from "./assets/images/high-high.jpg";

import {NotificationManager} from 'react-notifications';
import { columnsForImpactTablePersonal, columnsForImpactTableInstitutional, 
  columnsForImpactTableSocietal, columnsForImpactLevelFinal
} from './impact-levels';
import { dataForImpactTablePersonal, dataForImpactTableInstitutional, 
  dataForImpactTableSocietal, dataForImpactLevelFinal} from './impact-levels';
import * as P from "./data"
import {
  Button, 
  Form
} from 'react-bootstrap'
import ImpactRadarChart from './impactChart';
import RiskRadarChart from './riskChart';
import riskCell from './riskCell';
import { riskFactorsMitigationTable } from './mitigationActions';
import ScrollToTop from './scrollToTop';
import ScrollToTopButton from "react-scroll-to-top";
import ScrollButton from './scrollToTopButton';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const Styles = styled.div`
  padding: 0.5rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    font-size: 14px;
    background-color:white;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      padding: 0.5rem;
      background-color: #3D5A80;
      color: white;
      text-align: center;
    }
    td {
      
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :first-child {
        max-width:15%;
      }
      :nth-child(2) {
        max-width: 20%;
      }
      :last-child {
        
        border-right: 0;
      }
    }
  }
`
const TableDiv = styled.div`
  padding: 0.5rem 0rem;

  table {
    
    font-size: 14px;
    background-color:white;
    

    th {
      padding: 0.5rem;
      border-style: solid;
      border-color: #EE6C4D;
      border-width: 1px 0px;
      
      color: #3D5A80;
      text-align: left;
      text-transform: uppercase;
      font-weight: bold;
    }
    td {

      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #ececec;
      
      :first-child {
        
        font-weight: bold;
        min-width: 120px;
        
      }
      :nth-child(2) {
        
      }
      :last-child {
        
        border-right: 0;
      }
    }

    li {
      list-style-position: outside;
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table style={{width:'100%'}} {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default class Demo2 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formValues: [{ url: "" }],
      API_URL: '',
      data:'',
      settings: props.settings,
      urlSubmitted: false,
      sharingPartnerInfoArray: [],
      datasetsInfoArray: [],
      sharingEntityType: '',
      sharingAgreementType: '',
      datasetModelOptions: [
        
        { value: 'dataset', label: 'Dataset'},
        
      ],
      datasetModelSelected: false,
      selectedDatasetModel: { value: 'dataset', label: 'Dataset'},
      purposeOfSharingOptions: [
        { value: 'general-research', label: 'General Research'},
        { value: 'tool-development', label: 'Tool Development'},
        { value: 'ai-model', label: 'AI Model Training'}
      ],
      selectedPurposeOfSharing: { value: 'ai-model', label: 'AI Model Training'},
      

      dataTypeOptions: [
        { value: 'Financial transactions', label: 'Financial transactions'},
        { value: 'Credit card / bank account numbers', label: 'Credit card / bank account numbers'},
        { value: 'Health data,', label: 'Health data'},
        { value: 'Web browsing data,', label: 'Web browsing data'},
        { value: 'Biometric identifiers', label: 'Biometric identifiers'},
        { value: 'Device identifiers and serial numbers', label: 'Device identifiers and serial numbers'},
        { value: 'Geolocation data', label: 'Geolocation data'},
        { value: 'IP-address', label: 'IP-address'},
        { value: 'Camera fingerprints', label: 'Camera fingerprints'},
        { value: 'License Plates', label: 'License plates'},
        { value: 'Social security number', label: 'Social security number'},
        { value: 'Street Address, city', label: 'Street Address, city'},
        { value: 'E-mail addresses', label: 'E-mail addresses'},
        { value: 'Telephone/Fax numbers', label: 'Telephone/Fax numbers'},
        { value: 'Date of birth', label: 'Date of birth'},
        { value: 'Names', label: 'Names'},
        { value: 'Face', label: 'Face'},
        { value: 'Personal identification code', label: 'Personal identification code'},
        { value: 'Crime statistics/information', label: 'Crime statistics/information'},
        { value: 'Other Personal Data', label: 'Other Personal Data'},
        { value: 'Other Non-Personal FCT Data', label: 'Other Non-Personal FCT Data'},
      ],
      old_dataTypeOptions: [
        { value:'Non-Personal Data', label:'Non-Personal Data'},
        { value:'Personal Info', label:'Personal Info'},
        //{ value:'Physical Characteristics', label:'Physical Characteristics' },
        { value:'Social Activity', label:'Social Activity' },
        { value:'Location Data', label:'Location Data' },
        { value:'Online Activity', label:'Online Activity' },
        { value:'Financial Info', label:'Financial Info' },
        { value:'Health Data', label:'Health Data' },
        { value:'Other Personal Data', label:'Other Personal Data'},
        
      ],
      selectedDataTypes: [{ value: 'License Plates', label: 'License plates'}],
      specialDataTypeOptions: [
        { value: 'No Special Data', label: 'No Special Data'},
        { value: 'Ratial/Ethnic Info', label: 'Ratial/Ethnic Info'},
        { value: 'Sexual Orientation', label: 'Sexual Orientation'},
        { value: 'Political Views', label: 'Political Views'},
        { value: 'Religious Views', label: 'Religious Views'},
        { value: 'Political Party Memberships', label: 'Political Party Memberships'},
        { value: 'Genetic / Biometric Data', label: 'Genetic / Biometric Data'},
        { value: 'Criminal Past', label: 'Criminal Past'},
        { value: 'Children', label: 'Children'},
      ],
      selectedSpecialDataTypes: [{ value: 'No Special Data', label: 'No Special Data'}],
      dataFormatOptions: [
        { value: 'Text', label: 'Text'},
        { value: 'Images', label: 'Images'},
        { value: 'Video', label: 'Video'},
        { value: 'Audio', label: 'Audio'},
      ],
      selectedDataFormat: [{ value: 'Images', label: 'Images'}],
      /*
      dataFormatOptions: [
        <Option key='text' value='Text' />,
        <Option key='images' value='Images' />,
        <Option key='video' value='Video' />,
        <Option key='audio' value='Audio' />,
      ],
      selectedDataFormat: 'Text',
      */
      datasetNatureOptions: [
        { value: 'real', label: 'Real'},
        { value: 'synthetic', label: 'Synthetic'},
        { value: 'hybrid', label: 'Hybrid'},
      ],
      selectedDatasetNature: { value: 'real', label: 'Real'},
      datasetAnonymizationOptions: [
        <Option key='no' value='No' />,
        <Option key='yes' value='Yes' />,
        
      ],
      
      
      federatedLearningOptions: [
        <Option key='no' value='No' />,
        <Option key='yes' value='Yes' />,
        
      ],
      
      anonymizationToolTrlOptions: [
        <Option key='8to9' value='8 - 9' />,
        <Option key='5to7' value='5 - 7' />,
        <Option key='below5' value='Below 5' />,
      ],
      anonymizationToolOptions: [
        <Option key='license-plate' value='Other' />,
        <Option key='license-plate' value='License plates - VICOM' />,
        <Option key='license-plate' value='Faces - CERTH/ATOS' />,
        <Option key='license-plate' value='Usernames - CENTRIC' />,
      ],
      
      crimeDomainOptions: [
        <Option key='cybercrime' value='Cybercrime' />,
        <Option key='drug-trafficking' value='Drug trafficking' />,
        <Option key='firearms' value='Firearms' />,
        <Option key='counterfeiting' value='Counterfeiting' />,
        <Option key='environmental-crime' value='Environmental crime' />,
        <Option key='trafficking' value='Human Trafficking' />,
      ],
      selectedCrimeDomain: 'Cybercrime',
      usagePolicyOptions: [
        <Option key='open' value='Open' />,
        <Option key='strict' value='Strict' />,
        <Option key='very-strict' value='Very Strict' />,
      ],
      selectedUsagePolicy: 'Strict',
      organizationEntityOptions: [
        {value: 'lea', label: 'LEA'},
        {value: 'research', label: 'Research Institute / University'},
        {value: 'company', label: 'Company'},
        
      ],
      requestingEntityOptions: [
        {value: 'lea', label: 'LEA'},
        {value: 'research', label: 'Research Institute / University'},
        {value: 'company', label: 'Company'},
        {value: 'individual', label: 'Individual'},
        {value: 'other', label: 'Other'}
        
      ],
      
      selectedOrganizationEntityType: {value: 'lea', label: 'LEA'},
      selectedRequestingEntityType: {value: 'research', label: 'Research Institute / University'},
      basicDropdownOptions: [
        <Option key='no' value='No' />,
        <Option key='yes' value='Yes' />,
      ],
      percentageDropdownOptions: [
        <Option key='100' value='100%' />,
        <Option key='over95' value='95% - 100%' />,
        <Option key='90to95' value='90% - 95%' />,
        <Option key='below90' value='below 90%' />,
      ],

      effortDropdownOptions: [
        <Option key='minor' value='Minor' />,
        <Option key='major' value='Major' />,
      ],
      impactDropdownOptions: [
        <Option key='none' value='None' />,
        <Option key='low' value='Low' />,
        <Option key='medium' value='Medium' />,
        <Option key='high' value='High' />,
        <Option key='veryhigh' value='Very High' />,
      ],
      finalImpactDropdownOptions: [
        <Option key='no' value='No' />,
        <Option key='low' value='Low' />,
        <Option key='medium' value='Medium' />,
        <Option key='high' value='High' />,
        <Option key='veryhigh' value='Very High' />,
      ],
      threatLevelOptions: [
        <Option key='low' value='Low' />,
        <Option key='medium' value='Medium' />,
        <Option key='high' value='High' />,
      ],
      mitigationTable: {
        m1: {code: "m1", threat: 'Personal data exposure',  mitigationAction: "Use anonymization on the dataset to limit personal data exposure.", explanation: "The dataset contains personal data which according to GDPR must be protected. Sharing personal data without anonymization may lead to personal data exposure."},
        m2: {code: "m2", threat: 'Anonymization not verified by 3rd party',  mitigationAction: "Use a tool that is verified to ensure anonymization is performed properly.", explanation: "The dataset is anonymized but the anonymization tool is not verfiied by a independent third party. Verification ensures that the anonymization tool does what is supposed to do."},
        m3: {code: "m3", threat: 'Special data exposure',  mitigationAction: "Use anonymization on the dataset to limit special data exposure.", explanation: "The dataset contains special cases of personal data as described in GDPR. These special personal data must be protected with extra care."},
        m4: {code: "m4", threat: 'Data transfer method not secure',  mitigationAction: "Use a transfer method with end-to-end encryption.", explanation: "The method used for the tranfer of the dataset to the requester is not secure. Please use a method with end-to-end ecryption."},
        m5: {code: "m5", threat: 'Data access not protected/secured',  mitigationAction: "The requester should implement stricter data access protection mechanisms. (Data access must be restricted to only the appropriate personel. Access to specialists should be renewed at certain times per year for example, logged activites should be observed occasionally. In some cases, specialists have too great access rights and this is unnecessary for their tasks, thus they can access more data than they should. Also, accounts should not be shared between specialists etc.)", explanation: "Access to the dataset on the premises of the requester is not restricted/controled adequately."},
        m6: {code: "m6", threat: 'People not educated in handling sensitive data',  mitigationAction: "The requester should make use of DPOs (data protection officers) plus appropriate training in different related areas. This will help mitigate the risk of handling sensitive data.", explanation: "The people handling the datasets on the premises of the requester have not received adepuate training for handling sensitive data."},
        m7: {code: "m7", threat: 'No cybersecurity protocols',  mitigationAction: "Ask the requester to assess their cybersecurity with an external auditor and make the necessary changes in their infrastructure.", explanation: "The requester does not implement cybersecurity protocols on their premises."},
        m8: {code: "m8", threat: 'No data governance framework',  mitigationAction: "Ask the requester to implement a data governance framework. This will help mitigate the risk of accidental data exposure or data misappropriation", explanation: "The requester does not implement a data governance framework on their premises."},
        m9: {code: "m9", threat: 'No license agreement',  mitigationAction: "Create a license agreement with the requester.", explanation: "There is no license agreement with the requester."},
        m10: {code: "m10", threat: 'Dataset open to distribution',  mitigationAction: "Change the license to limit dataset distribution.", explanation: "The license with the requester allows the dataset to be distributed freely to third parties."},
        m11: {code: "m11", threat: 'Dataset or parts of it may be included in scientific publications',  mitigationAction: "Change the license to limit publication of parts of the dataset.", explanation: "The license with the requester allows parts of the dataset to be published in scientific publications."},
        m12: {code: "m12", threat: 'License not permanent',  mitigationAction: "Add an expiration date to the license.", explanation: "The license with the requester has no expiration date."},
        m13: {code: "m13", threat: 'Evidence of deletion',  mitigationAction: "Request evidence of deletion from the requester when the license expires.", explanation: "The requester is not obligated to submit evidence of data deletion after the license expires."},
        m14: {code: "m14", threat: 'Dataset can be used in any domain/industry',  mitigationAction: "Restrict dataset usage to specific domains/industries based on the plans of the requester.", explanation: "The license with the requester allows the dataset to be used in all domains/industries without restrictions."},
      },
      otherEntitySelected: false,
      selectedDistributionRestrictions: 'No',
      selectedDistributionModifications: 'No',
      selectedLicenseModifications: 'No',
      selectedModelTrainingOptions: 'No',
      selectedScientificPublicationOptions: 'No',
      selectedEvidenceDeletionOptions: 'No',
      selectedSensitiveContextOptions: 'No',
      selectedDatasetPersonalDataOptions: 'No',
      selectedLicensePermenant: 'Yes',
      selectedSharingFrom: 'LEA',
      selectedSharingTo:'LEA',
      finalRiskCalculation: 'Error',
      
      sectionGeneralActive: true,
      sectionGeneralSub1Active: true,
      sectionGeneralSub2Active: false,
      sectionImpactActive: false,
      sectionImpactSub1Active: false,
      sectionImpactSub2Active: false,
      sectionImpactSub3Active: false,
      sectionImpactSub4Active: false,
      sectionImpactSub5Active: false,
      sectionImpactSub6Active: false,
      sectionImpactSub7Active: false,
      sectionImpactSub8Active: false,
      sectionImpactSub9Active: false,
      sectionImpactSub10Active: false,
      sectionThreatsActive: false,
      sectionThreatsSub1Active: false,
      sectionThreatsSub2Active: false,
      sectionThreatsSub3Active: false,
      sectionThreatsSub4Active: false,
      sectionThreatsSub5Active: false,
      sectionThreatsSub6Active: false,
      sectionEvaluationActive: false,
      sectionEvaluationSub1Active: false,
      sectionEvaluationSub2Active: false,
      riskCalculated: false,
      impactOnIndividuals: false,
      impactOnInstitutionalReputation: false,
      impactOnInstitutionalLegal: false,
      impactOnSocietal: false,
      impactOnEthical: false,
      impactOnEconomical: false,
      impactOnTechnical: false,
      impactOnOther: false,
      impactLevelOnIndividuals: 'None',
      finalImpactLevelOnIndividuals: 'No',
      impactLevelOnInstitutionalRep: 'None',
      finalImpactLevelOnInstitutionalRep: 'No',
      impactLevelOnInstitutionalLegal: 'None',
      finalImpactLevelOnInstitutionalLegal: 'No',
      impactLevelOnSocietal: 'None',
      finalImpactLevelOnSocietal: 'No',
      impactLevelOnEthical: 'None',
      finalImpactLevelOnEthical: 'No',
      impactLevelOnEconomical: 'None',
      finalImpactLevelOnEconomical: 'No',
      impactLevelOnTechnical: 'None',
      finalImpactLevelOnTechnical: 'No',
      impactLevelOnOther: 'None',
      finalImpactLevelOnOther: 'No',
      individualsOvercome: 'No',
      levelOfImpactOnIndividuals: 'Minor',
      datasetContainsPersonalData: 'Yes',
      datasetContainsSensitiveData: 'No',
      levelOfLegalImplications: 'Minor',
      organizationContinueOperations: 'No',
      organizationLossOfTrust: 'No',
      //dataTypeOptions: ['name, address', 'other personal data', 'other non-personal data'];
      impactLevelCalculated: false,
      selectedDatasetAnonymizationOption: 'No',
      isDataAnonymized: 'No',
      anonymizationPercentage: '100%',
      isAnonymizationToolVerified: 'No',
      anonymizationThreatLevel: 'Low',
      areThereDataAnnotations: 'No',
      dataAnnotationsThreatLevel: 'Low',
      dataTransferSecure: 'No',
      dataTransferThreatLevel: 'Low',
      dataAccessProtection: 'No',
      dataAccessProtectionThreatLevel: 'Low',
      peopleEducation: 'No',
      peopleEducationThreatLevel: 'Low',
      institutionCybersecurity: 'No',
      institutionCybersecurityThreatLevel: 'Low',
      institutionGovernance: 'No',
      institutionGovernanceThreatLevel: 'Low',
      institutionDPO: 'No',
      institutionWorkLocationSecure: 'No',
      institutionWorkLocationSecureThreatLevel: 'Low',
      legalLicense: 'No',
      legalDistribution: 'No',
      legalDistributionModified: 'No',
      legalPublications: 'No',
      legalPermenant: 'No',
      legalEvidenceOfDeletion: 'No',
      legalAnyDomain: 'No',
      legalSubpopulations: 'No',
      legalEthicalReview: 'No',
      legalLegalReview: 'No',
      finalIndividualImpactLevel: 'Low',
      finalInstitutionalImpactLevel: 'Low',
      threatLevelCalculated: false,
      finalTechnologyThreatLevel: 'Low',
      finalPeopleThreatLevel: 'Low',
      finalInstitutionalThreatLevel: 'Low',
      finalLegalThreatLevel: 'Low',
      finalOtherThreatLevel: 'Low',

      finalImpactEvaluation: 'Low',
      finalThreatEvaluation: 'Low',
      finalRiskScoreEvaluation: 'Low',

      finalRiskTable: [],
      finalMitigationTable: [],

      //selectedRequestingEntity: 'Other',
      generalInfo: {
        'riskAssessmentTitle': 'Sharing licenseV4 dataset to ICCS',
        'yourName': 'Christos Baloukas',
        'nameOfYourOrganization': 'HPOL',
        'comments': 'In this demo assessment, we examine a hypothetical scenario for a FCT data exchange between ICCS (Research Institute) and Hellenic Police (LEA). ICCS requested images from traffic cameras to train an AI tool that will follow the movement of vehicles through the city.',
        'purposeOfSharing': 'Collaboration for project XXX',
        'nameOfRequestingEntity': 'ICCS',
        'typeOfRequestingEntity': 'Research Institute / University',
        'customTypeOfRequestingEntity': '',
        'objectOfAssessment': 'Dataset',
        
      },
      generalEmptyFields: false,
      impactEmptyFields: false,
      threatsEmptyFields: false,

      riskAssessmentData: {},
      impactLevels: {
        'confidentiality': {
          'checked' : true,
          'levels': {
            'personal': {
              'checked': true,
              'level': 'Medium',
              'comments': 'If images with personal license plates and the corresponding cars leak, this may have a Medium impact because, for example, criminals may copy the license plate of a person along with their car type and implicate them in criminal activities, therefore creating inconveniences for the people.'
            },
            'institutional': {
              'checked': true,
              'level': 'High',
              'comments': "If the dataset is leaked, it may induce a loss of trust to the LEA which will affect its operations as people may object to LEA's using traffic video cameras. This may need serious adjustments to operations and may require large amounts of time and funds to overcome. This constitutes a High impact. Also, if the license agreement between the LEA and the research institute does not cover GDPR, this may create legal issues and loss of funds, which is a Medium impact according to Institutional Impact Table. So the final impact level for the institution is High"
            },
            'societal': {
              'checked': true,
              'level': 'Medium',
              'comments': 'If there is a dataset leak, people may display distrust towards LEAs, ministries and government in general, which is a Medium impact level according to the Societal Impact Table'
            },
          }
        },
        'bias': {
          'checked' : true,
          'levels': {
            'personal': {
              'checked': true,
              'level': 'None',
              'comments': "Since the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. This does not have any personal impact. "
            },
            'institutional': {
              'checked': true,
              'level': 'Low',
              'comments': "Since the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. In that case, maybe ICCS will turn elsewhere for data and the whole process will be a wast of time. This can result in minor Institutional impact for HPOL because of the lost time and resources."
            },
            'societal': {
              'checked': true,
              'level': 'None',
              'comments': "Since, the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. In that case, maybe ICCS will turn elsewhere for data and the whole process will be a wast of time. This will have no societal impact."
            },
          }
        },
        'legal': {
          'checked' : true,
          'levels': {
            'personal': {
              'checked': true,
              'level': 'None',
              'comments': 'Not complying with the legal frameworks will have impact on just our organization not on people.'
            },
            'institutional': {
              'checked': true,
              'level': 'Medium',
              'comments': 'Since we are sharing data license plates that are considered personal data, we need to comply with GDPR. Failing to do so could result in minor legal fines, which constitutes a medium institutional impact accroding to the impact tables.'
            },
            'societal': {
              'checked': true,
              'level': 'None',
              'comments': 'Not complying with the legal frameworks will have an impact on our organization but our operations will continue as is and therefore our job towards protecting society will continue without disruptions. No societal impact is predicted.'
            },
          }
        },
        'misuse': {
          'checked' : true,
          'levels': {
            'personal': {
              'checked': true,
              'level': 'High',
              'comments': 'The data contain private vehicles doing their daily routines. If misused the data could be used to track certain individuals through their daily routines, or identify their homes, workplaces etc. This is a potential violation of privacy, which is a High personal impact.'
            },
            'institutional': {
              'checked': true,
              'level': 'Medium',
              'comments': 'Misusing traffic camera data could lead to protests about HPOL storing traffic data or even traffic cameras in general. This may result in minor loss of trust and a change in our operations to accommodate for restrictions in traffic camera use, which is a Medium institutional impact. '
            },
            'societal': {
              'checked': true,
              'level': 'High',
              'comments': 'The AI model that ICCS will create may be used for private surveillance purposes by HPOL if not used appropirately, which could constitute a significant violation of privacy that will have significant impact on our society in general. Large demonstrations and major distrust towards government agencies can be foreseen in that case, which is a High societal impact.'
            },
          }
        }
      },
      threatLevels: {
        'privacy': {
          'checked': true,
          'answers': {
            'anonymization': {
              'answer': 'No',
              'comments': 'The requester needs the original license plates to train the AI model.',
              'checked': true,
            },
            'verified': {
              'answer': '',
              'comments': '',
              'checked': false,
            },
            'manualVerification': {
              'answer': '',
              'comments': '',
              'checked': false,
            },
            'complete': {
              'answer': '',
              'comments': "",
              'checked': false,
            },
          },
          'level': 'N/A',
        },
        'bias': {
          'checked': true,
          'answers': {
            'biasChecked': {
              'answer': 'Yes',
              'comments': 'The images do not contain personal information (other than license plates), so they do not exhibit any bias towards people.',
              'checked': true,
            },
            'qualityChecked': {
              'answer': 'Yes',
              'comments': 'All images are clear with proper brightness and contrast levels.',
              'checked': true,
            },
            'purposeChecked': {
              'answer': 'Yes',
              'comments': 'The images have been checked manually that they show different angles of cars and a variety of cars and congestion situations. Bias related to people is not applicable here.',
              'checked': true,
            },
            'toolUsed': {
              'answer': 'Yes',
              'comments': 'The LAGO DQ tool has been used to verify the quality of the individual images.',
              'checked': true,
            },
            'verified': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
          },
          'level': 'Low',
        },
        'transfer': {
          'checked': true,
          'answers': {
            'electronic': {
              'answer': 'Electronic',
              'comments': 'The data will be sent through a service provided by ICCS.',
              'checked': true,
            },
            'security': {
              'answer': 'Yes',
              'comments': 'The transfer method uses end-to-end encryption ',
              'checked': true,
            },
            'thirdPartyServer': {
              'answer': 'No',
              'comments': "The data are transferred directly to ICCS's servers.",
              'checked': true,
            },
            'portable': {
              'answer': 'No',
              'comments': 'The data are transferred electronically.',
              'checked': true,
            },
            'bestPractices': {
              'answer': 'Yes',
              'comments': 'The data are transferred electronically.',
              'checked': true,
            },
          },
          'level': 'Low',
          },
        'cybersecurity': {
          'checked': true,
          'answers': {
            'standard': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'securityMeasures': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'accessRestrictions': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'lifecycle': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'experience': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'thirdPartyUsers': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'audit': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'cyberattack': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
          },
        'level': 'Medium',
        },
        'legalUnauthorized': {
          'checked': true,
          'answers': {
            'licenseExist': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'dataDistribution': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'workDistribution': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'publish': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'permanent': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'evidence': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'domain': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
          },
        'level': 'Low',
        },
        'legalCompliance': {
          'checked': true,
          'answers': {
            'clausesPersonal': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'storageOutside': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'fctregulations': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            }
          },
        'level': 'Low',
        },
        'misuse': {
          'checked': true,
          'answers': {
            'surveillance': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'manipulation': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            'endanger': {
              'answer': 'Yes',
              'comments': '',
              'checked': true,
            },
            'generalPurpose': {
              'answer': 'No',
              'comments': '',
              'checked': true,
            },
            
          },
        'level': 'High',
        },
        "total": {
          "unauthorized": '',
          "bias": '',
          "legal": '',
          "misuse": '',
        },
      },
      riskLevel: {
        "unauthorized": {
          "personal": '',
          "personalScore": '',
          "institutional": '',
          "institutionalScore": '',
          "societal": '',
          "societalScore": '',
          "total": '',
          "totalScore": '',
        },
        "bias": {
          "personal": '',
          "personalScore": '',
          "institutional": '',
          "institutionalScore": '',
          "societal": '',
          "societalScore": '',
          "total": '',
          "totalScore": '',
        },
        "legal": {
          "personal": '',
          "personalScore": '',
          "institutional": '',
          "institutionalScore": '',
          "societal": '',
          "societalScore": '',
          "total": '',
          "totalScore": '',
        },
        "misuse": {
          "personal": '',
          "personalScore": '',
          "institutional": '',
          "institutionalScore": '',
          "societal": '',
          "societalScore": '',
          "total": '',
          "totalScore": '',
        },
        "total": {
          "personal": '',
          "personalScore": '',
          "institutional": '',
          "institutionalScore": '',
          "societal": '',
          "societalScore": '',
          "total": '',
          "totalScore": '',
        },
      },
      mitigationActions: [],
      datasetSectionsNewMenu: {
        sectionDefinition: {
            title: 'Definition and Context of the Sharing Operation',
            id: '1',
            active: true,
            visited: true,
            enabled: true,
        },
        sectionImpact: {

        }
      },
      datasetSectionsMenu: [
        {
          title: 'Definition and Context of the Sharing Operation',
          id: '1',
          active: true,
          visited: true,
          enabled: true,
        },
        {
          id: '2',
          title: 'Impact Evaluation',
          visited: true,
          active: false,
          enabled: true,
          subNav: [
            {
              id: '2a',
              title: 'Unauthorized Access (loss of confidentiality)',
              visited: true,
              active: false,
              enabled: true,
            },
            {
              id: '2b',
              title: 'Low Quality Dataset',
              visited: true,
              active: false,
              enabled: true,
            },
            {
              id: '2c',
              title: 'Legal Non-Compliance',
              visited: true,
              active: false,
              enabled: true,
            },
            {
              id: '2d',
              title: 'Data Misuse',
              visited: true,
              active: false,
              enabled: true,
            },
            {
              id: '2e',
              title: 'Overall Impact Evaluation',
              visited: true,
              active: false,
              enabled: true,
            },
          ]
        },
        {
          id: '3',
          title: 'Threat Analysis',
          visited: true,
          active: false,
          enabled: true,
          subNav: [
            {
              id: '3a',
              title: 'Unauthorized Access',
              visited: true,
              active: false,
              enabled: true,
              subNav: [
                {
                  id: '3a.1',
                  title: 'Privacy Preservation',
                  visited: true,
                  active: false,
                  enabled: true,
                },
                {
                  id: '3a.2',
                  title: 'Data Transfer',
                  visited: true,
                  active: false,
                  enabled: true,
                },
                {
                  id: '3a.3',
                  title: 'Cybersecurity',
                  visited: true,
                  active: false,
                  enabled: true,
                },
                {
                  id: '3a.4',
                  title: 'License Agreement',
                  visited: true,
                  active: false,
                  enabled: true,
                },
              ]
            },
            {
              id: '3b',
              title: 'Low Quality Dataset',
              visited: true,
              active: false,
              enabled: true,
            },
            {
              id: '3c',
              title: 'Legal Non-Compliance',
              visited: true,
              active: false,
              enabled: true,
            },
            {
              id: '3d',
              title: 'Data Misuse',
              visited: true,
              active: false,
              enabled: true,
            },
            {
              id: '3e',
              title: 'Overall Threat Levels',
              visited: true,
              active: false,
              enabled: true,
            }
          ]
        },
        {
          id: '4',
          title: 'Risk Evaluation',
          visited: true,
          active: false,
          enabled: true,
        },
        {
          id: '5',
          title: 'Mitigation Actions',
          visited: true,
          active: false,
          enabled: true,
        },
        {
          id: '6',
          title: 'Assessment Export',
          visited: true,
          active: false,
          enabled: true,
        }
      ],

      theme: {
        riskColors: {
          risk3by3: {
            none: '#327FBE',
            low: '#32BE4E',
            medium: '#BE7132',
            high: '#BE3232'
          },
          risk4by3: {
            none: '#327FBE',
            low: '#32BE4E',
            medium: '#BE7132',
            high: '#BE3232',
            veryHigh: '#EB3030'
          },
        },
      },
      options: {
        riskMatrix: 'risk3by3',
      }
    }

    
    this.readFile = this.readFile.bind(this);
    this.parseAggregatedInfo = this.parseAggregatedInfo.bind(this);
    this.onChangeEntity = this.onChangeEntity.bind(this);
    this.onChangeAgreement = this.onChangeAgreement.bind(this);
    this.handleDatasetModelChange = this.handleDatasetModelChange.bind(this);
    this.handleDataTypeChange = this.handleDataTypeChange.bind(this);
    this.handleSpecialDataTypeChange = this.handleSpecialDataTypeChange.bind(this);
    this.handleDataFormatSelection = this.handleDataFormatSelection.bind(this);
    this.handleDatasetNatureSelection = this.handleDatasetNatureSelection.bind(this);
    this.handleDatasetAnonymizationSelection = this.handleDatasetAnonymizationSelection.bind(this);
    this.handleAnonymizationTrlSelection = this.handleAnonymizationTrlSelection.bind(this);
    this.handleUsagePolicySelection = this.handleUsagePolicySelection.bind(this);
    this.handleSharingFromSelection = this.handleSharingFromSelection.bind(this);
    this.hanldeSharingToSelection = this.hanldeSharingToSelection.bind(this);
    this.handleFederatedLearningSelection = this.handleFederatedLearningSelection.bind(this);
    this.handleLicensePermenantSelection = this.handleLicensePermenantSelection.bind(this);
    this.handleDistributionRestrictions = this.handleDistributionRestrictions.bind(this);
    this.handleAnonymizationToolSelection = this.handleAnonymizationToolSelection.bind(this);
    this.handleLicensePlatesSelection = this.handleLicensePlatesSelection.bind(this);
    this.calculateRisk = this.calculateRisk.bind(this);
    //this.sectionUpdate = this.sectionUpdate.bind(this);
    //this.sectionRefresh = this.sectionRefresh.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.handleImpactSelection = this.handleImpactSelection.bind(this);
    this.calculateImpactLevel = this.calculateImpactLevel.bind(this);
    //this.calculateThreatLevel = this.calculateThreatLevel.bind(this);
    this.hanldeIndividualsOvercome = this.hanldeIndividualsOvercome.bind(this);
    this.handleLevelOfImpactOnIndividual = this.handleLevelOfImpactOnIndividual.bind(this);
    this.handleDatasetContainsPersonalData = this.handleDatasetContainsPersonalData.bind(this);
    this.handleDatasetContainsSensitiveData = this.handleDatasetContainsSensitiveData.bind(this);
    this.handleLevelOfLegalImplications = this.handleLevelOfLegalImplications.bind(this);
    this.handleOrganizationContinueOperations = this.handleOrganizationContinueOperations.bind(this);
    this.handleOrganizationLossOfTrust = this.handleOrganizationLossOfTrust.bind(this);
    this.mapLevelToScore = this.mapLevelToScore.bind(this);
    this.onGeneralInfoChange = this.onGeneralInfoChange.bind(this);
    this.handleImpactLevelSelection = this.handleImpactLevelSelection.bind(this);
    this.handleLegalAnswers = this.handleLegalAnswers.bind(this);
    this.handleInstitutionalThreatsAnswers = this.handleInstitutionalThreatsAnswers.bind(this);
    this.handleTechnologyThreatsAnswers = this.handleTechnologyThreatsAnswers.bind(this);
    this.handlePeopleThreatsAnswers = this.handlePeopleThreatsAnswers.bind(this);
    this.handleFinalThreatChange = this.handleFinalThreatChange.bind(this);
    this.calculateFinalRiskEvaluation = this.calculateFinalRiskEvaluation.bind(this);
    this.exportRiskAssessmentDataToJson = this.exportRiskAssessmentDataToJson.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleSelectedRequestingEntityTypeUpdate = this.handleSelectedRequestingEntityTypeUpdate.bind(this);
    this.handleSelectedOrganizationEntityTypeUpdate = this.handleSelectedOrganizationEntityTypeUpdate.bind(this);
    this.handlePurposeOfSharingChange = this.handlePurposeOfSharingChange.bind(this);
    this.onChangeImpactLevel = this.onChangeImpactLevel.bind(this);
    
    this.onChangeThreatAnswer = this.onChangeThreatAnswer.bind(this);
    this.calculateRiskLevel = this.calculateRiskLevel.bind(this);
    this.impactProbabilityMap = this.impactProbabilityMap.bind(this);
    this.riskLevelColoredSpan = this.riskLevelColoredSpan.bind(this);

    this.clearMenus = this.clearMenus.bind(this);
    this.exportToPDF = this.exportToPDF.bind(this);

    
  }

  exportToPDF () {
    this.setState({
      exportReady: true,
    })
};
  

  riskLevelColoredSpan(level) {
    if (level === 'None') {
      return <span style={{color:this.state.theme.riskColors.risk3by3.none}}>None</span>
    }
    if (level === 'Low') {
      return <span style={{color:this.state.theme.riskColors.risk3by3.low}}>Low</span>
    }
    if (level === 'Medium') {
      return <span style={{color:this.state.theme.riskColors.risk3by3.medium, fontWeight:'bold'}}>Medium</span>
    }
    if (level === 'High') {
      return <span style={{color:this.state.theme.riskColors.risk3by3.high, fontWeight:'bold'}}>High</span>
    }
  }

  impactProbabilityMap(impactLevel, threatLevel) {
    //unauthorized access
    if(impactLevel === 'None' || threatLevel === 'N/A') {
      return 'None';
    }
    if(impactLevel === 'Low' && threatLevel === 'Low') {
      return 'Low';
    }
    if(impactLevel === 'Low' && threatLevel === 'Medium') {
      return 'Low';
    }
    if(impactLevel === 'Low' && threatLevel === 'High') {
      return 'Medium';
    }
    if(impactLevel === 'Medium' && threatLevel === 'Low') {
      return 'Medium';
    }
    if(impactLevel === 'Medium' && threatLevel === 'Medium') {
      return 'Medium';
    }
    if(impactLevel === 'Medium' && threatLevel === 'High') {
      return 'High';
    }
    if(impactLevel === 'High' && threatLevel === 'Low') {
      return 'High';
    }
    if(impactLevel === 'High' && threatLevel === 'Medium') {
      return 'High';
    }
    if(impactLevel === 'High' && threatLevel === 'High') {
      return 'High';
    }
    if(impactLevel === 'Very High' && threatLevel === 'Low') {
      return 'High';
    }
    if(impactLevel === 'Very High' && threatLevel === 'Medium') {
      return 'High';
    }
    if(impactLevel === 'Very High' && threatLevel === 'High') {
      return 'High';
    }
  }
  calculateThreatLevels() {
    let riskUnauthorizedAccessThreatScore = 0;
    let riskUnauthorizedAccessThreatLevel = '';

    if (this.state.threatLevels.privacy.level === 'N/A') {
      //we have 3 categories
      riskUnauthorizedAccessThreatScore = 
        this.mapLevelToScore(this.state.threatLevels.transfer.level) +
        this.mapLevelToScore(this.state.threatLevels.cybersecurity.level) +
        this.mapLevelToScore(this.state.threatLevels.legalUnauthorized.level);
      if (riskUnauthorizedAccessThreatScore > 6) {
        riskUnauthorizedAccessThreatLevel = 'High'
      }
      if (riskUnauthorizedAccessThreatScore <= 6) {
        riskUnauthorizedAccessThreatLevel = 'Medium'
      }
      if (riskUnauthorizedAccessThreatScore <= 3) {
        riskUnauthorizedAccessThreatLevel = 'Low'
      }
      
    } else {
      //we have 4 categories
      riskUnauthorizedAccessThreatScore = 
        this.mapLevelToScore(this.state.threatLevels.privacy.level) +
        this.mapLevelToScore(this.state.threatLevels.transfer.level) +
        this.mapLevelToScore(this.state.threatLevels.cybersecurity.level) +
        this.mapLevelToScore(this.state.threatLevels.legalUnauthorized.level);
      if (riskUnauthorizedAccessThreatScore > 8) {
        riskUnauthorizedAccessThreatLevel = 'High'
      }
      if (riskUnauthorizedAccessThreatScore <= 8) {
        riskUnauthorizedAccessThreatLevel = 'Medium'
      }
      if (riskUnauthorizedAccessThreatScore <= 4) {
        riskUnauthorizedAccessThreatLevel = 'Low'
      }
    }

    let riskBiasThreatLevel = this.state.threatLevels.bias.level;
    let riskLegalThreatLevel = this.state.threatLevels.legalCompliance.level;
    let riskMisuseThreatLevel = this.state.threatLevels.misuse.level;

    let threatLevels = this.state.threatLevels;
    threatLevels.total.unauthorized = riskUnauthorizedAccessThreatLevel;
    threatLevels.total.bias = riskBiasThreatLevel;
    threatLevels.total.legal = riskLegalThreatLevel;
    threatLevels.total.misuse = riskMisuseThreatLevel;

    let riskLevel = this.state.riskLevel;
    riskLevel.unauthorized.personal = this.impactProbabilityMap(this.state.impactLevels.confidentiality.levels.personal.level, threatLevels.total.unauthorized);
    riskLevel.unauthorized.institutional = this.impactProbabilityMap(this.state.impactLevels.confidentiality.levels.institutional.level, threatLevels.total.unauthorized);
    riskLevel.unauthorized.societal = this.impactProbabilityMap(this.state.impactLevels.confidentiality.levels.societal.level, threatLevels.total.unauthorized);

    riskLevel.bias.personal = this.impactProbabilityMap(this.state.impactLevels.bias.levels.personal.level, threatLevels.total.bias);
    riskLevel.bias.institutional = this.impactProbabilityMap(this.state.impactLevels.bias.levels.institutional.level, threatLevels.total.bias);
    riskLevel.bias.societal = this.impactProbabilityMap(this.state.impactLevels.bias.levels.societal.level, threatLevels.total.bias);

    riskLevel.legal.personal = this.impactProbabilityMap(this.state.impactLevels.legal.levels.personal.level, threatLevels.total.legal);
    riskLevel.legal.institutional = this.impactProbabilityMap(this.state.impactLevels.legal.levels.institutional.level, threatLevels.total.legal);
    riskLevel.legal.societal = this.impactProbabilityMap(this.state.impactLevels.legal.levels.societal.level, threatLevels.total.legal);

    riskLevel.misuse.personal = this.impactProbabilityMap(this.state.impactLevels.misuse.levels.personal.level, threatLevels.total.misuse);
    riskLevel.misuse.institutional = this.impactProbabilityMap(this.state.impactLevels.misuse.levels.institutional.level, threatLevels.total.misuse);
    riskLevel.misuse.societal = this.impactProbabilityMap(this.state.impactLevels.misuse.levels.societal.level, threatLevels.total.misuse);
    
    // mitigation actions
    let mitigationActions = [];

    // check unauthorized access - privacy preservation risk factors
    if (threatLevels.privacy.level === 'Medium' || threatLevels.privacy.level === 'High'){
      if (this.state.datasetContainsPersonalData) {
        if (threatLevels.privacy.answers.anonymization.answer !== 'Yes') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[1].riskfactor,
            'action': riskFactorsMitigationTable[1].action
          });
        }
        if (threatLevels.privacy.answers.verified.answer !== 'Yes') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[2].riskfactor,
            'action': riskFactorsMitigationTable[2].action
          });
        }
        if (threatLevels.privacy.answers.complete.answer !== 'Yes') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[3].riskfactor,
            'action': riskFactorsMitigationTable[3].action
          });
        }
      }
      if (this.state.datasetContainsSensitiveData) {
        if (threatLevels.privacy.answers.complete.answer !== 'Yes') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[4].riskfactor,
            'action': riskFactorsMitigationTable[4].action
          });
        }
      }
    }
    // check unauthorized access - data transfer method risk factors
    if (threatLevels.transfer.level === 'Medium' || threatLevels.transfer.level === 'High'){
      if (threatLevels.transfer.answers.electronic.answer === 'Electronic') {
        if (threatLevels.transfer.answers.security.answer === 'No') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[5].riskfactor,
            'action': riskFactorsMitigationTable[5].action
          });
        }
        if (threatLevels.transfer.answers.thirdPartyServer.answer === 'Yes') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[6].riskfactor,
            'action': riskFactorsMitigationTable[6].action
          });
        }
      } else {
        if (threatLevels.transfer.answers.bestPractices.answer === 'No') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[32].riskfactor,
            'action': riskFactorsMitigationTable[32].action
          });
        }
      }
    }
    // check unauthorized access - cybersecurity at the requester risk factors
    if (threatLevels.cybersecurity.level === 'Medium' || threatLevels.cybersecurity.level === 'High'){
      if (threatLevels.cybersecurity.answers.standard.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[7].riskfactor,
          'action': riskFactorsMitigationTable[7].action
        });
      }
      if (threatLevels.cybersecurity.answers.securityMeasures.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[8].riskfactor,
          'action': riskFactorsMitigationTable[8].action
        });
      }
      if (threatLevels.cybersecurity.answers.accessRestrictions.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[9].riskfactor,
          'action': riskFactorsMitigationTable[9].action
        });
      }
      if (threatLevels.cybersecurity.answers.lifecycle.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[10].riskfactor,
          'action': riskFactorsMitigationTable[10].action
        });
      }
      if (threatLevels.cybersecurity.answers.experience.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[11].riskfactor,
          'action': riskFactorsMitigationTable[11].action
        });
      }
      if (threatLevels.cybersecurity.answers.thirdPartyUsers.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[12].riskfactor,
          'action': riskFactorsMitigationTable[12].action
        });
      }
      if (threatLevels.cybersecurity.answers.audit.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[13].riskfactor,
          'action': riskFactorsMitigationTable[13].action
        });
      }
      if (threatLevels.cybersecurity.answers.cyberattack.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[14].riskfactor,
          'action': riskFactorsMitigationTable[14].action
        });
      }
    }
    // check unauthorized access - license agreement with the requester risk factors
    if (threatLevels.legalUnauthorized.level === 'Medium' || threatLevels.legalUnauthorized.level === 'High'){
      if (threatLevels.legalUnauthorized.answers.licenseExist.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[15].riskfactor,
          'action': riskFactorsMitigationTable[15].action
        });
      }
      if (threatLevels.legalUnauthorized.answers.dataDistribution.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[16].riskfactor,
          'action': riskFactorsMitigationTable[16].action
        });
      }
      if (threatLevels.legalUnauthorized.answers.workDistribution.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[17].riskfactor,
          'action': riskFactorsMitigationTable[17].action
        });
      }
      if (threatLevels.legalUnauthorized.answers.publish.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[18].riskfactor,
          'action': riskFactorsMitigationTable[18].action
        });
      }
      if (threatLevels.legalUnauthorized.answers.permanent.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[19].riskfactor,
          'action': riskFactorsMitigationTable[19].action
        });
      }
      if (threatLevels.legalUnauthorized.answers.evidence.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[20].riskfactor,
          'action': riskFactorsMitigationTable[20].action
        });
      }
      if (threatLevels.legalUnauthorized.answers.domain.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[21].riskfactor,
          'action': riskFactorsMitigationTable[21].action
        });
      }
    }
    // data quality risk factors
    if (threatLevels.bias.level === 'Medium' || threatLevels.bias.level === 'High'){
      if (threatLevels.bias.answers.biasChecked.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[22].riskfactor,
          'action': riskFactorsMitigationTable[22].action
        });
      }
      if (threatLevels.bias.answers.qualityChecked.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[23].riskfactor,
          'action': riskFactorsMitigationTable[23].action
        });
      }
      if (threatLevels.bias.answers.purposeChecked.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[24].riskfactor,
          'action': riskFactorsMitigationTable[24].action
        });
      }
      if (threatLevels.bias.answers.toolUsed.answer === 'Yes') {
        if (threatLevels.bias.answers.verified.answer === 'No') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[25].riskfactor,
            'action': riskFactorsMitigationTable[25].action
          });
        }
      }
    }
    // legal compliance risk factors
    if (threatLevels.legalCompliance.level === 'Medium' || threatLevels.legalCompliance.level === 'High'){
      if (this.state.datasetContainsPersonalData) {
        if (threatLevels.legalCompliance.answers.clausesPersonal.answer === 'No') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[26].riskfactor,
            'action': riskFactorsMitigationTable[26].action
          });
        }
        if (threatLevels.legalCompliance.answers.storageOutside.answer === 'Yes') {
          mitigationActions.push({
            'riskfactor': riskFactorsMitigationTable[27].riskfactor,
            'action': riskFactorsMitigationTable[27].action
          });
        }
      }
      if (threatLevels.legalCompliance.answers.fctregulations.answer === 'No') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[28].riskfactor,
          'action': riskFactorsMitigationTable[28].action
        });
      }
    }
    // data misuse risk factors
    if (threatLevels.misuse.level === 'Medium' || threatLevels.misuse.level === 'High'){
  
      if (threatLevels.misuse.answers.manipulation.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[29].riskfactor,
          'action': riskFactorsMitigationTable[29].action
        });
      }
      if (threatLevels.misuse.answers.endanger.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[30].riskfactor,
          'action': riskFactorsMitigationTable[30].action
        });
      }
      if (threatLevels.misuse.answers.generalPurpose.answer === 'Yes') {
        mitigationActions.push({
          'riskfactor': riskFactorsMitigationTable[31].riskfactor,
          'action': riskFactorsMitigationTable[31].action
        });
      }
    }
    
   
    
    

    this.setState({
      threatLevels: threatLevels,
      riskLevel: riskLevel,
      mitigationActions: mitigationActions,
    })

      
  }
  calculateRiskLevel() {
    
    //unauthorized access
    let unauthorizedAccessImpactLevel = this.state.impactLevels.confidentiality.levels;
    let unauthorizedAccessThreatLevel = this.state.threatLevels.s
  }

  handleMenuClick(item) {

    let datasetSectionsMenu = this.state.datasetSectionsMenu;
    if (item.enabled) {
      datasetSectionsMenu.forEach((menuItem) => {
      
        if (menuItem.id === item.id) {
          menuItem.active = true;
        } else {
          menuItem.active = false;
        }
        menuItem.subNav && menuItem.subNav.forEach((subMenuItem) => {
      
          if (subMenuItem.id === item.id) {
            subMenuItem.active = true;
          } else {
            subMenuItem.active = false;
          }

          subMenuItem.subNav && subMenuItem.subNav.forEach((subsubMenuItem) => {
      
            if (subsubMenuItem.id === item.id) {
              subsubMenuItem.active = true;
            } else {
              subsubMenuItem.active = false;
            }
          })
        })
      });
    }
    
    
    this.setState({
      datasetSectionsMenu: datasetSectionsMenu
    }, () => {
      this.calculateThreatLevels();
    });

  }
  

  onChangeImpactLevel(risk, area, isComment, event) {
    console.log('risk:', risk);
    console.log('area:', area);
    console.log('event.target.value:', event.target.value);
    console.log('event.target.checked:', event.target.checked);

    let impactLevels = this.state.impactLevels;

    if (risk === 'confidentiality') {
      if (area === 'personal') {
        
        if (isComment) {
          impactLevels.confidentiality.levels.personal.comments = event.target.value;
        } else {
          impactLevels.confidentiality.levels.personal.checked = true;
          impactLevels.confidentiality.levels.personal.level = event.target.value;
        }
      }
      if (area === 'institutional') {
        if (isComment) {
          impactLevels.confidentiality.levels.institutional.comments = event.target.value;
        } else {
          impactLevels.confidentiality.levels.institutional.checked = true;
        impactLevels.confidentiality.levels.institutional.level = event.target.value;
        }
      }
      if (area === 'societal') {
        if (isComment) {
          impactLevels.confidentiality.levels.societal.comments = event.target.value;
        } else {
          impactLevels.confidentiality.levels.societal.checked = true;
          impactLevels.confidentiality.levels.societal.level = event.target.value;
        }
      }
      if (impactLevels.confidentiality.levels.personal.checked && impactLevels.confidentiality.levels.institutional.checked && impactLevels.confidentiality.levels.societal.checked) {
        impactLevels.confidentiality.checked = true;
      }
    }
    if (risk === 'bias') {
      if (area === 'personal') {
        if (isComment) {
          impactLevels.bias.levels.personal.comments = event.target.value;
        } else {
          impactLevels.bias.levels.personal.checked = true;
          impactLevels.bias.levels.personal.level = event.target.value;
        }
      }
      if (area === 'institutional') {
        if (isComment) {
          impactLevels.bias.levels.institutional.comments = event.target.value;
        } else {
          impactLevels.bias.levels.institutional.checked = true;
          impactLevels.bias.levels.institutional.level = event.target.value;
        }
      }
      if (area === 'societal') {
        if (isComment) {
          impactLevels.bias.levels.societal.comments = event.target.value;
        } else {
          impactLevels.bias.levels.societal.checked = true;
          impactLevels.bias.levels.societal.level = event.target.value;
        }
      }
      if (impactLevels.bias.levels.personal.checked && impactLevels.bias.levels.institutional.checked && impactLevels.bias.levels.societal.checked) {
        impactLevels.bias.checked = true;
      }
    }
    if (risk === 'legal') {
      if (area === 'personal') {
        if (isComment) {
          impactLevels.legal.levels.personal.comments = event.target.value;
        } else {
          impactLevels.legal.levels.personal.checked = true;
          impactLevels.legal.levels.personal.level = event.target.value;
        }
      }
      if (area === 'institutional') {
        if (isComment) {
          impactLevels.legal.levels.institutional.comments = event.target.value;
        } else {
          impactLevels.legal.levels.institutional.checked = true;
          impactLevels.legal.levels.institutional.level = event.target.value;
        }
      }
      if (area === 'societal') {
        if (isComment) {
          impactLevels.legal.levels.societal.comments = event.target.value;
        } else {
          impactLevels.legal.levels.societal.checked = true;
          impactLevels.legal.levels.societal.level = event.target.value;
        }
      }
      if (impactLevels.legal.levels.personal.checked && impactLevels.legal.levels.institutional.checked && impactLevels.legal.levels.societal.checked) {
        impactLevels.legal.checked = true;
      }
    }
    if (risk === 'misuse') {
      if (area === 'personal') {
        if (isComment) {
          impactLevels.misuse.levels.personal.comments = event.target.value;
        } else {
          impactLevels.misuse.levels.personal.checked = true;
          impactLevels.misuse.levels.personal.level = event.target.value;
        }
      }
      if (area === 'institutional') {
        if (isComment) {
          impactLevels.misuse.levels.institutional.comments = event.target.value;
        } else {
          impactLevels.misuse.levels.institutional.checked = true;
          impactLevels.misuse.levels.institutional.level = event.target.value;
        }
      }
      if (area === 'societal') {
        if (isComment) {
          impactLevels.misuse.levels.societal.comments = event.target.value;
        } else {
          impactLevels.misuse.levels.societal.checked = true;
          impactLevels.misuse.levels.societal.level = event.target.value;
        }
      }
      if (impactLevels.misuse.levels.personal.checked && impactLevels.misuse.levels.institutional.checked && impactLevels.misuse.levels.societal.checked) {
        impactLevels.misuse.checked = true;
      }
    }

    this.setState({
      impactLevels: impactLevels
    }, () => {
      this.calculateThreatLevels();
    });
    
    
  }
  
  
  onChangeThreatAnswer (section, answer, isAnswer, isComment, event) {
    console.log('section:', section);
    console.log('answer:', answer);
    console.log('event.target.value:', event.target.value);
    console.log('event.target.checked:', event.target.checked);

    let threatLevels = this.state.threatLevels;
    console.log('threatLevels: ', threatLevels);

    if (section === 'privacy') {
      if (isAnswer) {
        if (answer === 'anonymization') {
          if (isComment) {
            threatLevels.privacy.answers.anonymization.comments = event.target.value;
          } else {
            threatLevels.privacy.answers.anonymization.answer = event.target.value;
            threatLevels.privacy.answers.anonymization.checked = true;
          }
        }
        if (answer === 'verified') {
          if (isComment) {
            threatLevels.privacy.answers.verified.comments = event.target.value;
          } else {
            threatLevels.privacy.answers.verified.answer = event.target.value;
            threatLevels.privacy.answers.verified.checked = true;
          }
        }
        if (answer === 'manualVerification') {
          if (isComment) {
            threatLevels.privacy.answers.manualVerification.comments = event.target.value;
          } else {
            threatLevels.privacy.answers.manualVerification.answer = event.target.value;
            threatLevels.privacy.answers.manualVerification.checked = true;
          }
        }
        if (answer === 'complete') {
          if (isComment) {
            threatLevels.privacy.answers.complete.comments = event.target.value;
          } else {
            threatLevels.privacy.answers.complete.answer = event.target.value;
            threatLevels.privacy.answers.complete.checked = true;
          }
        }
      }
      else {
        threatLevels.privacy.level = event.target.value;
      }
    }
    if (threatLevels.privacy.answers.anonymization.checked && threatLevels.privacy.answers.anonymization.answer === 'Yes') {
      threatLevels.privacy.checked = false;
      if (threatLevels.privacy.answers.verified.checked && threatLevels.privacy.answers.manualVerification.checked && threatLevels.privacy.answers.complete.checked && threatLevels.privacy.level) {
        threatLevels.privacy.checked = true;
      }
    }
    if (threatLevels.privacy.answers.anonymization.checked && threatLevels.privacy.answers.anonymization.answer === 'No') {
      threatLevels.privacy.checked = true;
    }
    
    if (section === 'quality') {
      if (isAnswer) {
        if (answer === 'biasChecked') {
          if (isComment) {
            threatLevels.bias.answers.biasChecked.comments = event.target.value;
          } else {
            threatLevels.bias.answers.biasChecked.answer = event.target.value;
            threatLevels.bias.answers.biasChecked.checked = true;
          }
        }
        if (answer === 'qualityChecked') {
          if (isComment) {
            threatLevels.bias.answers.qualityChecked.comments = event.target.value;
          } else {
            threatLevels.bias.answers.qualityChecked.answer = event.target.value;
            threatLevels.bias.answers.qualityChecked.checked = true;
          }
        }
        if (answer === 'purposeChecked') {
          if (isComment) {
            threatLevels.bias.answers.purposeChecked.comments = event.target.value;
          } else {
            threatLevels.bias.answers.purposeChecked.answer = event.target.value;
            threatLevels.bias.answers.purposeChecked.checked = true;
          }
        }
        if (answer === 'toolUsed') {
          if (isComment) {
            threatLevels.bias.answers.toolUsed.comments = event.target.value;
          } else {
            threatLevels.bias.answers.toolUsed.answer = event.target.value;
            threatLevels.bias.answers.toolUsed.checked = true;
          }
        }
        if (answer === 'verified') {
          if (isComment) {
            threatLevels.bias.answers.verified.comments = event.target.value;
          } else {
            threatLevels.bias.answers.verified.answer = event.target.value;
            threatLevels.bias.answers.verified.checked = true;
          }
        }
      }
      else {
        threatLevels.bias.level = event.target.value;
      }
    }
    if (threatLevels.bias.answers.toolUsed.checked && threatLevels.bias.answers.toolUsed.answer === 'Yes') {
      if (threatLevels.bias.answers.biasChecked.checked &&
        threatLevels.bias.answers.qualityChecked.checked && 
        threatLevels.bias.answers.purposeChecked.checked &&
        threatLevels.bias.answers.verified.checked && 
        threatLevels.bias.level) {
        threatLevels.bias.checked = true;
      }
    }
    if (threatLevels.bias.answers.toolUsed.checked && threatLevels.bias.answers.toolUsed.answer === 'No') {
      if (threatLevels.bias.answers.biasChecked.checked &&
        threatLevels.bias.answers.qualityChecked.checked && 
        threatLevels.bias.answers.purposeChecked.checked && 
        threatLevels.bias.level) {
        threatLevels.bias.checked = true;
      }
    }
      
    if (section === 'transfer') {
      if (isAnswer) {
        if (answer === 'electronic') {
          if (isComment) {
            threatLevels.transfer.answers.electronic.comments = event.target.value;
          } else {
            threatLevels.transfer.answers.electronic.answer = event.target.value;
            threatLevels.transfer.answers.electronic.checked = true;
          }
        }
        if (answer === 'security') {
          if (isComment) {
            threatLevels.transfer.answers.security.comments = event.target.value;
          } else {
            threatLevels.transfer.answers.security.answer = event.target.value;
            threatLevels.transfer.answers.security.checked = true;
          }
        }
        if (answer === 'thirdPartyServer') {
          if (isComment) {
            threatLevels.transfer.answers.thirdPartyServer.comments = event.target.value;
          } else {
            threatLevels.transfer.answers.thirdPartyServer.answer = event.target.value;
            threatLevels.transfer.answers.thirdPartyServer.checked = true;
          }
        }
        if (answer === 'portable') {
          if (isComment) {
            threatLevels.transfer.answers.portable.comments = event.target.value;
          } else {
            threatLevels.transfer.answers.portable.answer = event.target.value;
            threatLevels.transfer.answers.portable.checked = true;
          }
        }
        if (answer === 'bestPractices') {
          if (isComment) {
            threatLevels.transfer.answers.bestPractices.comments = event.target.value;
          } else {
            threatLevels.transfer.answers.bestPractices.answer = event.target.value;
            threatLevels.transfer.answers.bestPractices.checked = true;
          }
        }
      }
      else {
        threatLevels.transfer.level = event.target.value;
      }
    }
    if (threatLevels.transfer.answers.electronic.checked && threatLevels.transfer.answers.electronic.answer === 'Electronic') {
      if (threatLevels.transfer.answers.security.checked && 
          threatLevels.transfer.answers.thirdPartyServer.checked && 
          threatLevels.transfer.level
          ) 
      {
          threatLevels.transfer.checked = true;
      }
    } else {
      if (  threatLevels.transfer.answers.bestPractices.checked && 
            threatLevels.transfer.level) 
            {
        threatLevels.transfer.checked = true;
    }
    }
    

    if (section === 'cybersecurity') {
      if (isAnswer) {
        if (answer === 'standard') {
          if (isComment) {
            threatLevels.cybersecurity.answers.standard.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.standard.answer = event.target.value;
            threatLevels.cybersecurity.answers.standard.checked = true;
          }
        }
        if (answer === 'securityMeasures') {
          if (isComment) {
            threatLevels.cybersecurity.answers.securityMeasures.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.securityMeasures.answer = event.target.value;
            threatLevels.cybersecurity.answers.securityMeasures.checked = true;
          }
        }
        if (answer === 'accessRestrictions') {
          if (isComment) {
            threatLevels.cybersecurity.answers.accessRestrictions.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.accessRestrictions.answer = event.target.value;
            threatLevels.cybersecurity.answers.accessRestrictions.checked = true;
          }
        }
        if (answer === 'lifecycle') {
          if (isComment) {
            threatLevels.cybersecurity.answers.lifecycle.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.lifecycle.answer = event.target.value;
            threatLevels.cybersecurity.answers.lifecycle.checked = true;
          }
        }
        if (answer === 'experience') {
          if (isComment) {
            threatLevels.cybersecurity.answers.experience.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.experience.answer = event.target.value;
            threatLevels.cybersecurity.answers.experience.checked = true;
          }
        }
        if (answer === 'thirdPartyUsers') {
          if (isComment) {
            threatLevels.cybersecurity.answers.thirdPartyUsers.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.thirdPartyUsers.answer = event.target.value;
            threatLevels.cybersecurity.answers.thirdPartyUsers.checked = true;
          }
        }
        if (answer === 'audit') {
          if (isComment) {
            threatLevels.cybersecurity.answers.audit.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.audit.answer = event.target.value;
            threatLevels.cybersecurity.answers.audit.checked = true;
          }
        }
        if (answer === 'cyberattack') {
          if (isComment) {
            threatLevels.cybersecurity.answers.cyberattack.comments = event.target.value;
          } else {
            threatLevels.cybersecurity.answers.cyberattack.answer = event.target.value;
            threatLevels.cybersecurity.answers.cyberattack.checked = true;
          }
        }
        
      }
      else {
        threatLevels.cybersecurity.level = event.target.value;
      }
    }
    if (threatLevels.cybersecurity.answers.standard.checked && 
        threatLevels.cybersecurity.answers.securityMeasures.checked && 
        threatLevels.cybersecurity.answers.accessRestrictions.checked && 
        threatLevels.cybersecurity.answers.lifecycle.checked && 
        threatLevels.cybersecurity.answers.experience.checked && 
        threatLevels.cybersecurity.answers.thirdPartyUsers.checked && 
        threatLevels.cybersecurity.answers.audit.checked && 
        threatLevels.cybersecurity.answers.cyberattack.checked && 
        threatLevels.cybersecurity.level) {
      threatLevels.cybersecurity.checked = true;
    }

    if (section === 'agreementUnauthorized') {
      if (isAnswer) {
        if (answer === 'licenseExist') {
          if (isComment) {
            threatLevels.legalUnauthorized.answers.licenseExist.comments = event.target.value;
          } else {
            threatLevels.legalUnauthorized.answers.licenseExist.answer = event.target.value;
            threatLevels.legalUnauthorized.answers.licenseExist.checked = true;
          }
        }
        
        if (answer === 'dataDistribution') {
          if (isComment) {
            threatLevels.legalUnauthorized.answers.dataDistribution.comments = event.target.value;
          } else {
            threatLevels.legalUnauthorized.answers.dataDistribution.answer = event.target.value;
            threatLevels.legalUnauthorized.answers.dataDistribution.checked = true;
          }
        }
        if (answer === 'workDistribution') {
          if (isComment) {
            threatLevels.legalUnauthorized.answers.workDistribution.comments = event.target.value;
          } else {
            threatLevels.legalUnauthorized.answers.workDistribution.answer = event.target.value;
            threatLevels.legalUnauthorized.answers.workDistribution.checked = true;
          }
        }
        if (answer === 'publish') {
          if (isComment) {
            threatLevels.legalUnauthorized.answers.publish.comments = event.target.value;
          } else {
            threatLevels.legalUnauthorized.answers.publish.answer = event.target.value;
            threatLevels.legalUnauthorized.answers.publish.checked = true;
          }
        }
        if (answer === 'permanent') {
          if (isComment) {
            threatLevels.legalUnauthorized.answers.permanent.comments = event.target.value;
          } else {
            threatLevels.legalUnauthorized.answers.permanent.answer = event.target.value;
            threatLevels.legalUnauthorized.answers.permanent.checked = true;
          }
        }
        if (answer === 'evidence') {
          if (isComment) {
            threatLevels.legalUnauthorized.answers.evidence.comments = event.target.value;
          } else {
            threatLevels.legalUnauthorized.answers.evidence.answer = event.target.value;
            threatLevels.legalUnauthorized.answers.evidence.checked = true;
          }
        }
        if (answer === 'domain') {
          if (isComment) {
            threatLevels.legalUnauthorized.answers.domain.comments = event.target.value;
          } else {
            threatLevels.legalUnauthorized.answers.domain.answer = event.target.value;
            threatLevels.legalUnauthorized.answers.domain.checked = true;
          }
        }
        
      }
      else {
        
        threatLevels.legalUnauthorized.level = event.target.value;
        
      }
    }
    
      
    if (threatLevels.legalUnauthorized.answers.licenseExist.checked) {
      if (threatLevels.legalUnauthorized.answers.licenseExist.answer === 'Yes') {
        if (threatLevels.legalUnauthorized.answers.dataDistribution.checked && 
          threatLevels.legalUnauthorized.answers.workDistribution.checked && 
          threatLevels.legalUnauthorized.answers.publish.checked && 
          threatLevels.legalUnauthorized.answers.permanent.checked && 
          threatLevels.legalUnauthorized.answers.evidence.checked && 
          threatLevels.legalUnauthorized.answers.domain.checked && 
          threatLevels.legalUnauthorized.level) {
            threatLevels.legalUnauthorized.checked = true;
          }
      } else {
        if (threatLevels.legalUnauthorized.level) {
          threatLevels.legalUnauthorized.checked = true;
        }
      }
    }

    if (section === 'agreementCompliance') {
      if (isAnswer) {
        if (answer === 'clausesPersonal') {
          if (isComment) {
            threatLevels.legalCompliance.answers.clausesPersonal.comments = event.target.value;
          } else {
            threatLevels.legalCompliance.answers.clausesPersonal.answer = event.target.value;
            threatLevels.legalCompliance.answers.clausesPersonal.checked = true;
          }
        }
        if (answer === 'storageOutside') {
          if (isComment) {
            threatLevels.legalCompliance.answers.storageOutside.comments = event.target.value;
          } else {
            threatLevels.legalCompliance.answers.storageOutside.answer = event.target.value;
            threatLevels.legalCompliance.answers.storageOutside.checked = true;
          }
        }
        if (answer === 'fctregulations') {
          if (isComment) {
            threatLevels.legalCompliance.answers.fctregulations.comments = event.target.value;
          } else {
            threatLevels.legalCompliance.answers.fctregulations.answer = event.target.value;
            threatLevels.legalCompliance.answers.fctregulations.checked = true;
          }
        }
      } else {
        threatLevels.legalCompliance.level = event.target.value;
      }
    } 


    if (threatLevels.legalUnauthorized.answers.licenseExist.checked) {
      if (threatLevels.legalUnauthorized.answers.licenseExist.answer === 'Yes') {
        if (threatLevels.legalCompliance.answers.clausesPersonal.checked && 
          threatLevels.legalCompliance.answers.storageOutside.checked && 
          threatLevels.legalCompliance.answers.fctregulations.checked && 
          threatLevels.legalCompliance.level) {
            threatLevels.legalCompliance.checked = true;
          }
      } else {
        if (threatLevels.legalCompliance.answers.fctregulations.checked && threatLevels.legalCompliance.level) {
          threatLevels.legalCompliance.checked = true;
        }
      }
    }
    
     

    if (section === 'misuse') {
      if (isAnswer) {
        if (answer === 'surveillance') {
          if (isComment) {
            threatLevels.misuse.answers.surveillance.comments = event.target.value;
          } else {
            threatLevels.misuse.answers.surveillance.answer = event.target.value;
            threatLevels.misuse.answers.surveillance.checked = true;
          }
        }
        if (answer === 'manipulation') {
          if (isComment) {
            threatLevels.misuse.answers.manipulation.comments = event.target.value;
          } else {
            threatLevels.misuse.answers.manipulation.answer = event.target.value;
            threatLevels.misuse.answers.manipulation.checked = true;
          }
        }
        if (answer === 'endanger') {
          if (isComment) {
            threatLevels.misuse.answers.endanger.comments = event.target.value;
          } else {
            threatLevels.misuse.answers.endanger.answer = event.target.value;
            threatLevels.misuse.answers.endanger.checked = true;
          }
        }
        if (answer === 'generalPurpose') {
          if (isComment) {
            threatLevels.misuse.answers.generalPurpose.comments = event.target.value;
          } else {
            threatLevels.misuse.answers.generalPurpose.answer = event.target.value;
            threatLevels.misuse.answers.generalPurpose.checked = true;
          }
        }
      }
      else {
        threatLevels.misuse.level = event.target.value;
      }
    }
    if (threatLevels.misuse.answers.surveillance.checked && 
        threatLevels.misuse.answers.manipulation.checked && 
        threatLevels.misuse.answers.endanger.checked && 
        threatLevels.misuse.answers.generalPurpose.checked && 
        
        threatLevels.misuse.level) {
      threatLevels.misuse.checked = true;
    }
    
    this.setState({
      threatLevels: threatLevels,
    }, () => {
      this.calculateThreatLevels();
  })
  }
  

  /*
  sectionRefresh() {
    
    this.setState({
      sectionGeneralActive: false,
      sectionGeneralSub1Active: false,
      sectionGeneralSub2Active: false,
      sectionImpactActive: false,
      sectionImpactSub1Active: false,
      sectionImpactSub2Active: false,
      sectionImpactSub3Active: false,
      sectionThreatsActive: false,
      sectionThreatsSub1Active: false,
      sectionThreatsSub2Active: false,
      sectionThreatsSub3Active: false,
      sectionEvaluationActive: false,
      sectionEvaluationSub1Active: false,
      sectionEvaluationSub2Active: false,
      sectionEvaluationSub3Active: false,
    })
  }
  */
 /*
  sectionUpdate(activeSection) {
    this.sectionRefresh();
    switch (activeSection) {
      case 'general':
        this.setState({
          sectionGeneralActive: true,
          sectionGeneralSub1Active: true
        })
        break;
      case 'generalsub1':
        
        this.setState({
          sectionGeneralSub1Active: true
        })
        break;
      case 'generalsub2':
        
        this.setState({
          sectionGeneralSub2Active: true
        })
        break;
      case 'impact':
        
        this.setState({
          sectionImpactActive: true
        })
        break;
      case 'impactsub1':
        
        this.setState({
          sectionImpactSub1Active: true
        })
        break;
      case 'impactsub2':
        
        this.setState({
          sectionImpactSub2Active: true
        })
        break;
      case 'impactsub3':
        
        this.setState({
          sectionImpactSub3Active: true
        })
        break;
      case 'threats':
        
        this.setState({
          sectionThreatsActive: true
        })
        break;
      case 'threatssub1':
        
        this.setState({
          sectionThreatsSub1Active: true
        })
        break;
      case 'threatssub2':
        
        this.setState({
          sectionThreatsSub2Active: true
        })
        break;
      case 'threatssub3':
        
        this.setState({
          sectionThreatsSub3Active: true
        })
        break;
      case 'evaluation':
        
        this.setState({
          sectionEvaluationActive: true
        })
        break;
      case 'evaluationsub1':
        
        this.setState({
          sectionEvaluationSub1Active: true
        })
        break;
      case 'evaluationsub2':
        
        this.setState({
          sectionEvaluationSub2Active: true
        })
        break;
      case 'evaluationsub3':
        
        this.setState({
          sectionEvaluationSub3Active: true
        })
        break;
    
      default:
        break;
    } 
  }
  */

  clearMenus(menu) {

    menu.forEach((menuItem) => {
      menuItem.active = false;  
      menuItem.subNav && menuItem.subNav.forEach((subMenuItem) => {
        subMenuItem.active = false;
        subMenuItem.subNav && subMenuItem.subNav.forEach((subsubMenuItem) => {
          subsubMenuItem.active = false;
        });
      });
    });
  }

  nextStep(currentSection, targetSection) {
    console.log('nextStep');
    //this.sectionRefresh();
    
    let generalEmptyFields = false;
    let sectionGeneralActive = false;
    let sectionGeneralSub1Active = false;
    let sectionGeneralSub2Active = false;
    let sectionImpactActive = false;
    let sectionImpactSub1Active = false;
    let sectionImpactSub2Active = false;
    let sectionImpactSub3Active = false;
    let sectionImpactSub4Active = false;
    let sectionImpactSub5Active = false;
    let sectionImpactSub6Active = false;
    let sectionImpactSub7Active = false;
    let sectionImpactSub8Active = false;
    let sectionImpactSub9Active = false;
    let sectionImpactSub10Active = false;
    let sectionThreatsActive = false;
    let sectionThreatsSub1Active = false;
    
    let sectionThreatsSub2Active = false;
    let sectionThreatsSub3Active = false;
    let sectionThreatsSub4Active = false;
    let sectionThreatsSub5Active = false;
    let sectionThreatsSub6Active = false;
    let sectionThreatsSub7Active = false;
    let sectionEvaluationActive = false;
    let sectionEvaluationSub1Active = false;
    let sectionEvaluationSub2Active = false;
    let sectionEvaluationSub3Active = false;
    let sectionMitigationActive = false;
    let sectionExportActive = false;
    
    let riskCalculated = false;

    let datasetSectionsMenu = this.state.datasetSectionsMenu;
    

    if (currentSection === 'general') {
      if (this.state.generalInfo.riskAssessmentTitle === '' || this.state.generalInfo.yourName === '' || 
      this.state.generalInfo.nameOfYourOrganization === '' || this.state.generalInfo.purposeOfSharing === '' ||
      this.state.generalInfo.nameOfRequestingEntity === '' || this.state.selectedRequestingEntityType === '' || 
      this.state.generalInfo.selectedOrganizationEntityType === '' || this.state.selectedDatasetModel === '') {
        generalEmptyFields = true;
        console.log('empty fields in general section');
        alert("Please fill in all required fields");
      }
      else if (this.state.generalInfo.typeOfRequestingEntity === 'Other' && this.state.generalInfo.customTypeOfRequestingEntity === '') {
        generalEmptyFields = true;
        console.log('empty fields in other section');
        alert("Please fill in all required fields");
      } 
      else if (this.state.generalInfo.objectOfAssessment === 'Dataset' && (
        this.state.selectedDataTypes.length === 0 || this.state.selectedSpecialDataTypes.length === 0 || 
        this.state.selectedDataFormat.length === 0 || this.state.selectedDatasetNature.length === 0)) {
          generalEmptyFields = true;
          console.log('empty fields in dataset section');
          alert("Please fill in all required fields");
      }
      else {
        this.clearMenus(datasetSectionsMenu);
        //next section is impact
        sectionImpactActive = true;
        datasetSectionsMenu[1].active = true;
        datasetSectionsMenu[1].enabled = true;
        datasetSectionsMenu[1].visited = true;
      }
    }
    if (currentSection === 'impact') { 
      this.clearMenus(datasetSectionsMenu);
      //next section is impact
      sectionImpactSub1Active = true;
      datasetSectionsMenu[1].subNav[0].active = true;
      datasetSectionsMenu[1].subNav[0].enabled = true;
      datasetSectionsMenu[1].subNav[0].visited = true;

    }
    //section 2a: unauthorized access
    if (currentSection === 'impactSub1') {
      let impactLevels = this.state.impactLevels;
      if (impactLevels.confidentiality.checked) {
        this.clearMenus(datasetSectionsMenu);
        //next section is impact
        sectionImpactSub1Active = true;
        datasetSectionsMenu[1].subNav[1].active = true;
        datasetSectionsMenu[1].subNav[1].enabled = true;
        datasetSectionsMenu[1].subNav[1].visited = true;
      } else {
        console.log('empty fields in impactSub1 section');
        alert("Please fill in all required fields");
      }
      
    }
    //section 2b: biased dataset
    if (currentSection === 'impactSub2') {
      let impactLevels = this.state.impactLevels;
      if (impactLevels.bias.checked) {
        this.clearMenus(datasetSectionsMenu);
        //next section is impact
        sectionImpactSub2Active = true;
        datasetSectionsMenu[1].subNav[2].active = true;
        datasetSectionsMenu[1].subNav[2].enabled = true;
        datasetSectionsMenu[1].subNav[2].visited = true;
      } else {
        console.log('empty fields in impactSub2 section');
        alert("Please fill in all required fields");
      }
      
    }
    //section 2c legal Compliance
    if (currentSection === 'impactSub3') {
      let impactLevels = this.state.impactLevels;
      if (impactLevels.legal.checked) {
        this.clearMenus(datasetSectionsMenu);
        //next section is impact
        sectionThreatsActive = true;
        datasetSectionsMenu[1].subNav[3].active = true;
        datasetSectionsMenu[1].subNav[3].enabled = true;
        datasetSectionsMenu[1].subNav[3].visited = true;
      } else {
        console.log('empty fields in impactSub3 section');
        alert("Please fill in all required fields");
      }
    }
    //section 2d data misuse
    if (currentSection === 'impactSub4') {
      let impactLevels = this.state.impactLevels;
      if (impactLevels.misuse.checked) {
        this.clearMenus(datasetSectionsMenu);
        //next section is impact
        sectionThreatsActive = true;
        datasetSectionsMenu[1].subNav[4].active = true;
        datasetSectionsMenu[1].subNav[4].enabled = true;
        datasetSectionsMenu[1].subNav[4].visited = true;
      } else {
        console.log('empty fields in impactSub4 section');
        alert("Please fill in all required fields");
      }
    }
    //section 2e: Overall Impact Levels
    if (currentSection === 'impactSub5') {
      let impactLevels = this.state.impactLevels;
      
      this.clearMenus(datasetSectionsMenu);
        //next section is impact
        sectionThreatsActive = true;
        datasetSectionsMenu[2].active = true;
        datasetSectionsMenu[2].enabled = true;
        datasetSectionsMenu[2].visited = true;
      
    }
    if (currentSection === 'threats') {
      let impactLevels = this.state.impactLevels;
      
      this.clearMenus(datasetSectionsMenu);
      //next section is threatsSub1
      sectionThreatsSub1Active = true;
      datasetSectionsMenu[2].subNav[0].active = true;
      datasetSectionsMenu[2].subNav[0].enabled = true;
      datasetSectionsMenu[2].subNav[0].visited = true;
      
    }
    //Section 3a: Unauthorized Access Threat
    if (currentSection === 'threatsSub1') {
      
      this.clearMenus(datasetSectionsMenu);

      //next section is threatsSub1Sub1
      datasetSectionsMenu[2].subNav[0].subNav[0].active = true;
      datasetSectionsMenu[2].subNav[0].subNav[0].enabled = true;
      datasetSectionsMenu[2].subNav[0].subNav[0].visited = true;
    } 
    //Section 3a.1: Privacy Preservation Tools
    if (currentSection === 'threatsSub1Sub1') {
      
      if (this.state.threatLevels.privacy.checked) {
        this.clearMenus(datasetSectionsMenu);

        //next section is threatsSub1Sub2
        datasetSectionsMenu[2].subNav[0].subNav[1].active = true;
      datasetSectionsMenu[2].subNav[0].subNav[1].enabled = true;
      datasetSectionsMenu[2].subNav[0].subNav[1].visited = true;
      } else {
        console.log('empty fields in threatsSub1Sub1 section');
        alert("Please fill in all required fields");
      }
    }
    //Section 3a.2: Data Transfer Method
    if (currentSection === 'threatsSub1Sub2') {
      
      if (this.state.threatLevels.transfer.checked) {
        this.clearMenus(datasetSectionsMenu);

        //next section is threatsSub1Sub3
        datasetSectionsMenu[2].subNav[0].subNav[2].active = true;
        datasetSectionsMenu[2].subNav[0].subNav[2].enabled = true;
        datasetSectionsMenu[2].subNav[0].subNav[2].visited = true;
      } else {
        console.log('empty fields in threatsSub1Sub2 section');
        alert("Please fill in all required fields");
      }
    }
    //Section 3a.3: Cybersecurity
    if (currentSection === 'threatsSub1Sub3') {
      
      if (this.state.threatLevels.cybersecurity.checked) {
        this.clearMenus(datasetSectionsMenu);

        //next section is threatsSub1Sub4
        datasetSectionsMenu[2].subNav[0].subNav[3].active = true;
        datasetSectionsMenu[2].subNav[0].subNav[3].enabled = true;
        datasetSectionsMenu[2].subNav[0].subNav[3].visited = true;
      } else {
        console.log('empty fields in threatsSub1Sub3 section');
        alert("Please fill in all required fields");
      }
    }
    //Section 3a.4: License Agreement
    if (currentSection === 'threatsSub1Sub4') {
      
      if (this.state.threatLevels.legalUnauthorized.checked) {
        this.clearMenus(datasetSectionsMenu);

        //next section is threatsSub2
        datasetSectionsMenu[2].subNav[1].active = true;
        datasetSectionsMenu[2].subNav[1].enabled = true;
        datasetSectionsMenu[2].subNav[1].visited = true;
      } else {
        console.log('empty fields in threatsSub1Sub4 section');
        alert("Please fill in all required fields");
      }
    }
    //Section 3b: Low Data Quality
    if (currentSection === 'threatsSub2') {
      
      if (this.state.threatLevels.bias.checked) {
        this.clearMenus(datasetSectionsMenu);

        //next section is threatsSub3
        datasetSectionsMenu[2].subNav[2].active = true;
        datasetSectionsMenu[2].subNav[2].enabled = true;
        datasetSectionsMenu[2].subNav[2].visited = true;
      } else {
        console.log('empty fields in threatsSub2 section');
        alert("Please fill in all required fields");
      }
    }
    //Section 3c: Legal Non-Compliance
    if (currentSection === 'threatsSub3') {
      
      if (this.state.threatLevels.legalCompliance.checked) {
        this.clearMenus(datasetSectionsMenu);

        //next section is threatsSub4
        datasetSectionsMenu[2].subNav[3].active = true;
        datasetSectionsMenu[2].subNav[3].enabled = true;
        datasetSectionsMenu[2].subNav[3].visited = true;
      } else {
        console.log('empty fields in threatsSub3 section');
        alert("Please fill in all required fields");
      }
    }
    //Section 3d: Data Misuse
    if (currentSection === 'threatsSub4') {
      
      if (this.state.threatLevels.misuse.checked) {
        this.clearMenus(datasetSectionsMenu);

        //next section is threatsSub5
        datasetSectionsMenu[2].subNav[4].active = true;
        datasetSectionsMenu[2].subNav[4].enabled = true;
        datasetSectionsMenu[2].subNav[4].visited = true;
      } else {
        console.log('empty fields in threatsSub4 section');
        alert("Please fill in all required fields");
      }
    }
    //Section 3g: Overall Threat Levels
    if (currentSection === 'threatsSub5') {
      
      this.clearMenus(datasetSectionsMenu);

      //next section is evaluation
      sectionEvaluationActive = true;
      datasetSectionsMenu[3].active = true;
      datasetSectionsMenu[3].enabled = true;
      datasetSectionsMenu[3].visited = true;
    }
    //Section 4: Risk Evaluation
    if (currentSection === 'evaluation') {
      
      this.clearMenus(datasetSectionsMenu);
        //next section is evaluation
        sectionMitigationActive = true;
        datasetSectionsMenu[4].active = true;
        datasetSectionsMenu[4].enabled = true;
        datasetSectionsMenu[4].visited = true;

      
    }
    if (currentSection === 'mitigation') {
      
      this.clearMenus(datasetSectionsMenu);
      //next section is evaluation
      sectionExportActive = true;
      datasetSectionsMenu[5].active = true;
      datasetSectionsMenu[5].enabled = true;
      datasetSectionsMenu[5].visited = true;
    
    }

  
  
  
  this.setState({

      generalEmptyFields: generalEmptyFields,
      sectionGeneralActive: sectionGeneralActive,
      sectionGeneralSub1Active: sectionGeneralSub1Active,
      sectionGeneralSub2Active: sectionGeneralSub2Active,
      sectionImpactActive: sectionImpactActive,
      sectionImpactSub1Active: sectionImpactSub1Active,
      sectionImpactSub2Active: sectionImpactSub2Active,
      sectionImpactSub3Active: sectionImpactSub3Active,
      sectionImpactSub4Active: sectionImpactSub4Active,
      sectionImpactSub5Active: sectionImpactSub5Active,
      sectionImpactSub6Active: sectionImpactSub6Active,
      sectionImpactSub7Active: sectionImpactSub7Active,
      sectionImpactSub8Active: sectionImpactSub8Active,
      sectionImpactSub9Active: sectionImpactSub9Active,
      sectionImpactSub10Active: sectionImpactSub10Active,
      sectionThreatsActive: sectionThreatsActive,
      sectionThreatsSub1Active: sectionThreatsSub1Active,
      sectionThreatsSub2Active: sectionThreatsSub2Active,
      sectionThreatsSub3Active: sectionThreatsSub3Active,
      sectionThreatsSub4Active: sectionThreatsSub4Active,
      sectionThreatsSub5Active: sectionThreatsSub5Active,
      sectionEvaluationActive: sectionEvaluationActive,
      sectionEvaluationSub1Active: sectionEvaluationSub1Active,
      sectionEvaluationSub2Active: sectionEvaluationSub2Active,
      sectionEvaluationSub3Active: sectionEvaluationSub3Active,
      sectionMitigationActive: sectionMitigationActive,
      sectionExportActive: sectionExportActive,
      riskCalculated: riskCalculated,
      
      datasetSectionsMenu: datasetSectionsMenu,
    });
    
    
    
  
  }

  handlePurposeOfSharingChange = (updatedValue) => {
    console.log('purpose of sharing updatedValue: ', updatedValue);
    
    let generalInfo = this.state.generalInfo;

    generalInfo.purposeOfSharing = updatedValue ? updatedValue.label : '';

    this.setState({
      selectedPurposeOfSharing: updatedValue,
      generalInfo: generalInfo
    })
  }

  handleSelectedRequestingEntityTypeUpdate = (updatedValue) => {
    let generalInfo = this.state.generalInfo;
    generalInfo.typeOfRequestingEntity = updatedValue ? updatedValue.label : '';

    this.setState({
      selectedRequestingEntityType: updatedValue,
      generalInfo: generalInfo
    })
    
  }
  handleSelectedOrganizationEntityTypeUpdate = (updatedValue) => {
    let generalInfo = this.state.generalInfo;
    generalInfo.typeOfOrganizationEntity = updatedValue ? updatedValue.label : '';

    this.setState({
      selectedOrganizationEntityType: updatedValue,
      generalInfo: generalInfo
    })
  }
  


  onGeneralInfoChange = (event, field) => {
    let updatedValue = event.target.value;
    let generalInfo = this.state.generalInfo;
    
    //console.log('onGeneralInfoChange, event.target.value:', event.target.value);
    //console.log('onGeneralInfoChange, field:', field);

    switch (field) {
      case 'riskAssessmentTitle':
        generalInfo.riskAssessmentTitle = updatedValue;
        break;
      case 'yourName':
        generalInfo.yourName = updatedValue;
        break;
      case 'nameOfYourOrganization':
        generalInfo.nameOfYourOrganization = updatedValue;
        break;
      case 'comments':
        generalInfo.comments = updatedValue;
        break;
      case 'purposeOfSharing':
        generalInfo.purposeOfSharing = updatedValue;
        break;
      case 'nameOfRequestingEntity':
        generalInfo.nameOfRequestingEntity = updatedValue;
        break;
      case 'typeOfRequestingEntity':
        generalInfo.typeOfRequestingEntity = updatedValue;
        break;
      case 'customTypeOfRequestingEntity':
        generalInfo.customTypeOfRequestingEntity = updatedValue;
        break;
    
      default:
        break;
    }

    this.setState({
      generalInfo: generalInfo
    })
  }


  calculateRisk() {
    let selectedDataTypes = this.state.selectedDataTypes;
    let selectedSpecialDataTypes = this.state.selectedSpecialDataTypes;
    let selectedDataFormat = this.state.selectedDataFormat;
    let selectedDatasetNature = this.state.selectedDatasetNature;
    let selectedDatasetAnonymizationOption = this.state.selectedDatasetAnonymizationOption;
    let selectedAnonymizationTrl = this.state.selectedAnonymizationTrl;
    let selectedCrimeDomain = this.state.selectedCrimeDomain;
    let selectedUsagePolicy = this.state.selectedUsagePolicy;
    let selectedSharingFrom = this.state.selectedSharingFrom;
    let selectedSharingTo = this.state.selectedSharingTo;
    let selectedLicensePlatesAnonymization = this.state.selectedLicensePlatesAnonymization;



    let finalRiskDetails = [];
    let mitigationActions = [];

    let dataTypeScore = 0;
    let datasetNatureScore = 0;
    let anonymizationScore = 0;
    let anonymizationTrlScore = 0;
    let usagePolicyScore = 0;

    let finalRiskScore = 0;
    let personalDataTypeFound = false;
    for (let i = 0; i < selectedDataTypes.length; i++ ) {
      if (selectedDataTypes[i].value !== 'Non-Personal Data') {
        personalDataTypeFound = true;
      }
    }
    if (personalDataTypeFound) {
      finalRiskScore += 3;
      finalRiskDetails.push(<li>The dataset contains personal information according to the GDPR regulation must be protected. This fact creates a risk when sharing this particular dataset.</li>);
    } else {
      finalRiskScore += 0;
    }
    console.log('selectedDataTypes:', selectedDataTypes);
    console.log('risk score after dataType:', finalRiskScore);

    let specialPersonalDataTypeFound = false;
    for (let i = 0; i < selectedSpecialDataTypes.length; i++ ) {
      if (selectedSpecialDataTypes[i].value !== 'No Special Data') {
        specialPersonalDataTypeFound = true;
      }
    }
    if (specialPersonalDataTypeFound) {
      finalRiskScore += 3;
      finalRiskDetails.push(<li>The dataset contains special categories of data which merit higher protection. For that reason the risk is higher when sharing these particular data</li>);
    } else {
      finalRiskScore += 0;
    }
    console.log('selectedSpecialDataTypes:', selectedSpecialDataTypes);
    console.log('risk score after specialData:', finalRiskScore);
    
    if (selectedDatasetNature === 'Synthetic') {
      finalRiskScore = 0;
      finalRiskDetails.push(<li>However, The nature of the dataset is synthetic, which nulifies the risk of sharing the dataset</li>);
    }
    console.log('risk score after dataset nature:', finalRiskScore);
    
    if (selectedDatasetNature === 'Real' && selectedDatasetAnonymizationOption === 'Yes') {
      switch (selectedAnonymizationTrl) {
        case '8 - 9':
          finalRiskScore -= 3;
          finalRiskDetails.push(<li>The dataset containes real data, but it is anonymized with a high TRL tool. Therefore the risk for sharing the dataset is mitigated significantly</li>);
          break;

        case '5 - 7':
          finalRiskScore += 0;
          finalRiskDetails.push(<li>The dataset containes real data, but it is anonymized with a medium TRL tool. There maybe cases of data that have not been anonymized correctly. Therefore the risk for sharing the dataset is higher</li>);
          mitigationActions.push(<li>Use an anonymization tool with higher TRL</li>);
          break;
        
        case 'Below 5':
          finalRiskScore += 3;
          finalRiskDetails.push(<li>The dataset containes real data, but it is anonymized with a low TRL tool. There maybe cases of data that have not been anonymized correctly. Therefore the risk for sharing the dataset is higher</li>);
          mitigationActions.push(<li>Use an anonymization tool with higher TRL</li>);
          break;
        
        default:
          break;
      }
      
    }
    
    if ((personalDataTypeFound || specialPersonalDataTypeFound) && ( selectedDatasetNature === 'Real' && selectedDatasetAnonymizationOption === 'No')) {
      finalRiskScore += 10;
      finalRiskDetails.push(<li>The dataset contains personal data and has not been anonymized, which exposes the real data if shared. Therefore the risk of sharing is very high</li>);
      mitigationActions.push(<li>Anonymize the dataset before sharing</li>);
    }
    console.log('selectedDatasetNature:', selectedDatasetNature);
    console.log('selectedDatasetAnonymizationOption:', selectedDatasetAnonymizationOption);
    console.log('risk score after anonymization:', finalRiskScore);
    
    if (selectedDatasetNature !== 'Synthetic' && (personalDataTypeFound || specialPersonalDataTypeFound)) {

      if (selectedUsagePolicy === 'Open') {
        finalRiskScore += 6;
        finalRiskDetails.push(<li>The dataset is not accompanied by a usage policy. Therefore the risk of sharing is higher when personal data are involved</li>);
        mitigationActions.push(<li>Associate a license agreement to the dataset with a stricter usage policy</li>);
      }
      if (selectedUsagePolicy === 'Strict') {
        finalRiskScore += 3;
        finalRiskDetails.push(<li>The dataset is accompanied by a strict usage policy. Therefore the risk of sharing is somewhat mitigated</li>);
      }
      if (selectedUsagePolicy === 'Very Strict') {
        finalRiskScore += 0;
        finalRiskDetails.push(<li>The dataset is accompanied by a strict usage policy. Therefore the risk of sharing is mitigated significantly</li>);
      }
      if (selectedLicensePlatesAnonymization === 'Yes') {
        finalRiskScore -= 6;
      }
    }
    console.log('selectedUsagePolicy:', selectedUsagePolicy);
    console.log('risk score after usage policy:', finalRiskScore);

    if (selectedDatasetNature !== 'Synthetic') {
      if (selectedSharingFrom === 'LEA' && selectedSharingTo !== 'LEA') {
        finalRiskScore += 3;
      }
    }
    console.log('selectedSharingFrom:', selectedSharingFrom);
    console.log('selectedSharingTo:', selectedSharingTo);
    console.log('risk score after sharing from/to:', finalRiskScore);

    if (mitigationActions.length === 0) {
      mitigationActions.push('None');
    }
    if (finalRiskScore <= 3) {
      this.setState({
        riskCalculated: true,
        finalRiskCalculation: 'Low Risk',
        finalRiskDetails: finalRiskDetails,
        mitigationActions: mitigationActions
      })
    }
    if (finalRiskScore >3 && finalRiskScore <= 6) {
      this.setState({
        riskCalculated: true,
        finalRiskCalculation: 'Medium Risk',
        finalRiskDetails: finalRiskDetails,
        mitigationActions: mitigationActions
      })
    }
    if (finalRiskScore >6) {
      this.setState({
        riskCalculated: true,
        finalRiskCalculation: 'High Risk',
        finalRiskDetails: finalRiskDetails,
        mitigationActions: mitigationActions
      })
    }


    
    
  }
  parseAggregatedInfo(jsonData) {
    let sharingPartnerInfoArray = jsonData["LAGO"]['sharingPartner'];
    //console.log('sharingPartnerInfo:', sharingPartnerInfoArray);
    let datasetsInfoArray = jsonData["LAGO"]["datasetsInfo"];
    //console.log('datasetsInfo:', datasetsInfoArray);

    this.setState( {
      sharingPartnerInfoArray: sharingPartnerInfoArray,
      datasetsInfoArray: datasetsInfoArray
    })
  }

  readFile = async (e) => {
    //console.log('readFile, e', e);
    
    e.preventDefault()
    const reader = new FileReader();
    reader.readAsText(e.target.files[0],  "UTF-8");
    reader.onload = async (e) => { 
      const text = (e.target.result)
      //console.log(text)
      //console.log('readFile, JSON.parse(reader.result)', JSON.parse(reader.result));
      this.parseAggregatedInfo(JSON.parse(reader.result));
      this.setState({
        urlSubmitted: true,
        data: JSON.parse(reader.result)
      });
    };
    
  }

  onChangeEntity(event) {
    //console.log('onChangeEntity: event.target.value', event.target.value);
    //console.log('onChangeEntity: event.target.checked', event.target.checked);
    
    let sharingEntityType = this.state.sharingEntityType;
    //console.log('sharingEntityType before:', sharingEntityType);
    if (event.target.checked) {
      sharingEntityType = event.target.value;
    }
    //console.log('sharingEntityType after:', sharingEntityType);
    this.setState({sharingEntityType: sharingEntityType});
  }
  onChangeAgreement(event) {
    //console.log('onChangeAgreement: event.target.value', event.target.value);
    //console.log('onChangeAgreement: event.target.checked', event.target.checked);
    
    let sharingAgreementType = this.state.sharingAgreementType;
    //console.log('sharingAgreementType before:', sharingAgreementType);
    if (event.target.checked) {
      sharingAgreementType = event.target.value;
    }
    //console.log('sharingAgreementType after:', sharingAgreementType);
    this.setState({sharingAgreementType: sharingAgreementType});
  }
  
  handleDatasetModelChange = (selectedOption) => {
    console.log('selectedOption: ', selectedOption);
    let generalInfo = this.state.generalInfo;

    if (!selectedOption) {
      generalInfo.objectOfAssessment = '';
      this.setState({
        'selectedDatasetModel': '' ,
        'generalInfo': generalInfo,
      });
    } else {
      generalInfo.objectOfAssessment = selectedOption.label;
      this.setState({
        'generalInfo': generalInfo,
        'selectedDatasetModel': selectedOption
      });
    }
  
  }
  handleDataTypeChange = (selectedOption) => {
    console.log('selectedOption:', selectedOption);
    let personalDataTypeFound = 'No';
    console.log('personalDataTypeFound:', personalDataTypeFound);
    if (selectedOption.length===0) {
      
      this.setState({ 
          dataTypesSelected: false,
          datasetContainsPersonalData: 'No',
          selectedDataTypes: selectedOption,

      })
    } else {
      selectedOption.forEach(element => {
        if (
          element.label === 'Financial transactions' || 
          element.label === 'Credit card / bank account numbers' ||
          element.label === 'Health data' ||
          element.label === 'Web browsing data' ||
          element.label === 'Biometric identifiers' ||
          element.label === 'Geolocation data' ||
          element.label === 'IP-address' ||
          element.label === 'Camera fingerprints' ||
          element.label === 'License plates' ||
          element.label === 'Social security number' ||
          element.label === 'Face' ||
          element.label === 'Personal identification code' ||
          element.label === 'Other Personal Data'
        ) {
            personalDataTypeFound = 'Yes';
            console.log('personalDataTypeFound:', personalDataTypeFound);
        }
      });

      this.setState({ 
        dataTypesSelected: true,
        datasetContainsPersonalData: personalDataTypeFound,
        selectedDataTypes: selectedOption 
      });
    }
    
  }
  handleSpecialDataTypeChange = (selectedOption) => {
    let specialPersonalDataTypeFound = 'No';
    let personalDataTypeFound = this.state.personalDataTypeFound;

    if (selectedOption.length===0) {
        this.setState({ 
          specialDataTypesSelected: false,
          selectedSpecialDataTypes: selectedOption
        })
    } else {
      selectedOption.forEach(element => {
        if (
          element.label === 'Ratial/Ethnic Info' || 
          element.label === 'Sexual Orientation' ||
          element.label === 'Political Views' ||
          element.label === 'Religious Views' ||
          element.label === 'Political Party Memberships' ||
          element.label === 'Genetic / Biometric Data' ||
          element.label === 'Criminal Past' ||
          element.label === 'Children'
        ) {
            
          specialPersonalDataTypeFound = 'Yes';
          personalDataTypeFound = 'Yes';
            console.log('specialPersonalDataTypeFound:', specialPersonalDataTypeFound);
        }
      });
        this.setState({ 
          specialDataTypesSelected: true,
          datasetContainsSensitiveData: specialPersonalDataTypeFound,
          datasetContainsPersonalData: personalDataTypeFound,
          selectedSpecialDataTypes: selectedOption
        });
    }
  }
  handleDataFormatSelection = (selectedOption) => {
        
    if (selectedOption.length===0) {
    
        this.setState({ 
          dataFormatSelected: false,
          selectedDataFormat: selectedOption
        })
    } else {
        this.setState({ 
          dataFormatSelected: true,
          selectedDataFormat: selectedOption
        });
    }
    
  }
  /*
  handleDataFormatSelection = (event) => {
    this.setState({'selectedDataFormat': event.target.value});
  }*/
  handleDatasetNatureSelection = (selectedOption) => {
    if (selectedOption.length===0) {
      this.setState({ 
        selectedDatasetNature: selectedOption
      })
    } else {
      this.setState({ 
         
        selectedDatasetNature: selectedOption
      });
    }
  }
  handleDatasetAnonymizationSelection = (event) => {
    this.setState({'selectedDatasetAnonymizationOption': event.target.value});
  }
  handleAnonymizationTrlSelection = (event) => {
    this.setState({'selectedAnonymizationTrl': event.target.value});
  }
  handleFederatedLearningSelection = (event) => {
    this.setState({'selectedFederatedLearningOption': event.target.value});
  }
  handleLicensePermenantSelection = (event) => {
    this.setState({'selectedLicensePermenant': event.target.value});
  }
  handleCrimeDomainSelection = (event) => {
    this.setState({'selectedCrimeDomain': event.target.value});
  }
  handleUsagePolicySelection = (event) => {
    this.setState({'selectedUsagePolicy': event.target.value});
  }
  handleSharingFromSelection = (event) => {
    this.setState({'selectedSharingFrom': event.target.value});
  }
  hanldeSharingToSelection = (event) => {
    this.setState({'selectedSharingTo': event.target.value});
  }
  handleDistributionRestrictions = (event) => {
    this.setState({'selectedDistributionRestrictions': event.target.value});
  }
  handleDistributionModifications = (event) => {
    this.setState({'selectedDistributionModifications': event.target.value});
  }
  handleLicenseModifications = (event) => {
    this.setState({'selectedLicenseModifications': event.target.value});
  }
  handleModelTrainingOptions = (event) => {
    this.setState({'selectedModelTrainingOptions': event.target.value});
  }
  handleScientificPublicationOptions = (event) => {
    this.setState({'selectedScientificPublicationOptions': event.target.value});
  }
  handleEvidenceDeletionOptions = (event) => {
    this.setState({'selectedEvidenceDeletionOptions': event.target.value});
  }
  handleSensitiveContextOptions = (event) => {
    this.setState({'selectedSensitiveContextOptions': event.target.value});
  }
  handleDatasetPersonalDataOptions = (event) => {
    this.setState({'selectedDatasetPersonalDataOptions': event.target.value});
  }
  handleAnonymizationToolSelection = (event) => {
    this.setState({'selectedAnonymizationToolOptions': event.target.value});
  }
  handleLicensePlatesSelection = (event) => {
    this.setState({'selectedLicensePlatesAnonymization': event.target.value});
  }
  handleImpactSelection = (event, type) => {
    

    let eventValue = event.target.value === 'Yes' ? true : false;
    
    switch (type) {
      case 'individual':
        //console.log('impact:', type);
        //console.log('eventValue:', eventValue);
    
        this.setState({
          impactOnIndividuals: eventValue,
        });
        break;
      case 'institutionalrep':
        this.setState({
          
          impactOnInstitutionalReputation: eventValue,
          
        });
        break;
      case 'institutionallegal':
        this.setState({
          
          impactOnInstitutionalLegal: eventValue,
          
        });
        break;
      case 'societal':
        this.setState({
          
          impactOnSocietal: eventValue
        });
        break;
      case 'ethical':
        this.setState({
          
          impactOnEthical: eventValue
        });
        break;
      case 'economical':
        this.setState({
          
          impactOnEconomical: eventValue
        });
        break;
      case 'technical':
        this.setState({
          
          impactOnTechnical: eventValue
        });
        break;
      case 'other':
        this.setState({
          
          impactOnOther: eventValue
        });
        break;
    
      default:
        break;
    }
  }
  handleImpactLevelSelection = (event, type) => {
    let currentImpactLevelOnIndividuals = this.state.impactLevelOnIndividuals;
    let currentImpactLevelOnInstitutionalRep = this.state.impactLevelOnInstitutionalRep;
    let currentImpactLevelOnInstitutionalLegal = this.state.impactLevelOnInstitutionalLegal;
    let currentImpactLevelOnSocietal = this.state.impactLevelOnSocietal;
    let currentImpactLevelOnEthical = this.state.impactLevelOnEthical;
    let currentImpactLevelOnEconomical = this.state.impactLevelOnEconomical;
    let currentImpactLevelOnTechnical = this.state.impactLevelOnTechnical;
    let currentImpactLevelOnOther = this.state.impactLevelOnOther;
    
    let impactLevel = event.target.value;

    switch (type) {
      case 'individual':
        currentImpactLevelOnIndividuals = impactLevel;
        break;

      case 'institutionalrep':
        currentImpactLevelOnInstitutionalRep = impactLevel;
        break;

      case 'institutionallegal':
        currentImpactLevelOnInstitutionalLegal = impactLevel;
        break;
      case 'societal':
        currentImpactLevelOnSocietal = impactLevel;
        break;
      case 'ethical':
        currentImpactLevelOnEthical = impactLevel;
        break;
      case 'economical':
        currentImpactLevelOnEconomical = impactLevel;
        break;
      case 'technical':
        currentImpactLevelOnTechnical = impactLevel;
        break;
      case 'other':
        currentImpactLevelOnOther = impactLevel;
        break;
    
      default:
        break;
    }

    //console.log('impactLevel:', impactLevel);

    this.setState({
      impactLevelOnIndividuals: currentImpactLevelOnIndividuals,
      impactLevelOnInstitutionalRep: currentImpactLevelOnInstitutionalRep,
      impactLevelOnInstitutionalLegal: currentImpactLevelOnInstitutionalLegal,
      impactLevelOnSocietalEthical: currentImpactLevelOnSocietal,
      impactLevelOnEthical: currentImpactLevelOnEthical,
      impactLevelOnEconomical: currentImpactLevelOnEconomical,
      impactLevelOnTechnical: currentImpactLevelOnTechnical,
      impactLevelOnOtherf: currentImpactLevelOnOther,
    })
  }

  handleTechnologyThreatsAnswers = (event, type) => {
    let currentIsDataAnonymized = this.state.isDataAnonymized;
    let currentAnonymizationPercentage = this.state.anonymizationPercentage;
    let currentIsAnonymizationToolVerified = this.state.isAnonymizationToolVerified;
    let currentAnonymizationThreatLevel = this.state.anonymizationThreatLevel;
    let currentAreThereDataAnnotations = this.state.areThereDataAnnotations;
    let currentDataAnnotationsThreatLevel = this.state.dataAnnotationsThreatLevel;
    let currentDataTransferSecure = this.state.dataTransferSecure;
    let currentDataTransferThreatLevel = this.state.dataTransferThreatLevel;
    let currentDataAccessProtection = this.state.dataAccessProtection;
    let currentDataAccessProtectionThreatLevel = this.state.dataAccessProtectionThreatLevel;

    let answer = event.target.value;

    switch (type) {
      case 'isDataAnonymized':
        currentIsDataAnonymized = answer;
        break;
      case 'anonymizationPercentage':
        currentAnonymizationPercentage = answer;
        break;
      case 'isAnonymizationToolVerified':
        currentIsAnonymizationToolVerified = answer;
        break;
      case 'anonymizationThreatLevel':
        currentAnonymizationThreatLevel = answer;
        break; 
      case 'areThereDataAnnotations':
        currentAreThereDataAnnotations = answer;
        break;
      case 'dataAnnotationsThreatLevel':
        currentDataAnnotationsThreatLevel = answer;
        break;
      case 'dataTransferSecure':
        currentDataTransferSecure = answer;
        break;
      case 'dataTransferThreatLevel':
        currentDataTransferThreatLevel = answer;
        break;
      case 'dataAccessProtection':
        currentDataAccessProtection = answer;
        break;
      case 'dataAccessProtectionThreatLevel':
        currentDataAccessProtectionThreatLevel = answer;
        break;
    
      default:
        break;
    }

    this.setState({
      isDataAnonymized: currentIsDataAnonymized,
      anonymizationPercentage: currentAnonymizationPercentage,
      isAnonymizationToolVerified: currentIsAnonymizationToolVerified,
      anonymizationThreatLevel: currentAnonymizationThreatLevel,
      areThereDataAnnotations: currentAreThereDataAnnotations,
      dataAnnotationsThreatLevel: currentDataAnnotationsThreatLevel,
      dataTransferSecure: currentDataTransferSecure,
      dataTransferThreatLevel: currentDataTransferThreatLevel,
      dataAccessProtection: currentDataAccessProtection,
      dataAccessProtectionThreatLevel: currentDataAccessProtectionThreatLevel,
    })

  }

  handlePeopleThreatsAnswers = (event, type) => {
    let currentPeopleEducation = this.state.peopleEducation;
    let currentPeopleEducationThreatLevel = this.state.peopleEducationThreatLevel;

    let answer = event.target.value;

    switch (type) {
      case 'peopleEducation':
        currentPeopleEducation = answer;
        break;

      case 'peopleEducationThreatLevel':
        currentPeopleEducationThreatLevel = answer;
        break;
    
      default:
        break;
    }

    this.setState({
      peopleEducation: currentPeopleEducation,
      peopleEducationThreatLevel: currentPeopleEducationThreatLevel,
    });
  }

  handleInstitutionalThreatsAnswers = (event, type) => {
    let currentInstitutionCybersecurity = this.state.institutionCybersecurity;
    let currentInstitutionCybersecurityThreatLevel = this.state.institutionCybersecurityThreatLevel;
    let currentInstitutionGovernance = this.state.institutionGovernance;
    let currentInstitutionGovernanceThreatLevel = this.state.institutionGovernanceThreatLevel;
    let currentInstitutionWorkLocationSecure = this.state.institutionWorkLocationSecure;
    let currentInstitutionWorkLocationSecureThreatLevel = this.state.institutionWorkLocationSecureThreatLevel;

    let answer = event.target.value;

    switch (type) {
      case 'institutionCybersecurity':
        currentInstitutionCybersecurity = answer;
        break;
      case 'institutionCybersecurityThreatLevel':
        currentInstitutionCybersecurityThreatLevel = answer;
        break;
      case 'institutionGovernance':
        currentInstitutionGovernance = answer;
        break;
      case 'institutionGovernanceThreatLevel':
        currentInstitutionGovernanceThreatLevel = answer;
        break;
      case 'institutionWorkLocationSecure':
        currentInstitutionWorkLocationSecure = answer;
        break;
      case 'institutionWorkLocationSecureThreatLevel':
        currentInstitutionWorkLocationSecureThreatLevel = answer;
        break;
    
      default:
        break;
    }

    this.setState({
      institutionCybersecurity: currentInstitutionCybersecurity,
      institutionCybersecurityThreatLevel: currentInstitutionCybersecurityThreatLevel,
      institutionGovernance: currentInstitutionGovernance,
      institutionGovernanceThreatLevel: currentInstitutionGovernanceThreatLevel,
      institutionWorkLocationSecure: currentInstitutionWorkLocationSecure,
      institutionWorkLocationSecureThreatLevel: currentInstitutionWorkLocationSecureThreatLevel
    })

  }
  handleLegalAnswers = (event, type) => {
    let currentLegalLicense = this.state.legalLicense;
    let currentLegalDistribution = this.state.legalDistribution;
    let currentLegalDistributionModified = this.state.legalDistributionModified;
    let currentLegalPublications = this.state.legalPublications;
    let currentLegalPermenant = this.state.legalPermenant;
    let currentLegalEvidenceOfDeletion = this.state.legalEvidenceOfDeletion;
    let currentLegalAnyDomain = this.state.legalAnyDomain;
    let currentLegalSubpopulation = this.state.legalSubpopulations;
    let currentLegalEthicalReview = this.state.legalEthicalReview;
    let currentLegalLegalReview = this.state.legalLegalReview;

    let answer = event.target.value;

    switch (type) {
      case 'legalLicense':
        currentLegalLicense = answer;
        break;
      case 'legalDistribution':
        currentLegalDistribution = answer;
        break;
      case 'legalDistributionModified':
        currentLegalDistributionModified = answer;
        break;
      case 'legalPublications':
        currentLegalPublications = answer;
        break;
      case 'legalPermenant':
        currentLegalPermenant = answer;
        break;
      case 'legalEvidenceOfDeletion':
        currentLegalEvidenceOfDeletion = answer;
        break;
      case 'legalAnyDomain':
        currentLegalAnyDomain = answer;
        break;
      case 'legalSubpopulations':
        currentLegalSubpopulation = answer;
        break;
      case 'legalEthicalReview':
        currentLegalEthicalReview = answer;
        break;
      case 'legalLegalReview':
        currentLegalLegalReview = answer;
        break;


      default:
        break;
    }

    this.setState({
      legalLicense: currentLegalLicense,
      legalDistribution: currentLegalDistribution,
      legalDistributionModified: currentLegalDistributionModified,
      legalPublications: currentLegalPublications,
      legalPermenant: currentLegalPermenant,
      legalEvidenceOfDeletion: currentLegalEvidenceOfDeletion,
      legalAnyDomain: currentLegalAnyDomain,
      legalSubpopulations: currentLegalSubpopulation,
      legalEthicalReview: currentLegalEthicalReview,
      legalLegalReview: currentLegalLegalReview,
    });


  }

  handleFinalThreatChange = (event, type) => {
    let currentFinalTechnologyThreatLevel = this.state.finalTechnologyThreatLevel;
    let currentFinalPeopleThreatLevel = this.state.finalPeopleThreatLevel;
    let currentFinalInstitutionalThreatLevel = this.state.finalInstitutionalThreatLevel;
    let currentFinalLegalThreatLevel = this.state.finalLegalThreatLevel;

    let answer = event.target.value;
    switch(type) {
      case 'technological':
        currentFinalTechnologyThreatLevel = answer;
        break;
      case 'people':
        currentFinalPeopleThreatLevel = answer;
        break;
      case 'institutional':
        currentFinalInstitutionalThreatLevel = answer;
        break;
      case 'legal':
        currentFinalLegalThreatLevel = answer;
        break;

      default:
        break;
    }

    this.setState({
      finalTechnologyThreatLevel: currentFinalTechnologyThreatLevel,
      finalPeopleThreatLevel: currentFinalPeopleThreatLevel,
      finalInstitutionalThreatLevel: currentFinalInstitutionalThreatLevel,
      finalLegalThreatLevel: currentFinalLegalThreatLevel,
    });
  }



  handleLevelOfImpactOnIndividual = (event) => {
    let levelOfImpactOnIndividuals = this.state.levelOfImpactOnIndividuals;

    switch (event.target.value) {
      case 'Minor':
        this.setState({
          levelOfImpactOnIndividuals: 'Minor',
        })
        break;
      case 'Major':
        this.setState({
          levelOfImpactOnIndividuals: 'Major',
        });
        break;

      default:
        break;
    }
  }
  hanldeIndividualsOvercome = (event) => {
    let individualsOvercome = this.state.individualsOvercome;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          individualsOvercome: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          individualsOvercome: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleDatasetContainsPersonalData = (event) => {
    let datasetContainsPersonalData = this.state.datasetContainsPersonalData;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          datasetContainsPersonalData: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          datasetContainsPersonalData: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleDatasetContainsSensitiveData = (event) => {
    let datasetContainsSensitiveData = this.state.datasetContainsSensitiveData;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          datasetContainsSensitiveData: 'Yes',
          datasetContainsPersonalData: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          datasetContainsSensitiveData: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleLevelOfLegalImplications = (event) => {
    let levelOfLegalImplications = this.state.levelOfLegalImplications;

    switch (event.target.value) {
      case 'Major':
        this.setState({
          levelOfLegalImplications: 'Major',
        })
        break;
      case 'Minor':
        this.setState({
          levelOfLegalImplications: 'Minor',
        })
        break;
    
      default:
        break;
    }
  }
  handleOrganizationContinueOperations = (event) => {
    let organizationContinueOperations = this.state.organizationContinueOperations;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          organizationContinueOperations: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          organizationContinueOperations: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleOrganizationLossOfTrust = (event) => {
    let organizationLossOfTrust = this.state.organizationLossOfTrust;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          organizationLossOfTrust: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          organizationLossOfTrust: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  

  calculateImpactLevel() {
    let impactOnIndividuals = this.state.impactOnIndividuals;
    let impactOnLegal = this.state.impactOnLegal;
    let impactOnTrust = this.state.impactOnTrust;
    let individualsOvercome = this.state.individualsOvercome;
    let levelOfImpactOnIndividuals = this.state.levelOfImpactOnIndividuals;

    let finalIndividualImpactLevel = '';
    let finalInstitutionalImpactLevel = '';

    if (impactOnIndividuals) {
      if (individualsOvercome === 'Yes') {
        if (levelOfImpactOnIndividuals === 'Major') {
          finalIndividualImpactLevel = 'High'
        } else {
          finalIndividualImpactLevel = 'Medium';
        }
      } else {
        finalIndividualImpactLevel = 'High';
      }
    } else {
      finalIndividualImpactLevel = 'Low';
    }

    console.log('finalIndividualImpactLevel:', finalIndividualImpactLevel);
    
    this.setState({
      finalIndividualImpactLevel: finalIndividualImpactLevel,
      finalInstitutionalImpactLevel: 'High',
      impactLevelCalculated: true,
    })
  }
  /*
  calculateThreatLevel() {
    let currentFinalTechnologyThreatLevel = this.state.finalTechnologyThreatLevel;
    let currentFinalPeopleThreatLevel = this.state.finalPeopleThreatLevel;
    let currentFinalInstitutionalThreatLevel = this.state.finalInstitutionalThreatLevel;
    let currentFinalLegalThreatLevel = this.state.finalLegalThreatLevel;
    
    let technologyScore = 0;
    if (this.state.isDataAnonymized === 'No' && this.state.datasetContainsPersonalData) {
      technologyScore += 8;
    } else if (this.state.isDataAnonymized === 'Yes' && this.state.datasetContainsPersonalData) {
      switch (this.state.anonymizationThreatLevel) {
        case 'Low':
          technologyScore += 1;
          break;
        case 'Medium':
          technologyScore += 2;
          break;
        case 'High':
          technologyScore += 3;
          break;

        default:
          break;
      }
    }
    switch(this.state.dataAnnotationsThreatLevel) {
      case 'Low':
        technologyScore += 1;
        break;
      case 'Medium':
        technologyScore += 2;
        break;
      case 'High':
        technologyScore += 3;
        break;

      default:
        break;
    }
    switch(this.state.dataTransferThreatLevel) {
      case 'Low':
        technologyScore += 1;
        break;
      case 'Medium':
        technologyScore += 2;
        break;
      case 'High':
        technologyScore += 3;
        break;

      default:
        break;
    }
    switch(this.state.dataAccessProtectionThreatLevel) {
      case 'Low':
        technologyScore += 1;
        break;
      case 'Medium':
        technologyScore += 2;
        break;
      case 'High':
        technologyScore += 3;
        break;

      default:
        break;
    }

    if (technologyScore <= 4) {
      currentFinalTechnologyThreatLevel = 'Low';
    } else if (technologyScore <= 8) {
      currentFinalTechnologyThreatLevel = 'Medium';
    } else if (technologyScore > 8) {
      currentFinalTechnologyThreatLevel = 'High';
    }
    
    let peopleScore = 0;
    switch (this.state.peopleEducationThreatLevel) {
      case 'Low':
        peopleScore += 1;
        break;
      case 'Medium':
        peopleScore += 2;
        break;
      case 'High':
        peopleScore += 3;
        break;
    
      default:
        break;
    }
    if (peopleScore <= 1) {
      currentFinalPeopleThreatLevel = 'Low';
    } else if (peopleScore <= 2) {
      currentFinalPeopleThreatLevel = 'Medium';
    } else if (peopleScore > 2) {
      currentFinalPeopleThreatLevel = 'High';
    }

    let institutionalScore = 0;
    switch(this.state.institutionCybersecurityThreatLevel) {
      case 'Low':
        institutionalScore += 1;
        break;
      case 'Medium':
        institutionalScore += 2;
        break;
      case 'High':
        institutionalScore += 3;
        break;

      default:
        break;
    }
    switch(this.state.institutionGovernanceThreatLevel) {
      case 'Low':
        institutionalScore += 1;
        break;
      case 'Medium':
        institutionalScore += 2;
        break;
      case 'High':
        institutionalScore += 3;
        break;

      default:
        break;
    }
    if (institutionalScore <= 2) {
      currentFinalInstitutionalThreatLevel = 'Low';
    } else if (technologyScore <= 4) {
      currentFinalInstitutionalThreatLevel = 'Medium';
    } else if (technologyScore > 4) {
      currentFinalInstitutionalThreatLevel = 'High';
    }

    let legalScore = 0;
    if (this.state.legalLicense === 'No') {
      legalScore += 10;
    }
    if (this.state.legalDistribution === 'Yes') {
      legalScore += 3;
    }
    if (this.state.legalDistributionModified === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalPublications === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalPermenant === 'No') {
      legalScore += 3;
    }
    if (this.state.legalEvidenceOfDeletion === 'No') {
      legalScore += 2;
    }
    if (this.state.legalAnyDomain === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalSubpopulations === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalEthicalReview === 'No') {
      legalScore += 1;
    }
    if (this.state.legalLegalReview === 'No') {
      legalScore += 1;
    }

    if (legalScore <= 3) {
      currentFinalLegalThreatLevel = 'Low';
    } else if (legalScore <= 6) {
      currentFinalLegalThreatLevel = 'Medium';
    } else if (legalScore > 6) {
      currentFinalLegalThreatLevel = 'High';
    }

    this.setState({
      finalTechnologyThreatLevel: currentFinalTechnologyThreatLevel,
      finalPeopleThreatLevel: currentFinalPeopleThreatLevel,
      finalInstitutionalThreatLevel: currentFinalInstitutionalThreatLevel,
      finalLegalThreatLevel: currentFinalLegalThreatLevel,
      threatLevelCalculated: true,
    })
  }

  */

  mapLevelToScore(level) {
    let score = 0;
    switch (level) {
      case 'None':
        score = 0;
        break;
      case 'Low':
        score = 1;
        break;
      case 'Medium':
        score = 2;
        break;
      case 'High':
        score = 3;
        break;
      case 'Very High':
        score = 4;
        break;
    
      default:
        break;
    }
    return score
  }


  calculateFinalRiskEvaluation() {
    let currentFinalRiskScoreEvaluation = this.state.finalRiskScoreEvaluation;
    

    //calculate impact level
    let impactScore = 0;
    let impactEvaluation = 'Low';
    let isImpactLow = true;
    let isImpactMedium = false;
    let isImpactHigh = false;
    let isImpactVeryHigh = false;

    console.log('impactLevelOnIndividuals:', this.state.impactLevelOnIndividuals);
    if(this.state.impactLevelOnIndividuals === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnIndividuals === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnIndividuals === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnIndividuals === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnSocietal === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnSocietal === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnSocietal === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnSocietal === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnEthical === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnEthical === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnEthical === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnEthical === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnEconomical === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnEconomical === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnEconomical === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnEconomical === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnTechnical === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnTechnical === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnTechnical === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnTechnical === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(isImpactVeryHigh) {
      impactEvaluation = 'Very High'
    } else if (isImpactHigh) {
      impactEvaluation = 'High'
    } else if (isImpactMedium) {
      impactEvaluation = 'Medium'
    } else if (isImpactLow) {
      impactEvaluation = "Low"
    }

    let threatScore = 0;
    let threatEvaluation = 'Low';

    let isThreatProbabilityLow = true;
    let isThreatProbabilityMedium = false;
    let isThreatProbabilityHigh = false;



    if(this.state.finalTechnologyThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalTechnologyThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalTechnologyThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if(this.state.finalPeopleThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalPeopleThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalPeopleThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if(this.state.finalInstitutionalThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalInstitutionalThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalInstitutionalThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if(this.state.finalLegalThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalLegalThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalLegalThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if (isThreatProbabilityHigh) {
      threatEvaluation = 'High'
    } else if (isThreatProbabilityMedium) {
      threatEvaluation = 'Medium'
    } else if (isThreatProbabilityLow) {
      threatEvaluation = "Low"
    }

    if (impactEvaluation === 'Low') {
      if (threatEvaluation === 'Low' || threatEvaluation === 'Medium') {
        currentFinalRiskScoreEvaluation = 'Low'
      }
      if (threatEvaluation === 'High') {
        currentFinalRiskScoreEvaluation = 'Medium'
      }
    } else if (impactEvaluation === 'Medium') {
      if (threatEvaluation === 'Low' || threatEvaluation === 'Medium') {
        currentFinalRiskScoreEvaluation = 'Medium'
      }
      if (threatEvaluation === 'High') {
        currentFinalRiskScoreEvaluation = 'High'
      }
    } else if (impactEvaluation === 'High' || impactEvaluation === 'Very High') {
        currentFinalRiskScoreEvaluation = 'High'
    }

    let currentFinalRiskTable = [];
    let currentFinalMitigationTable = [];

    if(this.state.datasetContainsPersonalData === 'Yes' && this.state.isDataAnonymized === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m1.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m1.mitigationAction}</li>);
    }
    if(this.state.isDataAnonymized === 'Yes' && this.state.isAnonymizationToolVerified === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m2.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m2.mitigationAction}</li>);
    }
    if(this.state.datasetContainsSensitiveData === 'Yes' && this.state.isDataAnonymized === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m3.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m3.mitigationAction}</li>);
    }
    if(this.state.dataTransferSecure === 'No' || (this.state.dataTransferSecure === 'Yes' && this.state.dataTransferThreatLevel === 'Medium') || (this.state.dataTransferSecure === 'Yes' && this.state.dataTransferThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m4.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m4.mitigationAction}</li>);
    }
    if(this.state.dataAccessProtection === 'No' || (this.state.dataAccessProtection === 'Yes' && this.state.dataAccessProtectionThreatLevel === 'Medium') || (this.state.dataAccessProtection === 'Yes' && this.state.dataAccessProtectionThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m5.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m5.mitigationAction}</li>);
    }
    if(this.state.peopleEducation === 'No' || (this.state.peopleEducation === 'Yes' && this.state.peopleEducationThreatLevel === 'Medium') || (this.state.peopleEducation === 'Yes' && this.state.peopleEducationThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m6.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m6.mitigationAction}</li>);
    }
    if(this.state.institutionCybersecurity === 'No' || (this.state.institutionCybersecurity === 'Yes' && this.state.institutionCybersecurityThreatLevel === 'Medium') || (this.state.institutionCybersecurity === 'Yes' && this.state.institutionCybersecurityThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m7.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m7.mitigationAction}</li>);
    }
    if(this.state.institutionGovernance === 'No' || (this.state.institutionGovernance === 'Yes' && this.state.institutionGovernanceThreatLevel === 'Medium') || (this.state.institutionGovernance === 'Yes' && this.state.institutionGovernanceThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m8.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m8.mitigationAction}</li>);
    }
    if(this.state.legalLicense === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m9.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m9.mitigationAction}</li>);
    }
    if(this.state.legalDistribution === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m10.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m10.mitigationAction}</li>);
    }
    if(this.state.legalPublications === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m11.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m11.mitigationAction}</li>);
    }
    if(this.state.legalPermenant === 'Yes') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m12.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m12.mitigationAction}</li>);
    }
    if(this.state.legalEvidenceOfDeletion === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m13.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m13.mitigationAction}</li>);
    }
    if(this.state.legalAnyDomain === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m14.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m14.mitigationAction}</li>);
    }
    

    this.setState({
      finalImpactEvaluation: impactEvaluation,
      finalThreatEvaluation: threatEvaluation,
      finalRiskTable: currentFinalRiskTable,
      finalMitigationTable: currentFinalMitigationTable,
      finalRiskScoreEvaluation: currentFinalRiskScoreEvaluation
    })
  }
  exportRiskAssessmentDataToJson() {
    let riskData = {};

    riskData.toolDetails = {
      "toolName": "Risk Assessment",
      "toolVersion": 0.5,
      "jsonOutputVersion": 0.5,
    }

    //Export Section 1: Definition and Context of the Sharing Operation
    riskData.generalDetails = {
      "riskAssessmentTitle": this.state.generalInfo.riskAssessmentTitle,
      "userName": this.state.generalInfo.yourName,
      "organizationName": this.state.generalInfo.nameOfYourOrganization,
      "organizationType": this.state.generalInfo.typeOfOrganizationEntity,
      "purposeOfSharing": this.state.generalInfo.purposeOfSharing,
      "nameOfRequestingEntity": this.state.generalInfo.nameOfRequestingEntity,
      "typeOfRequestingEntity": this.state.generalInfo.typeOfRequestingEntity,
      "customTypeOfRequestingEntity": this.state.generalInfo.customTypeOfRequestingEntity,
      "comments": this.state.generalInfo.comments, 
    }
    let dataTypes = this.state.selectedDataTypes;
    let specialDataTypes = this.state.selectedSpecialDataTypes;
    let dataFormats = this.state.selectedDataFormat;
    console.log('selectedDataTypes:', this.state.selectedDataTypes);
    let dataTypeNames = [];
    let specialDataTypeNames = [];
    let dataFormatNames = [];

    for (let i = 0; i < dataTypes.length; i++ ) {
      console.log('dataTypes[i]:', dataTypes[i]);
      dataTypeNames.push(dataTypes[i].label);
    };
    for (let i = 0; i < specialDataTypes.length; i++ ) {
      specialDataTypeNames.push(specialDataTypes[i].label);
    };
    for (let i = 0; i < dataFormats.length; i++ ) {
      dataFormatNames.push(dataFormats[i].label);
    };
   
    
    riskData.generalDetails.dataTypes = dataTypeNames;
    riskData.generalDetails.specialDataTypes = specialDataTypeNames;
    riskData.generalDetails.dataFormats = dataFormatNames;
    
    //Export Section 2: Impact Evaluation
    riskData.impactLevels = this.state.impactLevels;

    //Export Section 3: Threat Analysis
    riskData.threatLevels = this.state.threatLevels;

    //Export Section 4: Risk Evaluation
    riskData.riskLevel = this.state.riskLevel;

    //Export Section 5: Mitigation & Control
    riskData.mitigationActions = this.state.mitigationActions;

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(riskData, null, 2)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "risk-assessment-v0.5-export.json";

    link.click();
  }

  exportRiskAssessmentDataToJsonOld() {
    let riskData = {};

    riskData.toolDetails = {
      "toolName": "Risk Assessment",
      "toolVersion": 0.5,
      "jsonOutputVersion": 0.5,
    }
    riskData.userDetails = {
      "userName": this.state.generalInfo.yourName,
      "organizationName": this.state.generalInfo.nameOfYourOrganization,
    }
    riskData.risk = {
      "name": "Unauthorized Access",
      "data": {
        "generalInfo": {
          "riskAssessmentTitle": this.state.generalInfo.riskAssessmentTitle,
          "comments": this.state.generalInfo.comments,
          "purposeOfSharing": this.state.generalInfo.purposeOfSharing,
          "typeOfSharingData": this.state.generalInfo.typeOfSharingData,
          "nameOfRequestingEntity": this.state.generalInfo.nameOfRequestingEntity,
          "typeOfRequestingEntity": this.state.generalInfo.typeOfRequestingEntity,
          "customTypeOfRequestingEntity": this.state.generalInfo.customTypeOfRequestingEntity,
        },
        "impactLevelAssessment": {
          "datasetNature": this.state.selectedDatasetNature,
        }
      }
    }
    

    let dataTypes = this.state.selectedDataTypes;
    let specialDataTypes = this.state.selectedSpecialDataTypes;
    let dataFormats = this.state.selectedDataFormat;

    let dataTypeNames = [];
    let specialDataTypeNames = [];
    let dataFormatNames = [];

    dataTypes.forEach((value, index, array) => {
      dataTypeNames.push(value.label);
    });
    specialDataTypes.forEach((value, index, array) => {
      specialDataTypeNames.push(value.label);
    });
    dataFormats.forEach((value, index, array) => {
      dataFormatNames.push(value.label);
    });

    riskData.risk.data.impactLevelAssessment.dataTypes = dataTypeNames;
    riskData.risk.data.impactLevelAssessment.specialDataTypes = specialDataTypeNames;
    riskData.risk.data.impactLevelAssessment.dataFormats = dataFormatNames;
    riskData.risk.data.impactLevelAssessment.areasOfEffect = [];
    riskData.risk.data.impactLevelAssessment.areasOfEffect.push(
      {
        "area": "Individuals",
        "questionsAndAnswers": [
          {
            "question": "Is there any impact to individuals?",
            "answer": this.state.impactOnIndividuals ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level to individuals?",
            "answer": this.state.impactLevelOnIndividuals
          },
          {
            "question": "Does the dataset contain personal data?",
            "answer": this.state.datasetContainsPersonalData
          },
          {
            "question": "Does the dataset contain sensitive data?",
            "answer": this.state.datasetContainsSensitiveData
          },
        
        ],
        "evaluation": this.state.finalIndividualImpactLevel
      },
      {
        "area": "Institutional (Reputation/Brand)",
        "questionsAndAnswers": [
          {
            "question": "Is there any impact for the organization in terms of its reputation or brand?",
            "answer": this.state.impactOnInstitutionalReputation ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnInstitutionalRep
          },
        ],
        "evaluation": this.state.impactLevelOnInstitutionalRep
      },
      {
        "area": "Institutional (Legal)",
        "questionsAndAnswers": [
          {
            "question": "Is there any legal impact for the organization?",
            "answer": this.state.impactOnInstitutionalLegal ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnInstitutionalLegal
          },
        ],
        "evaluation": this.state.impactLevelOnInstitutionalLegal
      },
      {
        "area": "Societal",
        "questionsAndAnswers": [
          {
            "question": "Is there any societal impact?",
            "answer": this.state.impactOnSocietal ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnSocietal
          },
        ],
        "evaluation": this.state.impactLevelOnSocietal
      },
      {
        "area": "Ethical",
        "questionsAndAnswers": [
          {
            "question": "Is there any ethical impact?",
            "answer": this.state.impactOnEthical ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnEthical
          },
        ],
        "evaluation": this.state.impactLevelOnEthical
      },
      {
        "area": "Economical",
        "questionsAndAnswers": [
          {
            "question": "Is there any economical impact?",
            "answer": this.state.impactOnEconomical ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnEconomical
          },
        ],
        "evaluation": this.state.impactLevelOnEconomical
      },
      {
        "area": "Technical",
        "questionsAndAnswers": [
          {
            "question": "Is there any technical impact?",
            "answer": this.state.impactOnTechnical ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnTechnical
          },
        ],
        "evaluation": this.state.impactLevelOnTechnical
      },
    );
    riskData.risk.data.initialImpactLevels = [
      {
        "area": "Individuals",
        "level": this.state.impactLevelOnIndividuals
      },
      {
        "area": "Institutional (Reputation/Brand)",
        "level": this.state.impactLevelOnInstitutionalRep
      },
      {
        "area": "Institutional (Legal)",
        "level": this.state.impactLevelOnInstitutionalLegal
      },
      {
        "area": "Societal",
        "level": this.state.impactLevelOnSocietal
      },
      {
        "area": "Ethical",
        "level": this.state.impactLevelOnEthical
      },
      {
        "area": "Economical",
        "level": this.state.impactLevelOnEconomical
      },
      {
        "area": "Technical",
        "level": this.state.impactLevelOnTechnical
      }
    ]
    riskData.risk.data.userImpactLevels = [
      {
        "area": "Individuals",
        "level": this.state.impactLevelOnIndividuals
      },
      {
        "area": "Institutional (Reputation/Brand)",
        "level": this.state.impactLevelOnInstitutionalRep
      },
      {
        "area": "Institutional (Legal)",
        "level": this.state.impactLevelOnInstitutionalLegal
      },
      {
        "area": "Societal",
        "level": this.state.impactLevelOnSocietal
      },
      {
        "area": "Ethical",
        "level": this.state.impactLevelOnEthical
      },
      {
        "area": "Economical",
        "level": this.state.impactLevelOnEconomical
      },
      {
        "area": "Technical",
        "level": this.state.impactLevelOnTechnical
      }
    ]
    riskData.risk.data.wereImpactLevelsOverridenByUser = false;
    riskData.risk.data.finalImpactLevelAssessment = this.state.finalImpactEvaluation;

    riskData.risk.data.threatLevelAssessment = [
      {
        "area": "Technology",
        "questionsAndAnswers": [
          {
            "question": "Are the datasets anonymized?",
            "answer": this.state.isDataAnonymized
          },
          {
            "question": "What is the percentage of anonymization success?",
            "answer": this.state.anonymizationPercentage
          },
          {
            "question": "Is the anonymization tool verfified by a third party?",
            "answer": this.state.isAnonymizationToolVerified
          },
          {
            "question": "Are there any data annotations?",
            "answer": this.state.areThereDataAnnotations
          },
          {
            "question": "Data annotations threat level:",
            "answer": this.state.dataAnnotationsThreatLevel
          },
          {
            "question": "Is the data transfer method secure?",
            "answer": this.state.dataTransferSecure
          },
          {
            "question": "Transfer method threat level:",
            "answer": this.state.dataTransferThreatLevel
          },
          {
            "question": "Does the requester protect data by restricting access to it?",
            "answer": this.state.dataAccessProtection
          },
          {
            "question": "Data access protection threat level:",
            "answer": this.state.dataAccessProtectionThreatLevel
          },
        ],
        "evaluation": this.finalTechnologyThreatLevel
      },
      {
        "area": "Parties/People involved",
        "questionsAndAnswers": [
          {
            "question": "Do the people handling the data have experience in handling sensitive data?",
            "answer": this.state.peopleEducation
          },
          {
            "question": "Threat level related to people:",
            "answer": this.state.peopleEducationThreatLevel
          },
        ],
        "evaluation": this.state.finalPeopleThreatLevel
      },
      {
        "area": "Institutional threats",
        "questionsAndAnswers": [
          {
            "question": "Does the requester have strong cybersecurity protocols in their premises?",
            "answer": this.state.institutionCybersecurity
          },
          {
            "question": "Cybersecurity threat level:",
            "answer": this.state.institutionCybersecurityThreatLevel
          },
          {
            "question": "Does the requester implement a data governance scheme on their premises?",
            "answer": this.state.institutionGovernance
          },
          {
            "question": "Data governance threat level:",
            "answer": this.state.institutionGovernanceThreatLevel
          },
        ],
        "evaluation": this.state.finalInstitutionalThreatLevel
      },
      {
        "area": "Legal threats",
        "questionsAndAnswers": [
          {
            "question": "Do you have a license agreement with the requester?",
            "answer": this.state.legalLicense
          },
          {
            "question": "Does the license allow distribution of the dataset to other parties?",
            "answer": this.state.legalDistribution
          },
          {
            "question": "May the licensee distribute the modified and adapted work?",
            "answer": this.state.legalDistributionModified
          },
          {
            "question": "Can parts of the dataset be published in scientific publications?",
            "answer": this.state.legalPublications
          },
          {
            "question": "Is the license permanent?",
            "answer": this.state.legalPermenant
          },
          {
            "question": "In the event that the license is terminated, shall the Licensee provide evidence of the deletion?",
            "answer": this.state.legalEvidenceOfDeletion
          },
          {
            "question": "Can the dataset be used in any domain/industry?",
            "answer": this.state.legalAnyDomain
          },
          {
            "question": "Does the dataset identify any subpopulations?",
            "answer": this.state.legalSubpopulations
          },
          {
            "question": "Has the dataset undergo an ethical review?",
            "answer": this.state.legalEthicalReview
          },
          {
            "question": "Has the dataset undergo a legal review? (GDPR, AI use, etc)?",
            "answer": this.state.legalLegalReview
          },
        ],
        "evaluation": this.state.finalLegalThreatLevel
      },
    ];
    riskData.risk.data.initialThreatOccuranceProbabilityLevels = [
      {
        "area": "Technological threats",
        "level": this.state.finalTechnologyThreatLevel
      },
      {
        "area": "People/Parties Involved",
        "level": this.state.finalPeopleThreatLevel
      },
      {
        "area": "Institutional threats",
        "level": this.state.finalInstitutionalThreatLevel
      },
      {
        "area": "Legal threats",
        "level": this.state.finalLegalThreatLevel
      }
    ];
    riskData.risk.data.userThreatOccuranceProbabilityLevels = [
      {
        "area": "Technological threats",
        "level": this.state.finalTechnologyThreatLevel
      },
      {
        "area": "People/Parties Involved",
        "level": this.state.finalPeopleThreatLevel
      },
      {
        "area": "Institutional threats",
        "level": this.state.finalInstitutionalThreatLevel
      },
      {
        "area": "Legal threats",
        "level": this.state.finalLegalThreatLevel
      }
    ];
    riskData.risk.data.wereThreatLevelsOverridenByUser = false;
    riskData.risk.data.finalThreatOccuranceProbabilityLevelAssessment = this.state.finalThreatEvaluation;

    riskData.risk.evaluation = {};
    riskData.risk.evaluation.finalRiskLevelAssessment = this.state.finalRiskScoreEvaluation;
    riskData.risk.evaluation.explanation = [];
    
    //console.log('riskTable:', this.state.finalRiskTable);
    this.state.finalRiskTable.forEach((risk, index, array) => {
      //console.log('risk:', risk.props.children);
      riskData.risk.evaluation.explanation.push(risk.props.children);
    });
    riskData.risk.mitigation = [];
    this.state.finalMitigationTable.forEach((mitigation, index, array) => {
      riskData.risk.mitigation.push(mitigation.props.children);
    });

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(riskData, null, 2)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "risk-assessment-v0.2-export.json";

    link.click();
  }

  render() {

    let generalInfoForm = 
    <>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Risk Assessment Title</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldInput type="text" placeholder='Descriptive title of the sharing operation' defaultValue={this.state.generalInfo.riskAssessmentTitle} onChange={(event) => this.onGeneralInfoChange(event, 'riskAssessmentTitle')}></S.GeneralFormFieldInput>
    </S.GeneralFormField>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Your Name</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldInput type="text" placeholder='Name of the person completing this risk assessment' defaultValue={this.state.generalInfo.yourName} onChange={(event) => this.onGeneralInfoChange(event, 'yourName')}></S.GeneralFormFieldInput>
    </S.GeneralFormField>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Name of your organization</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldInput type="text" placeholder='Name of the organization completing this risk assessment' defaultValue={this.state.generalInfo.nameOfYourOrganization} onChange={(event) => this.onGeneralInfoChange(event, 'nameOfYourOrganization')}></S.GeneralFormFieldInput>
    </S.GeneralFormField>
    <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Type of your organization</S.GeneralFormFieldLabel>
        {/*<Dropdown key='requestingEntity' defaultValue={this.state.generalInfo.typeOfRequestingEntity} onChange={(event) => this.onGeneralInfoChange(event, 'typeOfRequestingEntity')} buttonText=""> {this.state.entityOptions} </Dropdown>*/}
        <DropdownSelect key='organizationType' defaultValue={this.state.selectedOrganizationEntityType} isClearable={true} onChange={this.handleSelectedOrganizationEntityTypeUpdate} options={this.state.organizationEntityOptions}></DropdownSelect>
      </S.GeneralFormField>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Is this risk assessment about a Dataset or a Model?</S.GeneralFormFieldLabel>
        {/*<Dropdown key='datasetModel' defaultValue={this.state.selectedDatasetModel} buttonText="" onChange={this.handleDatasetModelChange}> {this.state.datasetModelOptions} </Dropdown>*/}
        <DropdownSelect key='datasetModel' defaultValue={this.state.selectedDatasetModel} isClearable={true} onChange={this.handleDatasetModelChange} options={this.state.datasetModelOptions}></DropdownSelect>
      </S.GeneralFormField>
    {this.state.generalInfo.objectOfAssessment === 'Dataset' && <>
    <S.GeneralFormField>
        <S.GeneralFormFieldLabel>What data types are present in the dataset?</S.GeneralFormFieldLabel>
          <MySelect key='data-types' placeholder='Please select all data types present in the dataset' defaultValue={this.state.selectedDataTypes} onChange={this.handleDataTypeChange} options={this.state.dataTypeOptions} />
        </S.GeneralFormField>
        <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Select any special data types that are present in the dataset.</S.GeneralFormFieldLabel>
          <MySelect key='special-data-types' placeholder='Please select any special data type present in the dataset' defaultValue={this.state.selectedSpecialDataTypes} onChange={this.handleSpecialDataTypeChange} options={this.state.specialDataTypeOptions} />  
        </S.GeneralFormField>

        <S.GeneralFormField>
        <S.GeneralFormFieldLabel>What data formats are present in the dataset?</S.GeneralFormFieldLabel>
          <MySelect key='data-formats' placeholder='Please select all data formats' defaultValue={this.state.selectedDataFormat} onChange={this.handleDataFormatSelection} options={this.state.dataFormatOptions}/>
        </S.GeneralFormField>
        <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Dataset Nature</S.GeneralFormFieldLabel>
          <DropdownSelect key='dataset-nature' placeholder='Does the dataset contain real data?' defaultValue={this.state.selectedDatasetNature} isClearable={false} onChange={this.handleDatasetNatureSelection} options= {this.state.datasetNatureOptions} ></DropdownSelect>
        </S.GeneralFormField>
    
    </>}
    <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Purpose of sharing</S.GeneralFormFieldLabel>
        {/*<S.GeneralFormFieldTextArea placeholder='Describe the purpose for this sharing operation' defaultValue={this.state.generalInfo.purposeOfSharing} onChange={(event) => this.onGeneralInfoChange(event, 'purposeOfSharing')}></S.GeneralFormFieldTextArea>*/}
        <DropdownSelect key='purposeOfSharing' defaultValue={this.state.selectedPurposeOfSharing} isClearable={true} onChange={this.handlePurposeOfSharingChange} options={this.state.purposeOfSharingOptions}></DropdownSelect>
      </S.GeneralFormField>
      <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Name of requesting entity</S.GeneralFormFieldLabel>
        <S.GeneralFormFieldInput type="text" placeholder='What is the name of the requester'  defaultValue={this.state.generalInfo.nameOfRequestingEntity} onChange={(event) => this.onGeneralInfoChange(event, 'nameOfRequestingEntity')}></S.GeneralFormFieldInput>
      </S.GeneralFormField>
      <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Type of requesting entity</S.GeneralFormFieldLabel>
        {/*<Dropdown key='requestingEntity' defaultValue={this.state.generalInfo.typeOfRequestingEntity} onChange={(event) => this.onGeneralInfoChange(event, 'typeOfRequestingEntity')} buttonText=""> {this.state.entityOptions} </Dropdown>*/}
        <DropdownSelect key='requestingEntity'  defaultValue={this.state.selectedRequestingEntityType} isClearable={true} onChange={this.handleSelectedRequestingEntityTypeUpdate} options={this.state.requestingEntityOptions}></DropdownSelect>
      </S.GeneralFormField>
      {this.state.generalInfo.typeOfRequestingEntity === 'Other' && 
        <S.GeneralFormField>
          <S.GeneralFormFieldLabel>What is the type of requesting entity?</S.GeneralFormFieldLabel>
          <S.GeneralFormFieldTextArea placeholder='Please describe the type of the requesting entity' defaultValue={this.state.generalInfo.customTypeOfRequestingEntity} onChange={(event) => this.onGeneralInfoChange(event, 'customTypeOfRequestingEntity')}></S.GeneralFormFieldTextArea>
        </S.GeneralFormField>
      }
      <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Comments</S.GeneralFormFieldLabel>
        <S.GeneralFormFieldTextArea placeholder='Please add any comment you would like to add for future reference' 
          defaultValue={this.state.generalInfo.comments} onChange={(event) => this.onGeneralInfoChange(event, 'comments')}></S.GeneralFormFieldTextArea>
      </S.GeneralFormField>
      </>

      let riskIdentificationForm = <>
        <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Data types</S.GeneralFormFieldLabel>
          <MySelect key='data-types' placeholder='Please select all data types present in the dataset' defaultValue={this.state.selectedDataTypes} onChange={this.handleDataTypeChange} options={this.state.dataTypeOptions} />
        </S.GeneralFormField>
        <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Special Data Types</S.GeneralFormFieldLabel>
          <MySelect key='special-data-types'  defaultValue={this.state.selectedSpecialDataTypes} onChange={this.handleSpecialDataTypeChange} options={this.state.specialDataTypeOptions} />  
        </S.GeneralFormField>
        <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Data Formats</S.GeneralFormFieldLabel>
          <MySelect key='data-formats' defaultValue={this.state.selectedDataFormat} onChange={this.handleDataFormatSelection} options={this.state.dataFormatOptions}/>
        </S.GeneralFormField>
        <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Dataset Nature</S.GeneralFormFieldLabel>
          <DropdownSelect key='dataset-nature' defaultValue={this.state.selectedDatasetNature} isClearable={false} onChange={this.handleDatasetNatureSelection} options= {this.state.datasetNatureOptions}></DropdownSelect>
        </S.GeneralFormField>
          
      </>


      //console.log('this.state.generalInfo.typeOfRequestingEntity: ', this.state.generalInfo.typeOfRequestingEntity);
      let sharingInfoForm = <></>

      
      
      let individualImpactFinal = <Dropdown key='individualImpactFinal' defaultValue={this.state.impactLevelOnIndividuals} onChange={(event) => this.handleImpactLevelSelection(event, 'individual')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let institutionalRepImpactFinal = <Dropdown key='institutionalRepImpactFinal' defaultValue={this.state.impactLevelOnInstitutionalRep} onChange={(event) => this.handleImpactLevelSelection(event, 'institutionalrep')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let institutionalLegalImpactFinal = <Dropdown key='institutionalLegalImpactFinal' defaultValue={this.state.impactLevelOnInstitutionalLegal} onChange={(event) => this.handleImpactLevelSelection(event, 'institutionallegal')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let societalImpactFinal = <Dropdown key='societalImpactFinal' defaultValue={this.state.impactLevelOnSocietal} onChange={(event) => this.handleImpactLevelSelection(event, 'societal')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let ethicalImpactFinal = <Dropdown key='ethicalImpactFinal' defaultValue={this.state.impactLevelOnEthical} onChange={(event) => this.handleImpactLevelSelection(event, 'ethical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let economicalImpactFinal = <Dropdown key='economicalImpactFinal' defaultValue={this.state.impactLevelOnEconomical} onChange={(event) => this.handleImpactLevelSelection(event, 'economical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let technicalImpactFinal = <Dropdown key='technicalImpactFinal' defaultValue={this.state.impactLevelOnTechnical} onChange={(event) => this.handleImpactLevelSelection(event, 'technical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let dataForImpactLevelFinal = [
        {"impactassessment": 'Unauthorized dataset access', 
        "aoeindividuals": individualImpactFinal, 
        "aoeinstitutionrep": institutionalRepImpactFinal,
        "aoeinstitutionlegal": institutionalLegalImpactFinal,
        "aoesocietal": societalImpactFinal,
        "aoeethical": ethicalImpactFinal,
        "aoeeconomical": economicalImpactFinal,
        "aoetechnical": technicalImpactFinal,
      },
      ];

      let columnsForTechnologicalThreats = [
        { Header: 'Technological Threats', accessor: 'technologicalthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let isDataAnonymizedDropDown = <Dropdown key='areDataAnonymized' defaultValue={this.state.isDataAnonymized} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'isDataAnonymized')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>;
      let anonymizationSuccessPercentageDropDown = <Dropdown key='anonymizationSuccessPercentageDropDown' defaultValue={this.state.anonymizationPercentage} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'anonymizationPercentage')} buttonText=""> {this.state.percentageDropdownOptions} </Dropdown>;
      let isAnonymizationToolVerified = <Dropdown key='isAnonymizationToolVerified' defaultValue={this.state.isAnonymizationToolVerified} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'isAnonymizationToolVerified')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>;
      let areThereDataAnnotationsDropdown = <Dropdown key='areThereDataAnnotationsDropdown' defaultValue={this.state.areThereDataAnnotations} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'areThereDataAnnotations')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>;
      let dataAnnotationsThreatLevelDropdown = <Dropdown key='dataAnnotationsThreatLevelDropdown' defaultValue={this.state.dataAnnotationsThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAnnotationsThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let isDataTransferSecureDropdown = <Dropdown key='isDataTransferSecureDropdown' defaultValue={this.state.dataTransferSecure} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataTransferSecure')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>
      let isDataTransferSecureThreatLevelDropdown = <Dropdown key='isDataTransferSecureThreatLevelDropdown' defaultValue={this.state.dataTransferThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataTransferThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let dataAccessProtectionDropdown = <Dropdown key='dataAccessProtectionDropdown' defaultValue={this.state.dataAccessProtection} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAccessProtection')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let dataAccessProtectionThreatLevelDropdown = <Dropdown key='dataAccessProtectionThreatLevelDropdown' defaultValue={this.state.dataAccessProtectionThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAccessProtectionThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;

      let commentForTechnologicalThreats1 = <><p>Data access must be restricted to only the appropriate personel.</p><p>Access to specialists should be renewed at certain times per year for example, logged activites should be observed occasionally. </p><p>In some cases, specialists have too great access rights and this is unnecessary for their tasks, thus they can access more data than they should. Also, sometimes one specialist lets the other to use their account etc.</p></>
      let dataForTechnologicalThreats = [
        {'technologicalthreats': 'Are the datasets anonymized?', 'answer': isDataAnonymizedDropDown, 'comments': 'Personal data must be protected accorind to GDPR therefore anonymization is strongly recommended when sharing personal data.'},
        //{'technologicalthreats': 'Is the success rate 100%', 'answer': yesNoDropDown, 'comments': 'Is the annonymization tool was not able to anonymize all instances of personal data, there may be identifiable personal data still in the dataset.'},
        {'technologicalthreats': 'What is the percentage of anonymization success?', 'answer': anonymizationSuccessPercentageDropDown, 'comments': 'The percentage of success defines how many instances of personal data were anonymized successfully. If the annonymization tool was not able to anonymize all instances of personal data, there may be identifiable personal data still in the dataset.'},
        {'technologicalthreats': 'Is the anonymization tool verfified by a third party?', 'answer': isAnonymizationToolVerified, 'comments': 'Anonymization tools play an important role in masking personal data. A verification of the anonymization tool by an independent third party will ensure that the tool performs the job as it should.'},
        {'technologicalthreats': 'Are there any data annotations?', 'answer': areThereDataAnnotationsDropdown, 'comments': 'Data annotations can reveal sensitive information about the dataset. We should make sure that the annotations do not contain sensitive data.'},
        {'technologicalthreats': 'Data annotations threat level: ', 'answer': dataAnnotationsThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
        {'technologicalthreats': 'Is the data transfer method secure?', 'answer': isDataTransferSecureDropdown, 'comments': 'A secure data transfer method with end-to-end encryption provides the most secure way to transfer data. Make sure the chosen method of transfer is encrypted from end-to-end.'},
        {'technologicalthreats': 'Transfer method threat level: ', 'answer': isDataTransferSecureThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
        {'technologicalthreats': 'Does the requester protect data by restricting access to it?', 'answer': dataAccessProtectionDropdown, 'comments': commentForTechnologicalThreats1},
        {'technologicalthreats': 'Data access protection threat level: ', 'answer': dataAccessProtectionThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
      ];
      let columnsForPeopleThreats = [
        { Header: 'Parties/People Threats', accessor: 'peoplethreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let peopleEducationDropdown = <Dropdown key='peopleEducationDropdown' defaultValue={this.state.peopleEducation} onChange={(event) => this.handlePeopleThreatsAnswers(event, 'peopleEducation')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let peopleEducationThreatLevelDropdown = <Dropdown key='peopleEducationThreatLevelDropdown' defaultValue={this.state.peopleEducationThreatLevel} onChange={(event) => this.handlePeopleThreatsAnswers(event, 'peopleEducationThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let peopleEducationComment1 = <><p>Sensitive data must be handled with care and the persons that handle these data should have received proper education/guidance.</p><p>Sometimes, People are unaware of what they are allowed to do and use, they attach their computers to open Wi-Fi etc. \nPeople used social media and channels without end-to-end encryption. People keep the data on their personal accounts or clouds that are not considered safe.</p><p> A specialist doesn't use appropriate secrity measures (for example leaves their work computer open and accessible), accidentally sends information without encryption or to a wrong channel (e-mail, system ets), does not know legal basis for data sharing and does not confirm</p></>
      let dataForPeopleThreats = [
        { 'peoplethreats': 'Do the people handling the data have experience in handling sensitive data?', 'answer': peopleEducationDropdown, 'comments': peopleEducationComment1},
        { 'peoplethreats': 'Threat level related to people: ', 'answer': peopleEducationThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
      ];
      let columnsForInstitutionalThreats = [
        { Header: 'Institutional Threats', accessor: 'institutionalthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let institutionCybersecurityDropdown = <Dropdown key='institutionCybersecurityDropdown' defaultValue={this.state.institutionCybersecurity} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionCybersecurity')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let institutionCybersecurityThreatLevelDropdown = <Dropdown key='institutionCybersecurityThreatLevelDropdown' defaultValue={this.state.institutionCybersecurityThreatLevel} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionCybersecurityThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let institutionGovernanceDropdown = <Dropdown key='institutionGovernanceDropdown' defaultValue={this.state.institutionGovernance} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionGovernance')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let institutionGovernanceThreatLevelDropdown = <Dropdown key='institutionGovernanceThreatLevelDropdown' defaultValue={this.state.institutionGovernanceThreatLevel} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionGovernanceThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let commentForCybersecurity1 = <><p>Cybersecurity is imperative for any organization that handles sensitive data. Internal systems must be constantly monitored for attacks.</p><p>Organizations should be audited by external cybersecurity experts. </p><p>Sometimes, people work from home or open offices where not anyone should be able to access data, programs and systems, other people can get access to their computers and workspace, there are no set rules for working outside the regular workspace, VPN or other security measures are not used, Wi-Fi channel is not secure. </p><p>Has the requester been attacked the last two years? If yes, there is a strong chance the necessary changes to strengthen the security of their organization have not been implemented yet.</p></>
      let dataForInstitutionalThreats = [
        { 'institutionalthreats': 'Does the requester have strong cybersecurity protocols in their premises?', 'answer': institutionCybersecurityDropdown, 'comments': commentForCybersecurity1},
        { 'institutionalthreats': 'Cybersecurity threat level: ', 'answer': institutionCybersecurityThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
        
        { 'institutionalthreats': 'Does the requester implement a data governance scheme on their premises?', 'answer': institutionGovernanceDropdown, 'comments': "When handling sensitive data it is important to maintain limits to who can access which data at any time. Data governance refers to the policies and standards an organization creates around how their data is collected, stored, activated, and deleted. Having control over the data's lifecycle is important for sensitive FCT data."},
        { 'institutionalthreats': 'Data governance threat level: ', 'answer': institutionGovernanceThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
      
      ];
      let columnsForLegalThreats = [
        { Header: 'Legal & Ethical Threats', accessor: 'legalthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let legalLicenseDropdown = <Dropdown key='legalLicenseDropdown' defaultValue={this.state.legalLicense} onChange={(event) => this.handleLegalAnswers(event, 'legalLicense')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalDistributionDropdown = <Dropdown key='legalDistributionDropdown' defaultValue={this.state.legalDistribution} onChange={(event) => this.handleLegalAnswers(event, 'legalDistribution')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalDistributionModifiedDropdown = <Dropdown key='legalDistributionModifiedDropdown' defaultValue={this.state.legalDistributionModified}  onChange={(event) => this.handleLegalAnswers(event, 'legalDistributionModified')}buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalPublicationsDropdown = <Dropdown key='legalPublicationsDropdown' defaultValue={this.state.legalPublications} onChange={(event) => this.handleLegalAnswers(event, 'legalPublications')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalPermenantDropdown = <Dropdown key='legalPermenantDropdown' defaultValue={this.state.legalPermenant} onChange={(event) => this.handleLegalAnswers(event, 'legalPermenant')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalEvidenceOfDeletionDropdown = <Dropdown key='legalEvidenceOfDeletionDropdown' defaultValue={this.state.legalEvidenceOfDeletion} onChange={(event) => this.handleLegalAnswers(event, 'legalEvidenceOfDeletion')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalAnyDomainDropdown = <Dropdown key='legalAnyDomainDropdown' defaultValue={this.state.legalAnyDomain} onChange={(event) => this.handleLegalAnswers(event, 'legalAnyDomain')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalSubpopulationsDropdown = <Dropdown key='legalSubpopulationsDropdown' defaultValue={this.state.legalSubpopulations} onChange={(event) => this.handleLegalAnswers(event, 'legalSubpopulations')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalEthicalReviewDropdown = <Dropdown key='legalEthicalReviewDropdown' defaultValue={this.state.legalEthicalReview} onChange={(event) => this.handleLegalAnswers(event, 'legalEthicalReview')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalLegalReviewDropdown = <Dropdown key='legalAnyDomainDroplegalLegalReviewDropdowndown' defaultValue={this.state.legalLegalReview} onChange={(event) => this.handleLegalAnswers(event, 'legalLegalReview')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;

      let dataForLegalThreats = [
        { 'legalthreats': 'Do you have a license agreement with the requester?', 'answer': legalLicenseDropdown, 'comments': 'FCT research may involve sensitive data that is subject to legal and ethical regulations. Failure to comply with these regulations can lead to reputational damage, legal penalties, and loss of funding'},
        { 'legalthreats': 'Does the license allow distribution of the dataset to other parties?', 'answer': legalDistributionDropdown, 'comments': ''},
        { 'legalthreats': 'May the licensee distribute the modified and adapted work?', 'answer': legalDistributionModifiedDropdown, 'comments': ''},
        { 'legalthreats': 'Can parts of the dataset be published in scientific publications?', 'answer': legalPublicationsDropdown, 'comments': ''},
        { 'legalthreats': 'Is the license permanent?', 'answer': legalPermenantDropdown, 'comments': ''},
        { 'legalthreats': 'In the event that the license is terminated, shall the Licensee provide evidence of the deletion?', 'answer': legalEvidenceOfDeletionDropdown, 'comments': ''},
        { 'legalthreats': 'Can the dataset be used in any domain/industry?', 'answer': legalAnyDomainDropdown, 'comments': ''},
        { 'legalthreats': 'Does the dataset identify any subpopulations?', 'answer': legalSubpopulationsDropdown, 'comments': ''},
        { 'legalthreats': 'Has the dataset undergo an ethical review?', 'answer': legalEthicalReviewDropdown, 'comments': ''},
        { 'legalthreats': 'Has the dataset undergo a legal review? (GDPR, AI use, etc)?', 'answer': legalLegalReviewDropdown, 'comments': ''},
      ];
      let columnsForOtherThreats = [
        { Header: 'Other Threats', accessor: 'otherthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let dataForOtherThreats = [
      ];
      let columnsForThreatLevelFinal = [
        { Header: 'Assessment Area', accessor: 'assessmentarea'},
        { Header: 'Probability Level', accessor: 'probabilitylevel'},
        
      ];
      let technologyThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalTechnologyThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'technological')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      let peopleThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalPeopleThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'people')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      let institutionalThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalInstitutionalThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'institutional')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      let legalThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalLegalThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'legal')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      

      let scoreOfTechnologicalThreats = this.mapLevelToScore(this.state.finalTechnologyThreatLevel);
      let dataForThreatLevelFinal = [
        {"assessmentarea": 'Technology threats', "probabilitylevel": technologyThreatFinal},
        {"assessmentarea": 'People/Parties involved', "probabilitylevel": peopleThreatFinal},
        {"assessmentarea": 'Institutional related threats', "probabilitylevel": institutionalThreatFinal},
        {"assessmentarea": 'Legal threats', "probabilitylevel": legalThreatFinal},
      ];
      let finalRiskCalculation = this.state.finalRiskScoreEvaluation;
      
      console.log('state.generalInfo: ', this.state.generalInfo);

      
      
      const impactChartDataTotal = {
        labels: ['Personal', 'Institutional', 'Societal'],
        datasets: [
          {
            label: 'Unauthorised Access',   
            data: [
              this.mapLevelToScore(this.state.impactLevels.confidentiality.levels.personal.level),
              this.mapLevelToScore(this.state.impactLevels.confidentiality.levels.institutional.level),
              this.mapLevelToScore(this.state.impactLevels.confidentiality.levels.societal.level),
            ],
            backgroundColor: 'rgb(115, 170, 242, 0.2)',
            borderColor: 'rgb(115, 170, 242)',
            borderWidth: 3,
          },
          {
            label: 'Biased Dataset',   
            data: [
              this.mapLevelToScore(this.state.impactLevels.bias.levels.personal.level),
              this.mapLevelToScore(this.state.impactLevels.bias.levels.institutional.level),
              this.mapLevelToScore(this.state.impactLevels.bias.levels.societal.level),
            ],
            backgroundColor: 'rgb(238, 108, 77, 0.2)',
            borderColor: 'rgb(238, 108, 77)',
            borderWidth: 3,
          },
          {
            label: 'Legal Non-Compliance',   
            data: [
              this.mapLevelToScore(this.state.impactLevels.legal.levels.personal.level),
              this.mapLevelToScore(this.state.impactLevels.legal.levels.institutional.level),
              this.mapLevelToScore(this.state.impactLevels.legal.levels.societal.level),
            ],
            backgroundColor: 'rgb(92, 131, 152, 0.2)',
            borderColor: 'rgb(92, 131, 152)',
            borderWidth: 3,
          },
          {
            label: 'Data Misuse',   
            data: [
              this.mapLevelToScore(this.state.impactLevels.misuse.levels.personal.level),
              this.mapLevelToScore(this.state.impactLevels.misuse.levels.institutional.level),
              this.mapLevelToScore(this.state.impactLevels.misuse.levels.societal.level),
            ],
            backgroundColor: 'rgb(248, 169, 2, 0.2)',
            borderColor: 'rgb(248, 169, 2)',
            borderWidth: 3,
          },
        ],
      };
      const riskChartDataTotal = {
        labels: ['Personal', 'Institutional', 'Societal'],
        datasets: [
          {
            label: 'Unauthorised Access',   
            data: [
              this.mapLevelToScore(this.state.riskLevel.unauthorized.personal),
              this.mapLevelToScore(this.state.riskLevel.unauthorized.institutional),
              this.mapLevelToScore(this.state.riskLevel.unauthorized.societal),
            ],
            backgroundColor: 'rgb(115, 170, 242, 0.2)',
            borderColor: 'rgb(115, 170, 242)',
            borderWidth: 3,
          },
          {
            label: 'Low Data Quality',   
            data: [
              this.mapLevelToScore(this.state.riskLevel.bias.personal),
              this.mapLevelToScore(this.state.riskLevel.bias.institutional),
              this.mapLevelToScore(this.state.riskLevel.bias.societal),
            ],
            backgroundColor: 'rgb(238, 108, 77, 0.2)',
            borderColor: 'rgb(238, 108, 77)',
            borderWidth: 3,
          },
          {
            label: 'Legal Non-Compliance',   
            data: [
              this.mapLevelToScore(this.state.riskLevel.legal.personal),
              this.mapLevelToScore(this.state.riskLevel.legal.institutional),
              this.mapLevelToScore(this.state.riskLevel.legal.societal),
            ],
            backgroundColor: 'rgb(92, 131, 152, 0.2)',
            borderColor: 'rgb(92, 131, 152)',
            borderWidth: 3,
          },
          {
            label: 'Data Misuse',   
            data: [
              this.mapLevelToScore(this.state.riskLevel.misuse.personal),
              this.mapLevelToScore(this.state.riskLevel.misuse.institutional),
              this.mapLevelToScore(this.state.riskLevel.misuse.societal),
            ],
            backgroundColor: 'rgb(248, 169, 2, 0.2)',
            borderColor: 'rgb(248, 169, 2)',
            borderWidth: 3,
          },
        ],
      }
      const impactChartDataBias = {
        labels: ['Personal', 'Institutional', 'Societal'],
        datasets: [
          {
            label: 'Biased Dataset',   
            data: [
              this.mapLevelToScore(this.state.impactLevels.bias.levels.personal.level),
              this.mapLevelToScore(this.state.impactLevels.bias.levels.institutional.level),
              this.mapLevelToScore(this.state.impactLevels.bias.levels.societal.level),
            ],
            backgroundColor: '#3D5A80B1',
            borderColor: '#98C1D8',
            borderWidth: 1,
          },
        ],
      };
      const impactChartDataLegal = {
        labels: ['Personal', 'Institutional', 'Societal'],
        datasets: [
          {
            label: 'Lack of Legal Compliance',   
            data: [
              this.mapLevelToScore(this.state.impactLevels.legal.levels.personal.level),
              this.mapLevelToScore(this.state.impactLevels.legal.levels.institutional.level),
              this.mapLevelToScore(this.state.impactLevels.legal.levels.societal.level),
            ],
            backgroundColor: '#3D5A80B1',
            borderColor: '#98C1D8',
            borderWidth: 1,
          },
        ],
      };

      let columnsForThreatEvaluationTable = [{
        Header: 'Risk',
        accessor: 'risk'
        },
        {
          Header: 'Threat Level',
          accessor: 'level'
        }
      ];
      let dataForThreatEvaluationTable = [
        {"risk": "Unauthorized Access (loss of confidentiality)", "level": this.state.threatLevels.total.unauthorized},
        {"risk": "Biased Dataset", "level": this.state.threatLevels.total.bias},
        {"risk": "Legal Non-Compliance", "level": this.state.threatLevels.total.legal},
        {"risk": "Data Misuse", "level": this.state.threatLevels.total.misuse},
      ];
      
      //section 2: overall impact evaluation
      let columnsForImpactEvaluationTable = [
        { Header: 'Risk', accessor: 'risk'},
        { Header: 'Personal Impact Level', accessor: 'personallevel'},
        { Header: 'Institutional Impact Level', accessor: 'institutionallevel'},
        { Header: 'Societal Impact Level', accessor: 'societallevel'},
      ];
      
      let dataForImpactEvaluationTable = [
        {'risk': 'Unauthorized Access (loss of confidentiality)', 'personallevel': this.state.impactLevels.confidentiality.levels.personal.level, 'institutionallevel': this.state.impactLevels.confidentiality.levels.institutional.level, 'societallevel':this.state.impactLevels.confidentiality.levels.societal.level},
        {'risk': 'Biased Dataset', 'personallevel': this.state.impactLevels.bias.levels.personal.level, 'institutionallevel': this.state.impactLevels.bias.levels.institutional.level, 'societallevel':this.state.impactLevels.bias.levels.societal.level},
        {'risk': 'Legal Non-Compliance', 'personallevel': this.state.impactLevels.legal.levels.personal.level, 'institutionallevel': this.state.impactLevels.legal.levels.institutional.level, 'societallevel':this.state.impactLevels.legal.levels.societal.level},
        {'risk': 'Data Misuse', 'personallevel': this.state.impactLevels.misuse.levels.personal.level, 'institutionallevel': this.state.impactLevels.misuse.levels.institutional.level, 'societallevel':this.state.impactLevels.misuse.levels.societal.level},
      ];

      //section 4: calculate risk levels
      console.log('this.state.riskLevel:', this.state.riskLevel);
      let columnsForRiskEvaluationTable = [
        { Header: 'Risk', accessor: 'risk'},
        { Header: 'Personal Risk Level', accessor: 'personallevel'},
        { Header: 'Institutional Risk Level', accessor: 'institutionallevel'},
        { Header: 'Societal Risk Level', accessor: 'societallevel'},
      ];
      
      let dataForRiskEvaluationTable = [
        {'risk': 'Unauthorized Access (loss of confidentiality)', 'personallevel': this.riskLevelColoredSpan(this.state.riskLevel.unauthorized.personal), 'institutionallevel': this.riskLevelColoredSpan(this.state.riskLevel.unauthorized.institutional), 'societallevel':this.riskLevelColoredSpan(this.state.riskLevel.unauthorized.societal)},
        {'risk': 'Biased Dataset', 'personallevel': this.riskLevelColoredSpan(this.state.riskLevel.bias.personal), 'institutionallevel': this.riskLevelColoredSpan(this.state.riskLevel.bias.institutional), 'societallevel':this.riskLevelColoredSpan(this.state.riskLevel.bias.societal)},
        {'risk': 'Legal Non-Compliance', 'personallevel': this.riskLevelColoredSpan(this.state.riskLevel.legal.personal), 'institutionallevel': this.riskLevelColoredSpan(this.state.riskLevel.legal.institutional), 'societallevel':this.riskLevelColoredSpan(this.state.riskLevel.legal.societal)},
        {'risk': 'Data Misuse', 'personallevel': this.riskLevelColoredSpan(this.state.riskLevel.misuse.personal), 'institutionallevel': this.riskLevelColoredSpan(this.state.riskLevel.misuse.institutional), 'societallevel':this.riskLevelColoredSpan(this.state.riskLevel.misuse.societal)},
      ]
      let columnsForMitigationActionsTable = [
        { Header: 'Risk Factor', accessor: 'riskfactor'},
        { Header: 'Mitigation Actions', accessor: 'action'},
      ]
      //section 5: mitigation actions

      /*
      let dataForRiskEvaluationTable = [
        { 'risk':"Unauthorized Access (loss of confidentiality)", 
          'personalLevel': "Low",
          'institutionalLevel': "Low",
          'societalLevel': "Low",
        },
        { 'risk':"Biased Dataset", 
          "personalLevel": this.state.riskLevel.bias.personal,
          "institutionalLevel": this.state.riskLevel.bias.institutional,
          "societalLevel": this.state.riskLevel.bias.societal,
        },
        { 'risk':"Legal Non-Compliance", 
          "personalLevel": this.state.riskLevel.legal.personal,
          "institutionalLevel": this.state.riskLevel.legal.institutional,
          "societalLevel": this.state.riskLevel.legal.societal,
        },
        { 'risk':"Data Misuse", 
          "personalLevel": this.state.riskLevel.misuse.personal,
          "institutionalLevel": this.state.riskLevel.misuse.institutional,
          "societalLevel": this.state.riskLevel.misuse.societal,
        },
      ];
      */
      console.log('columnsForRisk:', columnsForRiskEvaluationTable)
      console.log('dataForRisk:', dataForRiskEvaluationTable)

      const data = {/* Pass your data here  */}
      
      

      
    return(
           
      <>
      <S.PageWidth>
      
      <ScrollButton/>
        <S.Navigation><S.InnerVerticalMenu>
          {this.state.datasetSectionsMenu.map((item, index) => {
            return(
              <>
              <S.RiskSidebarMenuLinkDiv 
                active={item.active} 
                enabled={item.enabled}
                onClick={() => this.handleMenuClick(item)}>
              <S.Circle active={item.active} enabled={item.enabled}>{item.id}</S.Circle>
                {item.title}
              </S.RiskSidebarMenuLinkDiv>
              {item.subNav && item.enabled && 
                item.subNav.map((item, index) => {
                  
                  return(
                    <>
                    <S.RiskSidebarSubMenuLinkDiv 
                      active={item.active} 
                      enabled={item.enabled}
                      onClick={() => this.handleMenuClick(item)}>
                    <S.Square active={item.active} enabled={item.enabled}>{item.id}</S.Square>
                      {item.title}
                    </S.RiskSidebarSubMenuLinkDiv>
                    {item.subNav && item.enabled && 
                      item.subNav.map((subItem, index) => {
                        console.log('subNav.subNav')
                        return(
                          <S.RiskSidebarSubSubMenuLinkDiv 
                            active={subItem.active} 
                            enabled={subItem.enabled}
                            onClick={() => this.handleMenuClick(subItem)}>
                          <S.NewSquare active={subItem.active} enabled={subItem.enabled}>{subItem.id}</S.NewSquare>
                            {subItem.title}
                          </S.RiskSidebarSubSubMenuLinkDiv>
                          
                        )})
                    }
                    </>
                  )})
              }
              </>);
          })}
        </S.InnerVerticalMenu></S.Navigation>
      
      <S.MainInnerContent >
      
      {/*Section 1: Definition and Context of the Sharing Operation*/}
      {this.state.datasetSectionsMenu[0].active && <>
        
          <S.Card id="inner-content">
            <S.CardBody>
              <ScrollToTop />
              <p>Sharing FCT data can introduce the data provider to a range of risks from legal fines to irreperable damage to the institution's reputation and continuity of operations, 
                if these data are somehow leaked or inappropriately used. Equally important however, is the impact to individuals and society from personal data exposure, biased research, 
                inappropriate or misconfigured AI models, etc. Therefore, it is imperative to evaluate a sharing operation not only from a security perspective but also from other aspects like
                ethical, societal and legal aspects. The objective of this risk assessment evaluation tool is to account for the safe sharing of FCT data for research. Safe for the individual,
                 the institution and society.</p>
              
              <S.BoxDiv>Please note that none of the data/information entered in our platform is saved and it will not be available should the browser is closed. 
                It is therefore advisable that you perform the whole assessment at once. </S.BoxDiv>
                <p>At the last step of the risk assessment, you will be able to export all the information entered along to identified level of risk of the processing operations
                   in addition the proposed security (technical and organizational) measures (in PDF format).</p>
              <S.CardTitle><h4 class="section-title" >1. Definition and Context of the Sharing Operation</h4></S.CardTitle>
                <p>This step is the starting point of the risk assessment and is fundamental in order to define the relevant context of the sharing
                   operation. In doing so, the organization needs to consider certain aspects of the sharing operation regarding the data itself
                  (types of data, nature, formats etc.) and also the type of the requesting entity (organization, LEA, etc). Specific attention 
                  has to be paid to the fact that the analysis below regards a specific sharing operation. If you participate in several sharing
                  activities, the analysis below has to be performed for each sharing operation independently.</p>
                {generalInfoForm}
            </S.CardBody>
            </S.Card>
          <S.ButtonFlexLeft>
            <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
              className="normalButton" onClick={() => this.nextStep('general', 'impact')}>Continue</button> 
          </S.ButtonFlexLeft>
      
      </>}

      {/*Section 2: Impact Evaluation*/}

      {this.state.datasetSectionsMenu[1].active && <>
        <S.Card id="section2">
          <S.CardBody>
            <ScrollToTop />
            <S.CardTitle><h4 class="section-title" >2. Impact Evaluation</h4></S.CardTitle>
            <p>Based on the analysis of Step 1, the data provider at this stage must assess the impact on several areas (individuals, institutions,
              society) resulting from a possible unauthorized access, biased dataset or a legal compliance failure. Four levels of impact are considered 
              (Low, Medium, High, Very High) for each area of effect as shown in the tables below.
            </p>
            <S.CardSecondTitle>Impact table for individuals</S.CardSecondTitle>
            <TableDiv>
              <Table columns={columnsForImpactTablePersonal} data={dataForImpactTablePersonal} />
            </TableDiv>
            <S.CardSecondTitle>Impact table for the institution</S.CardSecondTitle>
            <TableDiv>
            <Table columns={columnsForImpactTableInstitutional} data={dataForImpactTableInstitutional} />
            </TableDiv>
            
            <S.CardSecondTitle>Impact table for society</S.CardSecondTitle>
            <TableDiv>
            <Table columns={columnsForImpactTableSocietal} data={dataForImpactTableSocietal} />
            </TableDiv>
            
            <p>The evaluation of impact is a qualitative process and a number of factors need to be considered
               by the data provider, such as the types of FCT data, the presence of personal data, the 
               criticality of the sharing operation, volume of personal data, etc.</p>
            
          </S.CardBody>
        </S.Card>
        <S.ButtonFlexLeft>
            <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
              className="normalButton" onClick={() => this.nextStep('impact', 'impactsub1')}>Continue</button> 
          </S.ButtonFlexLeft>
      </>}

      {/*Section 2a: Unauthorised Access*/}
      {this.state.datasetSectionsMenu[1].subNav[0].active && <>
        <S.Card id="section2a"><S.CardBody>
          <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >2a. Unauthorised Access (Loss of Confidentiallity)</h4></S.CardTitle>
            
            <p>Please reflect on the impact an unauthorised access could have on the individuals, your institution and our society, 
            and express a rating accordingly. Please take into account the type of data present in the dataset being shared and 
            consult the correspoding impact table for personal, institutional and societal impact.</p>
            
            <S.CardContentExamples>
              <S.CardContentExamplesTitle>
                Examples/Scenarios for unauthorised access (loss of confidentiality):
              </S.CardContentExamplesTitle>
              <S.CardContentExamplesList>
                {P.examplesDatasetConfidentiality.map((item, index) => {
                  return(<li>{item}</li>)
                })}
              </S.CardContentExamplesList>
            </S.CardContentExamples>
            
            <S.CardSection>
              <Form style={{width:'100%', marginTop:'20px'}}>
                <Form.Group className="mb-3" controlId="formConfidentialityIndividualImpact">
                <Form.Label style={{fontWeight:'bold'}}>Personal impact (Impact on Individuals)</Form.Label>
                  <div key='inline-impact-personal-radio' className="mb-3">
                    <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'personal', false, event)} label="None" name="group1" id="formImpactIndividualNone" defaultChecked={this.state.impactLevels.confidentiality.levels.personal.level === 'None' ? true : false}/>
                    <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'personal', false, event)} label="Low" name="group1" id="formImpactIndividualLow" defaultChecked={this.state.impactLevels.confidentiality.levels.personal.level === 'Low' ? true : false}/>
                    <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'personal', false, event)} label="Medium" name="group1" id="formImpactIndividualMedium" defaultChecked={this.state.impactLevels.confidentiality.levels.personal.level === 'Medium' ? true : false}/>
                    <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'personal', false, event)} label="High" name="group1" id="formImpactIndividualHigh" defaultChecked={this.state.impactLevels.confidentiality.levels.personal.level === 'High' ? true : false}/>
                    <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'personal', false, event)} label="Very High" name="group1" id="formImpactIndividualVeryHigh" defaultChecked={this.state.impactLevels.confidentiality.levels.personal.level === 'Very High' ? true : false}/>
                  </div>
                </Form.Group>
              </Form>
              <S.GeneralFormField>
                <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
                <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                  defaultValue={this.state.impactLevels.confidentiality.levels.personal.comments} onChange={(event) => this.onChangeImpactLevel('confidentiality', 'personal', true, event)}></S.GeneralFormFieldTextArea>
              </S.GeneralFormField>
            </S.CardSection>
            <S.CardSection>
              <Form style={{width:'100%'}}>
                <Form.Group className="mb-3" controlId="formConfidentialityInstitutionalImpact">
                <Form.Label style={{fontWeight:'bold'}}>Institutional impact (Impact on Your Organisation)</Form.Label>
                  <div key='inline-impact-institutional-radio' className="mb-3">
                    <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'institutional', false, event)} label="None" name="group1" id="formImpactInstitutionalNone" defaultChecked={this.state.impactLevels.confidentiality.levels.institutional.level === 'None' ? true : false}/>
                    <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'institutional', false, event)} label="Low" name="group1" id="formImpactInstitutionalLow" defaultChecked={this.state.impactLevels.confidentiality.levels.institutional.level === 'Low' ? true : false}/>
                    <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'institutional', false, event)} label="Medium" name="group1" id="formImpactInstitutionalMedium" defaultChecked={this.state.impactLevels.confidentiality.levels.institutional.level === 'Medium' ? true : false}/>
                    <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'institutional', false, event)} label="High" name="group1" id="formImpactInstitutionalHigh" defaultChecked={this.state.impactLevels.confidentiality.levels.institutional.level === 'High' ? true : false}/>
                    <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'institutional', false, event)} label="Very High" name="group1" id="formImpactInstitutionalVeryHigh" defaultChecked={this.state.impactLevels.confidentiality.levels.institutional.level === 'Very High' ? true : false}/>
                  </div>
                </Form.Group>
              </Form>
              <S.GeneralFormField>
                <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
                <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                  defaultValue={this.state.impactLevels.confidentiality.levels.institutional.comments} onChange={(event) => this.onChangeImpactLevel('confidentiality', 'institutional', true, event)}></S.GeneralFormFieldTextArea>
              </S.GeneralFormField>
            </S.CardSection>
            <S.CardSection>
              <Form style={{width:'100%'}}>
                <Form.Group className="mb-3" controlId="formConfidentialitySocietalImpact">
                <Form.Label style={{fontWeight:'bold'}}>Societal impact</Form.Label>
                  <div key='inline-impact-societal-radio' className="mb-3">
                    <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'societal', false, event)} label="None" name="group1" id="formImpactSocietalNone" defaultChecked={this.state.impactLevels.confidentiality.levels.societal.level === 'None' ? true : false}/>
                    <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'societal', false, event)} label="Low" name="group1" id="formImpactSocietalLow" defaultChecked={this.state.impactLevels.confidentiality.levels.societal.level === 'Low' ? true : false}/>
                    <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'societal', false, event)} label="Medium" name="group1" id="formImpactSocietalMedium" defaultChecked={this.state.impactLevels.confidentiality.levels.societal.level === 'Medium' ? true : false}/>
                    <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'societal', false, event)} label="High" name="group1" id="formImpactSocietalHigh" defaultChecked={this.state.impactLevels.confidentiality.levels.societal.level === 'High' ? true : false}/>
                    <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('confidentiality', 'societal', false, event)} label="Very High" name="group1" id="formImpactSocietalVeryHigh" defaultChecked={this.state.impactLevels.confidentiality.levels.societal.level === 'Very High' ? true : false}/>
                  </div>
                </Form.Group>
              </Form>
              <S.GeneralFormField>
                <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
                <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                  defaultValue={this.state.impactLevels.confidentiality.levels.societal.comments} onChange={(event) => this.onChangeImpactLevel('confidentiality', 'societal', true, event)}></S.GeneralFormFieldTextArea>
              </S.GeneralFormField>
            </S.CardSection>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
            <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
              className="normalButton" onClick={() => this.nextStep('impactSub1', 'impactSub2')}>Continue</button> 
          </S.ButtonFlexLeft>
        </>}

      {/*Section 2b: Bias*/}
      {this.state.datasetSectionsMenu[1].subNav[1].active && <>
      <S.Card><S.CardBody>
        <ScrollToTop />
        <S.CardTitle><h4 class="section-title" id='section2b'>2b. Low Quality Data</h4></S.CardTitle>
          
          <p>Please reflect on the impact that sharing a low quality dataset (biased, incomplete, etc) could have on the individuals, your institution and our society, 
          and express a rating accordingly. Please take into account the type of data present in the dataset being shared and 
          consult the correspoding impact table for personal, institutional and societal impact.</p>
          
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples/Scenarios for biased dataset :
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDatasetBias.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          
          <S.CardSection>
            <Form style={{width:'100%', marginTop:'20px'}}>
              <Form.Group className="mb-3" controlId="formConfidentialityIndividualImpact">
              <Form.Label style={{fontWeight:'bold'}}>Personal impact (Impact on Individuals)</Form.Label>
                <div key='inline-impact-personal-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('bias', 'personal', false, event)} label="None" name="group1" id="formImpactIndividualNone" defaultChecked={this.state.impactLevels.bias.levels.personal.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('bias', 'personal', false, event)} label="Low" name="group1" id="formImpactIndividualLow" defaultChecked={this.state.impactLevels.bias.levels.personal.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('bias', 'personal', false, event)} label="Medium" name="group1" id="formImpactIndividualMedium" defaultChecked={this.state.impactLevels.bias.levels.personal.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('bias', 'personal', false, event)} label="High" name="group1" id="formImpactIndividualHigh" defaultChecked={this.state.impactLevels.bias.levels.personal.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('bias', 'personal', false, event)} label="Very High" name="group1" id="formImpactIndividualVeryHigh" defaultChecked={this.state.impactLevels.bias.levels.personal.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.bias.levels.personal.comments} onChange={(event) => this.onChangeImpactLevel('bias', 'personal', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formConfidentialityInstitutionalImpact">
              <Form.Label style={{fontWeight:'bold'}}>Institutional impact (Impact on Your Organisation)</Form.Label>
                <div key='inline-impact-institutional-radio' className="mb-3" color="#000">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('bias', 'institutional', false, event)} label="None" name="group1" id="formImpactInstitutionalNone" defaultChecked={this.state.impactLevels.bias.levels.institutional.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('bias', 'institutional', false, event)} label="Low" name="group1" id="formImpactInstitutionalLow" defaultChecked={this.state.impactLevels.bias.levels.institutional.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('bias', 'institutional', false, event)} label="Medium" name="group1" id="formImpactInstitutionalMedium" defaultChecked={this.state.impactLevels.bias.levels.institutional.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('bias', 'institutional', false, event)} label="High" name="group1" id="formImpactInstitutionalHigh" defaultChecked={this.state.impactLevels.bias.levels.institutional.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('bias', 'institutional', false, event)} label="Very High" name="group1" id="formImpactInstitutionalVeryHigh" defaultChecked={this.state.impactLevels.bias.levels.institutional.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.bias.levels.institutional.comments} onChange={(event) => this.onChangeImpactLevel('bias', 'institutional', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formConfidentialitySocietalImpact">
              <Form.Label style={{fontWeight:'bold'}}>Societal impact</Form.Label>
                <div key='inline-impact-societal-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('bias', 'societal', false, event)} label="None" name="group1" id="formImpactSocietalNone" defaultChecked={this.state.impactLevels.bias.levels.societal.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('bias', 'societal', false, event)} label="Low" name="group1" id="formImpactSocietalLow" defaultChecked={this.state.impactLevels.bias.levels.societal.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('bias', 'societal', false, event)} label="Medium" name="group1" id="formImpactSocietalMedium" defaultChecked={this.state.impactLevels.bias.levels.societal.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('bias', 'societal', false, event)} label="High" name="group1" id="formImpactSocietalHigh" defaultChecked={this.state.impactLevels.bias.levels.societal.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('bias', 'societal', false, event)} label="Very High" name="group1" id="formImpactSocietalVeryHigh" defaultChecked={this.state.impactLevels.bias.levels.societal.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.bias.levels.societal.comments} onChange={(event) => this.onChangeImpactLevel('bias', 'societal', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
      </S.CardBody></S.Card>
      <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('impactSub2', 'impactSub3')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 2c: Legal Compliance*/}
      {this.state.datasetSectionsMenu[1].subNav[2].active && <>
      <S.Card><S.CardBody>
        <ScrollToTop />
        <S.CardTitle><h4 class="section-title"  id='section2c'>2c. Legal Compliance</h4></S.CardTitle>
          
          <p>Please reflect on the impact that not covering the legal basis for sharing these data could have on the individuals, your institution and our society, 
          and express a rating accordingly. Please take into account the type of data present in the dataset being shared and 
          consult the correspoding impact table for personal, institutional and societal impact.</p>
          
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples/Scenarios for lack of legal compliance :
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDatasetLegalCompliance.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          
          <S.CardSection>
            <Form style={{width:'100%', marginTop:'20px'}}>
              <Form.Group className="mb-3" controlId="formConfidentialityIndividualImpact">
              <Form.Label style={{fontWeight:'bold'}}>Personal impact (Impact on Individuals)</Form.Label>
                <div key='inline-impact-personal-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('legal', 'personal', false, event)} label="None" name="group1" id="formImpactIndividualNone" defaultChecked={this.state.impactLevels.legal.levels.personal.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('legal', 'personal', false, event)} label="Low" name="group1" id="formImpactIndividualLow" defaultChecked={this.state.impactLevels.legal.levels.personal.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('legal', 'personal', false, event)} label="Medium" name="group1" id="formImpactIndividualMedium" defaultChecked={this.state.impactLevels.legal.levels.personal.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('legal', 'personal', false, event)} label="High" name="group1" id="formImpactIndividualHigh" defaultChecked={this.state.impactLevels.legal.levels.personal.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('legal', 'personal', false, event)} label="Very High" name="group1" id="formImpactIndividualVeryHigh" defaultChecked={this.state.impactLevels.legal.levels.personal.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.legal.levels.personal.comments} onChange={(event) => this.onChangeImpactLevel('legal', 'personal', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formConfidentialityInstitutionalImpact">
              <Form.Label style={{fontWeight:'bold'}}>Institutional impact (Impact on Your Organisation)</Form.Label>
                <div key='inline-impact-institutional-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('legal', 'institutional', false, event)} label="None" name="group1" id="formImpactInstitutionalNone" defaultChecked={this.state.impactLevels.legal.levels.institutional.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('legal', 'institutional', false, event)} label="Low" name="group1" id="formImpactInstitutionalLow" defaultChecked={this.state.impactLevels.legal.levels.institutional.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('legal', 'institutional', false, event)} label="Medium" name="group1" id="formImpactInstitutionalMedium" defaultChecked={this.state.impactLevels.legal.levels.institutional.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('legal', 'institutional', false, event)} label="High" name="group1" id="formImpactInstitutionalHigh" defaultChecked={this.state.impactLevels.legal.levels.institutional.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('legal', 'institutional', false, event)} label="Very High" name="group1" id="formImpactInstitutionalVeryHigh" defaultChecked={this.state.impactLevels.legal.levels.institutional.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.legal.levels.institutional.comments} onChange={(event) => this.onChangeImpactLevel('legal', 'institutional', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formConfidentialitySocietalImpact">
              <Form.Label style={{fontWeight:'bold'}}>Societal impact</Form.Label>
                <div key='inline-impact-societal-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('legal', 'societal', false, event)} label="None" name="group1" id="formImpactSocietalNone" defaultChecked={this.state.impactLevels.legal.levels.societal.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('legal', 'societal', false, event)} label="Low" name="group1" id="formImpactSocietalLow" defaultChecked={this.state.impactLevels.legal.levels.societal.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('legal', 'societal', false, event)} label="Medium" name="group1" id="formImpactSocietalMedium" defaultChecked={this.state.impactLevels.legal.levels.societal.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('legal', 'societal', false, event)} label="High" name="group1" id="formImpactSocietalHigh" defaultChecked={this.state.impactLevels.legal.levels.societal.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('legal', 'societal', false, event)} label="Very High" name="group1" id="formImpactSocietalVeryHigh" defaultChecked={this.state.impactLevels.legal.levels.societal.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.legal.levels.societal.comments} onChange={(event) => this.onChangeImpactLevel('legal', 'societal', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
      </S.CardBody></S.Card>
      <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('impactSub3', 'impactSub4')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}
      {/*Section 2d: Data Misuse*/}
      {this.state.datasetSectionsMenu[1].subNav[3].active && <>
      <S.Card><S.CardBody>
      <ScrollToTop />
        <S.CardTitle><h4 class="section-title"  id='section2c'>2d. Data Misuse</h4></S.CardTitle>
          
          <p>Please reflect on the impact that an inappropirate use of the data could have on individuals, your institution and the society.
            Please consider the intended use and how this can be misused by the requester to create systems that may endanger the safety or 
            violate human rights.
          </p>

          
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples/Scenarios for data misuse:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDatasetDataMisuse.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          
          <S.CardSection>
            <Form style={{width:'100%', marginTop:'20px'}}>
              <Form.Group className="mb-3" controlId="formConfidentialityIndividualImpact">
              <Form.Label style={{fontWeight:'bold'}}>Personal impact (Impact on Individuals)</Form.Label>
                <div key='inline-impact-personal-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('misuse', 'personal', false, event)} label="None" name="group1" id="formImpactIndividualNone" defaultChecked={this.state.impactLevels.misuse.levels.personal.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('misuse', 'personal', false, event)} label="Low" name="group1" id="formImpactIndividualLow" defaultChecked={this.state.impactLevels.misuse.levels.personal.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('misuse', 'personal', false, event)} label="Medium" name="group1" id="formImpactIndividualMedium" defaultChecked={this.state.impactLevels.misuse.levels.personal.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('misuse', 'personal', false, event)} label="High" name="group1" id="formImpactIndividualHigh" defaultChecked={this.state.impactLevels.misuse.levels.personal.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('misuse', 'personal', false, event)} label="Very High" name="group1" id="formImpactIndividualVeryHigh" defaultChecked={this.state.impactLevels.misuse.levels.personal.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.misuse.levels.personal.comments} onChange={(event) => this.onChangeImpactLevel('misuse', 'personal', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formConfidentialityInstitutionalImpact">
              <Form.Label style={{fontWeight:'bold'}}>Institutional impact (Impact on Your Organisation)</Form.Label>
                <div key='inline-impact-institutional-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('misuse', 'institutional', false, event)} label="None" name="group1" id="formImpactInstitutionalNone" defaultChecked={this.state.impactLevels.misuse.levels.institutional.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('misuse', 'institutional', false, event)} label="Low" name="group1" id="formImpactInstitutionalLow" defaultChecked={this.state.impactLevels.misuse.levels.institutional.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('misuse', 'institutional', false, event)} label="Medium" name="group1" id="formImpactInstitutionalMedium" defaultChecked={this.state.impactLevels.misuse.levels.institutional.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('misuse', 'institutional', false, event)} label="High" name="group1" id="formImpactInstitutionalHigh" defaultChecked={this.state.impactLevels.misuse.levels.institutional.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('misuse', 'institutional', false, event)} label="Very High" name="group1" id="formImpactInstitutionalVeryHigh" defaultChecked={this.state.impactLevels.misuse.levels.institutional.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.misuse.levels.institutional.comments} onChange={(event) => this.onChangeImpactLevel('misuse', 'institutional', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formConfidentialitySocietalImpact">
              <Form.Label style={{fontWeight:'bold'}}>Societal impact</Form.Label>
                <div key='inline-impact-societal-radio' className="mb-3">
                  <Form.Check inline type="radio" value="None" onChange={(event) => this.onChangeImpactLevel('misuse', 'societal', false, event)} label="None" name="group1" id="formImpactSocietalNone" defaultChecked={this.state.impactLevels.misuse.levels.societal.level === 'None' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeImpactLevel('misuse', 'societal', false, event)} label="Low" name="group1" id="formImpactSocietalLow" defaultChecked={this.state.impactLevels.misuse.levels.societal.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeImpactLevel('misuse', 'societal', false, event)} label="Medium" name="group1" id="formImpactSocietalMedium" defaultChecked={this.state.impactLevels.misuse.levels.societal.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeImpactLevel('misuse', 'societal', false, event)} label="High" name="group1" id="formImpactSocietalHigh" defaultChecked={this.state.impactLevels.misuse.levels.societal.level === 'High' ? true : false}/>
                  <Form.Check inline type="radio" value="Very High" onChange={(event) => this.onChangeImpactLevel('misuse', 'societal', false, event)} label="Very High" name="group1" id="formImpactSocietalVeryHigh" defaultChecked={this.state.impactLevels.misuse.levels.societal.level === 'Very High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for the impact level you selected' 
                defaultValue={this.state.impactLevels.misuse.levels.societal.comments} onChange={(event) => this.onChangeImpactLevel('misuse', 'societal', true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
      </S.CardBody></S.Card>
      <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('impactSub4', 'impactSub5')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 2e: Overall Impact Evaluation*/}
      {this.state.datasetSectionsMenu[1].subNav[4].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title"  id='impactEvaluationConfidentiality'>2e. Overall Impact Evaluation</h4></S.CardTitle>
          <p>This is an overview of the selected impact levels for each risk and area of effect</p>
          <TableDiv>
            <Table columns={columnsForImpactEvaluationTable} data={dataForImpactEvaluationTable} />
          </TableDiv>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('impactSub5', 'threats')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>} 

      {/*Section 3: Threats Overview*/}
      {this.state.datasetSectionsMenu[2].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title"  >3. Threat Analysis</h4></S.CardTitle>
          <p>A threat is any circumstance or event, which has the potential to adversely affect the safety of sharing FCT data.
          At this step, the goal for the data provider is to understand the threats related to the overall process of sharing FCT data 
          and assess their likelihood (threat occurrence probability). Varying levels and types of threats could be considered for each 
          risk.</p>

          <p>Similar to the case of the evaluation of impact, the assessment of threat occurrence probability can only be qualitative, 
            as it is very much related to the specific conditions of the sharing operation (type of data, partners involved, method of transfer, etc.). 
            In the context of our approach, three levels of threat occurrence probability are defined, namely:
          </p>
          <S.CardSection>
          <S.ListCircle style={{marginLeft:'20px'}}>
            <li><b>Low</b>: the threat is unlikely to materialize.</li>
            <li><b>Medium</b>: it is possible that the threat materializes.</li>
            <li><b>High</b>: the threat is likely to materialize.</li>
          </S.ListCircle>
          </S.CardSection>
          
          <p>For each threat the user can select a threat occurrence probability and each level is attributed a specific score as follows:</p>
          <TableDiv>
            <Table columns={P.columnsForThreatScore} data={P.dataForThreatScore} />
          </TableDiv>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threats', 'threatsSub1')}>Continue</button> 
        </S.ButtonFlexLeft>
      
      </>}
      {/* Section 3a: Unauthorized Access Threat*/}
      {this.state.datasetSectionsMenu[2].subNav[0].active && <>
        <S.Card><S.CardBody>
          <ScrollToTop />
          <S.CardTitle><h4 class="section-title">3a. Threats for Unauthorized Access</h4></S.CardTitle>
          <p>An unauthorized access can come as a result of many factors including cybersecurity attacks, lack of access protections, physical data center breach, anonymization failures, etc.</p>
          <p>To simplify the process, threats that can contribute to unauthorized access have been categorised into four different areas, namely:</p>
          <S.CardSection>
          <S.ListCircle style={{marginLeft:'20px'}}>
            <li><b>Privacy Preservation</b>, where we examine the privacy preservation tools used to anonymize personal data and the possibility that they may fail to do so completely, which can lead to personal data exposure.</li>
            <li><b>Data Transfer</b>, where we examine the data transfer method used and its security characteristics.</li>
            <li><b>Cybersecurity of the Requester</b>, where we examine the technical infrastructure, physical protection measures, personnel and other cybersecurity factors at the premises of the requester, which can guarantee the safety of the shared data.</li>
            <li><b>License Agreement</b>, where we examine certain aspects of the license agreement between your organization and the requester, that may allow the requester to share data to other parties, which in turn may lead to additional data exposure.</li>
          </S.ListCircle>
          </S.CardSection>
          </S.CardBody></S.Card>
          <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub1', 'threatsSub1Sub1')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 3a.1: Privacy Preservation*/}
      {this.state.datasetSectionsMenu[2].subNav[0].subNav[0].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >3a.1. Privacy Preservation Threats</h4></S.CardTitle>
          <p>Personal data can be shared "as is" under the strick requirments of the GDPR regulation. However, 
            if your dataset contains personal data that you are not required to share "as is", then personal data
            should be anonymised to preserve the privacy of individuals.
          </p>
          <p>In this section, we examine the threat occurrence probability that the anonymization process will lead to pesonal data exposure.</p>
          {this.state.datasetContainsPersonalData === 'No' && <>
            <p><b>The dataset contains no personal data. This section is relevant only when sharing personal data. Please select 'N/A' in the threat probability below:</b> </p>
          </>}
          {this.state.datasetContainsPersonalData === 'Yes' && <>
          
            <S.ListSquare>
              <li>The dataset contains personal data. Have personal data been anonymized?</li>
            </S.ListSquare>
            <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDatasetPrivacyAnonymization.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDatasetPrivacyAnonymization.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formPrivacyPreservationVerified">
              
                <div key='inline-threat-privacy-anonymization-radio' className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('privacy', 'anonymization', true, false, event)} label="Yes" name="group1" id="formThreatLevelPrivacyAnswersAnonymizationYes" defaultChecked={this.state.threatLevels.privacy.answers.anonymization.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('privacy', 'anonymization', true, false, event)} label="No" name="group1" id="formThreatLevelPrivacyAnswersAnonymizationNo" defaultChecked={this.state.threatLevels.privacy.answers.anonymization.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.privacy.answers.anonymization.comments} onChange={(event) => this.onChangeThreatAnswer('privacy', 'anonymization', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          
          </>}

          
          
          {this.state.datasetContainsPersonalData === 'Yes' && this.state.threatLevels.privacy.answers.anonymization.answer === 'Yes' && <>
          <S.ListSquare>
            <li>Is the anonymisation tool verified by a third-party?</li>
          </S.ListSquare>

          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationPrivacyPreservationVerified.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesPrivacyPreservationVerified.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formPrivacyPreservationVerified">
              
                <div key='inline-threat-privacy-verrified-radio' className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('privacy', 'verified', true, false, event)} label="Yes" name="group1" id="formThreatLevelPrivacyAnswersVerifiedYes" defaultChecked={this.state.threatLevels.privacy.answers.verified.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('privacy', 'verified', true, false, event)} label="No" name="group1" id="formThreatLevelPrivacyAnswersVerifiedNo" defaultChecked={this.state.threatLevels.privacy.answers.verified.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.privacy.answers.verified.comments} onChange={(event) => this.onChangeThreatAnswer('privacy', 'verified', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare>
            <li>Have you verified the anonymised data manually?</li>
          </S.ListSquare>

          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationPrivacyPreservationManualVerification.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesPrivacyPreservationManualVerification.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formPrivacyPreservationManualVerification">
              
                <div key='inline-threat-privacy-manual-radio' className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('privacy', 'manualVerification', true, false, event)} label="Yes" name="group1" id="formThreatLevelPrivacyAnswersManualYes" defaultChecked={this.state.threatLevels.privacy.answers.manualVerification.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('privacy', 'manualVerification', true, false, event)} label="No" name="group1" id="formThreatLevelPrivacyAnswersManualNo" defaultChecked={this.state.threatLevels.privacy.answers.manualVerification.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.privacy.answers.manualVerification.comments} onChange={(event) => this.onChangeThreatAnswer('privacy', 'manualVerification', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare>
            <li>Are all personal data completely anonymised? (if unsure please select 'No')</li>
          </S.ListSquare>

          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationPrivacyPreservationAnonymizationComplete.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesPrivacyPreservationAnonymizationComplete.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3" controlId="formPrivacyPreservationComplete">
              
                <div key='inline-threat-privacy-complete-radio' className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('privacy', 'complete', true, false, event)} label="Yes" name="group1" id="formThreatLevelPrivacyAnswersCompleteYes" defaultChecked={this.state.threatLevels.privacy.answers.complete.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('privacy', 'complete', true, false, event)} label="No" name="group1" id="formThreatLevelPrivacyAnswersCompleteNo" defaultChecked={this.state.threatLevels.privacy.answers.complete.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.privacy.answers.complete.comments} onChange={(event) => this.onChangeThreatAnswer('privacy', 'complete', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          </>}

          {this.state.datasetContainsPersonalData === 'Yes' && <>
          <p>Based on the selections and justifications above, please assess the probability that the anonymization process will lead to personal data exposure. If personal data are not anonymized please select 'N/A'.</p>
          </>}
          <S.CardSection style={{backgroundColor:'#EEEEEE'}}>
            <Form style={{width:'100%', margin: '10px'}}>
              <Form.Group>
                <div style={{marginRight:'10px',fontWeight:'bold',display:'flex', justifyContent:'space-between'}}>
                  <Form.Check inline type="radio" value="N/A" onChange={(event) => this.onChangeThreatAnswer('privacy', 'level', false, false, event)} label="N/A" name="group1" id="formThreatLevelPrivacyAnswersLevelNA" defaultChecked={this.state.threatLevels.privacy.level === 'N/A' ? true : false}/>
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeThreatAnswer('privacy', 'level', false, false, event)} label="Low" name="group1" id="formThreatLevelPrivacyAnswersLevelLow" defaultChecked={this.state.threatLevels.privacy.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeThreatAnswer('privacy', 'level', false, false, event)} label="Medium" name="group1" id="formThreatLevelPrivacyAnswersLevelMedium" defaultChecked={this.state.threatLevels.privacy.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeThreatAnswer('privacy', 'level', false, false, event)} label="High" name="group1" id="formThreatLevelPrivacyAnswersLevelHigh" defaultChecked={this.state.threatLevels.privacy.level === 'High' ? true : false}/>
                  </div>
              </Form.Group>
            </Form>
          </S.CardSection>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub1Sub1', 'threatsSub1Sub2')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 3a.2: Data Transfer Method*/}
      {this.state.datasetSectionsMenu[2].subNav[0].subNav[1].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >3a.2. Data Transfer Threats</h4></S.CardTitle>
          <p>The data transfer method can be either physical or electronic. 
          </p>
          <p>In this evaluation we examine the threats occurrence probability for data being intercepted during tranfer. </p>
        

          <S.ListSquare><li>Do you use electronic or physical transfer for the data?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationTransferMethod.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesTransferMethod.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Electronic" onChange={(event) => this.onChangeThreatAnswer('transfer', 'electronic', true, false, event)} label="Electronic" name="group1" id="formThreatLevelTransferAnswersElectronicCheckedYes" defaultChecked={this.state.threatLevels.transfer.answers.electronic.answer === 'Electronic' ? true : false}/>
                  <Form.Check inline type="radio" value="Physical" onChange={(event) => this.onChangeThreatAnswer('transfer', 'electronic', true, false, event)} label="Physical" name="group1" id="formThreatLevelTransferAnswersElectronicCheckedNo" defaultChecked={this.state.threatLevels.transfer.answers.electronic.answer === 'Physical' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.transfer.answers.electronic.comments} onChange={(event) => this.onChangeThreatAnswer('transfer', 'electronic', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          {this.state.threatLevels.transfer.answers.electronic.answer === 'Electronic' && <>
          <S.ListSquare><li>Is the transfer channel using SOTA security measures?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationTransferSecurity.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesTransferSecurity.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('transfer', 'security', true, false, event)} label="Yes" name="group1" id="formThreatLevelTransferAnswerssecurityCheckedYes" defaultChecked={this.state.threatLevels.transfer.answers.security.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('transfer', 'security', true, false, event)} label="No" name="group1" id="formThreatLevelTransferAnswerssecurityCheckedNo" defaultChecked={this.state.threatLevels.transfer.answers.security.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.transfer.answers.security.comments} onChange={(event) => this.onChangeThreatAnswer('transfer', 'security', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Are the data stored (even temporary) on any third-party server?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationTransferStoredThirdPartyServer.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesTransferStoredThirdPartServer.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('transfer', 'thirdPartyServer', true, false, event)} label="Yes" name="group1" id="formThreatLevelTransferAnswerthirdPartyServerCheckedYes" defaultChecked={this.state.threatLevels.transfer.answers.thirdPartyServer.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('transfer', 'thirdPartyServer', true, false, event)} label="No" name="group1" id="formThreatLevelTransferAnswerthirdPartyServerCheckedNo" defaultChecked={this.state.threatLevels.transfer.answers.thirdPartyServer.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.transfer.answers.thirdPartyServer.comments} onChange={(event) => this.onChangeThreatAnswer('transfer', 'thirdPartyServer', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          </>}

          {this.state.threatLevels.transfer.answers.electronic.answer === 'Physical' && <>
              
          <S.ListSquare><li>Are you following best practices for securing the data on such device during transfer?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationTransferPhysicalBestPractices.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesTransferPhysicalBestPractices.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('transfer', 'bestPractices', true, false, event)} label="Yes" name="group1" id="formThreatLevelTransferAnswerbestPracticesCheckedYes" defaultChecked={this.state.threatLevels.transfer.answers.bestPractices.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('transfer', 'bestPractices', true, false, event)} label="No" name="group1" id="formThreatLevelTransferAnswerbestPracticesCheckedNo" defaultChecked={this.state.threatLevels.transfer.answers.bestPractices.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.transfer.answers.bestPractices.comments} onChange={(event) => this.onChangeThreatAnswer('transfer', 'bestPractices', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          </>}
          

          <p>Based on the selections and justifications above, please assess the threat occurrence probability that the transfer method may contribute to a data leak.</p>
          <S.CardSection style={{backgroundColor:'#EEEEEE'}}>
            <Form style={{width:'100%', margin: '10px'}}>
              <Form.Group>
                <div style={{marginRight:'10px',fontWeight:'bold',display:'flex', justifyContent:'space-between'}}>
                  {/*<Form.Check inline type="radio" value="N/A" onChange={(event) => this.onChangeThreatAnswer('transfer', 'level', false, false, event)} label="N/A" name="group1" id="formThreatLeveltransferAnswersLevelNA" defaultChecked={this.state.threatLevels.transfer.level === 'N/A' ? true : false}/>*/}
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeThreatAnswer('transfer', 'level', false, false, event)} label="Low" name="group1" id="formThreatLeveltransferAnswersLevelLow" defaultChecked={this.state.threatLevels.transfer.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeThreatAnswer('transfer', 'level', false, false, event)} label="Medium" name="group1" id="formThreatLeveltransferAnswersLevelMedium" defaultChecked={this.state.threatLevels.transfer.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeThreatAnswer('transfer', 'level', false, false, event)} label="High" name="group1" id="formThreatLeveltransferAnswersLevelHigh" defaultChecked={this.state.threatLevels.transfer.level === 'High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
          </S.CardSection>
        
          
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub1Sub2', 'threatsSub1Sub3')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}


      {/*Section 3a.3: Cybersecurity*/}
      {this.state.datasetSectionsMenu[2].subNav[0].subNav[2].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >3a.3. Cybersecurity at the requester's premises</h4></S.CardTitle>
          <p>The receiver of the data should implement state of the art cybersecurity protocols 
            in order to protect their network and systems from cybersecurity related attacks that 
            may compromise the security of the data.
          </p>
          <p>In this evaluation we examine the threats occurrence probability for an unauthorised access due to a cybersecurity attack.</p>
        
          <S.ListSquare><li>Does the requester follow a cybersecurity standard/framework on their premises?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityStandard.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityStandard.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'standard', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswersstandardCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.standard.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'standard', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswersstandardCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.standard.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.standard.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'standard', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>


          <S.ListSquare><li>Does the requester implement SOTA security measures for their technical insfrastructure?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityTechnical.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityTechnical.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'securityMeasures', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswerssecurityMeasuresCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.securityMeasures.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'securityMeasures', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswerssecurityMeasuresCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.securityMeasures.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.securityMeasures.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'securityMeasures', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Does the requester implement data access restrictions? </li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityAccess.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityAccess.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'accessRestrictions', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswersaccessRestrictionsCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.accessRestrictions.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'accessRestrictions', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswersaccessRestrictionsCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.accessRestrictions.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.accessRestrictions.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'accessRestrictions', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Does the requester manages data lifecycle? </li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityLifeCycle.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityLifeCycle.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'lifecycle', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswerslifecycleCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.lifecycle.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'lifecycle', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswerslifecycleCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.lifecycle.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.lifecycle.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'lifecycle', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Have the persons, who will be handling the data, received appropriate training? </li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityTraining.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityTraining.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'experience', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswersexperienceCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.experience.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'experience', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswersexperienceCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.experience.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.experience.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'experience', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Does the requester monitor and control third-party users and applications? </li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityThirdParty.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityThirdParty.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'thirdPartyUsers', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswersthirdPartyUsersCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.thirdPartyUsers.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'thirdPartyUsers', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswersthirdPartyUsersCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.thirdPartyUsers.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.thirdPartyUsers.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'thirdPartyUsers', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Is the requester being audited by an external party for cybersecurity?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityAudit.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityAudit.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'audit', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswersauditCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.audit.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'audit', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswersauditCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.audit.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.audit.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'audit', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Has the requester suffered a cyberattack or any other security breach over the last two years?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationCybersecurityAttack.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesCybersecurityAttack.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'cyberattack', true, false, event)} label="Yes" name="group1" id="formThreatLevelcybersecurityAnswerscyberattackCheckedYes" defaultChecked={this.state.threatLevels.cybersecurity.answers.cyberattack.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'cyberattack', true, false, event)} label="No" name="group1" id="formThreatLevelcybersecurityAnswerscyberattackCheckedNo" defaultChecked={this.state.threatLevels.cybersecurity.answers.cyberattack.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.cybersecurity.answers.cyberattack.comments} onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'cyberattack', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <p>Based on the selections and justifications above, please assess the threat occurrence probability that there may be a data leak due to cybersecurity reasons.</p>
          <S.CardSection style={{backgroundColor:'#EEEEEE'}}>
            <Form style={{width:'100%', margin: '10px'}}>
              <Form.Group>
                <div style={{marginRight:'10px',fontWeight:'bold',display:'flex', justifyContent:'space-between'}}>
                  {/*<Form.Check inline type="radio" value="N/A" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'level', false, false, event)} label="N/A" name="group1" id="formThreatLevelcybersecurityAnswersLevelNA" defaultChecked={this.state.threatLevels.cybersecurity.level === 'N/A' ? true : false}/>*/}
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'level', false, false, event)} label="Low" name="group1" id="formThreatLevelcybersecurityAnswersLevelLow" defaultChecked={this.state.threatLevels.cybersecurity.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'level', false, false, event)} label="Medium" name="group1" id="formThreatLevelcybersecurityAnswersLevelMedium" defaultChecked={this.state.threatLevels.cybersecurity.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeThreatAnswer('cybersecurity', 'level', false, false, event)} label="High" name="group1" id="formThreatLevelcybersecurityAnswersLevelHigh" defaultChecked={this.state.threatLevels.cybersecurity.level === 'High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
          </S.CardSection>
        
          
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub1Sub3', 'threatsSub1Sub4')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 3a.4: Licence Agreement*/}
      {this.state.datasetSectionsMenu[2].subNav[0].subNav[3].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
        <S.CardTitle><h4 class="section-title" >3e. License Agreement with the Requester</h4></S.CardTitle>
          <p>The license agreement between the data provider and the requester must include certain clauses depending on the type of data being shared.
            Moreover, the license must control certain aspects of the data sharing operation (like data distribution) to prevent any unauthorized access. 
          </p>
          <p>In this evaluation, we examine the threats occurrence probability for an unauthorised access due to the inability of the license agreement to control data distribution.</p>
        
          <S.ListSquare><li>Do you have a license agreement with the requester?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicenseExist.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicenseExist.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>

          
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'licenseExist', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswerslicenseExistCheckedYes" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.licenseExist.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'licenseExist', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswerslicenseExistCheckedNo" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.licenseExist.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalUnauthorized.answers.licenseExist.comments} onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'licenseExist', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          {this.state.threatLevels.legalUnauthorized.answers.licenseExist.answer === 'Yes' && <>
          
          

          <S.ListSquare><li>Does the license allow distribution of the dataset to third-parties?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicenseDistributionThirdParty.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicenseDistributionThirdParty.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'dataDistribution', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswersdataDistributionCheckedYes" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.dataDistribution.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'dataDistribution', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswersdataDistributionCheckedNo" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.dataDistribution.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalUnauthorized.answers.dataDistribution.comments} onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'dataDistribution', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>
          
          <S.ListSquare><li>May the licensee distribute the modified and adapted work?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicenseDistributionModifiedWork.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicenseDistributionModifiedWork.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'workDistribution', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswersworkDistributionCheckedYes" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.workDistribution.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'workDistribution', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswersworkDistributionCheckedNo" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.workDistribution.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalUnauthorized.answers.workDistribution.comments} onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'workDistribution', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Can parts of the dataset be published in scientific publications?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicensePublication.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicensePublication.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'publish', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswerspublishCheckedYes" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.publish.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'publish', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswerspublishCheckedNo" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.publish.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalUnauthorized.answers.publish.comments} onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'publish', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Is the license permanent?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicensePermanent.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicensePermanent.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'permanent', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswerspermanentCheckedYes" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.permanent.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'permanent', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswerspermanentCheckedNo" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.permanent.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalUnauthorized.answers.permanent.comments} onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'permanent', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>In the event that the license is terminated, shall the Licensee provide evidence of the deletion?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicenseEvidenceDeletion.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicenseEvidenceDeletion.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'evidence', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswersEvidenceCheckedYes" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.evidence.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'evidence', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswersEvidenceCheckedNo" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.evidence.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalUnauthorized.answers.evidence.comments} onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'evidence', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Can the dataset be used in any domain/industry?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicenseDomain.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicenseDomain.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'domain', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswersdomainCheckedYes" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.domain.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'domain', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswersdomainCheckedNo" defaultChecked={this.state.threatLevels.legalUnauthorized.answers.domain.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalUnauthorized.answers.domain.comments} onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'domain', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          </>
          }

          <p>Based on the selections and justifications above, please assess the threat occurrence probability that there may be a data leak due to the license agreement not controlling all aspects of the dataset distribution and access.</p>
          <S.CardSection style={{backgroundColor:'#EEEEEE'}}>
            <Form style={{width:'100%', margin: '10px'}}>
              <Form.Group>
                <div style={{marginRight:'10px',fontWeight:'bold',display:'flex', justifyContent:'space-between'}}>
                  {/*<Form.Check inline type="radio" value="N/A" onChange={(event) => this.onChangeThreatAnswer('agreement', 'levelUnauthorized', false, false, event)} label="N/A" name="group1" id="formThreatLevelagreementAnswersLevelNA" defaultChecked={this.state.threatLevels.legalUnauthorized.level === 'N/A' ? true : false}/>*/}
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'level', false, false, event)} label="Low" name="group1" id="formThreatLevelagreementAnswersLevelLow" defaultChecked={this.state.threatLevels.legalUnauthorized.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'level', false, false, event)} label="Medium" name="group1" id="formThreatLevelagreementAnswersLevelMedium" defaultChecked={this.state.threatLevels.legalUnauthorized.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeThreatAnswer('agreementUnauthorized', 'level', false, false, event)} label="High" name="group1" id="formThreatLevelagreementAnswersLevelHigh" defaultChecked={this.state.threatLevels.legalUnauthorized.level === 'High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
          </S.CardSection>

        

        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub3', 'threatsSub4')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>

      }
        
        
      {/*Section 3b: Low Data Quality*/}
      {this.state.datasetSectionsMenu[2].subNav[1].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >3b. Low Data Quality</h4></S.CardTitle>
          <p>Data quality checks is an important step before sharing any dataset as it can identify potential bias among other issues.
          </p>
          <p>In this section we examine the threat occurrence probability for bias in the dataset.</p>
          
          <S.ListSquare><li>Have you checked the data for potential bias?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationDataQualityBias.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDataQualityBias.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('quality', 'biasChecked', true, false, event)} label="Yes" name="group1" id="formThreatLevelQualityAnswersBiasCheckedYes" defaultChecked={this.state.threatLevels.bias.answers.biasChecked.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('quality', 'biasChecked', true, false, event)} label="No" name="group1" id="formThreatLevelQualityAnswersBiasCheckedNo" defaultChecked={this.state.threatLevels.bias.answers.biasChecked.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.bias.answers.biasChecked.comments} onChange={(event) => this.onChangeThreatAnswer('quality', 'biasChecked', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>


          <S.ListSquare><li>Have you checked the quality of the data?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationDataQualityCheck.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDataQualityCheck.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('quality', 'qualityChecked', true, false, event)} label="Yes" name="group1" id="formThreatLevelQualityAnswersQualityCheckedYes" defaultChecked={this.state.threatLevels.bias.answers.qualityChecked.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('quality', 'qualityChecked', true, false, event)} label="No" name="group1" id="formThreatLevelQualityAnswersQualityCheckedNo" defaultChecked={this.state.threatLevels.bias.answers.qualityChecked.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.bias.answers.qualityChecked.comments} onChange={(event) => this.onChangeThreatAnswer('quality', 'qualityChecked', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Have you verified that the data can be used for the intended purpose?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationDataQualityIntendedPurpose.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDataQualityIntendedPurpose.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('quality', 'purposeChecked', true, false, event)} label="Yes" name="group1" id="formThreatLevelQualityAnswersPurposeCheckedYes" defaultChecked={this.state.threatLevels.bias.answers.purposeChecked.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('quality', 'purposeChecked', true, false, event)} label="No" name="group1" id="formThreatLevelQualityAnswersPurposeCheckedNo" defaultChecked={this.state.threatLevels.bias.answers.purposeChecked.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.bias.answers.purposeChecked.comments} onChange={(event) => this.onChangeThreatAnswer('quality', 'purposeChecked', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Are you using an automatic tool for checking the data quality?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationDataQualityAutomatic.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDataQualityAutomatic.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('quality', 'toolUsed', true, false, event)} label="Yes" name="group1" id="formThreatLevelQualityAnswersToolUsedYes" defaultChecked={this.state.threatLevels.bias.answers.toolUsed.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('quality', 'toolUsed', true, false, event)} label="No" name="group1" id="formThreatLevelQualityAnswersToolUsedNo" defaultChecked={this.state.threatLevels.bias.answers.toolUsed.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.bias.answers.toolUsed.comments} onChange={(event) => this.onChangeThreatAnswer('quality', 'toolUsed', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          {this.state.threatLevels.bias.answers.toolUsed.answer === 'Yes' && <>
          <S.ListSquare><li>Is the data quality tool verfified by a third party?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationDataQualityVerified.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesDataQualityVerified.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('quality', 'verified', true, false, event)} label="Yes" name="group1" id="formThreatLevelQualityAnswersVerifiedYes" defaultChecked={this.state.threatLevels.bias.answers.verified.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('quality', 'verified', true, false, event)} label="No" name="group1" id="formThreatLevelQualityAnswersVerifiedNo" defaultChecked={this.state.threatLevels.bias.answers.verified.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.bias.answers.verified.comments} onChange={(event) => this.onChangeThreatAnswer('quality', 'verified', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          </>
          }
          
          <p>Based on the selections and justifications above, please assess the threat occurrence probability for this evaluation area.</p>
          <S.CardSection style={{backgroundColor:'#EEEEEE'}}>
            <Form style={{width:'100%', margin: '10px'}}>
              <Form.Group>
                <div style={{marginRight:'10px',fontWeight:'bold',display:'flex', justifyContent:'space-between'}}>
                  {/*<Form.Check inline type="radio" value="N/A" onChange={(event) => this.onChangeThreatAnswer('quality', 'level', false, false, event)} label="N/A" name="group1" id="formThreatLevelQualityAnswersLevelNA" defaultChecked={this.state.threatLevels.bias.level === 'N/A' ? true : false}/>*/}
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeThreatAnswer('quality', 'level', false, false, event)} label="Low" name="group1" id="formThreatLevelQualityAnswersLevelLow" defaultChecked={this.state.threatLevels.bias.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeThreatAnswer('quality', 'level', false, false, event)} label="Medium" name="group1" id="formThreatLevelQualityAnswersLevelMedium" defaultChecked={this.state.threatLevels.bias.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeThreatAnswer('quality', 'level', false, false, event)} label="High" name="group1" id="formThreatLevelQualityAnswersLevelHigh" defaultChecked={this.state.threatLevels.bias.level === 'High' ? true : false}/>
                  </div>
              </Form.Group>
            </Form>
          </S.CardSection>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub2', 'threatsSub3')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      
      {/*Section 3c: Legal Non-Compliance*/}
      {this.state.datasetSectionsMenu[2].subNav[2].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
        <S.CardTitle><h4 class="section-title" >3c. Legal Non-Compliance</h4></S.CardTitle>
          <p>When sharing personal data, a license agreement is necessary to comply with the GDPR. Moreover, if the data are used for AI development, the license agreement must comply with the AI-Act.
          </p>
          <p>In this evaluation, we examine the threats occurrence probability for a lack of legal compliance with GDPR and AI-Act</p>
          {console.log('this.state.datasetContainsPersonalData: ', this.state.datasetContainsPersonalData)}
          {console.log('threatLevels: ', this.state.threatLevels)}
          {this.state.threatLevels.legalUnauthorized.answers.licenseExist.answer === 'No' && this.state.datasetContainsPersonalData === 'Yes' && <>
          <p style={{fontWeight:'bold'}}>There is no license agreement with the requester, but there are personal data being shared. A license agreement is necessary for GDPR compliance. Please consider carefully the lawful basis for sharing personal data and select the appropriate threat level.</p>
          </>}
          {this.state.threatLevels.legalUnauthorized.answers.licenseExist.answer === 'No' && this.state.datasetContainsPersonalData === 'No' && <>
          <p style={{fontWeight:'bold'}}>There is no license agreement with the requester. Please consider carefully the lawful basis for data sharing.</p>
          </>}

          {}
        
          {this.state.threatLevels.legalUnauthorized.answers.licenseExist.answer === 'Yes' && this.state.datasetContainsPersonalData === 'Yes' && <>
          <S.ListSquare><li>Does the license include clauses for personal data?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicensePersonal.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicensePersonal.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'clausesPersonal', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswersclausesPersonalCheckedYes" defaultChecked={this.state.threatLevels.legalCompliance.answers.clausesPersonal.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'clausesPersonal', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswersclausesPersonalCheckedNo" defaultChecked={this.state.threatLevels.legalCompliance.answers.clausesPersonal.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalCompliance.answers.clausesPersonal.comments} onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'clausesPersonal', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Does the requester plan to store any data outside of the EU?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicenseOutsideEU.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicenseOutsideEU.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'storageOutside', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswersstorageOutsideCheckedYes" defaultChecked={this.state.threatLevels.legalCompliance.answers.storageOutside.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'storageOutside', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswersstorageOutsideCheckedNo" defaultChecked={this.state.threatLevels.legalCompliance.answers.storageOutside.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalCompliance.answers.storageOutside.comments} onChange={(event) => this.onChangeThreatAnswer('agreement', 'storageOutside', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          </>}

          <S.ListSquare><li>Have you considered national laws or and other directives that may prohibit or in general regulate FCT data sharing?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationLicenseFCTRegulations.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesLicenseFCTRegulations.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'fctregulations', true, false, event)} label="Yes" name="group1" id="formThreatLevelagreementAnswersfctregulationsCheckedYes" defaultChecked={this.state.threatLevels.legalCompliance.answers.fctregulations.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'fctregulations', true, false, event)} label="No" name="group1" id="formThreatLevelagreementAnswersfctregulationsCheckedNo" defaultChecked={this.state.threatLevels.legalCompliance.answers.fctregulations.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.legalCompliance.answers.fctregulations.comments} onChange={(event) => this.onChangeThreatAnswer('agreement', 'fctregulations', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          
          

          <p>Based on the selections and justifications above, please assess the threat occurrence probability that the license agreement does not include all the necessary clauses for compliance with appropriate regulations and legal frameworks.</p>
          <S.CardSection style={{backgroundColor:'#EEEEEE'}}>
            <Form style={{width:'100%', margin: '10px'}}>
              <Form.Group>
                <div style={{marginRight:'10px',fontWeight:'bold',display:'flex', justifyContent:'space-between'}}>
                  {/*<Form.Check inline type="radio" value="N/A" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'level', false, false, event)} label="N/A" name="group1" id="formThreatLevellevelComplianceAnswersLevelNA" defaultChecked={this.state.threatLevels.legalUnauthorized.level === 'N/A' ? true : false}/>*/}
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'level', false, false, event)} label="Low" name="group1" id="formThreatLevellevelComplianceAnswersLevelLow" defaultChecked={this.state.threatLevels.legalCompliance.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'level', false, false, event)} label="Medium" name="group1" id="formThreatLevellevelComplianceAnswersLevelMedium" defaultChecked={this.state.threatLevels.legalCompliance.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeThreatAnswer('agreementCompliance', 'level', false, false, event)} label="High" name="group1" id="formThreatLevellevelComplianceAnswersLevelHigh" defaultChecked={this.state.threatLevels.legalCompliance.level === 'High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
          </S.CardSection>
        
          
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub3', 'threatsSub4')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 3f: Data Misuse*/}
      {this.state.datasetSectionsMenu[2].subNav[3].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >3d. Data Misuse</h4></S.CardTitle>
          <p>Access to massive amounts of FCT data can have the ability to result in the creation of potentially 
            harmful systems like mass surveillance systems or AI that manipulates human behavior (which is forbidden by the AI act)
          </p>
          <p>In this evaluation we examine the threats occurrence probability for data misuse by the requester in 
            comparison with their declared intended use.</p>
        
          <S.ListSquare><li>Can the data be used to create mass surveillance systems?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationMisuseSurveillance.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesMisuseSurveillance.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('misuse', 'surveillance', true, false, event)} label="Yes" name="group1" id="formThreatLevelmisuseAnswerssurveillanceCheckedYes" defaultChecked={this.state.threatLevels.misuse.answers.surveillance.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('misuse', 'surveillance', true, false, event)} label="No" name="group1" id="formThreatLevelmisuseAnswerssurveillanceCheckedNo" defaultChecked={this.state.threatLevels.misuse.answers.surveillance.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.misuse.answers.surveillance.comments} onChange={(event) => this.onChangeThreatAnswer('misuse', 'surveillance', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Are the data being used to create systems (including AI) that manipulate human behavior, use real-time remote biometric identification in public spaces or rank people based on personal characteristics, socio-economic staus or behavior?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationMisuseManipulation.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesMisuseManipulation.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('misuse', 'manipulation', true, false, event)} label="Yes" name="group1" id="formThreatLevelmisuseAnswersmanipulationCheckedYes" defaultChecked={this.state.threatLevels.misuse.answers.manipulation.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('misuse', 'manipulation', true, false, event)} label="No" name="group1" id="formThreatLevelmisuseAnswersmanipulationCheckedNo" defaultChecked={this.state.threatLevels.misuse.answers.manipulation.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.misuse.answers.manipulation.comments} onChange={(event) => this.onChangeThreatAnswer('misuse', 'manipulation', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Can the data be used to create systems (including AI) that may endanger health, safety or fundamental rights of persons?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationMisuseEndanger.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesMisuseEndanger.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('misuse', 'endanger', true, false, event)} label="Yes" name="group1" id="formThreatLevelmisuseAnswersendangerCheckedYes" defaultChecked={this.state.threatLevels.misuse.answers.endanger.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('misuse', 'endanger', true, false, event)} label="No" name="group1" id="formThreatLevelmisuseAnswersendangerCheckedNo" defaultChecked={this.state.threatLevels.misuse.answers.endanger.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.misuse.answers.endanger.comments} onChange={(event) => this.onChangeThreatAnswer('misuse', 'endanger', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <S.ListSquare><li>Are the data being used to create general purpose AI systems?</li></S.ListSquare>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Explanation:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.explanationMisuseGeneralPurpose.map((item, index) => {
                return(<p>{item}</p>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardContentExamples>
            <S.CardContentExamplesTitle>
              Examples:
            </S.CardContentExamplesTitle>
            <S.CardContentExamplesList>
              {P.examplesMisuseGeneralPurpose.map((item, index) => {
                return(<li>{item}</li>)
              })}
            </S.CardContentExamplesList>
          </S.CardContentExamples>
          <S.CardSection>
            <Form style={{width:'100%'}}>
              <Form.Group className="mb-3">
              
                <div className="mb-3">
                  <Form.Check inline type="radio" value="Yes" onChange={(event) => this.onChangeThreatAnswer('misuse', 'generalPurpose', true, false, event)} label="Yes" name="group1" id="formThreatLevelmisuseAnswersgeneralPurposeCheckedYes" defaultChecked={this.state.threatLevels.misuse.answers.generalPurpose.answer === 'Yes' ? true : false}/>
                  <Form.Check inline type="radio" value="No" onChange={(event) => this.onChangeThreatAnswer('misuse', 'generalPurpose', true, false, event)} label="No" name="group1" id="formThreatLevelmisuseAnswersgeneralPurposeCheckedNo" defaultChecked={this.state.threatLevels.misuse.answers.generalPurpose.answer === 'No' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Justification</S.GeneralFormFieldLabel>
              <S.GeneralFormFieldTextArea placeholder='Please provide an explanatory note for your selection' 
                defaultValue={this.state.threatLevels.misuse.answers.generalPurpose.comments} onChange={(event) => this.onChangeThreatAnswer('misuse', 'generalPurpose', true, true, event)}></S.GeneralFormFieldTextArea>
            </S.GeneralFormField>
          </S.CardSection>

          <p>Based on the selections and justifications above, please assess the threat occurrence probability that the data may be misused to create systems that will endanger the safety and rights of people and society.</p>
          <S.CardSection style={{backgroundColor:'#EEEEEE'}}>
            <Form style={{width:'100%', margin: '10px'}}>
              <Form.Group>
                <div style={{marginRight:'10px',fontWeight:'bold',display:'flex', justifyContent:'space-between'}}>
                  {/*<Form.Check inline type="radio" value="N/A" onChange={(event) => this.onChangeThreatAnswer('misuse', 'level', false, false, event)} label="N/A" name="group1" id="formThreatLevelMisuseAnswersLevelNA" defaultChecked={this.state.threatLevels.misuse.level === 'N/A' ? true : false}/>*/}
                  <Form.Check inline type="radio" value="Low" onChange={(event) => this.onChangeThreatAnswer('misuse', 'level', false, false, event)} label="Low" name="group1" id="formThreatLevelMisuseAnswersLevelLow" defaultChecked={this.state.threatLevels.misuse.level === 'Low' ? true : false}/>
                  <Form.Check inline type="radio" value="Medium" onChange={(event) => this.onChangeThreatAnswer('misuse', 'level', false, false, event)} label="Medium" name="group1" id="formThreatLevelMisuseAnswersLevelMedium" defaultChecked={this.state.threatLevels.misuse.level === 'Medium' ? true : false}/>
                  <Form.Check inline type="radio" value="High" onChange={(event) => this.onChangeThreatAnswer('misuse', 'level', false, false, event)} label="High" name="group1" id="formThreatLevelMisuseAnswersLevelHigh" defaultChecked={this.state.threatLevels.misuse.level === 'High' ? true : false}/>
                </div>
              </Form.Group>
            </Form>
          </S.CardSection>
        
          
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub4', 'threatsSub5')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 3g: Overall Threat Levels*/}
      {this.state.datasetSectionsMenu[2].subNav[4].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >3g. Overall Threat Levels</h4></S.CardTitle>
          <p>Based on the answers in the previous sections here is the calculated threat level per risk
          </p>
          <TableDiv>
            <Table columns={columnsForThreatEvaluationTable} data={dataForThreatEvaluationTable} />
          </TableDiv>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('threatsSub5', 'evaluation')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 4: Risk Evaluation*/}
      
      {this.state.datasetSectionsMenu[3].active && <>
        
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >4. Risk Evaluation</h4></S.CardTitle>
          <p>Based on the impact level and threat occurrence probability for each risk, the  risk level can be calculated 
            by multiplying impact and probability.
          </p>
          <TableDiv>
            <Table columns={columnsForRiskEvaluationTable} data={dataForRiskEvaluationTable} />
          </TableDiv>
          <S.CardSection style={{marginTop:'30px'}}>
            <p>This is an overview of the overall risk level for each risk and evaluation area</p>
            <RiskRadarChart data={riskChartDataTotal}/>
          </S.CardSection>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('evaluation', 'mitigation')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 5: Mitigation & Control*/}
      {this.state.datasetSectionsMenu[4].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >5. Mitigation & Control</h4></S.CardTitle>
          <p>For the areas that present a medium or high risk, the following measures have the most priority. Implementing them can reduce the risk for safe FCT data sharing.</p>
          <TableDiv>
            <Table columns={columnsForMitigationActionsTable} data={this.state.mitigationActions} />
          </TableDiv>
        </S.CardBody></S.Card>
        <S.ButtonFlexLeft>
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.nextStep('mitigation', 'export')}>Continue</button> 
        </S.ButtonFlexLeft>
      </>}

      {/*Section 6: Export*/}
      {this.state.datasetSectionsMenu[5].active && <>
        <S.Card><S.CardBody>
        <ScrollToTop />
          <S.CardTitle><h4 class="section-title" >6. Assessment Export</h4></S.CardTitle>
          <p>From this section you can export your assessment either as pdf document of as a machine readable format (JSON) for further processing.</p>
          
          {this.state.exportReady && <>
            <div>
            <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
            {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download now!'
            }
          </PDFDownloadLink>
            </div>
          </>}

          <S.MainComponentLinksWrapper>
          <button type="button" style={{width:'300px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" >PDF Export (not yet available)</button> 
          <button type="button" style={{width:'200px', padding:'10px 50px 10px 50px', marginBottom:30, marginTop: 20}} 
            className="normalButton" onClick={() => this.exportRiskAssessmentDataToJson()}>JSON Export</button> 
          </S.MainComponentLinksWrapper>
        </S.CardBody></S.Card>

      </>}


      </S.MainInnerContent>
      </S.PageWidth>
      
      



      {this.state.sectionLoginActive === true && 
      <div>
      <S.ContainerFluid>
        <S.Row>
          <S.LogInOneLoginColumnWrapper className='login-column-wrapper'>
            <S.LoginOneLoginContentWrapper className='login-content-wrapper'>
              <S.LoginOneHeaderWrapper>
                
                <S.LoginOneHeaderTitle>Welcome Back!</S.LoginOneHeaderTitle>
                <S.LoginOneHeaderSubTitle>Sign in to access all available tools</S.LoginOneHeaderSubTitle>
              </S.LoginOneHeaderWrapper>
              <S.LoginOneFormWrapper>
                <S.LoginOneForm >
                  <S.LogInOneFormField>
                    
                    <S.LoginOneFormFieldLabel htmlFor="username">Username</S.LoginOneFormFieldLabel>
                    <S.LoginOneFormFieldInput 
                      type="text" 
                      placeholder="Enter your username" 
                    ></S.LoginOneFormFieldInput>
                  </S.LogInOneFormField>
                  <S.LogInOneFormField>
                    
                    <S.LoginOneFormFieldLabel htmlFor="password">Password</S.LoginOneFormFieldLabel>
                    <S.LoginOneFormFieldInput type="password" placeholder="Enter your password" ></S.LoginOneFormFieldInput>
                  </S.LogInOneFormField>
                  <S.LoginOneFormSubmitButtonWrapper>
                    <S.LoginOneFormSubmitButton>
                      
                      Log In
                      </S.LoginOneFormSubmitButton>
                    
                  </S.LoginOneFormSubmitButtonWrapper>
                  
                </S.LoginOneForm>
              </S.LoginOneFormWrapper>
              <S.LoginOneFooterWrapper>
                <p>Don't have an account? Please <S.PrimaryLink href='mailto:cmpalouk@gmail.com' >contact your administrator</S.PrimaryLink>.</p>
                <p>© 2023 Risk Assessment Prototype - LAGO - ICCS</p>
              </S.LoginOneFooterWrapper>
              

            </S.LoginOneLoginContentWrapper>
          </S.LogInOneLoginColumnWrapper>
          
        </S.Row>
      </S.ContainerFluid>

    </div>
    } 
  </>)
  }
}