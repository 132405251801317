import React, {useState, useRef, useEffect} from "react";

import * as S from "./styles";

import { Routes, Route, Link } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import GeneralSettings from "./settings-general";

import Logo from "./assets/images/logo.svg";
import LogoLight from "./assets/images/logo.svg";
import LogoDark from "./assets/images/logo-dark.png"
import LogoIcon from "./assets/images/LAGO-logo-icon.png"

import { AdminSidebarData, ModeratorSidebarData, UserSidebarData, ExperimentsSidebarData } from "./components/NavBar/sidebarData";

import Fullscreen from 'react-fullscreen-crossbrowser';
import { MainContent } from "./main-content";
import * as Tools from "./tools";


import AuthService from "./services/auth.service";

import Logout from './logout';
import { LoginOne } from "./login";


import { defaultTheme, lightTheme, lagoTheme } from "./theme";
import { defaultSettings } from "./constants";
//import * as toolset from './toolset';

export class NewDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: false,
      loggedIn: true,
      currentUser: {},
      showAdminBoard: false,
      showModeraborBoard: false,
      isHovered: false,
      userMenuClicked: false,
      notificationsBellClicked: false,
      isFullScreen: false,
      menuCollapse: false,
      
      currentData: [],
      //messagesTable: JSON.parse(localStorage.getItem('messagesTable')) ? JSON.parse(localStorage.getItem('messagesTable')) : {}, //this map logs all messages and read/unread status. The format is {item: true/false} where true is unread and false is read
      messagesTable: {},
      readTable: {}, //this object holds key value pairs of [rowid:true/false] for read/unread status
      readKeyTable: {}, //[rowId: key]

      newNotifications: false,
      newNotificationsTable: [],
      newNotificationsDisplayMax: 20,
      getDataLocally: false,

      columns: [],
      data: [],
      itemData: [],
      keys: [],
      labels: [],
      autoRefreshEnabled: false,
      
      messageCounter: 0,
      searchBarQuery: '',
      availableTools: [],
      availableExperiments: [],
      showAvailableTools: false,
      showLinksMenu: false,

      settings: JSON.parse(localStorage.getItem('settings')) ? JSON.parse(localStorage.getItem('settings')) : defaultSettings
      
    }

    this.userMenuRef = React.createRef();
    this.notificationsMenuRef = React.createRef();
    this.searchBarRef = React.createRef();
    this.linksMenuRef = React.createRef();
    this.handleClickOutsideUserMenu = this.handleClickOutsideUserMenu.bind(this);
    this.handleClickOutsideNotificationsMenu = this.handleClickOutsideNotificationsMenu.bind(this);
    this.handleClickOutsideSearchBarDropDown = this.handleClickOutsideSearchBarDropDown.bind(this);
    this.handleClickOutsideLinksMenuDropDown = this.handleClickOutsideLinksMenuDropDown.bind(this);

    this.logIn = this.logIn.bind(this);
    this.logOut = this.logOut.bind(this);
    this.handleMenuCollapse = this.handleMenuCollapse.bind(this);
    this.changeIsHovered = this.changeIsHovered.bind(this);
    this.handleUserMenuClicked = this.handleUserMenuClicked.bind(this);
    this.handleLinksMenuClicked = this.handleLinksMenuClicked.bind(this);
    this.handleNotificationsBellClicked = this.handleNotificationsBellClicked.bind(this);
    this.handleFullScreenEnter = this.handleFullScreenEnter.bind(this);
    this.handleFullScreenExit = this.handleFullScreenExit.bind(this);

    this.processData = this.processData.bind(this);
    this.analyzeData = this.analyzeData.bind(this);
    
    
    
    
    this.updateAutoRefresh = this.updateAutoRefresh.bind(this);
    

    this.generateAvailableTools = this.generateAvailableTools.bind(this);

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.onSearchBarBlur = this.onSearchBarBlur.bind(this);
    this.onSearchBarFocus = this.onSearchBarFocus.bind(this);
    
    this.handleSettingsUpdate = this.handleSettingsUpdate.bind(this);
    
  }

  generateAvailableTools(sidebarData) {
    let availableTools = [];
    let toolCounter = 0;
    for (let i = 0; i < sidebarData.length; i++) {
      let tool = {};
      sidebarData[i].externalPath ?
      
      tool = {
        id: toolCounter,
        title: sidebarData[i].title,
        externalPath: sidebarData[i].externalPath
      }
      :
      tool = {
        id: toolCounter,
        title: sidebarData[i].title,
        path: sidebarData[i].path
      }

      availableTools.push(tool);
      toolCounter++;

      if (sidebarData[i].subNav) {
        for (let j = 0; j < sidebarData[i].subNav.length; j++) {

          let subTool = {};

          sidebarData[i].subNav[j].externalPath ?

          subTool = {
            id: toolCounter,
            title: sidebarData[i].subNav[j].title,
            externalPath: sidebarData[i].subNav[j].externalPath,
          }
          :
          subTool = {
            id: toolCounter,
            title: sidebarData[i].subNav[j].title,
            path: sidebarData[i].subNav[j].path,
          };

          availableTools.push(subTool);
        }
      }
    }

    return availableTools;
    

  }

  componentDidMount() {
    
    console.log('dashboard - this.state.settings:', this.state.settings);

    if (!this.state.loggedIn) {
      console.log('not logged in');
      this.logIn();
    } else {
      console.log('logged in');
      this.updateAutoRefresh();
    }

    localStorage.setItem('settings', JSON.stringify(this.state.settings));
    
  }
  componentDidUnMount() {
    
  }

  logIn() {
    console.log('logIn()');
    const user = AuthService.getCurrentUser();
    console.log('user:', user);
    if(user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        loggedIn:true
      }, () => {
        this.updateAutoRefresh();
        let availableTools = this.state.showAdminBoard 
      ? this.generateAvailableTools(AdminSidebarData) 
      : this.state.showModeraborBoard ? this.generateAvailableTools(ModeratorSidebarData) 
      : this.generateAvailableTools(UserSidebarData);
    console.log('availableTools:', availableTools);
    this.setState({
      availableTools: availableTools
    })
    let availableExperiments = this.state.showAdminBoard 
      ? this.generateAvailableTools(ExperimentsSidebarData)
      : []
    
    console.log('availableExperiments:', availableExperiments);
    this.setState({
      availableExperiments: availableExperiments
    })
      })
    }
    //document.addEventListener("mousedown", this.handleClickOutsideNotificationsMenu);
    //document.addEventListener("mousedown", this.handleClickOutsideUserMenu);
  }

  logOut() {
    AuthService.logout();
    clearInterval(this.state.timer);
    //document.removeEventListener("mousedown", this.handleClickOutsideNotificationsMenu);
    //document.removeEventListener("mousedown", this.handleClickOutsideUserMenu);
    this.setState({
      currentUser: undefined,
      showModeratorBoard: false,
      showAdminBoard: false,
      loggedIn: true
    })
  }

  handleClickOutsideUserMenu(event) {
    if (this.userMenuRef && !this.userMenuRef.current.contains(event.target)) {
      this.setState({
        userMenuClicked: false
      })  
    }
    
  }
  handleClickOutsideNotificationsMenu(event) {
    if (this.notificationsMenuRef && !this.notificationsMenuRef.current.contains(event.target)) {
      this.setState({
        notificationsBellClicked: false
      })  
    } 
  }
  handleClickOutsideSearchBarDropDown(event) {
    if (this.searchBarRef && !this.searchBarRef.current.contains(event.target)) {
      this.setState({
        showAvailableTools: false
      })  
    } 
  }
  handleClickOutsideLinksMenuDropDown(event) {
    if (this.linksMenuRef && !this.linksMenuRef.current.contains(event.target)) {
      this.setState({
        showLinksMenu: false
      })  
    } 
  }
  
  handleMenuCollapse() {
    let menuCollapse = this.state.menuCollapse;
    this.setState({
      menuCollapse: !menuCollapse
    })
  }
  handleSettingsUpdate(settings) {
    this.setState({
      settings: settings
    }, () => {
      localStorage.setItem('settings', JSON.stringify(this.state.settings));
      this.handleAutoRefreshChanged(true);
    })
  }

  changeIsHovered() {
    let isHovered = this.state.isHovered;
    this.setState({
      isHovered: !isHovered
    })
  }
  handleUserMenuClicked() {
    let userMenuClicked = this.state.userMenuClicked;
    this.setState({
      userMenuClicked: !userMenuClicked
    })
  }
  handleNotificationsBellClicked() {
    let notificationsBellClicked = this.state.notificationsBellClicked;
    this.setState({
      notificationsBellClicked: !notificationsBellClicked
    })
  }
  handleLinksMenuClicked() {
    let showLinksMenu = this.state.showLinksMenu;
    this.setState({
      showLinksMenu: !showLinksMenu
    })
  }
  
  handleFullScreenEnter() {
    console.log('handleFullScreenEnter')
    this.setState({ isFullScreen: true });
  }
  handleFullScreenExit() {
    this.setState({ isFullScreen: false });
  }

  MenuGroup = (props) => {
    return(
      <>
        <S.List>
          <S.ListHeader>
            {props.title}
          </S.ListHeader>
          
          {props.children}
        </S.List>
      </>
    )
  }
  
  closeUserMenu = (e)=>{
    if(this.state.userMenu.current && this.state.userMenuClicked && !this.state.userMenu.current.contains(e.target)){
      this.setState({
        userMenuClicked: false
      })
    }
  }

  updateAutoRefresh() {
    clearInterval(this.state.timer);
    if (this.state.autoRefreshEnabled && this.state.settings.notificationsFetchInterval > 0) {
      this.state.getDataLocally ?
        this.setState({
          timer: setInterval(this.getNotificationDataFromFile, this.state.settings.notificationsFetchInterval)
        })
        :
        this.setState({
          timer: setInterval(this.getNotificationData, this.state.settings.notificationsFetchInterval)
        })
    }
  }
  handleAutoRefreshChanged() {
    console.log('handleAutoRefreshChanged');
    console.log('autoRefreshEnabled:', this.state.autoRefreshEnabled);
    console.log('notificationsFetchInterval:', this.state.settings.notificationsFetchInterval);
    if (this.state.autoRefreshEnabled && this.state.settings.notificationsFetchInterval > 0) {
      clearInterval(this.state.timer);
      this.state.getDataLocally ?
        this.setState({
          timer: setInterval(this.getNotificationDataFromFile, this.state.settings.notificationsFetchInterval)
        })
      :
        this.setState({
          timer: setInterval(this.getNotificationData, this.state.settings.notificationsFetchInterval)
        })
      
    } else {
      clearInterval(this.state.timer);
    }
    
  }
  onSearchBarBlur() {
    this.setState({
      showAvailableTools: false
    })
  }
  onSearchBarFocus() {
    this.setState({
      showAvailableTools: true
    })
  }

  checkIfDataIsLoaded = (dataLoadedStatus) => {
    this.setState( { isDataLoaded: dataLoadedStatus });
  }

  //process data for the react-table
  processData() {
    let localKeys=[];
    let localColumns=[];
    let itemData=[];
    let localLabels=[];
    
    if (this.state.data.length > 0) {
      const firstItem = this.state.data[0]['message'];
      for (let key in firstItem) {
          if (firstItem.hasOwnProperty(key)) {
              localKeys.push(key);
          }
      }
      for (let key in localKeys) {
          localColumns.push(
              {
                  Header: localKeys[key],
                  accessor: localKeys[key]
              }
          )
      }
      
      for (let item in this.state.data) {
          //console.log('item.message:', this.state.data[item]['message'])
          let newItem = this.state.data[item]['message'];
          itemData.push(newItem);
      }
      //we reverse the items so that more recent events are first
      itemData.reverse();
    
      this.setState({ 
          itemData: itemData,
          isTransfering: false,
          columns:localColumns,
          keys:localKeys,
          labels:localLabels,
          isDataLoaded: true
      }, () => this.analyzeData());
      
    }
  }

  analyzeData() {
    const currentData = this.state.currentData;
    const newData = this.state.itemData;
    let messagesTable = this.state.messagesTable;

    for (let i = 0; i < newData.length; i++) {
      let key = newData[i]['uuid'] + newData[i]['asset'] + newData[i]['service_name'] + newData[i]['alert_timestamp'];
      if (!(key in messagesTable)) {
        console.log('new data found');
        messagesTable[key] = true;
        currentData.unshift(newData[i]);

        //new data found so set notification bell to true
        this.setState({newNotifications: true});
      }
    }
    
    //create readTable with [rowId: read/unread status]
    let readTable = {};
    let readKeyTable = {};
    let newNotificationsTable = [];
    let newNotificationsCounter = 0;
    for (let i = 0; i < currentData.length; i++) {
      let key = currentData[i]['uuid'] + currentData[i]['asset'] + currentData[i]['service_name'] + currentData[i]['alert_timestamp'];
      readTable[i] = messagesTable[key];
      readKeyTable[i] = key;

      // if this row is unread store it in newNotificationsTable so we can have a table
      // with the latest five unread notifications available
      if (messagesTable[key] === true) {
        if (newNotificationsCounter++ < this.state.newNotificationsDisplayMax)
          newNotificationsTable.unshift(currentData[i]);
      }

    }

    
    this.setState({
      currentData: currentData,
      newNotificationsTable: newNotificationsTable,
      messagesTable: messagesTable,
      readTable: readTable,
      readKeyTable: readKeyTable
    }, () => {
      localStorage.setItem('messagesTable', JSON.stringify(this.state.messagesTable));
    })
  }

  
  
  toggleFullscreen() {
    if (
        !document.fullscreenElement &&
  /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
    ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
                Element.ALLOW_KEYBOARD_INPUT
            );
        }
    } else {
        if (document.cancelFullScreen) {
            document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
        }
    }
  }


  render() {

  return (
    <>
    <ThemeProvider theme={this.state.settings.theme === 'light' ? lagoTheme : defaultTheme}>
    { !this.state.loggedIn && <S.LogInScreenWrapper>
      <LoginOne logIn={this.logIn}/>
      </S.LogInScreenWrapper>}
    { this.state.loggedIn && 
      <Fullscreen
            enabled={this.state.isFullScreen}
            onChange={(isFullScreen) => this.setState({ isFullScreen })}
          >
      <S.DashboardWrapper>
    
      <S.PageTopbar collapsed={this.state.menuCollapse}>
        <S.NavbarHeader>
          <S.dFlex>
            <S.BrandWrapper>
              <Link to='/'>
              <S.dFlex>
                <S.BrandImage src={this.state.settings.theme === 'light' ? LogoIcon : Logo} alt='logo' className='logo'></S.BrandImage> 
                <h2 style={{color:'white', marginTop: '5px',marginLeft:'20px'}}>Risk Assessment Tool</h2>
                </S.dFlex>
              </Link>
              
            </S.BrandWrapper>
            
          </S.dFlex>
          <S.dFlex>
          <Link to='/'>
          <h4 style={{color:'white', marginRight:'30px'}}>Home</h4>
          </Link>
          <Link to='/documentation'>
          <h4 style={{color:'white', marginRight:'30px'}}>Documentation</h4>
          </Link>
          <Link to='/contact'>
          <h4 style={{color:'white', marginRight:'30px'}}>Contact</h4>
          </Link>
            
          </S.dFlex>
        </S.NavbarHeader>
      </S.PageTopbar>
      
      
      
      <S.MainContent collapsed={this.state.menuCollapse}>

      <Routes>
        
          
          {/*<Route path="/riskassessment-toolset-standalone" element={<Tools.RiskAssessmentStandalone settings={this.state.settings}/>} />*/}
          {/*<Route path="/riskv2" element={<Tools.RiskV2Standalone settings={this.state.settings}/>} />*/}
          {/*<Route path="/riskv3" element={<Tools.RiskV3Standalone settings={this.state.settings}/>} />*/}
          {/*<Route path="/riskv4" element={<Tools.RiskV4Standalone settings={this.state.settings}/>} />*/}
          {/*<Route path="/riskv5" element={<Tools.RiskV5Standalone settings={this.state.settings}/>} />*/}
          {/*<Route path="/newdemo" element={<Tools.RiskV5NewDemo settings={this.state.settings}/>} />*/}
          <Route path="/riskv6" element={<Tools.RiskV6Standalone settings={this.state.settings}/>} />
          <Route path="/newdemo" element={<Tools.RiskV6NewDemo settings={this.state.settings}/>} />
          <Route path="/documentation" element={<Tools.Documentation settings={this.state.settings}/>} />
          <Route path="/contact" element={<Tools.Contact settings={this.state.settings}/>} />
          
        
        
        {this.state.showAdminBoard && <Route path='/usermanagement' element = {<Tools.UserManagementTool />}/>}
        {this.state.showAdminBoard && 
        <Route path='/settings' element = 
            {<MainContent 
              key='Settings'
              title='Settings' 
              breadcrumpToolsetLink='/settings' 
              breadcrumpToolsetTitle='Settings'
              mainComponent= 
                {<GeneralSettings 
                  handleSettingsUpdate={this.handleSettingsUpdate}
                  settings={this.state.settings}
                />}
            />} 
          />}
        <Route path='/' element={<Tools.MainHome settings={this.state.settings}/>}/>
        <Route path='/logout' element={<Logout logOut={this.logOut} settings={this.state.settings}/>} />
      </Routes>
      
      </S.MainContent>
      {
      <S.Footer collapsed={this.state.menuCollapse}>
          <S.ContainerFluid>
            <S.Row>
              Risk Assessment v6 - Developed by ICCS for the LAGO Project
            </S.Row>
          </S.ContainerFluid>
        </S.Footer>
    }
    
    
    </S.DashboardWrapper>
    </Fullscreen>
    
    
    
    }
    </ThemeProvider>
    </>
  )};
}
