import React from 'react';
import * as S from './styles.js'


export default class ContactV1 extends React.Component {

  render() {
    return(
      <>
      <S.PageWidth>
    
        <S.MainInnerContent >
          
          
            <S.Card id="inner-content">
            <S.CardBody>
              
            <S.CardTitle><h4 className="section-title" >How to provide feedback or ask for help</h4></S.CardTitle>
            <p>First of all, thank you very much for using our Risk Assessment for sharing FCT data tool.</p>
            <p>If you wish to provide any feedback or ask anything please send an email to any of our contacts. We'll 
              be happy to hear from you.</p>
            <p>Contacts:</p>
            <S.ListSimpleUl>
              <S.ListSimpleLi>Christos Baloukas: christosbaloukas (at) microlab.ntua.gr</S.ListSimpleLi>
            </S.ListSimpleUl>
            
            </S.CardBody>
            </S.Card>
          
        </S.MainInnerContent>
          

        </S.PageWidth>

    </>)
  }

}