export let questions = {
  'privacy' : {
    'anonymization': 'The dataset contains personal data. Have personal data been anonymized?',
    'verified': 'Is the anonymisation tool verified by a third-party?',
    'manualVerification': 'Have you verified the anonymised data manually?',
    'complete': 'Are all personal data completely anonymised? (if unsure please select "No")',
  },
  "transfer": {
    "electronic": "Do you use electronic or physical transfer for the data?",
    "security": "Is the transfer channel using SOTA security measures?",
    "thirdPartyServer": "Are the data stored (even temporary) on any third-party server?",
    "bestPractices": "Are you following best practices for securing the data on such device during transfer?",
  },
  "cybersecurity": {
    "standard": "Does the requester follow a cybersecurity standard/framework on their premises?",
    "securityMeasures": "Does the requester implement SOTA security measures for their technical insfrastructure?",
    "accessRestrictions": "Does the requester implement data access restrictions?",
    "lifecycle": "Does the requester manages data lifecycle?",
    "experience": "Have the persons, who will be handling the data, received appropriate training?",
    "thirdPartyUsers": "Does the requester monitor and control third-party users and applications?",
    "audit": "Is the requester being audited by an external party for cybersecurity?",
    "cyberattack": "Has the requester suffered a cyberattack or any other security breach over the last two years?",
  },
  "agreementUnauthorized": {
    "licenseExist": "Do you have a license agreement with the requester?",
    "dataDistribution": "Does the license allow distribution of the dataset to third-parties?",
    "workDistribution": "May the licensee distribute the modified and adapted work?",
    "publish": "Can parts of the dataset be published in scientific publications?",
    "permanent": "Is the license permanent?",
    "evidence": "In the event that the license is terminated, shall the Licensee provide evidence of the deletion?",
    "domain": "Can the dataset be used in any domain/industry?",
  },
  "quality": {
    "biasChecked": "Have you checked the data for potential bias?",
    "qualityChecked": "Have you checked the quality of the data?",
    "purposeChecked": "Have you verified that the data can be used for the intended purpose?",
    "toolUsed": "Are you using an automatic tool for checking the data quality?",
    "verified": "Is the data quality tool verfified by a third party?", 
  },
  "agreementCompliance": {
    "clausesPersonal": "Does the license include clauses for personal data?",
    "storageOutside": "Does the requester plan to store any data outside of the EU?",
    "fctregulations": "Have you considered national laws or and other directives that may prohibit or in general regulate FCT data sharing?",
  },
  "misuse": {
    "surveillance": "Can the data be used to create mass surveillance systems?",
    "manipulation": "Are the data being used to create systems (including AI) that manipulate human behavior, use real-time remote biometric identification in public spaces or rank people based on personal characteristics, socio-economic staus or behavior?",
    "endanger": "Can the data be used to create systems (including AI) that may endanger health, safety or fundamental rights of persons?",
    "generalPurpose": "Are the data being used to create general purpose AI systems?",
    
  }
}

export let examplesDatasetConfidentiality = [
  "Shared FCT data are intercepted during electronic transfer by a third-party.",
  "Shared data are accessed by a person that has no permission to access the data.",
  "Data are stolen from the requester's infrastructure due to a cyber attack.",
  "Personal data are not anymized correctly and specific individuals can be identified by the requester.",
]

export let examplesDatasetBias = [
  "People are not represented equally regarding their color, ethnicity, gender, etc.",
  "Records present criminal activity from certain population groups only",
]

export let examplesDatasetLegalCompliance = [
  "License agreement does not cover GDPR when personal data are involved",
  "License agreement not compliant with AI-Act"
]
export let examplesDatasetDataMisuse = [
  "Data are used to create AI systems that can may potentially take control of critical infrastructures.",
  "Data are used to create AI systems that help police officers identify criminal behavior from video surveillance but is also used to track people's personal moments."
]

export let examplesAIRisk = [
  ""
]
export let examplesDatasetPrivacyAnonymization = [
  "Anonymizing personal data is an important step in preserving the privacy of individuals when sharing datasets with personal data. However, in some cases research must be carried out on the original data. In that case no anonymization must be performed and data must be shared 'as is'."
]
export let explanationDatasetPrivacyAnonymization = [
  "An AI model requires training on the original data rather than random data (e.g. license plates)."
]
export let explanationPrivacyPreservationVerified = [
  "It is important to verify that the anonymization tool does indeed perform the anonymization correctly. Every tool has it's shortcomings that must be identified and documented in detail. "
]
export let examplesPrivacyPreservationVerified = [
  "An anonymization tool is open-source and can be verified by other users.",
  "An anonymization tool has been reviewed by an independent committee and its shortcomings have been identified in detail.",
]
export let explanationPrivacyPreservationManualVerification = [
  "Sometimes, manual verification of the anonymization result may help verify that the anonymization has been complete. Especially for images (faces, license plates, etc) a human can manually verify that all personal data have been either removed or replaced by synthetic ones."
]
export let examplesPrivacyPreservationManualVerification = [
  "All files are checked manually one-to-one for remaining personal information before being shared"
]
export let explanationPrivacyPreservationAnonymizationComplete = [
  "Before sharing a dataset all personal data must be removed or replaced by synthetic data to preserve the privacy of the individuals."
]
export let examplesPrivacyPreservationAnonymizationComplete = [
  "A dataset containing personal ids is being shared but not all ids have been successfully anonymized due to technical limitations of the anonymization tool.",
  "A dataset containing people faces is being shared but not all faces have been blurred or replaced by synthesized ones.",
  "A dataset containing images of cars with their license plate visible is being shared but not all license plates have been blurred or replaced by synthesized ones, due to technical limitations of the anonymization tool (e.g. licese plate too small)",
]
export let explanationAnnotationsExist = [
  ""
]
// Data Quality - Bias
export let explanationDataQualityBias = [
  "It is important to check the data before sharing for potential bias regarding human race, ethnicity, gender and other types of people-related bias to make sure that all humans are represented equally."
]
export let examplesDataQualityBias = [
  "A dataset containing gender and race information is identified as biased towards white males.",
]
export let explanationDataQualityCheck = [
  "Data quality is essential for any data-related processing. Poor data quality results in unreliable analysis. High data quality ensures accurate processing and enables high quality output, whether it is a models, an algorithm or analysis. Identifying and correcting data quality issues before sharing a dataset ensures time and resources are not wasted."
]
export let examplesDataQualityCheck = [
  "Inaccurate data, e.g. wrong addresses, zip codes, etc.",
  "Incomplete data, e.g. empty fields in tabular data. Missing names, addresses, etc.",
  "Duplicate data, e.g. a set of people's faces contains the same faces many times, which reduces its value in a AI training scenario.",
  "Inconsistent formatting, e.g. date fields contain the month first in some entries and second in others. ",
  "Usable data, e.g. images have the proper brightness and contrast for processing."
]
export let explanationDataQualityIntendedPurpose = [
  "Before sharing any data it is important to verify that the requested set of data can perform the desired role, e.g. used as input for AI model training. Otherwise, a lot of time and resources will be wasted for negotiations, contracts etc with no result."
]
export let examplesDataQualityIntendedPurpose = [
  "An AI model's training requires multiple angles of CCTV camera but the dataset contains only one angle.",
  "An AI model's training requires faces of people of various races and ethnicities but the dataset contains only white people."
]
export let explanationDataQualityAutomatic = [
  "An automatic data quality monitoring tool can be a great solution for an exhaustive data quality check for bias, completeness, errors etc. However, it should be used appropriately to make sure all data quality parameters are covered."
]
export let examplesDataQualityAutomatic = [
  "The LAGO data quality tool offers an automatic data quality analysis for several indicators.",
  "Open source data quality tools like Great Expectations (GX), Deequ, MobyDQ, etc."
]

export let explanationDataQualityVerified = [
  "It is important to verify that the data quality tool does indeed analyze the dataset for potential bias. Every tool has it's shortcomings that must be identified and documented in detail. "
]
export let examplesDataQualityVerified = [
  "An data quality tool is open-source and can be verified by other users.",
  "An data quality tool has been reviewed by an independent committee and its shortcomings have been identified in detail.",
]

// Data Transfer Method
export let explanationTransferMethod = [
  "Electronic transfer offers a convenient way to share data but the channels used must be secured. On the other hand, physically transporting data avoids electronic pitfalls, but can be potentially risky, as it exposes the data to theft, or loss by mistake (if the drive with the data is left somewhere unattended.)",
]
export let examplesTransferMethod = [
  "Data are transmitted electronically through a web service provided by the data requester.",
  "Data are transmitted electronically through common transfer services like email, weTransfer, dropbox, etc.",
  "Data are saved to a USB drive or optical disk that is then delivered physically to the requester."
]
export let explanationTransferElectronic = [
  "Electronic transfer offers a convenient way to share data but the channels used must be secured."
]
export let examplesTransferElectronic = [
  "Data are transmitted through a web service provided by the data requester.",
  "Data are transmitted through common transfer services like email, weTransfer, dropbox, etc.",

]
export let explanationTransferSecurity = [
  "The data transfer method must use end-to-end encryption and keep up to date with the latest threats in data transfer security."
]
export let examplesTransferSecurity = [
  "Data are encrypted before transmission.",
  "The upload service is configured to accept only specific file types, file sizes, file name size etc.",
  "The upload service has documented policies and procedures to guide all stakeholders for initiating or completing a data transfer process.",
  "The data transfer infrastructure is architected to be secure and resilient, by separating the data transfer systems from the rest of the network.",
  "A “fingerprint” of files before and after uploading is created, using a strong hash function such as SHA 256 and compared at the destination of the data transfer."
]
export let explanationTransferStoredThirdPartyServer = [
  "When using a third-party vendor platform for data transfer, the data may remain for some time on the vendor's servers. This can introduce another vector of attack for data interception.", 
]
export let examplesTransferStoredThirdPartServer = [
  "Data are transferred through email. The data remain on the email provider's servers until deleted manually.",
  "Data are transferred through a web-transfer service like weTransfer, or other cloud based solution. The data remain at the service provider's servers for some time until downloaded by the data receiver."
]
export let explanationTransferPhysical = [
  "Physically transporting data can be potentially risky, as it exposes the data to theft, or loss by mistake (if the drive with the data is left somewhere unattended.)"
]
export let examplesTransferPhysical = [
  "Data are saved to a USB drive or optical disk that is then delivered physically to the requester."
]
export let explanationTransferPhysicalBestPractices = [
  "When physically sending the data it is imperative to follow best practices to cover the risk of exposing sensitive information if the drive is stolen, or lost."
]
export let examplesTransferPhysicalBestPractices = [
  "Removable media devices are encrypted using a self-encrypted USB drive, application-layer encryption or both.",
  "Removable media devices should be labeled, tracked, and stored securely.",
  "Data contained within these devices should be securely wiped when it's no longer required.",
]
export let explanationCybersecurityStandard = [
  "Following well-established standard and protocols can lead to a strong cybersecurity foundation in any organization."
]
export let examplesCybersecurityStandard = [
  "The data requester implements ISO27001, a standard for information security, cybersecurity and privacy protection",
  "The data requester implements NIST Cybersecurity Framework, NIST CSF",
]

export let explanationCybersecurityTechnical = [
  "It is important to follow the state of the art in cybersecurity solutions to guard the data processing systems from potential breaches."
]
export let examplesCybersecurityTechnical = [
  "The data requester enforces strong passwords and multi-factor authentication.",
  "The data requester updates and enforces security policies like computer settings that require the users to enter their password if they are away for a few minutes.",
  "Security updates are regularly installed and data are regularly backed up.",
  "Data backups are being checked regularly for integrity.",
]
export let explanationCybersecurityAccess = [
  "Data access restrictions are important to provide users with different levels of access. Sensitive data must be accessed only by the appropriate personel."
]
export let examplesCybersecurityAccess = [
  "Specialists have access only to the data attributed to their role and tasks.",
  "Access to specialists is renewed regularly (e.g. certain times per year), to ensure access is restricted when no longer necessary.",
  "Logged activities are observed regularly to ensure proper data usage.",
]
export let explanationCybersecurityLifeCycle = [
  "Data lifecycle management (DLM) is an approach to managing data throughout its lifecycle, from data entry to data destruction."
]
export let examplesCybersecurityLifeCycle = [
  "Data is stored in a secure database or other repositories (data warehouse, data lake, etc).",
  "Data are encrypted and can only be decoded by the appropriate personnel.",
  "Data are deleted from all systems when no longer needed."
]
export let explanationCybersecurityTraining = [
  "Much of the success of a cybersecurity protocol implementation relies on employees upholding all security policies. It is necessary for all involved employees to understand and follow the suggested security practices."
]
export let examplesCybersecurityTraining = [
  "Specialists store data on their personal laptops, which may not be secure.",
  "Specialists access unprotected networks through their laptops exposing the data to potential sniffing attacks.",
  "Specialists store data on their personal cloud accounts (google drive, icloud, etc).",
  "People forget to log off before leaving their work computer.",
  "People letting other co-workers use their account.",
  "People share sensitive data with other co-workers without using established protocols by the organization (e.g. through email, etc).",
]
export let explanationCybersecurityThirdParty = [
  "Sometimes organizations provide access to third-party specialists and applications to leverage their technical expertise (e.g. for data analysis). This access must be monitored so that inappropriate data usage or transfers are prohibited."
]
export let examplesCybersecurityThirdParty = [
  "Third-party users and applications are provided with a one-time password.",
  "Each access from a third-party user or application is monitored and recorded in log files and real-time.",
  "Third-party user and application access is restricted only to the specific data as needed by their task.",
]
export let explanationCybersecurityAudit = [
  "A cybersecurity audit establishes criteria that organizations and employees can use to check they are consistently defending against risks, especially as cybersecurity risks grow more sophisticated. Audits should be conducted regularly. For organizations handling personal data experts recommend an audit at least twice per year."
]
export let examplesCybersecurityAudit = [
  "Review of network access control, encryption use, data security at rest, and transmissions",
  "Review of security policies, procedures, and controls.",
  "Review of network & security controls, anti-virus configurations, security monitoring capabilities, etc.",
  "Review of hardening processes, patching processes, privileged account management, role-based access, etc.",
  "Review of disk encryption, role-based access controls, biometric data, multi-factor authentication, etc.",
]
export let explanationCybersecurityAttack = [
  "If the requester has been attacked during the last two years or there has been an indication that this might be the case, they may not have been able to fix the attack vectors yet (including personnel training)",
]
export let examplesCybersecurityAttack = [
  "The IT department has discovered an increased number of unsuccessful attempts from external systems to gain unauthorized access to the database.",
  "Locks in the central data centre have been violated.",
]
export let explanationLicenseExist = [
  "Before sharing any sensitive data a license agreement must be signed to ensure proper usage of the dataset and legal compliance with the appropriate frameworks. The license agreement must control aspects of the intended usage binding the requester to the appropriate use of the dataset.",
]
export let examplesLicenseExist = [
  "A license agreement is signed that binds the requester to the intended usage of the dataset.",
  "The license agreement states that the dataset can only be used for training AI models in the automotive domain.",
  "The license agreement forbids the distribution of the data to third parties, thus limiting potential exposure to other parties/environments.",
  "The license agreement states that no data can be presented 'as is' in research papers, thus protecting any potential exposure."
]
export let explanationLicensePersonal = [
  "In case personal data are being shared the license agreement must be compliant with GDPR."
]
export let examplesLicensePersonal = [
  "A dataset with personal data is shared and the legal agreement describes the lawful basis for sharing the data."
]
export let explanationLicenseOutsideEU = [
  "Storage of personal data of EU citizens outside the EU is forbidden by GDPR. There are exceptions but must be referred to by the license agreement."
]
export let examplesLicenseOutsideEU = [
  "Data must be stored to a datacenter outside the EU, but in a country that is declared as offering adequate level of protection through a European Commission decision."
]
export let explanationLicenseFCTRegulations = [
  "National laws or special directives that may be applicable on a case-by-case basis may prohibit or enforce regulations for FCT data sharing. It is important to consider all these aspects if applicable, so that you are legally compliant when sharing FCT data."
]
export let examplesLicenseFCTRegulations = [
  "An LEA organization is prohibited from sharing any FCT data to other parties or even internally to other departments due to national laws or local organization directives that when broken will lead to legal issues."
]
export let explanationLicenseAIActCompliance = [

]
export let explanationLicenseDistributionThirdParty = [
  "Data are sometimes shared to third-parties for further processing."
]
export let examplesLicenseDistributionThirdParty = [
  "A research institute cooperates with a third-party for processing of big data."
]
export let explanationLicenseDistributionModifiedWork = [
  "Sometimes a licensee may need to share the modified work created by using data from the data provider. This may lead to unauthorized access if the modified work can be reverse engineered and the original data are exposed. For example, a generative text model trained on sensitive data can actually memorize its training data. "
]
export let examplesLicenseDistributionModifiedWork = [
  "A research institute creates a predictive text model which then shares with other parties or on the internet."
]
export let explanationLicensePublication = [
  "Sometimes it is necessary for a publication to show the original data. If the original data contain personal information this must be done withing the boundaries of the lawful basis of GDPR."
]
export let examplesLicensePublication = [
  "A research institute publishes their work for a fraud detection algorithm that was trained on personal data received. The publication contains examples of this original data to demonstrate the classification used in the paper."
]
export let explanationLicensePermanent = [
  "The license agreement should have an expiration date to ensure data are used for a specific purpose only. If data must be used for another purpose, then a new license agreement must be generated."
]
export let examplesLicensePermanent = [
  "A research institute receives data from a law enforcement agency to train an AI model. The license agreement states that it is no longer valid after the training has finished."
]
export let explanationLicenseEvidenceDeletion = [
  "It is important to ensure that data are used only for the intended purpose. Therefore, the license agreement must legally bind the data requester to delete any shared data after the intended task has been completed."
]
export let examplesLicenseEvidenceDeletion = [
  "A research institute receives data from a law enforcement agency to train an AI model. After training is complete data are deleted from the institute's premises."
]
export let explanationLicenseDomain = [
  "It is important to control the usage of the shared data. Therefore the license must legally bind the requester to use the data only on specific domains/industries. A new license can cover other domains if needed."
]
export let examplesLicenseDomain = [
  "A research institute receives data from a law enforcement agency to develop a financial fraud detection system. Later they decide to use the same data to train a personal financial advisor system."
]
export let explanationMisuseSurveillance = [
  "Mass surveillance systems can be dangerous if used inappropriately especially in the hands of organizations without oversight. Such systems, must be carefully controlled."
]
export let examplesMisuseSurveillance = [
  "Data are used to create a system that tracks vehicles, people across the city."
]
export let explanationMisuseManipulation = [
  "AI systems that manipulate human behavior are forbidden by the AI-Act and must not be created due to their unacceptable risk status."
]
export let examplesMisuseManipulation = [
  "Data are used to create a system that analyses human online behavior and rands people as potential buyers.",
  "Data are used to create a system that ranks people based on their economic status.",
  "Data are used to create a system that identifies people in real-time by their faces in public spaces."
]
export let explanationMisuseEndanger = [
  "AI systems that are build to control critical infrastructures or systems that may be used by the police to help them identify or control crimes are considered high risk systems that must be subject to obligations of quality, transparency, human supervision and security as defined by the AI Act "
]
export let examplesMisuseEndanger = [
  "The requester uses the data to create a system that can control critical infrastructures for water, electricity etc, in a city.",
  "The requester creates a system that identifies violent behavior in a camera feed.",
]
export let explanationMisuseGeneralPurpose = [
  "General purpose AI systems include foundation models like ChatGPT and are used for a wide range of use cases like language models, image generation, music generation and others. The systems present limited risk but are subject to transparency obligations, meaning that they must inform the user that they are interacting with an AI."
]
export let examplesMisuseGeneralPurpose = [
  "The requester creates a ChatGPT-like AI system that uses prompts to help police officers organize crime cases.",
  "The requester creates an AI system that is used by police to manipulate images or video to identify the faces of criminals when not clear to the human eye.",
]



export let columnsForImpactTableSocietal = [{
  Header: 'Level of Impact',
  accessor: 'levelofimpact'
  },
  {
    Header: 'Area of effect – Societal',
    accessor: 'aoesocietal'
  }];

export let columnsForThreatScore = [
  {
    Header: 'Level of Probability',
    accessor: 'levelofprobability'
  },
  {
    Header: 'Score',
    accessor: 'score'
  }];
export let dataForThreatScore = [
  {"levelofprobability": 'Low', "score": '1'},
  {"levelofprobability": 'Medium', "score": '2'},
  {"levelofprobability": 'High', "score": '3'},]