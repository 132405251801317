export let riskFactorsMitigationTable = {
  1: {
    'codename': 'mit1',
    'riskfactor': 'Dataset contains personal information.',
    'action': 'Anonymize personal data.',
    'explanation': 'The dataset contains personal data which according to GDPR must be protected. Sharing personal data without anonymization may lead to personal data exposure'
  },
  2: {
    'codename': 'mit2',
    'riskfactor': 'Anonymization not verified by 3rd party.',
    'action': 'Use a tool that is verified to ensure anonymization is performed properly.',
    'explanation': 'The dataset is anonymized but the anonymization tool is not verified by a independent third party. Verification ensures that the anonymization tool does what is supposed to do.'
  },
  3: {
    'codename': 'mit3',
    'riskfactor': 'Anonymization not 100% successful.',
    'action': 'Provide statistics about the anonymization process.',
    'explanation': 'Some data have not been anonymized. If the anonymization tool can provide statistics about the anonymization process the user can have some idea about the number of personal data instances that have not been anonymized correctly.'
  },
  4: {
    'codename': 'mit4',
    'riskfactor': 'Special data exposure.',
    'action': 'Ensure 100% anonymization of the special personal data ',
    'explanation': 'The dataset contains special cases of personal data as described in GDPR. These special personal data must be protected with extra care.'
  },
  5: {
    'codename': 'mit5',
    'riskfactor': 'Electronic data transfer channel lacks state-of-the-art (SOTA) security measures.',
    'action': 'Oblige the requester through the appropriate license agreement to use SOTA security measures like the following: 1) Data are encrypted before transmission. 2) The upload service is configured to accept only specific file types, file sizes, file name size etc. 3) The upload service has documented policies and procedures to guide all stakeholders for initiating or completing a data transfer process. 4) The data transfer infrastructure is architected to be secure and resilient, by separating the data transfer systems from the rest of the network. 5) A “fingerprint” of files before and after uploading is created, using a strong hash function such as SHA 256 and compared at the destination of the data transfer.Involve DPO or refer to ISO standard for training employees',
    'explanation': 'The data transfer method must use end-to-end encryption and keep up to date with the latest threats in data transfer security.'
  },
  6: {
    'codename': 'mit6',
    'riskfactor': 'Data are stored in a third-party server (even temporarily).',
    'action': 'Use a data transfer method that directly sends the data to the requester without storing any data on third-party servers.',
    'explanation': "When using a third-party vendor platform for data transfer, the data may remain for some time on the vendor's servers. This can introduce another vector of attack for data interception."
  },
  7: {
    'codename': 'mit7',
    'riskfactor': 'Requester not following a cybersecurity standard/framework on their premises.',
    'action':  <a href="/">Test</a> + 'the requester through the appropriate license agreement to implement a cybersecurity standard/framework and get certified (e.g. ISO27001, NIST Cybersecurity Framework, etc).',
    'explanation': "Following well-established standard and protocols can lead to a strong cybersecurity foundation in any organization."
  },
  8: {
    'codename': 'mit8',
    'riskfactor': 'Requester not implementing SOTA security measures for their technical infrastructure.',
    'action': 'Oblige the requester through the appropriate license agreement to implement SOTA security measures for their technical infrastructure like the following: 1) Enforce the use of strong passwords and multi-factor authentication. 2) Update and enforce security policies like computer settings that require the users to enter their password if they are away for a few minutes. 3) Security updates are regularly installed and data are regularly backed up. 4) Data backups are being checked regularly for integrity.',
    'explanation': 'It is important to follow the state of the art in cybersecurity solutions to guard the data processing systems from potential breaches.'
  },
  9: {
    'codename': 'mit9',
    'riskfactor': 'Requester not implementing data access restrictions.',
    'action': 'Oblige the requester through the appropriate license agreement to implement data access restrictions like the following: 1) Specialists have access only to the data attributed to their role and tasks. 2) Access to specialists is renewed regularly (e.g. certain times per year), to ensure access is restricted when no longer necessary. 3) Logged activities are observed regularly to ensure proper data usage.',
    'explanation': 'Data access restrictions are important to provide users with different levels of access. Sensitive data must be accessed only by the appropriate personnel.',
  },
  10: {
    'codename': 'mit10',
    'riskfactor': 'Requester not managing data lifecycle.',
    'action': 'Oblige the requester through the appropriate license agreement to manage the data lifecycle by implementing proper data lifecycle controls like the following: 1) Data is stored in a secure database or other repositories (data warehouse, data lake, etc). 2) Data are encrypted and can only be decoded by the appropriate personnel. 3) Data are deleted from all systems when no longer needed.',
    'explanation': 'Data lifecycle management (DLM) is an approach to managing data throughout its lifecycle, from data entry to data destruction.',
  },
  11: {
    'codename': 'mit11',
    'riskfactor': 'Employees handling the data lack sufficient training.',
    'action': 'Oblige the requester through the appropriate license agreement to care for appropriately training all involved personnel to follow best security practices like the following: 1) Not storing sensitive data on their personal laptops unless secured by IT experts 2) Not storing sensitive data on personal cloud accounts (google drive, dropbox, icloud etc) 3) Logging off whenever someone leaves their work computer 4) Not sharing accounts with other co-workers 5) Not sending data to other co-workers through unsecure channels even within the organization but use only specifically designed channels designated by IT experts.',
    'explanation': 'Much of the success of a cybersecurity protocol implementation relies on employees upholding all security policies. It is necessary for all involved employees to understand and follow the suggested security practices.',
  },
  12: {
    'codename': 'mit12',
    'riskfactor': 'Requester not monitoring and controlling third-party users and applications.',
    'action': 'Oblige the requester through the appropriate license agreement to implement best practices when providing access to third-party users or applications like the following: 1) Third-party users and applications are provided with a one-time password. 2) Each access from a third-party user or application is monitored and recorded in log files and real-time. 3) Third-party user and application access is restricted only to the specific data as needed by their task.',
    'explanation': 'Sometimes organizations provide access to third-party specialists and applications to leverage their technical expertise (e.g. for data analysis). This access must be monitored so that inappropriate data usage or transfers are prohibited.',

  },
  13: {
    'codename': 'mit13',
    'riskfactor': 'Requester not being audited by an external party for cybersecurity.',
    'action': 'Oblige the requester through the appropriate license agreement to perform regular audits with external cybersecurity experts to check issues with the following areas: 1) Network access control, encryption use, data security at rest, and transmissions 2) Security policies, procedures, and controls. 3) Network & security controls, anti-virus configurations, security monitoring capabilities, etc. 4) Hardening processes, patching processes, privileged account management, role-based access, etc. 5) Disk encryption, role-based access controls, biometric data, multi-factor authentication, etc.',
    'explanation': 'A cybersecurity audit establishes criteria that organizations and employees can use to check they are consistently defending against risks, especially as cybersecurity risks grow more sophisticated. Audits should be conducted regularly. For organizations handling personal data experts recommend an audit at least twice per year.',
  },
  14: {
    'codename': "mit14",
    'riskfactor': 'Requester suffered a cyberattack or some other security breach over the last two years.',
    'action': "Oblige the requester through the appropriate license agreement to perform a cybersecurity audit with an external cybersecurity expert to check issues with the following areas: 1) Network access control, encryption use, data security at rest, and transmissions 2) Security policies, procedures, and controls. 3) Network & security controls, anti-virus configurations, security monitoring capabilities, etc. 4) Hardening processes, patching processes, privileged account management, role-based access, etc. 5) Disk encryption, role-based access controls, biometric data, multi-factor authentication, etc. 6) Physical security at the requester's premises.",
    'explanation': "If the requester has been attacked during the last two years or there has been an indication that this might be the case, they may not have been able to fix the attack vectors yet (including personnel training)",
  },
  15: {
    'codename': 'mit15',
    'riskfactor': "No license agreement with the requester.",
    'action': "Negotiate a license agreement with the requester to ensure that the requester is legally bind to: 1) keeping the data secure 2) sticking to the intended use 3) share the data only with the pre-defined set of third parties (or not at all) 4) limit the use of the data to the indented domain/industry",
    'explanation': "Before sharing any sensitive data a license agreement must be signed to ensure proper usage of the dataset and legal compliance with the appropriate frameworks. The license agreement must control aspects of the intended usage binding the requester to the appropriate use of the dataset.",
  },
  16: {
    'codename': 'mit16',
    'riskfactor': "The license agreement does not explicitly forbid distribution of the dataset to third-parties.",
    'action': "If distribution of data to other parties is not necessary to the requester to perform the intended work with the data, update the license agreement to explicitly forbid data distribution to third parties to limit the chances of data exposure.",
    'explanation': "Data are sometimes shared to third-parties for further processing. This requires attention as third-parties may not adhere to the same security and data handling practices as the requester.",
  },
  17: {
    'codename': 'mit17',
    'riskfactor': "The licensee is allowed to distribute the modified and adapted work.",
    'action': "Oblige the requester through the appropriate license agreement to take measures to protect against data exposure from reverse engineering of the modified/adapted work. Alternatively, adapt the license agreement to forbid distribution of the modified/adapted work.",
    'explanation': "Sometimes a licensee may need to share the modified work created by using data from the data provider. This may lead to unauthorized access if the modified work can be reverse engineered and the original data are exposed. For example, a generative text model trained on sensitive data can actually memorize its training data.",
  },
  18: {
    'codename': 'mit18',
    'riskfactor': "The licensee is allowed to publish parts of the dataset in scientific publications.",
    'action': "Oblige the requester through the appropriate license agreement to adhere to the requirements of the GDPR for sharing data that contain personal information.",
    'explanation': "Sometimes it is necessary for a publication to show the original data. If the original data contain personal information this must be done withing the boundaries of the lawful basis of GDPR.",
  },
  19: {
    'codename': 'mit19',
    'riskfactor': "License agreement with the requester is permanent.",
    'action': "Update the license agreement with the requester to add an expiration date.",
    'explanation': "The license agreement should have an expiration date to ensure data are used for a specific purpose only. If data must be used for another purpose, then a new license agreement must be generated.",
  },
  20: {
    'codename': 'mit20',
    'riskfactor': "The license agreement does not explicitly request evidence of data deletion from the requester.",
    'action': "Update the license agreement to oblige the requester to show evidence of data deletion when the license agreement has expired.",
    'explanation': "It is important to ensure that data are used only for the intended purpose. Therefore, the license agreement must legally bind the data requester to delete any shared data after the intended task has been completed.",
  },
  21: {
    'codename': 'mit21',
    'riskfactor': "The license agreement does not restrict dataset usage to specific domains/industries?",
    'action': "Update the license agreement to oblige the requester to use the dataset only in the specified domains/industries.",
    'explanation': "It is important to control the usage of the shared data. Therefore the license must legally bind the requester to use the data only on specific domains/industries. A new license can cover other domains if needed.",
  },
  22: {
    'codename': 'mit22',
    'riskfactor': "Dataset not checked for bias.",
    'action': "Check the data for potential bias regarding race, ethnicity, gender or other types of people-related bias to make sure that all humans are represented equally.",
    'explanation': "It is important to check the data before sharing for potential bias regarding human race, ethnicity, gender and other types of people-related bias to make sure that all humans are represented equally.",
  },
  23: {
    'codename': 'mit23',
    'riskfactor': "Data not checked for quality.",
    'action': "Check the data for common data quality issues like the following: 1) Inaccurate data, e.g. wrong addresses, zip codes, etc. 2) Incomplete data, e.g. empty fields in tabular data. Missing names, addresses, etc. 3) Duplicate data, e.g. a set of people's faces contains the same faces many times, which reduces its value in a AI training scenario. 4) Inconsistent formatting, e.g. date fields contain the month first in some entries and second in others. 5) Usable data, e.g. images have the proper brightness and contrast for processing.", 
    'explanation': "Data quality is essential for any data-related processing. Poor data quality results in unreliable analysis. High data quality ensures accurate processing and enables high quality output, whether it is a models, an algorithm or analysis. Identifying and correcting data quality issues before sharing a dataset ensures time and resources are not wasted.",
  },
  24: {
    'codename': 'mit24',
    'riskfactor': "Data not verified as suitable for the intended purpose.",
    'action': "Check that the data being shared can indeed serve the indented purpose (e.g. AI model training) as stated by the requester to avoid unnecessary actions.",
    'explanation': "Before sharing any data it is important to verify that the requested set of data can perform the desired role, e.g. used as input for AI model training. Otherwise, a lot of time and resources will be wasted for negotiations, contracts etc with no result.",
  },
  25: {
    'codename': 'mit25',
    'riskfactor': "Data quality tool not verified by a third-party.",
    'action': "Verify that the tool used is an established solution to ensure that the results are correct. If not possible, consider also using open source solutions that can be a great alternative as they are verified by a lot of users.",
    'explanation': "It is important to verify that the data quality tool does indeed analyze the dataset for potential bias. Every tool has it's shortcomings that must be identified and documented in detail.",
  },
  26: {
    'codename': 'mit26',
    'riskfactor': "The license agreement does not include clauses for personal data however the dataset includes personal data.",
    'action': "Add the necessary clauses to comply with GDPR.",
    'explanation': "In case personal data are being shared the license agreement must be compliant with GDPR.",
  },
  27: {
    'codename': 'mit27',
    'riskfactor': "Personal data are stored outside of the EU.",
    'action': "Either store data only in a EU datacenter or make sure the license agreement explains the lawful basis for this exception.",
    'explanation': "Storage of personal data of EU citizens outside the EU is forbidden by GDPR. There are exceptions but must be referred to by the license agreement.",
  },
  28: {
    'codename': 'mit28',
    'riskfactor': "No legal consideration for national laws or directives that govern sharing FCT data",
    'action': "Please review national legislation or directives that may govern sharing FCT data for your organization before sharing any actual data.",
    'explanation': "National laws or special directives that may be applicable on a case-by-case basis may prohibit or enforce regulations for FCT data sharing. It is important to consider all these aspects if applicable, so that you are legally compliant when sharing FCT data.",
  },
  29: {
    'codename': 'mit29',
    'riskfactor': "Data will be used to create systems (including AI) that may manipulate human behavior, use real-time remote biometric identification in public spaces or rank people based on personal characteristics, socio-economic status or behavior.",
    'action': "AI systems that manipulate human behavior are forbidden by the AI-Act. Either do not share data at all or update the license agreement to make sure the data are not used for a purpose that is allowed by the AI-act.",
    'explanation': "AI systems that manipulate human behavior are forbidden by the AI-Act and must not be created due to their unacceptable risk status.",
  },
  30: {
    'codename': 'mit30',
    'riskfactor': "Data may be used to create systems (including AI) that may endanger health, safety or fundamental rights of persons.",
    'action': "Such systems are subject to monitoring and additional requirements by the EU. Ensure that the requester is compliant with these requirements before sharing the data.",
    'explanation': "AI systems that are build to control critical infrastructures or systems that may be used by the police to help them identify or control crimes are considered high risk systems that must be subject to obligations of quality, transparency, human supervision and security as defined by the AI Act.",
  },
  31: {
    'codename': 'mit31',
    'riskfactor': "Data are being used to create general purpose AI systems.",
    'action': "Such systems are subject to monitoring and additional requirements by the EU. Ensure that the requester is compliant with these requirements before sharing the data.",
    'explanation': "General purpose AI systems include foundation models like ChatGPT and are used for a wide range of use cases like language models, image generation, music generation and others. The systems present limited risk but are subject to transparency obligations, meaning that they must inform the user that they are interacting with an AI.",
  },
  32: {
    'codename': 'mit32',
    'riskfactor': "Physical transfer does not use best practices.",
    'action': "Follow best practices when moving data physically like the following: 1) Removable media devices are encrypted using a self-encrypted USB drive, application-layer encryption or both. 2) Removable media devices should be labeled, tracked, and stored securely. 3) Data contained within these devices should be securely wiped when it's no longer required.",
    'explanation': "When physically sending the data it is imperative to follow best practices to cover the risk of exposing sensitive information if the drive is stolen, or lost.",
  },

  
}