export const uc1 = {
  "toolDetails": {
    "toolName": "Risk Assessment",
    "toolVersion": "5.1",
    "jsonOutputVersion": "0.6"
  },
  "generalDetails": {
    "riskAssessmentTitle": "Sharing licenseV4 dataset to ICCS",
    "userName": "Christos Baloukas",
    "organizationName": "HPOL",
    "organizationType": "LEA",
    "purposeOfSharing": "AI Model Training",
    "datasetModel": "Dataset",
    "datasetNature": "Real",
    "nameOfRequestingEntity": "ICCS",
    "typeOfRequestingEntity": "Research Institute / University",
    "customTypeOfRequestingEntity": "",
    "comments": "In this demo assessment, we examine a hypothetical scenario for a FCT data exchange between ICCS (Research Institute) and Hellenic Police (LEA). ICCS requested images from traffic cameras to train an AI tool that will follow the movement of vehicles through the city.",
    "exportDate": "Wed, 4 Sept 2024",
    "dataTypes": [
      "License plates"
    ],
    "specialDataTypes": [
      "No Special Data"
    ],
    "dataFormats": [
      "Images"
    ]
  },
  "datasetContainsPersonalData": "Yes",
  "datasetContainsSensitiveData": "No",
  "impactLevels": {
    "confidentiality": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "Medium",
          "comments": "If images with personal license plates and the corresponding cars leak, this may have a Medium impact because, for example, criminals may copy the license plate of a person along with their car type and implicate them in criminal activities, therefore creating inconveniences for the people."
        },
        "institutional": {
          "checked": true,
          "level": "High",
          "comments": "If the dataset is leaked, it may induce a loss of trust to the LEA which will affect its operations as people may object to LEA's using traffic video cameras. This may need serious adjustments to operations and may require large amounts of time and funds to overcome. This constitutes a High impact. Also, if the license agreement between the LEA and the research institute does not cover GDPR, this may create legal issues and loss of funds, which is a Medium impact according to Institutional Impact Table. So the final impact level for the institution is High"
        },
        "societal": {
          "checked": true,
          "level": "Medium",
          "comments": "If there is a dataset leak, people may display distrust towards LEAs, ministries and government in general, which is a Medium impact level according to the Societal Impact Table"
        }
      }
    },
    "quality": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "None",
          "comments": "Since the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. This does not have any personal impact. "
        },
        "institutional": {
          "checked": true,
          "level": "Low",
          "comments": "Since the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. In that case, maybe ICCS will turn elsewhere for data and the whole process will be a wast of time. This can result in minor Institutional impact for HPOL because of the lost time and resources."
        },
        "societal": {
          "checked": true,
          "level": "None",
          "comments": "Since, the traffic camera images show vehicles and their license plates low quality data could mean that images show certain types of cars or certain angles only. The AI model that will be developed using this data maybe won't be very capable. In that case, maybe ICCS will turn elsewhere for data and the whole process will be a wast of time. This will have no societal impact."
        }
      }
    },
    "legal": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "None",
          "comments": "Not complying with the legal frameworks will have impact on just our organization not on people."
        },
        "institutional": {
          "checked": true,
          "level": "Medium",
          "comments": "Since we are sharing data license plates that are considered personal data, we need to comply with GDPR. Failing to do so could result in minor legal fines, which constitutes a medium institutional impact accroding to the impact tables."
        },
        "societal": {
          "checked": true,
          "level": "None",
          "comments": "Not complying with the legal frameworks will have an impact on our organization but our operations will continue as is and therefore our job towards protecting society will continue without disruptions. No societal impact is predicted."
        }
      }
    },
    "misuse": {
      "checked": true,
      "levels": {
        "personal": {
          "checked": true,
          "level": "High",
          "comments": "The data contain private vehicles doing their daily routines. If misused the data could be used to track certain individuals through their daily routines, or identify their homes, workplaces etc. This is a potential violation of privacy, which is a High personal impact."
        },
        "institutional": {
          "checked": true,
          "level": "Medium",
          "comments": "Misusing traffic camera data could lead to protests about HPOL storing traffic data or even traffic cameras in general. This may result in minor loss of trust and a change in our operations to accommodate for restrictions in traffic camera use, which is a Medium institutional impact. "
        },
        "societal": {
          "checked": true,
          "level": "High",
          "comments": "The AI model that ICCS will create may be used for private surveillance purposes by HPOL if not used appropirately, which could constitute a significant violation of privacy that will have significant impact on our society in general. Large demonstrations and major distrust towards government agencies can be foreseen in that case, which is a High societal impact."
        }
      }
    }
  },
  "threatLevels": {
    "privacy": {
      "checked": true,
      "answers": {
        "anonymization": {
          "question": "The dataset contains personal data. Have personal data been anonymized?",
          "answer": "No",
          "comments": "The requester needs the original license plates to train the AI model.",
          "checked": true
        },
        "verified": {
          "question": "Is the anonymisation tool verified by a third-party?",
          "answer": "",
          "comments": "",
          "checked": false
        },
        "manualVerification": {
          "question": "Have you verified the anonymised data manually?",
          "answer": "",
          "comments": "",
          "checked": false
        },
        "complete": {
          "question": "Are all personal data completely anonymised? (if unsure please select 'No')",
          "answer": "",
          "comments": "",
          "checked": false
        }
      },
      "level": "N/A"
    },
    "transfer": {
      "checked": true,
      "answers": {
        "electronic": {
          "question": "Do you use electronic or physical transfer for the data?",
          "answer": "Electronic",
          "comments": "The data will be sent through a service provided by ICCS.",
          "checked": true
        },
        "security": {
          "question": "Is the transfer channel using SOTA security measures?",
          "answer": "Yes",
          "comments": "The transfer method uses end-to-end encryption ",
          "checked": true
        },
        "thirdPartyServer": {
          "question": "Are the data stored (even temporary) on any third-party server?",
          "answer": "No",
          "comments": "The data are transferred directly to ICCS's servers.",
          "checked": true
        },
        "bestPractices": {
          "question": "Are you following best practices for securing the data on such device during transfer?",
          "answer": "Yes",
          "comments": "The data are transferred electronically.",
          "checked": true
        }
      },
      "level": "Low"
    },
    "cybersecurity": {
      "checked": true,
      "answers": {
        "standard": {
          "question": "Does the requester follow a cybersecurity standard/framework on their premises?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "securityMeasures": {
          "question": "Does the requester implement SOTA security measures for their technical insfrastructure?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "accessRestrictions": {
          "question": "Does the requester implement data access restrictions?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "lifecycle": {
          "question": "Does the requester manages data lifecycle?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "experience": {
          "question": "Have the persons, who will be handling the data, received appropriate training?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "thirdPartyUsers": {
          "question": "Does the requester monitor and control third-party users and applications?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "audit": {
          "question": "Is the requester being audited by an external party for cybersecurity?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "cyberattack": {
          "question": "Has the requester suffered a cyberattack or any other security breach over the last two years?",
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "Medium"
    },
    "legalUnauthorized": {
      "checked": true,
      "answers": {
        "licenseExist": {
          "question": "Do you have a license agreement with the requester?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "dataDistribution": {
          "question": "Does the license allow distribution of the dataset to third-parties?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "workDistribution": {
          "question": "May the licensee distribute the modified and adapted work?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "publish": {
          "question": "Can parts of the dataset be published in scientific publications?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "permanent": {
          "question": "Is the license permanent?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "evidence": {
          "question": "In the event that the license is terminated, shall the Licensee provide evidence of the deletion?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "domain": {
          "question": "Can the dataset be used in any domain/industry?",
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "Low"
    },
    "quality": {
      "checked": true,
      "answers": {
        "biasChecked": {
          "question": "Have you checked the data for potential bias?",
          "answer": "Yes",
          "comments": "The images do not contain personal information (other than license plates), so they do not exhibit any bias towards people.",
          "checked": true
        },
        "qualityChecked": {
          "question": "Have you checked the quality of the data?",
          "answer": "Yes",
          "comments": "All images are clear with proper brightness and contrast levels.",
          "checked": true
        },
        "purposeChecked": {
          "question": "Have you verified that the data can be used for the intended purpose?",
          "answer": "Yes",
          "comments": "The images have been checked manually that they show different angles of cars and a variety of cars and congestion situations. Bias related to people is not applicable here.",
          "checked": true
        },
        "toolUsed": {
          "question": "Are you using an automatic tool for checking the data quality?",
          "answer": "Yes",
          "comments": "The LAGO DQ tool has been used to verify the quality of the individual images.",
          "checked": true
        },
        "verified": {
          "question": "Is the data quality tool verfified by a third party?",
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "Low"
    },
    "legalCompliance": {
      "checked": true,
      "answers": {
        "clausesPersonal": {
          "question": "Does the license include clauses for personal data?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "storageOutside": {
          "question": "Does the requester plan to store any data outside of the EU?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "fctregulations": {
          "question": "Have you considered national laws or and other directives that may prohibit or in general regulate FCT data sharing?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        }
      },
      "level": "Low"
    },
    "misuse": {
      "checked": true,
      "answers": {
        "surveillance": {
          "question": "Can the data be used to create mass surveillance systems?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "manipulation": {
          "question": "Are the data being used to create systems (including AI) that manipulate human behavior, use real-time remote biometric identification in public spaces or rank people based on personal characteristics, socio-economic staus or behavior?",
          "answer": "No",
          "comments": "",
          "checked": true
        },
        "endanger": {
          "question": "Can the data be used to create systems (including AI) that may endanger health, safety or fundamental rights of persons?",
          "answer": "Yes",
          "comments": "",
          "checked": true
        },
        "generalPurpose": {
          "question": "Are the data being used to create general purpose AI systems?",
          "answer": "No",
          "comments": "",
          "checked": true
        }
      },
      "level": "High"
    },
    "total": {
      "unauthorized": "Medium",
      "quality": "Low",
      "legal": "Low",
      "misuse": "High"
    }
  },
  "riskLevel": {
    "unauthorized": {
      "personal": "Medium",
      "personalScore": "",
      "institutional": "High",
      "institutionalScore": "",
      "societal": "Medium",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "quality": {
      "personal": "None",
      "personalScore": "",
      "institutional": "Low",
      "institutionalScore": "",
      "societal": "None",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "legal": {
      "personal": "None",
      "personalScore": "",
      "institutional": "Medium",
      "institutionalScore": "",
      "societal": "None",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "misuse": {
      "personal": "High",
      "personalScore": "",
      "institutional": "High",
      "institutionalScore": "",
      "societal": "High",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    },
    "total": {
      "personal": "",
      "personalScore": "",
      "institutional": "",
      "institutionalScore": "",
      "societal": "",
      "societalScore": "",
      "total": "",
      "totalScore": ""
    }
  },
  "mitigationActions": [
    {
      "riskfactor": "Requester not following a cybersecurity standard/framework on their premises.",
      "action": "Oblige the requester through the appropriate license agreement to implement a cybersecurity standard/framework and get certified (e.g. ISO27001, NIST Cybersecurity Framework, etc)."
    },
    {
      "riskfactor": "Requester not implementing SOTA security measures for their technical infrastructure.",
      "action": "Oblige the requester through the appropriate license agreement to implement SOTA security measures for their technical infrastructure like the following: 1) Enforce the use of strong passwords and multi-factor authentication. 2) Update and enforce security policies like computer settings that require the users to enter their password if they are away for a few minutes. 3) Security updates are regularly installed and data are regularly backed up. 4) Data backups are being checked regularly for integrity."
    },
    {
      "riskfactor": "Requester not being audited by an external party for cybersecurity.",
      "action": "Oblige the requester through the appropriate license agreement to perform regular audits with external cybersecurity experts to check issues with the following areas: 1) Network access control, encryption use, data security at rest, and transmissions 2) Security policies, procedures, and controls. 3) Network & security controls, anti-virus configurations, security monitoring capabilities, etc. 4) Hardening processes, patching processes, privileged account management, role-based access, etc. 5) Disk encryption, role-based access controls, biometric data, multi-factor authentication, etc."
    },
    {
      "riskfactor": "Data may be used to create systems (including AI) that may endanger health, safety or fundamental rights of persons.",
      "action": "Such systems are subject to monitoring and additional requirements by the EU. Ensure that the requester is compliant with these requirements before sharing the data."
    }
  ],
  "datasetSectionsMenu": [
    {
      "title": "Definition and Context of the Sharing Operation",
      "id": "1",
      "active": true,
      "visited": true,
      "enabled": true
    },
    {
      "id": "2",
      "title": "Impact Evaluation",
      "visited": true,
      "active": false,
      "enabled": true,
      "subNav": [
        {
          "id": "2a",
          "title": "Unauthorized Access (loss of confidentiality)",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2b",
          "title": "Low Quality Dataset",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2c",
          "title": "Legal Non-Compliance",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2d",
          "title": "Data Misuse",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "2e",
          "title": "Overall Impact Evaluation",
          "visited": true,
          "active": false,
          "enabled": true
        }
      ]
    },
    {
      "id": "3",
      "title": "Threat Analysis",
      "visited": true,
      "active": false,
      "enabled": true,
      "subNav": [
        {
          "id": "3a",
          "title": "Unauthorized Access",
          "visited": true,
          "active": false,
          "enabled": true,
          "subNav": [
            {
              "id": "3a.1",
              "title": "Privacy Preservation",
              "visited": true,
              "active": false,
              "enabled": true
            },
            {
              "id": "3a.2",
              "title": "Data Transfer",
              "visited": true,
              "active": false,
              "enabled": true
            },
            {
              "id": "3a.3",
              "title": "Cybersecurity",
              "visited": true,
              "active": false,
              "enabled": true
            },
            {
              "id": "3a.4",
              "title": "License Agreement",
              "visited": true,
              "active": false,
              "enabled": true
            }
          ]
        },
        {
          "id": "3b",
          "title": "Low Quality Dataset",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "3c",
          "title": "Legal Non-Compliance",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "3d",
          "title": "Data Misuse",
          "visited": true,
          "active": false,
          "enabled": true
        },
        {
          "id": "3e",
          "title": "Overall Threat Levels",
          "visited": true,
          "active": false,
          "enabled": true
        }
      ]
    },
    {
      "id": "4",
      "title": "Risk Evaluation",
      "visited": true,
      "active": false,
      "enabled": true
    },
    {
      "id": "5",
      "title": "Mitigation Actions",
      "visited": true,
      "active": false,
      "enabled": true
    },
    {
      "id": "6",
      "title": "Assessment Export",
      "visited": true,
      "active": false,
      "enabled": true
    }
  ]
}