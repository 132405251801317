import React from 'react';
import * as S from './styles'

import {
  Button, 
  Form
} from 'react-bootstrap';
import MySelect from "./components/ntua/dropMenu";
import { Dropdown, Option } from "./components/ntua/dropdown";
import {BsFillArrowRightCircleFill} from "react-icons/bs"
import styled from 'styled-components'
import { useTable } from 'react-table'
import HighRiskEvaluationExample from "./assets/images/final-risk-evaluation-example.png";
import MediumEvaluationExample from "./assets/images/final-medium-risk-evaluation-example.png";
import LowLowEvaluation from "./assets/images/low-low.jpg";
import LowMediumEvaluation from "./assets/images/low-medium.jpg";
import LowHighEvaluation from "./assets/images/low-high.jpg";
import MediumLowEvaluation from "./assets/images/medium-low.jpg";
import MediumMediumEvaluation from "./assets/images/medium-medium.jpg";
import MediumHighEvaluation from "./assets/images/medium-high.jpg";
import HighLowEvaluation from "./assets/images/high-low.jpg";
import HighMediumEvaluation from "./assets/images/high-medium.jpg";
import HighHighEvaluation from "./assets/images/high-high.jpg";

const Styles = styled.div`
  padding: 0.5rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    font-size: 14px;
    background-color:white;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      padding: 0.5rem;
      background-color: #3D5A80;
      color: white;
      text-align: center;
    }
    td {
      
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :first-child {
        max-width:15%;
      }
      :nth-child(2) {
        max-width: 20%;
      }
      :last-child {
        
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table style={{width:'100%'}} {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default class RiskV3 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formValues: [{ url: "" }],
      API_URL: '',
      data:'',
      settings: props.settings,
      urlSubmitted: false,
      sharingPartnerInfoArray: [],
      datasetsInfoArray: [],
      sharingEntityType: '',
      sharingAgreementType: '',
      datasetModelOptions: [
        <Option key='metadata' value='Metadata' />,
        <Option key='dataset' value='Dataset' />,
        <Option key='model' value='Model' />
      ],
      datasetModelSelected: false,
      selectedDatasetModel: 'Dataset',
      dataTypeOptions: [
        { value: 'Financial transactions', label: 'Financial transactions'},
        { value: 'Credit card / bank account numbers', label: 'Credit card / bank account numbers'},
        { value: 'Health data,', label: 'Health data'},
        { value: 'Web browsing data,', label: 'Web browsing data'},
        { value: 'Biometric identifiers', label: 'Biometric identifiers'},
        { value: 'Device identifiers and serial numbers', label: 'Device identifiers and serial numbers'},
        { value: 'Geolocation data', label: 'Geolocation data'},
        { value: 'IP-address', label: 'IP-address'},
        { value: 'Camera fingerprints', label: 'Camera fingerprints'},
        { value: 'License Plates', label: 'License plates'},
        { value: 'Social security number', label: 'Social security number'},
        { value: 'Street Address, city', label: 'Street Address, city'},
        { value: 'E-mail addresses', label: 'E-mail addresses'},
        { value: 'Telephone/Fax numbers', label: 'Telephone/Fax numbers'},
        { value: 'Date of birth', label: 'Date of birth'},
        { value: 'Names', label: 'Names'},
        { value: 'Face', label: 'Face'},
        { value: 'Personal identification code', label: 'Personal identification code'},
        { value: 'Crime statistics/information', label: 'Crime statistics/information'},
        { value: 'Other Personal Data', label: 'Other Personal Data'},
        { value: 'Other Non-Personal FCT Data', label: 'Other Non-Personal FCT Data'},
      ],
      old_dataTypeOptions: [
        { value:'Non-Personal Data', label:'Non-Personal Data'},
        { value:'Personal Info', label:'Personal Info'},
        //{ value:'Physical Characteristics', label:'Physical Characteristics' },
        { value:'Social Activity', label:'Social Activity' },
        { value:'Location Data', label:'Location Data' },
        { value:'Online Activity', label:'Online Activity' },
        { value:'Financial Info', label:'Financial Info' },
        { value:'Health Data', label:'Health Data' },
        { value:'Other Personal Data', label:'Other Personal Data'},
        
      ],
      selectedDataTypes: [{ value:'Non-Personal Data', label:'Non-Personal Data'}],
      specialDataTypeOptions: [
        { value: 'No Special Data', label: 'No Special Data'},
        { value: 'Ratial/Ethnic Info', label: 'Ratial/Ethnic Info'},
        { value: 'Sexual Orientation', label: 'Sexual Orientation'},
        { value: 'Political Views', label: 'Political Views'},
        { value: 'Religious Views', label: 'Religious Views'},
        { value: 'Political Party Memberships', label: 'Political Party Memberships'},
        { value: 'Genetic / Biometric Data', label: 'Genetic / Biometric Data'},
        { value: 'Criminal Past', label: 'Criminal Past'},
        { value: 'Children', label: 'Children'},
      ],
      selectedSpecialDataTypes: [{ value: 'No Special Data', label: 'No Special Data'}],
      dataFormatOptions: [
        { value: 'Text', label: 'Text'},
        { value: 'Images', label: 'Images'},
        { value: 'Video', label: 'Video'},
        { value: 'Audio', label: 'Audio'},
      ],
      selectedDataFormat: [{ value: 'Text', label: 'Text'}],
      /*
      dataFormatOptions: [
        <Option key='text' value='Text' />,
        <Option key='images' value='Images' />,
        <Option key='video' value='Video' />,
        <Option key='audio' value='Audio' />,
      ],
      selectedDataFormat: 'Text',
      */
      datasetNatureOptions: [
        
        <Option key='real' value='Real' />,
        <Option key='synthetic' value='Synthetic' />,
        <Option key='hybrid' value='Hybrid' />,
      ],
      selectedDatasetNature: 'Real',
      datasetAnonymizationOptions: [
        <Option key='no' value='No' />,
        <Option key='yes' value='Yes' />,
        
      ],
      
      
      federatedLearningOptions: [
        <Option key='no' value='No' />,
        <Option key='yes' value='Yes' />,
        
      ],
      selectedFederatedLearningOption: 'No',
      anonymizationToolTrlOptions: [
        <Option key='8to9' value='8 - 9' />,
        <Option key='5to7' value='5 - 7' />,
        <Option key='below5' value='Below 5' />,
      ],
      anonymizationToolOptions: [
        <Option key='license-plate' value='Other' />,
        <Option key='license-plate' value='License plates - VICOM' />,
        <Option key='license-plate' value='Faces - CERTH/ATOS' />,
        <Option key='license-plate' value='Usernames - CENTRIC' />,
      ],
      selectedAnonymizationToolOptions: 'Other',
      selectedAnonymizationTrl: '5 - 7',
      crimeDomainOptions: [
        <Option key='cybercrime' value='Cybercrime' />,
        <Option key='drug-trafficking' value='Drug trafficking' />,
        <Option key='firearms' value='Firearms' />,
        <Option key='counterfeiting' value='Counterfeiting' />,
        <Option key='environmental-crime' value='Environmental crime' />,
        <Option key='trafficking' value='Human Trafficking' />,
      ],
      selectedCrimeDomain: 'Cybercrime',
      usagePolicyOptions: [
        <Option key='open' value='Open' />,
        <Option key='strict' value='Strict' />,
        <Option key='very-strict' value='Very Strict' />,
      ],
      selectedUsagePolicy: 'Strict',
      entityOptions: [
        <Option key='lea' value='LEA' />,
        <Option key='research' value='Research Institute / University' />,
        <Option key='company' value='Company' />,
        <Option key='other' value="Other" />
      ],
      selectedEntityType: 'Other',
      basicDropdownOptions: [
        <Option key='no' value='No' />,
        <Option key='yes' value='Yes' />,
      ],
      percentageDropdownOptions: [
        <Option key='100' value='100%' />,
        <Option key='over95' value='95% - 100%' />,
        <Option key='90to95' value='90% - 95%' />,
        <Option key='below90' value='below 90%' />,
      ],

      effortDropdownOptions: [
        <Option key='minor' value='Minor' />,
        <Option key='major' value='Major' />,
      ],
      impactDropdownOptions: [
        <Option key='none' value='None' />,
        <Option key='low' value='Low' />,
        <Option key='medium' value='Medium' />,
        <Option key='high' value='High' />,
        <Option key='veryhigh' value='Very High' />,
      ],
      finalImpactDropdownOptions: [
        <Option key='no' value='No' />,
        <Option key='low' value='Low' />,
        <Option key='medium' value='Medium' />,
        <Option key='high' value='High' />,
        <Option key='veryhigh' value='Very High' />,
      ],
      threatLevelOptions: [
        <Option key='low' value='Low' />,
        <Option key='medium' value='Medium' />,
        <Option key='high' value='High' />,
      ],
      mitigationTable: {
        m1: {code: "m1", threat: 'Personal data exposure',  mitigationAction: "Use anonymization on the dataset to limit personal data exposure.", explanation: "The dataset contains personal data which according to GDPR must be protected. Sharing personal data without anonymization may lead to personal data exposure."},
        m2: {code: "m2", threat: 'Anonymization not verified by 3rd party',  mitigationAction: "Use a tool that is verified to ensure anonymization is performed properly.", explanation: "The dataset is anonymized but the anonymization tool is not verfiied by a independent third party. Verification ensures that the anonymization tool does what is supposed to do."},
        m3: {code: "m3", threat: 'Special data exposure',  mitigationAction: "Use anonymization on the dataset to limit special data exposure.", explanation: "The dataset contains special cases of personal data as described in GDPR. These special personal data must be protected with extra care."},
        m4: {code: "m4", threat: 'Data transfer method not secure',  mitigationAction: "Use a transfer method with end-to-end encryption.", explanation: "The method used for the tranfer of the dataset to the requester is not secure. Please use a method with end-to-end ecryption."},
        m5: {code: "m5", threat: 'Data access not protected/secured',  mitigationAction: "The requester should implement stricter data access protection mechanisms. (Data access must be restricted to only the appropriate personel. Access to specialists should be renewed at certain times per year for example, logged activites should be observed occasionally. In some cases, specialists have too great access rights and this is unnecessary for their tasks, thus they can access more data than they should. Also, accounts should not be shared between specialists etc.)", explanation: "Access to the dataset on the premises of the requester is not restricted/controled adequately."},
        m6: {code: "m6", threat: 'People not educated in handling sensitive data',  mitigationAction: "The requester should make use of DPOs (data protection officers) plus appropriate training in different related areas. This will help mitigate the risk of handling sensitive data.", explanation: "The people handling the datasets on the premises of the requester have not received adepuate training for handling sensitive data."},
        m7: {code: "m7", threat: 'No cybersecurity protocols',  mitigationAction: "Ask the requester to assess their cybersecurity with an external auditor and make the necessary changes in their infrastructure.", explanation: "The requester does not implement cybersecurity protocols on their premises."},
        m8: {code: "m8", threat: 'No data governance framework',  mitigationAction: "Ask the requester to implement a data governance framework. This will help mitigate the risk of accidental data exposure or data misappropriation", explanation: "The requester does not implement a data governance framework on their premises."},
        m9: {code: "m9", threat: 'No license agreement',  mitigationAction: "Create a license agreement with the requester.", explanation: "There is no license agreement with the requester."},
        m10: {code: "m10", threat: 'Dataset open to distribution',  mitigationAction: "Change the license to limit dataset distribution.", explanation: "The license with the requester allows the dataset to be distributed freely to third parties."},
        m11: {code: "m11", threat: 'Dataset or parts of it may be included in scientific publications',  mitigationAction: "Change the license to limit publication of parts of the dataset.", explanation: "The license with the requester allows parts of the dataset to be published in scientific publications."},
        m12: {code: "m12", threat: 'License not permanent',  mitigationAction: "Add an expiration date to the license.", explanation: "The license with the requester has no expiration date."},
        m13: {code: "m13", threat: 'Evidence of deletion',  mitigationAction: "Request evidence of deletion from the requester when the license expires.", explanation: "The requester is not obligated to submit evidence of data deletion after the license expires."},
        m14: {code: "m14", threat: 'Dataset can be used in any domain/industry',  mitigationAction: "Restrict dataset usage to specific domains/industries based on the plans of the requester.", explanation: "The license with the requester allows the dataset to be used in all domains/industries without restrictions."},
      },
      otherEntitySelected: false,
      selectedDistributionRestrictions: 'No',
      selectedDistributionModifications: 'No',
      selectedLicenseModifications: 'No',
      selectedModelTrainingOptions: 'No',
      selectedScientificPublicationOptions: 'No',
      selectedEvidenceDeletionOptions: 'No',
      selectedSensitiveContextOptions: 'No',
      selectedDatasetPersonalDataOptions: 'No',
      selectedLicensePermenant: 'Yes',
      selectedSharingFrom: 'LEA',
      selectedSharingTo:'LEA',
      finalRiskCalculation: 'Error',
      finalRiskDetails: [
        <li style={{marginBottom:20}}>The dataset contains license plates which are considered personal data and therefore must be protected under the GDPR directive.</li>,
        <li style={{marginBottom:20}}>The dataset if shared in its original form could have minor consequences for individuals, which they will be able to overcome. It would also have very minor legal consequences for your organization. The operations of the organizations are not in any danger. All the previous lead to the risk of unauthorized access having a low impact assessment.</li>,
        <li style={{marginBottom:20}}>The dataset is anonymized, however the anonymization tool reported a success rate of 95% which creates a risk of some personal data being still identifiable in the dataset.</li>,
        <li style={{marginBottom:20}}>The license agreement allows for the distribution of the dataset to other third-parties which creates a risk because the third parties are not controlled by your organization.</li>
      ],
      mitigationActions: [
        <li style={{marginBottom:20}}>Remove the data that have not been anonymized as reported by the anonymization tool. Otherwise, review them manually and approve. Finally, you may use another anonymization tool to achieve a 100% success rate.</li>,
        <li style={{marginBottom:20}}>Change the license to restrict distribution</li>,
        <li style={{marginBottom:20}}>Change the license to restrict domain/industry usage</li>,
        <li style={{marginBottom:20}}>Change the license to impose evidence of deletion</li>
      ],
      sectionGeneralActive: true,
      sectionGeneralSub1Active: true,
      sectionGeneralSub2Active: false,
      sectionImpactActive: false,
      sectionImpactSub1Active: false,
      sectionImpactSub2Active: false,
      sectionImpactSub3Active: false,
      sectionImpactSub4Active: false,
      sectionImpactSub5Active: false,
      sectionImpactSub6Active: false,
      sectionImpactSub7Active: false,
      sectionImpactSub8Active: false,
      sectionImpactSub9Active: false,
      sectionImpactSub10Active: false,
      sectionThreatsActive: false,
      sectionThreatsSub1Active: false,
      sectionThreatsSub2Active: false,
      sectionThreatsSub3Active: false,
      sectionThreatsSub4Active: false,
      sectionThreatsSub5Active: false,
      sectionThreatsSub6Active: false,
      sectionEvaluationActive: false,
      sectionEvaluationSub1Active: false,
      sectionEvaluationSub2Active: false,
      riskCalculated: false,
      impactOnIndividuals: false,
      impactOnInstitutionalReputation: false,
      impactOnInstitutionalLegal: false,
      impactOnSocietal: false,
      impactOnEthical: false,
      impactOnEconomical: false,
      impactOnTechnical: false,
      impactOnOther: false,
      impactLevelOnIndividuals: 'None',
      finalImpactLevelOnIndividuals: 'No',
      impactLevelOnInstitutionalRep: 'None',
      finalImpactLevelOnInstitutionalRep: 'No',
      impactLevelOnInstitutionalLegal: 'None',
      finalImpactLevelOnInstitutionalLegal: 'No',
      impactLevelOnSocietal: 'None',
      finalImpactLevelOnSocietal: 'No',
      impactLevelOnEthical: 'None',
      finalImpactLevelOnEthical: 'No',
      impactLevelOnEconomical: 'None',
      finalImpactLevelOnEconomical: 'No',
      impactLevelOnTechnical: 'None',
      finalImpactLevelOnTechnical: 'No',
      impactLevelOnOther: 'None',
      finalImpactLevelOnOther: 'No',
      individualsOvercome: 'No',
      levelOfImpactOnIndividuals: 'Minor',
      datasetContainsPersonalData: 'No',
      datasetContainsSensitiveData: 'No',
      levelOfLegalImplications: 'Minor',
      organizationContinueOperations: 'No',
      organizationLossOfTrust: 'No',
      //dataTypeOptions: ['name, address', 'other personal data', 'other non-personal data'];
      impactLevelCalculated: false,
      selectedDatasetAnonymizationOption: 'No',
      isDataAnonymized: 'No',
      anonymizationPercentage: '100%',
      isAnonymizationToolVerified: 'No',
      anonymizationThreatLevel: 'Low',
      areThereDataAnnotations: 'No',
      dataAnnotationsThreatLevel: 'Low',
      dataTransferSecure: 'No',
      dataTransferThreatLevel: 'Low',
      dataAccessProtection: 'No',
      dataAccessProtectionThreatLevel: 'Low',
      peopleEducation: 'No',
      peopleEducationThreatLevel: 'Low',
      institutionCybersecurity: 'No',
      institutionCybersecurityThreatLevel: 'Low',
      institutionGovernance: 'No',
      institutionGovernanceThreatLevel: 'Low',
      institutionDPO: 'No',
      institutionWorkLocationSecure: 'No',
      institutionWorkLocationSecureThreatLevel: 'Low',
      legalLicense: 'No',
      legalDistribution: 'No',
      legalDistributionModified: 'No',
      legalPublications: 'No',
      legalPermenant: 'No',
      legalEvidenceOfDeletion: 'No',
      legalAnyDomain: 'No',
      legalSubpopulations: 'No',
      legalEthicalReview: 'No',
      legalLegalReview: 'No',
      finalIndividualImpactLevel: 'Low',
      finalInstitutionalImpactLevel: 'Low',
      threatLevelCalculated: false,
      finalTechnologyThreatLevel: 'Low',
      finalPeopleThreatLevel: 'Low',
      finalInstitutionalThreatLevel: 'Low',
      finalLegalThreatLevel: 'Low',
      finalOtherThreatLevel: 'Low',

      finalImpactEvaluation: 'Low',
      finalThreatEvaluation: 'Low',
      finalRiskScoreEvaluation: 'Low',

      finalRiskTable: [],
      finalMitigationTable: [],

      selectedRequestingEntity: 'Other',
      generalInfo: {
        'riskAssessmentTitle': '',
        'yourName': '',
        'nameOfYourOrganization': '',
        'comments': '',
        'purposeOfSharing': '',
        'nameOfRequestingEntity': '',
        'typeOfRequestingEntity': 'LEA',
        'customTypeOfRequestingEntity': ''
      },
      generalEmptyFields: false,
      impactEmptyFields: false,
      threatsEmptyFields: false,

      riskAssessmentData: {},
    }

    
    this.readFile = this.readFile.bind(this);
    this.parseAggregatedInfo = this.parseAggregatedInfo.bind(this);
    this.onChangeEntity = this.onChangeEntity.bind(this);
    this.onChangeAgreement = this.onChangeAgreement.bind(this);
    this.handleDatasetModelChange = this.handleDatasetModelChange.bind(this);
    this.handleDataTypeChange = this.handleDataTypeChange.bind(this);
    this.handleSpecialDataTypeChange = this.handleSpecialDataTypeChange.bind(this);
    this.handleDataFormatSelection = this.handleDataFormatSelection.bind(this);
    this.handleDatasetNatureSelection = this.handleDatasetNatureSelection.bind(this);
    this.handleDatasetAnonymizationSelection = this.handleDatasetAnonymizationSelection.bind(this);
    this.handleAnonymizationTrlSelection = this.handleAnonymizationTrlSelection.bind(this);
    this.handleUsagePolicySelection = this.handleUsagePolicySelection.bind(this);
    this.handleSharingFromSelection = this.handleSharingFromSelection.bind(this);
    this.hanldeSharingToSelection = this.hanldeSharingToSelection.bind(this);
    this.handleFederatedLearningSelection = this.handleFederatedLearningSelection.bind(this);
    this.handleLicensePermenantSelection = this.handleLicensePermenantSelection.bind(this);
    this.handleDistributionRestrictions = this.handleDistributionRestrictions.bind(this);
    this.handleAnonymizationToolSelection = this.handleAnonymizationToolSelection.bind(this);
    this.handleLicensePlatesSelection = this.handleLicensePlatesSelection.bind(this);
    this.calculateRisk = this.calculateRisk.bind(this);
    //this.sectionUpdate = this.sectionUpdate.bind(this);
    //this.sectionRefresh = this.sectionRefresh.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.handleImpactSelection = this.handleImpactSelection.bind(this);
    this.calculateImpactLevel = this.calculateImpactLevel.bind(this);
    this.calculateThreatLevel = this.calculateThreatLevel.bind(this);
    this.hanldeIndividualsOvercome = this.hanldeIndividualsOvercome.bind(this);
    this.handleLevelOfImpactOnIndividual = this.handleLevelOfImpactOnIndividual.bind(this);
    this.handleDatasetContainsPersonalData = this.handleDatasetContainsPersonalData.bind(this);
    this.handleDatasetContainsSensitiveData = this.handleDatasetContainsSensitiveData.bind(this);
    this.handleLevelOfLegalImplications = this.handleLevelOfLegalImplications.bind(this);
    this.handleOrganizationContinueOperations = this.handleOrganizationContinueOperations.bind(this);
    this.handleOrganizationLossOfTrust = this.handleOrganizationLossOfTrust.bind(this);
    this.mapLevelToScore = this.mapLevelToScore.bind(this);
    this.onGeneralInfoChange = this.onGeneralInfoChange.bind(this);
    this.handleImpactLevelSelection = this.handleImpactLevelSelection.bind(this);
    this.handleLegalAnswers = this.handleLegalAnswers.bind(this);
    this.handleInstitutionalThreatsAnswers = this.handleInstitutionalThreatsAnswers.bind(this);
    this.handleTechnologyThreatsAnswers = this.handleTechnologyThreatsAnswers.bind(this);
    this.handlePeopleThreatsAnswers = this.handlePeopleThreatsAnswers.bind(this);
    this.handleFinalThreatChange = this.handleFinalThreatChange.bind(this);
    this.calculateFinalRiskEvaluation = this.calculateFinalRiskEvaluation.bind(this);
    this.exportRiskAssessmentDataToJson = this.exportRiskAssessmentDataToJson.bind(this);


  }
  /*
  sectionRefresh() {
    
    this.setState({
      sectionGeneralActive: false,
      sectionGeneralSub1Active: false,
      sectionGeneralSub2Active: false,
      sectionImpactActive: false,
      sectionImpactSub1Active: false,
      sectionImpactSub2Active: false,
      sectionImpactSub3Active: false,
      sectionThreatsActive: false,
      sectionThreatsSub1Active: false,
      sectionThreatsSub2Active: false,
      sectionThreatsSub3Active: false,
      sectionEvaluationActive: false,
      sectionEvaluationSub1Active: false,
      sectionEvaluationSub2Active: false,
      sectionEvaluationSub3Active: false,
    })
  }
  */
 /*
  sectionUpdate(activeSection) {
    this.sectionRefresh();
    switch (activeSection) {
      case 'general':
        this.setState({
          sectionGeneralActive: true,
          sectionGeneralSub1Active: true
        })
        break;
      case 'generalsub1':
        
        this.setState({
          sectionGeneralSub1Active: true
        })
        break;
      case 'generalsub2':
        
        this.setState({
          sectionGeneralSub2Active: true
        })
        break;
      case 'impact':
        
        this.setState({
          sectionImpactActive: true
        })
        break;
      case 'impactsub1':
        
        this.setState({
          sectionImpactSub1Active: true
        })
        break;
      case 'impactsub2':
        
        this.setState({
          sectionImpactSub2Active: true
        })
        break;
      case 'impactsub3':
        
        this.setState({
          sectionImpactSub3Active: true
        })
        break;
      case 'threats':
        
        this.setState({
          sectionThreatsActive: true
        })
        break;
      case 'threatssub1':
        
        this.setState({
          sectionThreatsSub1Active: true
        })
        break;
      case 'threatssub2':
        
        this.setState({
          sectionThreatsSub2Active: true
        })
        break;
      case 'threatssub3':
        
        this.setState({
          sectionThreatsSub3Active: true
        })
        break;
      case 'evaluation':
        
        this.setState({
          sectionEvaluationActive: true
        })
        break;
      case 'evaluationsub1':
        
        this.setState({
          sectionEvaluationSub1Active: true
        })
        break;
      case 'evaluationsub2':
        
        this.setState({
          sectionEvaluationSub2Active: true
        })
        break;
      case 'evaluationsub3':
        
        this.setState({
          sectionEvaluationSub3Active: true
        })
        break;
    
      default:
        break;
    } 
  }
  */

  nextStep(targetSection) {

    //this.sectionRefresh();

    let generalEmptyFields = false;
    let sectionGeneralActive = false;
    let sectionGeneralSub1Active = false;
    let sectionGeneralSub2Active = false;
    let sectionImpactActive = false;
    let sectionImpactSub1Active = false;
    let sectionImpactSub2Active = false;
    let sectionImpactSub3Active = false;
    let sectionImpactSub4Active = false;
    let sectionImpactSub5Active = false;
    let sectionImpactSub6Active = false;
    let sectionImpactSub7Active = false;
    let sectionImpactSub8Active = false;
    let sectionImpactSub9Active = false;
    let sectionImpactSub10Active = false;
    let sectionThreatsActive = false;
    let sectionThreatsSub1Active = false;
    let sectionThreatsSub2Active = false;
    let sectionThreatsSub3Active = false;
    let sectionThreatsSub4Active = false;
    let sectionThreatsSub5Active = false;
    let sectionEvaluationActive = false;
    let sectionEvaluationSub1Active = false;
    let sectionEvaluationSub2Active = false;
    let sectionEvaluationSub3Active = false;
    let riskCalculated = false;
    /*
    if (this.state.generalInfo.riskAssessmentTitle === '' || this.state.generalInfo.yourName === '' || 
        this.state.generalInfo.nameOfYourOrganization === '' || this.state.generalInfo.purposeOfSharing === '' ||
        this.state.generalInfo.nameOfRequestingEntity === '') {
          generalEmptyFields = true;
          //alert("Please fill in all required fields");
        }
    else if (this.state.generalInfo.typeOfRequestingEntity === 'Other' && this.state.generalInfo.customTypeOfRequestingEntity === '') {
        generalEmptyFields = true;
        //alert("Please fill in all required fields");
    } else {
      */
      switch (targetSection) {
        case 'generalsub1':
          sectionGeneralActive = true;
          sectionGeneralSub1Active = true;
          break;
        case 'generalsub2':
          sectionGeneralActive = true;
          sectionGeneralSub2Active = true;
          break;
        case 'impactsub1':
          sectionImpactActive = true;
          sectionImpactSub1Active = true;
          break;
        case 'impactsub2':
          sectionImpactActive = true;
          sectionImpactSub2Active = true;
          break;
        case 'impactsub3':
          sectionImpactActive = true;
          sectionImpactSub3Active = true;
          
          break;
        case 'impactsub4':
          sectionImpactActive = true;
          sectionImpactSub4Active = true;
          
          break;
        case 'impactsub5':
          sectionImpactActive = true;
          sectionImpactSub5Active = true;
          
          break;
        case 'impactsub6':
          sectionImpactActive = true;
          sectionImpactSub6Active = true;
          
          break;
        case 'impactsub7':
          sectionImpactActive = true;
          sectionImpactSub7Active = true;
          
          break;
        case 'impactsub8':
          sectionImpactActive = true;
          sectionImpactSub8Active = true;
          
          break;
        case 'impactsub9':
          sectionImpactActive = true;
          sectionImpactSub9Active = true;
          
          break;
        case 'impactsub10':
          sectionImpactActive = true;
          sectionImpactSub10Active = true;
          
          break;
        case 'threatssub1':
          sectionThreatsActive = true;
          sectionThreatsSub1Active = true;
          break;
        case 'threatssub2':
          sectionThreatsActive = true;
          sectionThreatsSub2Active = true;
          break;
        case 'threatssub3':
          sectionThreatsActive = true;
          sectionThreatsSub3Active = true;
          break;
        case 'threatssub4':
          sectionThreatsActive = true;
          sectionThreatsSub4Active = true;
          break;
        case 'threatssub5':
          this.calculateThreatLevel();
          sectionThreatsActive = true;
          sectionThreatsSub5Active = true;
          break;
        case 'evaluationsub1':
          this.calculateFinalRiskEvaluation();
          sectionEvaluationActive = true;
          sectionEvaluationSub1Active = true;
          break;
        case 'evaluationsub2':
          sectionEvaluationActive = true;
          sectionEvaluationSub2Active = true;
          break;
        case 'evaluationsub3':
          sectionEvaluationActive = true;
          sectionEvaluationSub3Active = true;
          break;
      
        default:
          break;
      } 
    //}
      //console.log('sectionImpactSub1Active: ', sectionImpactSub1Active);
      //console.log('this.sectionImpactSub1Active: ', this.state.sectionImpactSub1Active);

    this.setState({

      generalEmptyFields: generalEmptyFields,
      sectionGeneralActive: sectionGeneralActive,
      sectionGeneralSub1Active: sectionGeneralSub1Active,
      sectionGeneralSub2Active: sectionGeneralSub2Active,
      sectionImpactActive: sectionImpactActive,
      sectionImpactSub1Active: sectionImpactSub1Active,
      sectionImpactSub2Active: sectionImpactSub2Active,
      sectionImpactSub3Active: sectionImpactSub3Active,
      sectionImpactSub4Active: sectionImpactSub4Active,
      sectionImpactSub5Active: sectionImpactSub5Active,
      sectionImpactSub6Active: sectionImpactSub6Active,
      sectionImpactSub7Active: sectionImpactSub7Active,
      sectionImpactSub8Active: sectionImpactSub8Active,
      sectionImpactSub9Active: sectionImpactSub9Active,
      sectionImpactSub10Active: sectionImpactSub10Active,
      sectionThreatsActive: sectionThreatsActive,
      sectionThreatsSub1Active: sectionThreatsSub1Active,
      sectionThreatsSub2Active: sectionThreatsSub2Active,
      sectionThreatsSub3Active: sectionThreatsSub3Active,
      sectionThreatsSub4Active: sectionThreatsSub4Active,
      sectionThreatsSub5Active: sectionThreatsSub5Active,
      sectionEvaluationActive: sectionEvaluationActive,
      sectionEvaluationSub1Active: sectionEvaluationSub1Active,
      sectionEvaluationSub2Active: sectionEvaluationSub2Active,
      sectionEvaluationSub3Active: sectionEvaluationSub3Active,
      riskCalculated: riskCalculated

    });

    
  }
  onGeneralInfoChange = (event, field) => {
    let updatedValue = event.target.value;
    let generalInfo = this.state.generalInfo;
    //console.log('onGeneralInfoChange, event.target.value:', event.target.value);
    //console.log('onGeneralInfoChange, field:', field);

    switch (field) {
      case 'riskAssessmentTitle':
        generalInfo.riskAssessmentTitle = updatedValue;
        break;
      case 'yourName':
        generalInfo.yourName = updatedValue;
        break;
      case 'nameOfYourOrganization':
        generalInfo.nameOfYourOrganization = updatedValue;
        break;
      case 'comments':
        generalInfo.comments = updatedValue;
        break;
      case 'purposeOfSharing':
        generalInfo.purposeOfSharing = updatedValue;
        break;
      case 'nameOfRequestingEntity':
        generalInfo.nameOfRequestingEntity = updatedValue;
        break;
      case 'typeOfRequestingEntity':
        generalInfo.typeOfRequestingEntity = updatedValue;
        break;
      case 'customTypeOfRequestingEntity':
        generalInfo.customTypeOfRequestingEntity = updatedValue;
        break;
    
      default:
        break;
    }

    this.setState({
      generalInfo: generalInfo
    })
  }


  calculateRisk() {
    let selectedDataTypes = this.state.selectedDataTypes;
    let selectedSpecialDataTypes = this.state.selectedSpecialDataTypes;
    let selectedDataFormat = this.state.selectedDataFormat;
    let selectedDatasetNature = this.state.selectedDatasetNature;
    let selectedDatasetAnonymizationOption = this.state.selectedDatasetAnonymizationOption;
    let selectedAnonymizationTrl = this.state.selectedAnonymizationTrl;
    let selectedCrimeDomain = this.state.selectedCrimeDomain;
    let selectedUsagePolicy = this.state.selectedUsagePolicy;
    let selectedSharingFrom = this.state.selectedSharingFrom;
    let selectedSharingTo = this.state.selectedSharingTo;
    let selectedLicensePlatesAnonymization = this.state.selectedLicensePlatesAnonymization;



    let finalRiskDetails = [];
    let mitigationActions = [];

    let dataTypeScore = 0;
    let datasetNatureScore = 0;
    let anonymizationScore = 0;
    let anonymizationTrlScore = 0;
    let usagePolicyScore = 0;

    let finalRiskScore = 0;
    let personalDataTypeFound = false;
    for (let i = 0; i < selectedDataTypes.length; i++ ) {
      if (selectedDataTypes[i].value !== 'Non-Personal Data') {
        personalDataTypeFound = true;
      }
    }
    if (personalDataTypeFound) {
      finalRiskScore += 3;
      finalRiskDetails.push(<li>The dataset contains personal information according to the GDPR regulation must be protected. This fact creates a risk when sharing this particular dataset.</li>);
    } else {
      finalRiskScore += 0;
    }
    console.log('selectedDataTypes:', selectedDataTypes);
    console.log('risk score after dataType:', finalRiskScore);

    let specialPersonalDataTypeFound = false;
    for (let i = 0; i < selectedSpecialDataTypes.length; i++ ) {
      if (selectedSpecialDataTypes[i].value !== 'No Special Data') {
        specialPersonalDataTypeFound = true;
      }
    }
    if (specialPersonalDataTypeFound) {
      finalRiskScore += 3;
      finalRiskDetails.push(<li>The dataset contains special categories of data which merit higher protection. For that reason the risk is higher when sharing these particular data</li>);
    } else {
      finalRiskScore += 0;
    }
    console.log('selectedSpecialDataTypes:', selectedSpecialDataTypes);
    console.log('risk score after specialData:', finalRiskScore);
    
    if (selectedDatasetNature === 'Synthetic') {
      finalRiskScore = 0;
      finalRiskDetails.push(<li>However, The nature of the dataset is synthetic, which nulifies the risk of sharing the dataset</li>);
    }
    console.log('risk score after dataset nature:', finalRiskScore);
    
    if (selectedDatasetNature === 'Real' && selectedDatasetAnonymizationOption === 'Yes') {
      switch (selectedAnonymizationTrl) {
        case '8 - 9':
          finalRiskScore -= 3;
          finalRiskDetails.push(<li>The dataset containes real data, but it is anonymized with a high TRL tool. Therefore the risk for sharing the dataset is mitigated significantly</li>);
          break;

        case '5 - 7':
          finalRiskScore += 0;
          finalRiskDetails.push(<li>The dataset containes real data, but it is anonymized with a medium TRL tool. There maybe cases of data that have not been anonymized correctly. Therefore the risk for sharing the dataset is higher</li>);
          mitigationActions.push(<li>Use an anonymization tool with higher TRL</li>);
          break;
        
        case 'Below 5':
          finalRiskScore += 3;
          finalRiskDetails.push(<li>The dataset containes real data, but it is anonymized with a low TRL tool. There maybe cases of data that have not been anonymized correctly. Therefore the risk for sharing the dataset is higher</li>);
          mitigationActions.push(<li>Use an anonymization tool with higher TRL</li>);
          break;
        
        default:
          break;
      }
      
    }
    
    if ((personalDataTypeFound || specialPersonalDataTypeFound) && ( selectedDatasetNature === 'Real' && selectedDatasetAnonymizationOption === 'No')) {
      finalRiskScore += 10;
      finalRiskDetails.push(<li>The dataset contains personal data and has not been anonymized, which exposes the real data if shared. Therefore the risk of sharing is very high</li>);
      mitigationActions.push(<li>Anonymize the dataset before sharing</li>);
    }
    console.log('selectedDatasetNature:', selectedDatasetNature);
    console.log('selectedDatasetAnonymizationOption:', selectedDatasetAnonymizationOption);
    console.log('risk score after anonymization:', finalRiskScore);
    
    if (selectedDatasetNature !== 'Synthetic' && (personalDataTypeFound || specialPersonalDataTypeFound)) {

      if (selectedUsagePolicy === 'Open') {
        finalRiskScore += 6;
        finalRiskDetails.push(<li>The dataset is not accompanied by a usage policy. Therefore the risk of sharing is higher when personal data are involved</li>);
        mitigationActions.push(<li>Associate a license agreement to the dataset with a stricter usage policy</li>);
      }
      if (selectedUsagePolicy === 'Strict') {
        finalRiskScore += 3;
        finalRiskDetails.push(<li>The dataset is accompanied by a strict usage policy. Therefore the risk of sharing is somewhat mitigated</li>);
      }
      if (selectedUsagePolicy === 'Very Strict') {
        finalRiskScore += 0;
        finalRiskDetails.push(<li>The dataset is accompanied by a strict usage policy. Therefore the risk of sharing is mitigated significantly</li>);
      }
      if (selectedLicensePlatesAnonymization === 'Yes') {
        finalRiskScore -= 6;
      }
    }
    console.log('selectedUsagePolicy:', selectedUsagePolicy);
    console.log('risk score after usage policy:', finalRiskScore);

    if (selectedDatasetNature !== 'Synthetic') {
      if (selectedSharingFrom === 'LEA' && selectedSharingTo !== 'LEA') {
        finalRiskScore += 3;
      }
    }
    console.log('selectedSharingFrom:', selectedSharingFrom);
    console.log('selectedSharingTo:', selectedSharingTo);
    console.log('risk score after sharing from/to:', finalRiskScore);

    if (mitigationActions.length === 0) {
      mitigationActions.push('None');
    }
    if (finalRiskScore <= 3) {
      this.setState({
        riskCalculated: true,
        finalRiskCalculation: 'Low Risk',
        finalRiskDetails: finalRiskDetails,
        mitigationActions: mitigationActions
      })
    }
    if (finalRiskScore >3 && finalRiskScore <= 6) {
      this.setState({
        riskCalculated: true,
        finalRiskCalculation: 'Medium Risk',
        finalRiskDetails: finalRiskDetails,
        mitigationActions: mitigationActions
      })
    }
    if (finalRiskScore >6) {
      this.setState({
        riskCalculated: true,
        finalRiskCalculation: 'High Risk',
        finalRiskDetails: finalRiskDetails,
        mitigationActions: mitigationActions
      })
    }


    
    
  }
  parseAggregatedInfo(jsonData) {
    let sharingPartnerInfoArray = jsonData["LAGO"]['sharingPartner'];
    //console.log('sharingPartnerInfo:', sharingPartnerInfoArray);
    let datasetsInfoArray = jsonData["LAGO"]["datasetsInfo"];
    //console.log('datasetsInfo:', datasetsInfoArray);

    this.setState( {
      sharingPartnerInfoArray: sharingPartnerInfoArray,
      datasetsInfoArray: datasetsInfoArray
    })
  }

  readFile = async (e) => {
    //console.log('readFile, e', e);
    
    e.preventDefault()
    const reader = new FileReader();
    reader.readAsText(e.target.files[0],  "UTF-8");
    reader.onload = async (e) => { 
      const text = (e.target.result)
      //console.log(text)
      //console.log('readFile, JSON.parse(reader.result)', JSON.parse(reader.result));
      this.parseAggregatedInfo(JSON.parse(reader.result));
      this.setState({
        urlSubmitted: true,
        data: JSON.parse(reader.result)
      });
    };
    
  }

  onChangeEntity(event) {
    //console.log('onChangeEntity: event.target.value', event.target.value);
    //console.log('onChangeEntity: event.target.checked', event.target.checked);
    
    let sharingEntityType = this.state.sharingEntityType;
    //console.log('sharingEntityType before:', sharingEntityType);
    if (event.target.checked) {
      sharingEntityType = event.target.value;
    }
    //console.log('sharingEntityType after:', sharingEntityType);
    this.setState({sharingEntityType: sharingEntityType});
  }
  onChangeAgreement(event) {
    //console.log('onChangeAgreement: event.target.value', event.target.value);
    //console.log('onChangeAgreement: event.target.checked', event.target.checked);
    
    let sharingAgreementType = this.state.sharingAgreementType;
    //console.log('sharingAgreementType before:', sharingAgreementType);
    if (event.target.checked) {
      sharingAgreementType = event.target.value;
    }
    //console.log('sharingAgreementType after:', sharingAgreementType);
    this.setState({sharingAgreementType: sharingAgreementType});
  }
  handleDatasetModelChange = (event) => {
    this.setState({'selectedDatasetModel': event.target.value});  
  
  }
  handleDataTypeChange = (selectedOption) => {
    //console.log('selectedOption:', selectedOption);
    let personalDataTypeFound = 'No';
    console.log('personalDataTypeFound:', personalDataTypeFound);
    if (selectedOption.length===0) {
      
      this.setState({ 
          dataTypesSelected: false,
          selectedDataTypes: selectedOption
      })
    } else {
      selectedOption.forEach(element => {
        if (
          element.label === 'Financial transactions' || 
          element.label === 'Credit card / bank account numbers' ||
          element.label === 'Health data' ||
          element.label === 'Web browsing data' ||
          element.label === 'Biometric identifiers' ||
          element.label === 'Geolocation data' ||
          element.label === 'IP-address' ||
          element.label === 'Camera fingerprints' ||
          element.label === 'License plates' ||
          element.label === 'Social security number' ||
          element.label === 'Face' ||
          element.label === 'Personal identification code' ||
          element.label === 'Other Personal Data'
        ) {
            
            personalDataTypeFound = 'Yes';
            console.log('personalDataTypeFound:', personalDataTypeFound);
        }
      });


      this.setState({ 
        dataTypesSelected: true,
        datasetContainsPersonalData: personalDataTypeFound,
        selectedDataTypes: selectedOption 
      });
    }
    
  }
  handleSpecialDataTypeChange = (selectedOption) => {
    let specialPersonalDataTypeFound = 'No';

    if (selectedOption.length===0) {
        this.setState({ 
          specialDataTypesSelected: false,
          selectedSpecialDataTypes: selectedOption
        })
    } else {
      selectedOption.forEach(element => {
        if (
          element.label === 'Ratial/Ethnic Info' || 
          element.label === 'Sexual Orientation' ||
          element.label === 'Political Views' ||
          element.label === 'Religious Views' ||
          element.label === 'Political Party Memberships' ||
          element.label === 'Genetic / Biometric Data' ||
          element.label === 'Criminal Past' ||
          element.label === 'Children'
        ) {
            
          specialPersonalDataTypeFound = 'Yes';
            console.log('specialPersonalDataTypeFound:', specialPersonalDataTypeFound);
        }
      });
        this.setState({ 
          specialDataTypesSelected: true,
          datasetContainsSensitiveData: specialPersonalDataTypeFound,
          selectedSpecialDataTypes: selectedOption
        });
    }
  }
  handleDataFormatSelection = (selectedOption) => {
        
    if (selectedOption.length===0) {
    
        this.setState({ 
          dataFormatSelected: false,
          selectedDataFormat: selectedOption
        })
    } else {
        this.setState({ 
          dataFormatSelected: true,
          selectedDataFormat: selectedOption
        });
    }
    
  }
  /*
  handleDataFormatSelection = (event) => {
    this.setState({'selectedDataFormat': event.target.value});
  }*/
  handleDatasetNatureSelection = (event) => {
    this.setState({'selectedDatasetNature': event.target.value});
  }
  handleDatasetAnonymizationSelection = (event) => {
    this.setState({'selectedDatasetAnonymizationOption': event.target.value});
  }
  handleAnonymizationTrlSelection = (event) => {
    this.setState({'selectedAnonymizationTrl': event.target.value});
  }
  handleFederatedLearningSelection = (event) => {
    this.setState({'selectedFederatedLearningOption': event.target.value});
  }
  handleLicensePermenantSelection = (event) => {
    this.setState({'selectedLicensePermenant': event.target.value});
  }
  handleCrimeDomainSelection = (event) => {
    this.setState({'selectedCrimeDomain': event.target.value});
  }
  handleUsagePolicySelection = (event) => {
    this.setState({'selectedUsagePolicy': event.target.value});
  }
  handleSharingFromSelection = (event) => {
    this.setState({'selectedSharingFrom': event.target.value});
  }
  hanldeSharingToSelection = (event) => {
    this.setState({'selectedSharingTo': event.target.value});
  }
  handleDistributionRestrictions = (event) => {
    this.setState({'selectedDistributionRestrictions': event.target.value});
  }
  handleDistributionModifications = (event) => {
    this.setState({'selectedDistributionModifications': event.target.value});
  }
  handleLicenseModifications = (event) => {
    this.setState({'selectedLicenseModifications': event.target.value});
  }
  handleModelTrainingOptions = (event) => {
    this.setState({'selectedModelTrainingOptions': event.target.value});
  }
  handleScientificPublicationOptions = (event) => {
    this.setState({'selectedScientificPublicationOptions': event.target.value});
  }
  handleEvidenceDeletionOptions = (event) => {
    this.setState({'selectedEvidenceDeletionOptions': event.target.value});
  }
  handleSensitiveContextOptions = (event) => {
    this.setState({'selectedSensitiveContextOptions': event.target.value});
  }
  handleDatasetPersonalDataOptions = (event) => {
    this.setState({'selectedDatasetPersonalDataOptions': event.target.value});
  }
  handleAnonymizationToolSelection = (event) => {
    this.setState({'selectedAnonymizationToolOptions': event.target.value});
  }
  handleLicensePlatesSelection = (event) => {
    this.setState({'selectedLicensePlatesAnonymization': event.target.value});
  }
  handleImpactSelection = (event, type) => {
    

    let eventValue = event.target.value === 'Yes' ? true : false;
    
    switch (type) {
      case 'individual':
        //console.log('impact:', type);
        //console.log('eventValue:', eventValue);
    
        this.setState({
          impactOnIndividuals: eventValue,
        });
        break;
      case 'institutionalrep':
        this.setState({
          
          impactOnInstitutionalReputation: eventValue,
          
        });
        break;
      case 'institutionallegal':
        this.setState({
          
          impactOnInstitutionalLegal: eventValue,
          
        });
        break;
      case 'societal':
        this.setState({
          
          impactOnSocietal: eventValue
        });
        break;
      case 'ethical':
        this.setState({
          
          impactOnEthical: eventValue
        });
        break;
      case 'economical':
        this.setState({
          
          impactOnEconomical: eventValue
        });
        break;
      case 'technical':
        this.setState({
          
          impactOnTechnical: eventValue
        });
        break;
      case 'other':
        this.setState({
          
          impactOnOther: eventValue
        });
        break;
    
      default:
        break;
    }
  }
  handleImpactLevelSelection = (event, type) => {
    let currentImpactLevelOnIndividuals = this.state.impactLevelOnIndividuals;
    let currentImpactLevelOnInstitutionalRep = this.state.impactLevelOnInstitutionalRep;
    let currentImpactLevelOnInstitutionalLegal = this.state.impactLevelOnInstitutionalLegal;
    let currentImpactLevelOnSocietal = this.state.impactLevelOnSocietal;
    let currentImpactLevelOnEthical = this.state.impactLevelOnEthical;
    let currentImpactLevelOnEconomical = this.state.impactLevelOnEconomical;
    let currentImpactLevelOnTechnical = this.state.impactLevelOnTechnical;
    let currentImpactLevelOnOther = this.state.impactLevelOnOther;
    
    let impactLevel = event.target.value;

    switch (type) {
      case 'individual':
        currentImpactLevelOnIndividuals = impactLevel;
        break;

      case 'institutionalrep':
        currentImpactLevelOnInstitutionalRep = impactLevel;
        break;

      case 'institutionallegal':
        currentImpactLevelOnInstitutionalLegal = impactLevel;
        break;
      case 'societal':
        currentImpactLevelOnSocietal = impactLevel;
        break;
      case 'ethical':
        currentImpactLevelOnEthical = impactLevel;
        break;
      case 'economical':
        currentImpactLevelOnEconomical = impactLevel;
        break;
      case 'technical':
        currentImpactLevelOnTechnical = impactLevel;
        break;
      case 'other':
        currentImpactLevelOnOther = impactLevel;
        break;
    
      default:
        break;
    }

    //console.log('impactLevel:', impactLevel);

    this.setState({
      impactLevelOnIndividuals: currentImpactLevelOnIndividuals,
      impactLevelOnInstitutionalRep: currentImpactLevelOnInstitutionalRep,
      impactLevelOnInstitutionalLegal: currentImpactLevelOnInstitutionalLegal,
      impactLevelOnSocietalEthical: currentImpactLevelOnSocietal,
      impactLevelOnEthical: currentImpactLevelOnEthical,
      impactLevelOnEconomical: currentImpactLevelOnEconomical,
      impactLevelOnTechnical: currentImpactLevelOnTechnical,
      impactLevelOnOtherf: currentImpactLevelOnOther,
    })
  }

  handleTechnologyThreatsAnswers = (event, type) => {
    let currentIsDataAnonymized = this.state.isDataAnonymized;
    let currentAnonymizationPercentage = this.state.anonymizationPercentage;
    let currentIsAnonymizationToolVerified = this.state.isAnonymizationToolVerified;
    let currentAnonymizationThreatLevel = this.state.anonymizationThreatLevel;
    let currentAreThereDataAnnotations = this.state.areThereDataAnnotations;
    let currentDataAnnotationsThreatLevel = this.state.dataAnnotationsThreatLevel;
    let currentDataTransferSecure = this.state.dataTransferSecure;
    let currentDataTransferThreatLevel = this.state.dataTransferThreatLevel;
    let currentDataAccessProtection = this.state.dataAccessProtection;
    let currentDataAccessProtectionThreatLevel = this.state.dataAccessProtectionThreatLevel;

    let answer = event.target.value;

    switch (type) {
      case 'isDataAnonymized':
        currentIsDataAnonymized = answer;
        break;
      case 'anonymizationPercentage':
        currentAnonymizationPercentage = answer;
        break;
      case 'isAnonymizationToolVerified':
        currentIsAnonymizationToolVerified = answer;
        break;
      case 'anonymizationThreatLevel':
        currentAnonymizationThreatLevel = answer;
        break; 
      case 'areThereDataAnnotations':
        currentAreThereDataAnnotations = answer;
        break;
      case 'dataAnnotationsThreatLevel':
        currentDataAnnotationsThreatLevel = answer;
        break;
      case 'dataTransferSecure':
        currentDataTransferSecure = answer;
        break;
      case 'dataTransferThreatLevel':
        currentDataTransferThreatLevel = answer;
        break;
      case 'dataAccessProtection':
        currentDataAccessProtection = answer;
        break;
      case 'dataAccessProtectionThreatLevel':
        currentDataAccessProtectionThreatLevel = answer;
        break;
    
      default:
        break;
    }

    this.setState({
      isDataAnonymized: currentIsDataAnonymized,
      anonymizationPercentage: currentAnonymizationPercentage,
      isAnonymizationToolVerified: currentIsAnonymizationToolVerified,
      anonymizationThreatLevel: currentAnonymizationThreatLevel,
      areThereDataAnnotations: currentAreThereDataAnnotations,
      dataAnnotationsThreatLevel: currentDataAnnotationsThreatLevel,
      dataTransferSecure: currentDataTransferSecure,
      dataTransferThreatLevel: currentDataTransferThreatLevel,
      dataAccessProtection: currentDataAccessProtection,
      dataAccessProtectionThreatLevel: currentDataAccessProtectionThreatLevel,
    })

  }

  handlePeopleThreatsAnswers = (event, type) => {
    let currentPeopleEducation = this.state.peopleEducation;
    let currentPeopleEducationThreatLevel = this.state.peopleEducationThreatLevel;

    let answer = event.target.value;

    switch (type) {
      case 'peopleEducation':
        currentPeopleEducation = answer;
        break;

      case 'peopleEducationThreatLevel':
        currentPeopleEducationThreatLevel = answer;
        break;
    
      default:
        break;
    }

    this.setState({
      peopleEducation: currentPeopleEducation,
      peopleEducationThreatLevel: currentPeopleEducationThreatLevel,
    });
  }

  handleInstitutionalThreatsAnswers = (event, type) => {
    let currentInstitutionCybersecurity = this.state.institutionCybersecurity;
    let currentInstitutionCybersecurityThreatLevel = this.state.institutionCybersecurityThreatLevel;
    let currentInstitutionGovernance = this.state.institutionGovernance;
    let currentInstitutionGovernanceThreatLevel = this.state.institutionGovernanceThreatLevel;
    let currentInstitutionWorkLocationSecure = this.state.institutionWorkLocationSecure;
    let currentInstitutionWorkLocationSecureThreatLevel = this.state.institutionWorkLocationSecureThreatLevel;

    let answer = event.target.value;

    switch (type) {
      case 'institutionCybersecurity':
        currentInstitutionCybersecurity = answer;
        break;
      case 'institutionCybersecurityThreatLevel':
        currentInstitutionCybersecurityThreatLevel = answer;
        break;
      case 'institutionGovernance':
        currentInstitutionGovernance = answer;
        break;
      case 'institutionGovernanceThreatLevel':
        currentInstitutionGovernanceThreatLevel = answer;
        break;
      case 'institutionWorkLocationSecure':
        currentInstitutionWorkLocationSecure = answer;
        break;
      case 'institutionWorkLocationSecureThreatLevel':
        currentInstitutionWorkLocationSecureThreatLevel = answer;
        break;
    
      default:
        break;
    }

    this.setState({
      institutionCybersecurity: currentInstitutionCybersecurity,
      institutionCybersecurityThreatLevel: currentInstitutionCybersecurityThreatLevel,
      institutionGovernance: currentInstitutionGovernance,
      institutionGovernanceThreatLevel: currentInstitutionGovernanceThreatLevel,
      institutionWorkLocationSecure: currentInstitutionWorkLocationSecure,
      institutionWorkLocationSecureThreatLevel: currentInstitutionWorkLocationSecureThreatLevel
    })

  }
  handleLegalAnswers = (event, type) => {
    let currentLegalLicense = this.state.legalLicense;
    let currentLegalDistribution = this.state.legalDistribution;
    let currentLegalDistributionModified = this.state.legalDistributionModified;
    let currentLegalPublications = this.state.legalPublications;
    let currentLegalPermenant = this.state.legalPermenant;
    let currentLegalEvidenceOfDeletion = this.state.legalEvidenceOfDeletion;
    let currentLegalAnyDomain = this.state.legalAnyDomain;
    let currentLegalSubpopulation = this.state.legalSubpopulations;
    let currentLegalEthicalReview = this.state.legalEthicalReview;
    let currentLegalLegalReview = this.state.legalLegalReview;

    let answer = event.target.value;

    switch (type) {
      case 'legalLicense':
        currentLegalLicense = answer;
        break;
      case 'legalDistribution':
        currentLegalDistribution = answer;
        break;
      case 'legalDistributionModified':
        currentLegalDistributionModified = answer;
        break;
      case 'legalPublications':
        currentLegalPublications = answer;
        break;
      case 'legalPermenant':
        currentLegalPermenant = answer;
        break;
      case 'legalEvidenceOfDeletion':
        currentLegalEvidenceOfDeletion = answer;
        break;
      case 'legalAnyDomain':
        currentLegalAnyDomain = answer;
        break;
      case 'legalSubpopulations':
        currentLegalSubpopulation = answer;
        break;
      case 'legalEthicalReview':
        currentLegalEthicalReview = answer;
        break;
      case 'legalLegalReview':
        currentLegalLegalReview = answer;
        break;


      default:
        break;
    }

    this.setState({
      legalLicense: currentLegalLicense,
      legalDistribution: currentLegalDistribution,
      legalDistributionModified: currentLegalDistributionModified,
      legalPublications: currentLegalPublications,
      legalPermenant: currentLegalPermenant,
      legalEvidenceOfDeletion: currentLegalEvidenceOfDeletion,
      legalAnyDomain: currentLegalAnyDomain,
      legalSubpopulations: currentLegalSubpopulation,
      legalEthicalReview: currentLegalEthicalReview,
      legalLegalReview: currentLegalLegalReview,
    });


  }

  handleFinalThreatChange = (event, type) => {
    let currentFinalTechnologyThreatLevel = this.state.finalTechnologyThreatLevel;
    let currentFinalPeopleThreatLevel = this.state.finalPeopleThreatLevel;
    let currentFinalInstitutionalThreatLevel = this.state.finalInstitutionalThreatLevel;
    let currentFinalLegalThreatLevel = this.state.finalLegalThreatLevel;

    let answer = event.target.value;
    switch(type) {
      case 'technological':
        currentFinalTechnologyThreatLevel = answer;
        break;
      case 'people':
        currentFinalPeopleThreatLevel = answer;
        break;
      case 'institutional':
        currentFinalInstitutionalThreatLevel = answer;
        break;
      case 'legal':
        currentFinalLegalThreatLevel = answer;
        break;

      default:
        break;
    }

    this.setState({
      finalTechnologyThreatLevel: currentFinalTechnologyThreatLevel,
      finalPeopleThreatLevel: currentFinalPeopleThreatLevel,
      finalInstitutionalThreatLevel: currentFinalInstitutionalThreatLevel,
      finalLegalThreatLevel: currentFinalLegalThreatLevel,
    });
  }



  handleLevelOfImpactOnIndividual = (event) => {
    let levelOfImpactOnIndividuals = this.state.levelOfImpactOnIndividuals;

    switch (event.target.value) {
      case 'Minor':
        this.setState({
          levelOfImpactOnIndividuals: 'Minor',
        })
        break;
      case 'Major':
        this.setState({
          levelOfImpactOnIndividuals: 'Major',
        });
        break;

      default:
        break;
    }
  }
  hanldeIndividualsOvercome = (event) => {
    let individualsOvercome = this.state.individualsOvercome;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          individualsOvercome: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          individualsOvercome: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleDatasetContainsPersonalData = (event) => {
    let datasetContainsPersonalData = this.state.datasetContainsPersonalData;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          datasetContainsPersonalData: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          datasetContainsPersonalData: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleDatasetContainsSensitiveData = (event) => {
    let datasetContainsSensitiveData = this.state.datasetContainsSensitiveData;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          datasetContainsSensitiveData: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          datasetContainsSensitiveData: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleLevelOfLegalImplications = (event) => {
    let levelOfLegalImplications = this.state.levelOfLegalImplications;

    switch (event.target.value) {
      case 'Major':
        this.setState({
          levelOfLegalImplications: 'Major',
        })
        break;
      case 'Minor':
        this.setState({
          levelOfLegalImplications: 'Minor',
        })
        break;
    
      default:
        break;
    }
  }
  handleOrganizationContinueOperations = (event) => {
    let organizationContinueOperations = this.state.organizationContinueOperations;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          organizationContinueOperations: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          organizationContinueOperations: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  handleOrganizationLossOfTrust = (event) => {
    let organizationLossOfTrust = this.state.organizationLossOfTrust;

    switch (event.target.value) {
      case 'Yes':
        this.setState({
          organizationLossOfTrust: 'Yes',
        })
        break;
      case 'No':
        this.setState({
          organizationLossOfTrust: 'No',
        })
        break;
    
      default:
        break;
    }
  }
  

  calculateImpactLevel() {
    let impactOnIndividuals = this.state.impactOnIndividuals;
    let impactOnLegal = this.state.impactOnLegal;
    let impactOnTrust = this.state.impactOnTrust;
    let individualsOvercome = this.state.individualsOvercome;
    let levelOfImpactOnIndividuals = this.state.levelOfImpactOnIndividuals;

    let finalIndividualImpactLevel = '';
    let finalInstitutionalImpactLevel = '';

    if (impactOnIndividuals) {
      if (individualsOvercome === 'Yes') {
        if (levelOfImpactOnIndividuals === 'Major') {
          finalIndividualImpactLevel = 'High'
        } else {
          finalIndividualImpactLevel = 'Medium';
        }
      } else {
        finalIndividualImpactLevel = 'High';
      }
    } else {
      finalIndividualImpactLevel = 'Low';
    }

    console.log('finalIndividualImpactLevel:', finalIndividualImpactLevel);
    
    this.setState({
      finalIndividualImpactLevel: finalIndividualImpactLevel,
      finalInstitutionalImpactLevel: 'High',
      impactLevelCalculated: true,
    })
  }
  calculateThreatLevel() {
    let currentFinalTechnologyThreatLevel = this.state.finalTechnologyThreatLevel;
    let currentFinalPeopleThreatLevel = this.state.finalPeopleThreatLevel;
    let currentFinalInstitutionalThreatLevel = this.state.finalInstitutionalThreatLevel;
    let currentFinalLegalThreatLevel = this.state.finalLegalThreatLevel;
    
    let technologyScore = 0;
    if (this.state.isDataAnonymized === 'No' && this.state.datasetContainsPersonalData) {
      technologyScore += 8;
    } else if (this.state.isDataAnonymized === 'Yes' && this.state.datasetContainsPersonalData) {
      switch (this.state.anonymizationThreatLevel) {
        case 'Low':
          technologyScore += 1;
          break;
        case 'Medium':
          technologyScore += 2;
          break;
        case 'High':
          technologyScore += 3;
          break;

        default:
          break;
      }
    }
    switch(this.state.dataAnnotationsThreatLevel) {
      case 'Low':
        technologyScore += 1;
        break;
      case 'Medium':
        technologyScore += 2;
        break;
      case 'High':
        technologyScore += 3;
        break;

      default:
        break;
    }
    switch(this.state.dataTransferThreatLevel) {
      case 'Low':
        technologyScore += 1;
        break;
      case 'Medium':
        technologyScore += 2;
        break;
      case 'High':
        technologyScore += 3;
        break;

      default:
        break;
    }
    switch(this.state.dataAccessProtectionThreatLevel) {
      case 'Low':
        technologyScore += 1;
        break;
      case 'Medium':
        technologyScore += 2;
        break;
      case 'High':
        technologyScore += 3;
        break;

      default:
        break;
    }

    if (technologyScore <= 4) {
      currentFinalTechnologyThreatLevel = 'Low';
    } else if (technologyScore <= 8) {
      currentFinalTechnologyThreatLevel = 'Medium';
    } else if (technologyScore > 8) {
      currentFinalTechnologyThreatLevel = 'High';
    }
    
    let peopleScore = 0;
    switch (this.state.peopleEducationThreatLevel) {
      case 'Low':
        peopleScore += 1;
        break;
      case 'Medium':
        peopleScore += 2;
        break;
      case 'High':
        peopleScore += 3;
        break;
    
      default:
        break;
    }
    if (peopleScore <= 1) {
      currentFinalPeopleThreatLevel = 'Low';
    } else if (peopleScore <= 2) {
      currentFinalPeopleThreatLevel = 'Medium';
    } else if (peopleScore > 2) {
      currentFinalPeopleThreatLevel = 'High';
    }

    let institutionalScore = 0;
    switch(this.state.institutionCybersecurityThreatLevel) {
      case 'Low':
        institutionalScore += 1;
        break;
      case 'Medium':
        institutionalScore += 2;
        break;
      case 'High':
        institutionalScore += 3;
        break;

      default:
        break;
    }
    switch(this.state.institutionGovernanceThreatLevel) {
      case 'Low':
        institutionalScore += 1;
        break;
      case 'Medium':
        institutionalScore += 2;
        break;
      case 'High':
        institutionalScore += 3;
        break;

      default:
        break;
    }
    if (institutionalScore <= 2) {
      currentFinalInstitutionalThreatLevel = 'Low';
    } else if (technologyScore <= 4) {
      currentFinalInstitutionalThreatLevel = 'Medium';
    } else if (technologyScore > 4) {
      currentFinalInstitutionalThreatLevel = 'High';
    }

    let legalScore = 0;
    if (this.state.legalLicense === 'No') {
      legalScore += 10;
    }
    if (this.state.legalDistribution === 'Yes') {
      legalScore += 3;
    }
    if (this.state.legalDistributionModified === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalPublications === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalPermenant === 'No') {
      legalScore += 3;
    }
    if (this.state.legalEvidenceOfDeletion === 'No') {
      legalScore += 2;
    }
    if (this.state.legalAnyDomain === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalSubpopulations === 'Yes') {
      legalScore += 1;
    }
    if (this.state.legalEthicalReview === 'No') {
      legalScore += 1;
    }
    if (this.state.legalLegalReview === 'No') {
      legalScore += 1;
    }

    if (legalScore <= 3) {
      currentFinalLegalThreatLevel = 'Low';
    } else if (legalScore <= 6) {
      currentFinalLegalThreatLevel = 'Medium';
    } else if (legalScore > 6) {
      currentFinalLegalThreatLevel = 'High';
    }

    this.setState({
      finalTechnologyThreatLevel: currentFinalTechnologyThreatLevel,
      finalPeopleThreatLevel: currentFinalPeopleThreatLevel,
      finalInstitutionalThreatLevel: currentFinalInstitutionalThreatLevel,
      finalLegalThreatLevel: currentFinalLegalThreatLevel,
      threatLevelCalculated: true,
    })
  }

  

  mapLevelToScore(level) {
    let score = 0;
    switch (level) {
      case 'Low':
        score = 1;
        break;
      case 'Medium':
        score = 2;
        break;
      case 'High':
        score = 3;
        break;
      case 'Very High':
        score = 4;
        break;
    
      default:
        break;
    }
    return score
  }


  calculateFinalRiskEvaluation() {
    let currentFinalRiskScoreEvaluation = this.state.finalRiskScoreEvaluation;
    

    //calculate impact level
    let impactScore = 0;
    let impactEvaluation = 'Low';
    let isImpactLow = true;
    let isImpactMedium = false;
    let isImpactHigh = false;
    let isImpactVeryHigh = false;

    console.log('impactLevelOnIndividuals:', this.state.impactLevelOnIndividuals);
    if(this.state.impactLevelOnIndividuals === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnIndividuals === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnIndividuals === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnIndividuals === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalRep === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnInstitutionalLegal === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnSocietal === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnSocietal === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnSocietal === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnSocietal === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnEthical === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnEthical === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnEthical === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnEthical === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnEconomical === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnEconomical === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnEconomical === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnEconomical === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(this.state.impactLevelOnTechnical === 'Low') {
      isImpactLow = true;
    }
    if(this.state.impactLevelOnTechnical === 'Medium') {
      isImpactMedium = true;
    }
    if(this.state.impactLevelOnTechnical === 'High') {
      isImpactHigh = true;
    }
    if(this.state.impactLevelOnTechnical === 'Very High') {
      isImpactVeryHigh = true;
    }
    if(isImpactVeryHigh) {
      impactEvaluation = 'Very High'
    } else if (isImpactHigh) {
      impactEvaluation = 'High'
    } else if (isImpactMedium) {
      impactEvaluation = 'Medium'
    } else if (isImpactLow) {
      impactEvaluation = "Low"
    }

    let threatScore = 0;
    let threatEvaluation = 'Low';

    let isThreatProbabilityLow = true;
    let isThreatProbabilityMedium = false;
    let isThreatProbabilityHigh = false;



    if(this.state.finalTechnologyThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalTechnologyThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalTechnologyThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if(this.state.finalPeopleThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalPeopleThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalPeopleThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if(this.state.finalInstitutionalThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalInstitutionalThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalInstitutionalThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if(this.state.finalLegalThreatLevel === 'Low') {
      isThreatProbabilityLow = true;
    }
    if(this.state.finalLegalThreatLevel === 'Medium') {
      isThreatProbabilityMedium = true;
    }
    if(this.state.finalLegalThreatLevel === 'High') {
      isThreatProbabilityHigh = true;
    }
    if (isThreatProbabilityHigh) {
      threatEvaluation = 'High'
    } else if (isThreatProbabilityMedium) {
      threatEvaluation = 'Medium'
    } else if (isThreatProbabilityLow) {
      threatEvaluation = "Low"
    }

    if (impactEvaluation === 'Low') {
      if (threatEvaluation === 'Low' || threatEvaluation === 'Medium') {
        currentFinalRiskScoreEvaluation = 'Low'
      }
      if (threatEvaluation === 'High') {
        currentFinalRiskScoreEvaluation = 'Medium'
      }
    } else if (impactEvaluation === 'Medium') {
      if (threatEvaluation === 'Low' || threatEvaluation === 'Medium') {
        currentFinalRiskScoreEvaluation = 'Medium'
      }
      if (threatEvaluation === 'High') {
        currentFinalRiskScoreEvaluation = 'High'
      }
    } else if (impactEvaluation === 'High' || impactEvaluation === 'Very High') {
        currentFinalRiskScoreEvaluation = 'High'
    }

    let currentFinalRiskTable = [];
    let currentFinalMitigationTable = [];

    if(this.state.datasetContainsPersonalData === 'Yes' && this.state.isDataAnonymized === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m1.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m1.mitigationAction}</li>);
    }
    if(this.state.isDataAnonymized === 'Yes' && this.state.isAnonymizationToolVerified === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m2.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m2.mitigationAction}</li>);
    }
    if(this.state.datasetContainsSensitiveData === 'Yes' && this.state.isDataAnonymized === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m3.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m3.mitigationAction}</li>);
    }
    if(this.state.dataTransferSecure === 'No' || (this.state.dataTransferSecure === 'Yes' && this.state.dataTransferThreatLevel === 'Medium') || (this.state.dataTransferSecure === 'Yes' && this.state.dataTransferThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m4.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m4.mitigationAction}</li>);
    }
    if(this.state.dataAccessProtection === 'No' || (this.state.dataAccessProtection === 'Yes' && this.state.dataAccessProtectionThreatLevel === 'Medium') || (this.state.dataAccessProtection === 'Yes' && this.state.dataAccessProtectionThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m5.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m5.mitigationAction}</li>);
    }
    if(this.state.peopleEducation === 'No' || (this.state.peopleEducation === 'Yes' && this.state.peopleEducationThreatLevel === 'Medium') || (this.state.peopleEducation === 'Yes' && this.state.peopleEducationThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m6.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m6.mitigationAction}</li>);
    }
    if(this.state.institutionCybersecurity === 'No' || (this.state.institutionCybersecurity === 'Yes' && this.state.institutionCybersecurityThreatLevel === 'Medium') || (this.state.institutionCybersecurity === 'Yes' && this.state.institutionCybersecurityThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m7.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m7.mitigationAction}</li>);
    }
    if(this.state.institutionGovernance === 'No' || (this.state.institutionGovernance === 'Yes' && this.state.institutionGovernanceThreatLevel === 'Medium') || (this.state.institutionGovernance === 'Yes' && this.state.institutionGovernanceThreatLevel === 'High')) {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m8.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m8.mitigationAction}</li>);
    }
    if(this.state.legalLicense === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m9.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m9.mitigationAction}</li>);
    }
    if(this.state.legalDistribution === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m10.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m10.mitigationAction}</li>);
    }
    if(this.state.legalPublications === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m11.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m11.mitigationAction}</li>);
    }
    if(this.state.legalPermenant === 'Yes') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m12.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m12.mitigationAction}</li>);
    }
    if(this.state.legalEvidenceOfDeletion === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m13.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m13.mitigationAction}</li>);
    }
    if(this.state.legalAnyDomain === 'No') {
      currentFinalRiskTable.push(<li>{this.state.mitigationTable.m14.explanation}</li>);
      currentFinalMitigationTable.push(<li>{this.state.mitigationTable.m14.mitigationAction}</li>);
    }
    

    this.setState({
      finalImpactEvaluation: impactEvaluation,
      finalThreatEvaluation: threatEvaluation,
      finalRiskTable: currentFinalRiskTable,
      finalMitigationTable: currentFinalMitigationTable,
      finalRiskScoreEvaluation: currentFinalRiskScoreEvaluation
    })
  }

  exportRiskAssessmentDataToJson() {
    let riskData = {};

    riskData.toolDetails = {
      "toolName": "Risk Assessment",
      "toolVersion": 0.2,
      "jsonOutputVersion": 0.2,
    }
    riskData.userDetails = {
      "userName": this.state.generalInfo.yourName,
      "organizationName": this.state.generalInfo.nameOfYourOrganization,
    }
    riskData.risk = {
      "name": "Unauthorized Access",
      "data": {
        "generalInfo": {
          "riskAssessmentTitle": this.state.generalInfo.riskAssessmentTitle,
          "comments": this.state.generalInfo.comments,
          "purposeOfSharing": this.state.generalInfo.purposeOfSharing,
          "typeOfSharingData": this.state.generalInfo.typeOfSharingData,
          "nameOfRequestingEntity": this.state.generalInfo.nameOfRequestingEntity,
          "typeOfRequestingEntity": this.state.generalInfo.typeOfRequestingEntity,
          "customTypeOfRequestingEntity": this.state.generalInfo.customTypeOfRequestingEntity,
        },
        "impactLevelAssessment": {
          "datasetNature": this.state.selectedDatasetNature,
        }
      }
    }
    

    let dataTypes = this.state.selectedDataTypes;
    let specialDataTypes = this.state.selectedSpecialDataTypes;
    let dataFormats = this.state.selectedDataFormat;

    let dataTypeNames = [];
    let specialDataTypeNames = [];
    let dataFormatNames = [];

    dataTypes.forEach((value, index, array) => {
      dataTypeNames.push(value.label);
    });
    specialDataTypes.forEach((value, index, array) => {
      specialDataTypeNames.push(value.label);
    });
    dataFormats.forEach((value, index, array) => {
      dataFormatNames.push(value.label);
    });

    riskData.risk.data.impactLevelAssessment.dataTypes = dataTypeNames;
    riskData.risk.data.impactLevelAssessment.specialDataTypes = specialDataTypeNames;
    riskData.risk.data.impactLevelAssessment.dataFormats = dataFormatNames;
    riskData.risk.data.impactLevelAssessment.areasOfEffect = [];
    riskData.risk.data.impactLevelAssessment.areasOfEffect.push(
      {
        "area": "Individuals",
        "questionsAndAnswers": [
          {
            "question": "Is there any impact to individuals?",
            "answer": this.state.impactOnIndividuals ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level to individuals?",
            "answer": this.state.impactLevelOnIndividuals
          },
          {
            "question": "Does the dataset contain personal data?",
            "answer": this.state.datasetContainsPersonalData
          },
          {
            "question": "Does the dataset contain sensitive data?",
            "answer": this.state.datasetContainsSensitiveData
          },
        
        ],
        "evaluation": this.state.finalIndividualImpactLevel
      },
      {
        "area": "Institutional (Reputation/Brand)",
        "questionsAndAnswers": [
          {
            "question": "Is there any impact for the organization in terms of its reputation or brand?",
            "answer": this.state.impactOnInstitutionalReputation ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnInstitutionalRep
          },
        ],
        "evaluation": this.state.impactLevelOnInstitutionalRep
      },
      {
        "area": "Institutional (Legal)",
        "questionsAndAnswers": [
          {
            "question": "Is there any legal impact for the organization?",
            "answer": this.state.impactOnInstitutionalLegal ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnInstitutionalLegal
          },
        ],
        "evaluation": this.state.impactLevelOnInstitutionalLegal
      },
      {
        "area": "Societal",
        "questionsAndAnswers": [
          {
            "question": "Is there any societal impact?",
            "answer": this.state.impactOnSocietal ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnSocietal
          },
        ],
        "evaluation": this.state.impactLevelOnSocietal
      },
      {
        "area": "Ethical",
        "questionsAndAnswers": [
          {
            "question": "Is there any ethical impact?",
            "answer": this.state.impactOnEthical ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnEthical
          },
        ],
        "evaluation": this.state.impactLevelOnEthical
      },
      {
        "area": "Economical",
        "questionsAndAnswers": [
          {
            "question": "Is there any economical impact?",
            "answer": this.state.impactOnEconomical ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnEconomical
          },
        ],
        "evaluation": this.state.impactLevelOnEconomical
      },
      {
        "area": "Technical",
        "questionsAndAnswers": [
          {
            "question": "Is there any technical impact?",
            "answer": this.state.impactOnTechnical ? "Yes" : "No" 
          },
          {
            "question": "What is the impact level?",
            "answer": this.state.impactLevelOnTechnical
          },
        ],
        "evaluation": this.state.impactLevelOnTechnical
      },
    );
    riskData.risk.data.initialImpactLevels = [
      {
        "area": "Individuals",
        "level": this.state.impactLevelOnIndividuals
      },
      {
        "area": "Institutional (Reputation/Brand)",
        "level": this.state.impactLevelOnInstitutionalRep
      },
      {
        "area": "Institutional (Legal)",
        "level": this.state.impactLevelOnInstitutionalLegal
      },
      {
        "area": "Societal",
        "level": this.state.impactLevelOnSocietal
      },
      {
        "area": "Ethical",
        "level": this.state.impactLevelOnEthical
      },
      {
        "area": "Economical",
        "level": this.state.impactLevelOnEconomical
      },
      {
        "area": "Technical",
        "level": this.state.impactLevelOnTechnical
      }
    ]
    riskData.risk.data.userImpactLevels = [
      {
        "area": "Individuals",
        "level": this.state.impactLevelOnIndividuals
      },
      {
        "area": "Institutional (Reputation/Brand)",
        "level": this.state.impactLevelOnInstitutionalRep
      },
      {
        "area": "Institutional (Legal)",
        "level": this.state.impactLevelOnInstitutionalLegal
      },
      {
        "area": "Societal",
        "level": this.state.impactLevelOnSocietal
      },
      {
        "area": "Ethical",
        "level": this.state.impactLevelOnEthical
      },
      {
        "area": "Economical",
        "level": this.state.impactLevelOnEconomical
      },
      {
        "area": "Technical",
        "level": this.state.impactLevelOnTechnical
      }
    ]
    riskData.risk.data.wereImpactLevelsOverridenByUser = false;
    riskData.risk.data.finalImpactLevelAssessment = this.state.finalImpactEvaluation;

    riskData.risk.data.threatLevelAssessment = [
      {
        "area": "Technology",
        "questionsAndAnswers": [
          {
            "question": "Are the datasets anonymized?",
            "answer": this.state.isDataAnonymized
          },
          {
            "question": "What is the percentage of anonymization success?",
            "answer": this.state.anonymizationPercentage
          },
          {
            "question": "Is the anonymization tool verfified by a third party?",
            "answer": this.state.isAnonymizationToolVerified
          },
          {
            "question": "Are there any data annotations?",
            "answer": this.state.areThereDataAnnotations
          },
          {
            "question": "Data annotations threat level:",
            "answer": this.state.dataAnnotationsThreatLevel
          },
          {
            "question": "Is the data transfer method secure?",
            "answer": this.state.dataTransferSecure
          },
          {
            "question": "Transfer method threat level:",
            "answer": this.state.dataTransferThreatLevel
          },
          {
            "question": "Does the requester protect data by restricting access to it?",
            "answer": this.state.dataAccessProtection
          },
          {
            "question": "Data access protection threat level:",
            "answer": this.state.dataAccessProtectionThreatLevel
          },
        ],
        "evaluation": this.finalTechnologyThreatLevel
      },
      {
        "area": "Parties/People involved",
        "questionsAndAnswers": [
          {
            "question": "Do the people handling the data have experience in handling sensitive data?",
            "answer": this.state.peopleEducation
          },
          {
            "question": "Threat level related to people:",
            "answer": this.state.peopleEducationThreatLevel
          },
        ],
        "evaluation": this.state.finalPeopleThreatLevel
      },
      {
        "area": "Institutional threats",
        "questionsAndAnswers": [
          {
            "question": "Does the requester have strong cybersecurity protocols in their premises?",
            "answer": this.state.institutionCybersecurity
          },
          {
            "question": "Cybersecurity threat level:",
            "answer": this.state.institutionCybersecurityThreatLevel
          },
          {
            "question": "Does the requester implement a data governance scheme on their premises?",
            "answer": this.state.institutionGovernance
          },
          {
            "question": "Data governance threat level:",
            "answer": this.state.institutionGovernanceThreatLevel
          },
        ],
        "evaluation": this.state.finalInstitutionalThreatLevel
      },
      {
        "area": "Legal threats",
        "questionsAndAnswers": [
          {
            "question": "Do you have a license agreement with the requester?",
            "answer": this.state.legalLicense
          },
          {
            "question": "Does the license allow distribution of the dataset to other parties?",
            "answer": this.state.legalDistribution
          },
          {
            "question": "May the licensee distribute the modified and adapted work?",
            "answer": this.state.legalDistributionModified
          },
          {
            "question": "Can parts of the dataset be published in scientific publications?",
            "answer": this.state.legalPublications
          },
          {
            "question": "Is the license permanent?",
            "answer": this.state.legalPermenant
          },
          {
            "question": "In the event that the license is terminated, shall the Licensee provide evidence of the deletion?",
            "answer": this.state.legalEvidenceOfDeletion
          },
          {
            "question": "Can the dataset be used in any domain/industry?",
            "answer": this.state.legalAnyDomain
          },
          {
            "question": "Does the dataset identify any subpopulations?",
            "answer": this.state.legalSubpopulations
          },
          {
            "question": "Has the dataset undergo an ethical review?",
            "answer": this.state.legalEthicalReview
          },
          {
            "question": "Has the dataset undergo a legal review? (GDPR, AI use, etc)?",
            "answer": this.state.legalLegalReview
          },
        ],
        "evaluation": this.state.finalLegalThreatLevel
      },
    ];
    riskData.risk.data.initialThreatOccuranceProbabilityLevels = [
      {
        "area": "Technological threats",
        "level": this.state.finalTechnologyThreatLevel
      },
      {
        "area": "People/Parties Involved",
        "level": this.state.finalPeopleThreatLevel
      },
      {
        "area": "Institutional threats",
        "level": this.state.finalInstitutionalThreatLevel
      },
      {
        "area": "Legal threats",
        "level": this.state.finalLegalThreatLevel
      }
    ];
    riskData.risk.data.userThreatOccuranceProbabilityLevels = [
      {
        "area": "Technological threats",
        "level": this.state.finalTechnologyThreatLevel
      },
      {
        "area": "People/Parties Involved",
        "level": this.state.finalPeopleThreatLevel
      },
      {
        "area": "Institutional threats",
        "level": this.state.finalInstitutionalThreatLevel
      },
      {
        "area": "Legal threats",
        "level": this.state.finalLegalThreatLevel
      }
    ];
    riskData.risk.data.wereThreatLevelsOverridenByUser = false;
    riskData.risk.data.finalThreatOccuranceProbabilityLevelAssessment = this.state.finalThreatEvaluation;

    riskData.risk.evaluation = {};
    riskData.risk.evaluation.finalRiskLevelAssessment = this.state.finalRiskScoreEvaluation;
    riskData.risk.evaluation.explanation = [];
    
    //console.log('riskTable:', this.state.finalRiskTable);
    this.state.finalRiskTable.forEach((risk, index, array) => {
      //console.log('risk:', risk.props.children);
      riskData.risk.evaluation.explanation.push(risk.props.children);
    });
    riskData.risk.mitigation = [];
    this.state.finalMitigationTable.forEach((mitigation, index, array) => {
      riskData.risk.mitigation.push(mitigation.props.children);
    });

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(riskData, null, 2)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "risk-assessment-v0.2-export.json";

    link.click();
  }

  render() {

    let generalInfoForm = 
    <>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Risk Assessment Title</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldInput type="text" defaultValue={this.state.generalInfo.riskAssessmentTitle} onChange={(event) => this.onGeneralInfoChange(event, 'riskAssessmentTitle')}></S.GeneralFormFieldInput>
    </S.GeneralFormField>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Your Name</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldInput type="text" defaultValue={this.state.generalInfo.yourName} onChange={(event) => this.onGeneralInfoChange(event, 'yourName')}></S.GeneralFormFieldInput>
    </S.GeneralFormField>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Name of your organization</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldInput type="text" defaultValue={this.state.generalInfo.nameOfYourOrganization} onChange={(event) => this.onGeneralInfoChange(event, 'nameOfYourOrganization')}></S.GeneralFormFieldInput>
    </S.GeneralFormField>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Is this risk assessment about a Dataset, Model or Metadata?</S.GeneralFormFieldLabel>
        <Dropdown key='datasetModel' defaultValue={this.state.selectedDatasetModel} buttonText="" onChange={this.handleDatasetModelChange}> {this.state.datasetModelOptions} </Dropdown>
      </S.GeneralFormField>
    <S.GeneralFormField>
      <S.GeneralFormFieldLabel>Comments</S.GeneralFormFieldLabel>
      <S.GeneralFormFieldTextArea defaultValue={this.state.generalInfo.comments} onChange={(event) => this.onGeneralInfoChange(event, 'comments')}></S.GeneralFormFieldTextArea>
    </S.GeneralFormField>
      </>
      //console.log('this.state.generalInfo.typeOfRequestingEntity: ', this.state.generalInfo.typeOfRequestingEntity);
      let sharingInfoForm = <>
      <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Purpose of sharing</S.GeneralFormFieldLabel>
        <S.GeneralFormFieldTextArea defaultValue={this.state.generalInfo.purposeOfSharing} onChange={(event) => this.onGeneralInfoChange(event, 'purposeOfSharing')}></S.GeneralFormFieldTextArea>
      </S.GeneralFormField>
      <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Name of requesting entity</S.GeneralFormFieldLabel>
        <S.GeneralFormFieldInput type="text" defaultValue={this.state.generalInfo.nameOfRequestingEntity} onChange={(event) => this.onGeneralInfoChange(event, 'nameOfRequestingEntity')}></S.GeneralFormFieldInput>
      </S.GeneralFormField>
      <S.GeneralFormField>
        <S.GeneralFormFieldLabel>Type of requesting entity</S.GeneralFormFieldLabel>
        <Dropdown key='requestingEntity' defaultValue={this.state.generalInfo.typeOfRequestingEntity} onChange={(event) => this.onGeneralInfoChange(event, 'typeOfRequestingEntity')} buttonText=""> {this.state.entityOptions} </Dropdown>
      </S.GeneralFormField>
      {this.state.generalInfo.typeOfRequestingEntity === 'Other' && 
        <S.GeneralFormField>
          <S.GeneralFormFieldLabel>What is the type of requesting entity?</S.GeneralFormFieldLabel>
          <S.GeneralFormFieldTextArea defaultValue={this.state.generalInfo.customTypeOfRequestingEntity} onChange={(event) => this.onGeneralInfoChange(event, 'customTypeOfRequestingEntity')}></S.GeneralFormFieldTextArea>
        </S.GeneralFormField>
      }
      
      
      </>

      let columnsForImpactLevel = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of Effect - Individuals',
          accessor: 'aoeindividuals'
        },
        {
          Header: 'Area of Effect - Institution',
          accessor: 'aoeinstitution'
        }];
      let columnsForImpactTablePersonal = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of Effect - Personal (Individuals)',
          accessor: 'aoeindividuals'
        }];
      let columnsForImpactTableInstitutionalRep = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of effect – Institutional, reputation and brand (business/organizations)',
          accessor: 'aoeinstitutionalrep'
        }];
      let columnsForImpactTableInstitutionalLegal = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of effect - Institutional, Legal(business/organizations)',
          accessor: 'aoeinstitutionallegal'
        }];
      let columnsForImpactTableSocietal = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of effect – Societal',
          accessor: 'aoesocietal'
        }];
      let columnsForImpactTableEthical = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of effect – Ethical',
          accessor: 'aoeethical'
        }];
      let columnsForImpactTableEconomical = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of effect – Economical',
          accessor: 'aoeeconomical'
        }];
      let columnsForImpactTableTechnical = [{
        Header: 'Level of Impact',
        accessor: 'levelofimpact'
        },
        {
          Header: 'Area of effect – Technical',
          accessor: 'aoetechnical'
        }];
      let lowImpactPersonal = 
        <S.ListCircle><li>Individuals may encounter a few minor inconveniences, which they will overcome without any problem.</li>
          <li>Individuals won't notice a problem unless they are notified in order to show trust.</li>
          <li>Individuals' opinion of the organization does not change despite the issues.</li>
        </S.ListCircle>

      let lowImpactInstitutionalReputation = 
        <S.ListCircle><li>Organization may need to make adjustments in operations but will continue operations as is.</li>
          <li>If there is a problem within something, if it does not affect other areas; for example one part of the system is down and other(s) can be still used, then this can be considered low risk.</li>
          <li>The issue arises from human error within the organization worker, but it does not pose great threat.</li>
        </S.ListCircle>
      let lowImpactInstitutionalLegal = 
        <S.ListCircle><li>Minor legal issues that won’t affect the organization and its operations.</li>
          <li>If there are requirements stemming from laws or contracts, it may cause an organization or LEA to go under supervisory procedure or oversight process that is minor, for example data protection institiution may want to know specifics and how to avoid situations like these in the future.</li>
        </S.ListCircle>
      let lowImpactSocietal = 
        <S.ListCircle><li>Minor inconveniences for a large group of people.</li>
          <li>Minor inconveniences for an individual and their circle of friends and family.</li>
        </S.ListCircle>;
      let lowImpactEthical = 
        <S.ListCircle><li>Minor inconveniences to minorities because of a misrepresentation in the datset.</li>
          <li>Data is being used for another purpose that poses no high threat, for example people are sent newsletters etc.</li>
        </S.ListCircle>;
      let lowImpactEconomical = 
        <S.ListCircle><li>Low risks usually do not cause economic loss. It could be that instead time is an issue, for example a procedure takes longer than expected and it is an inconvenience.</li></S.ListCircle>
      let lowImpactTechnical = 
        <S.ListCircle><li>If one part of the system is down and other works, it is not that big of a risk.</li>
          <li>There is not need to use extra money for technical issues, organization's own people can provide enough support.</li>
        </S.ListCircle>;
      let mediumImpactPersonal = 
        <S.ListCircle><li>Individuals may encounter significant inconveniences, which they will be able to overcome despite a few difficulties (minor phycological effects, minor loss of money, loss of access, minor social distress).</li>
          <li>Individuals may ask further quesitions when being notified of issues and they might want to follow up.</li>
          <li>It causes individuals to wait longer for something, basically it may take up more of their time that initally planned.</li>
          <li>Individuals have questions regarding trust and stability, they may think or do cut ties.</li>
        </S.ListCircle>;
      let mediumImpactInstitutionalReputation = 
        <S.ListCircle><li>Organization may need to make major adjustments in operations to avoid loosing trust.</li>
          <li>Organization is not able to fulfil contracts or laws.</li>
          <li>Organization may fail to prove it's usefulness and have competetitors step in.</li>
        </S.ListCircle>;
      let mediumImpactInstitutionalLegal = 
        <S.ListCircle>
          <li>Minor legal issues that will affect the organization’s operations but will be overcome despite a few difficulties (minor legal fees etc).</li>
          <li>If there are requirements stemming from laws or contracts, it may cause an organization or LEA to have to pay a fine. In case a person within a company has done something to cause the issue, they may face consequences.</li>
        </S.ListCircle>;
      let mediumImpactSocietal = 
        <S.ListCircle><li>Distrust grows towards the organizations or country itself when it has something to do with LEAs or minitries.</li>
          <li>Inconveniences to a big group of people.</li>
          <li>Smaller demonstrations, articles in newpapers.</li>
        </S.ListCircle>;
      let mediumImpactEthical = 
        <S.ListCircle><li>Data is being used for other purposes that may affect an individual more than just in low risk area.</li></S.ListCircle>;
      let mediumImpactEconomical = 
        <S.ListCircle><li>Individuals risk a loss of money for different reasons, for example if their data is openly available somewhere, they may not get a job and it affects them long term.</li>
          <li>Organization has to use extra funds to create better security systems or pay fines.</li>
        </S.ListCircle>;
      let mediumImpactTechnical = 
        <S.ListCircle><li>Difficulties in one area may affect other things, for example issues with data integrity may give false results.</li>
          <li>Organization may have to use external specialists.</li>
        </S.ListCircle>;
      let highImpactPersonal = 
        <S.ListCircle>
          <li>Individuals may encounter significant consequences that they are able to overcome but with significant effort or loss of resources.</li>
          <li>Individuals face losses in different areas as the integrity, confidentiality and accessability is lost.</li>
          <li>Individuals have doubt in the whole process and they may become hostile towards it.</li>
          <li>Individuals opinion and trust is very low towards all the connected partners.</li>
        </S.ListCircle>
      let highImpactInstitutionalReputation = 
        <S.ListCircle>
          <li>Organizations might suffer minor damage to reputation or trust.</li>
          <li>Organizations might suffer loss of funding.</li>
          <li>Organization may have to fill claims or face smaller fines.</li>
          <li>Issues in one area affect other areas of the system and processes.</li>
        </S.ListCircle>;
      let highImpactInstitutionalLegal = 
        <S.ListCircle>
          <li>Organizations might suffer major legal penalties.</li>
          <li>Contracts may change, operations will be affected in a major way.</li>
          <li>Support from other institutions or organizations, partners etc may be pulled.</li>
        </S.ListCircle>;
      let highImpactSocietal = 
        <S.ListCircle>
          <li>Sensitive Data Exposure may affect International-Diplomatic Relations.</li>
          <li>Large demonstrations.</li>
          <li>Negative opinions in media.</li>
          <li>People and organizations do not trust the institution, there are issues with believing in LEAs that may cause a lot of problems in different areas.</li>
        </S.ListCircle>;
      let highImpactEthical = 
        <S.ListCircle>
          <li>Data is used for other purposes, for example sold etc.</li>
        </S.ListCircle>
      let highImpactEconomical = 
        <S.ListCircle>
          <li>Bigger fines, claims etc for the organization.</li>
          <li>People face losing their money or actually suffer greatly economically.</li>
        </S.ListCircle>
      let highImpactTechnical = 
        <S.ListCircle>
          <li>Systems don't work properly.</li>
          <li>Wrong information is being used and processed.</li>
          <li>Organization has to use outside support, specialists or change something in technical aspects, create new systems etc.</li>
        </S.ListCircle>
      let veryHighImpactPersonal = 
        <S.ListCircle>
          <li>Risk to life or health (mental & physical) and wellbeing of individuals.</li>
          <li>Claims from others as well as the individuals concerned.</li>
          <li>Risks do not come to life immediately, but the risk is long-lasting, for example when datasets happen to be uploaded to dark web or sold to other countries, especially outside EU.</li>
          <li>Individuals may not want to have anything to do with any part of it, they demand their data and anything else to be deleted and they may turn to courts, legal advisors or supervisory institutions.</li>
        </S.ListCircle>;
      let veryHighImpactInstitutionalReputation = 
        <S.ListCircle>
          <li>Serious consequences to the business’ continuity of operations (business/organization might cease to exist).</li>
          <li>Serious damage to organization’s trust.</li>
          <li>Loss of major funds.</li>
          <li>Difficulties between partners.</li>
          <li>Organization or institution is unable to function.</li>
        </S.ListCircle>;
      let veryHighImpactInstitutionalLegal = 
        <S.ListCircle>
          <li>Organizations not able to meet legal or regulatory obligations.</li>
          <li>Organization commits crime.</li>
          <li>Organization faces major legal processing in  different areas.</li>
        </S.ListCircle>;
      let veryHighImpactSocietal = 
        <S.ListCircle>
          <li>Mass exposure of personal information can lead to protests, public demonstrations and disruption of social peace and national security.</li>
          <li>Unstablility causing riots.</li>
          <li>Complete loss of trust and prestige.</li>
        </S.ListCircle>;
      let veryHighImpactEthical = 
        <S.ListCircle>
          <li>Data is used for other purposes that cause serius risks, peoples' identity can be taken over, which may cause everything from physical harm to loss of money, job etc.</li>
        </S.ListCircle>;
      let veryHighImpactEconomical = 
        <S.ListCircle>
          <li>The biggest fines, claims etc for the organization.</li>
          <li>People loose a lot of money and funding, they cannot get a job or get a loan.</li>
        </S.ListCircle>;
      let veryHighImpactTechnical = 
        <S.ListCircle>
          <li>Nothing works as it should, data is deleted or changed forever.</li>
          <li>Resolutions and decisions are made on wrong processes.</li>
          <li>Organization cannot handle technical issues on their own, finding new partners and aid is difficult and costly.</li>
        </S.ListCircle>;
      let dataForImpactTablePersonal = [
        {"levelofimpact": 'Low', "aoeindividuals": lowImpactPersonal},
        {"levelofimpact": 'Medium', "aoeindividuals": mediumImpactPersonal},
        {"levelofimpact": 'High', "aoeindividuals": highImpactPersonal},
        {"levelofimpact": 'Very High', "aoeindividuals": veryHighImpactPersonal},
      ];
      let dataForImpactTableInstitutionalReputation = [
        {"levelofimpact": 'Low', "aoeinstitutionalrep": lowImpactInstitutionalReputation},
        {"levelofimpact": 'Medium', "aoeinstitutionalrep": mediumImpactInstitutionalReputation},
        {"levelofimpact": 'High', "aoeinstitutionalrep": highImpactInstitutionalReputation},
        {"levelofimpact": 'Very High', "aoeinstitutionalrep": veryHighImpactInstitutionalReputation},
      ];
      let dataForImpactTableInstitutionalLegal = [
        {"levelofimpact": 'Low', "aoeinstitutionallegal": lowImpactInstitutionalLegal},
        {"levelofimpact": 'Medium', "aoeinstitutionallegal": mediumImpactInstitutionalLegal},
        {"levelofimpact": 'High', "aoeinstitutionallegal": highImpactInstitutionalLegal},
        {"levelofimpact": 'Very High', "aoeinstitutionallegal": veryHighImpactInstitutionalLegal},
      ];
      let dataForImpactTableSocietal = [
        {"levelofimpact": 'Low', "aoesocietal": lowImpactSocietal},
        {"levelofimpact": 'Medium', "aoesocietal": mediumImpactSocietal},
        {"levelofimpact": 'High', "aoesocietal": highImpactSocietal},
        {"levelofimpact": 'Very High', "aoesocietal": veryHighImpactSocietal},
      ];
      let dataForImpactTableEthical = [
        {"levelofimpact": 'Low', "aoeethical": lowImpactEthical},
        {"levelofimpact": 'Medium', "aoeethical": mediumImpactEthical},
        {"levelofimpact": 'High', "aoeethical": highImpactEthical},
        {"levelofimpact": 'Very High', "aoeethical": veryHighImpactEthical},
      ];
      let dataForImpactTableEconomical = [
        {"levelofimpact": 'Low', "aoeeconomical": lowImpactEconomical},
        {"levelofimpact": 'Medium', "aoeeconomical": mediumImpactEconomical},
        {"levelofimpact": 'High', "aoeeconomical": highImpactEconomical},
        {"levelofimpact": 'Very High', "aoeeconomical": veryHighImpactEconomical},
      ];
      let dataForImpactTableTechnical = [
        {"levelofimpact": 'Low', "aoetechnical": lowImpactTechnical},
        {"levelofimpact": 'Medium', "aoetechnical": mediumImpactTechnical},
        {"levelofimpact": 'High', "aoetechnical": highImpactTechnical},
        {"levelofimpact": 'Very High', "aoetechnical": veryHighImpactTechnical},
      ];
      
      let columnsForImpactLevelFinal = [
        { Header: 'Impact Assessment', accessor: 'impactassessment'},
        { Header: 'Area of Effect - Personal', accessor: 'aoeindividuals'},
        { Header: 'Area of Effect - Institutional Reputation', accessor: 'aoeinstitutionrep'},
        { Header: 'Area of Effect - Institutional Legal', accessor: 'aoeinstitutionlegal'},
        { Header: 'Area of Effect - Societal', accessor: 'aoesocietal'},
        { Header: 'Area of Effect - Ethical', accessor: 'aoeethical'},
        { Header: 'Area of Effect - Economical', accessor: 'aoeeconomical'},
        { Header: 'Area of Effect - Technical', accessor: 'aoetechnical'},
      ];

      let individualImpactFinal = <Dropdown key='individualImpactFinal' defaultValue={this.state.impactLevelOnIndividuals} onChange={(event) => this.handleImpactLevelSelection(event, 'individual')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let institutionalRepImpactFinal = <Dropdown key='institutionalRepImpactFinal' defaultValue={this.state.impactLevelOnInstitutionalRep} onChange={(event) => this.handleImpactLevelSelection(event, 'institutionalrep')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let institutionalLegalImpactFinal = <Dropdown key='institutionalLegalImpactFinal' defaultValue={this.state.impactLevelOnInstitutionalLegal} onChange={(event) => this.handleImpactLevelSelection(event, 'institutionallegal')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let societalImpactFinal = <Dropdown key='societalImpactFinal' defaultValue={this.state.impactLevelOnSocietal} onChange={(event) => this.handleImpactLevelSelection(event, 'societal')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let ethicalImpactFinal = <Dropdown key='ethicalImpactFinal' defaultValue={this.state.impactLevelOnEthical} onChange={(event) => this.handleImpactLevelSelection(event, 'ethical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let economicalImpactFinal = <Dropdown key='economicalImpactFinal' defaultValue={this.state.impactLevelOnEconomical} onChange={(event) => this.handleImpactLevelSelection(event, 'economical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let technicalImpactFinal = <Dropdown key='technicalImpactFinal' defaultValue={this.state.impactLevelOnTechnical} onChange={(event) => this.handleImpactLevelSelection(event, 'technical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>;
      let dataForImpactLevelFinal = [
        {"impactassessment": 'Unauthorized dataset access', 
        "aoeindividuals": individualImpactFinal, 
        "aoeinstitutionrep": institutionalRepImpactFinal,
        "aoeinstitutionlegal": institutionalLegalImpactFinal,
        "aoesocietal": societalImpactFinal,
        "aoeethical": ethicalImpactFinal,
        "aoeeconomical": economicalImpactFinal,
        "aoetechnical": technicalImpactFinal,
      },
      ];

      let columnsForTechnologicalThreats = [
        { Header: 'Technological Threats', accessor: 'technologicalthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let isDataAnonymizedDropDown = <Dropdown key='areDataAnonymized' defaultValue={this.state.isDataAnonymized} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'isDataAnonymized')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>;
      let anonymizationSuccessPercentageDropDown = <Dropdown key='anonymizationSuccessPercentageDropDown' defaultValue={this.state.anonymizationPercentage} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'anonymizationPercentage')} buttonText=""> {this.state.percentageDropdownOptions} </Dropdown>;
      let isAnonymizationToolVerified = <Dropdown key='isAnonymizationToolVerified' defaultValue={this.state.isAnonymizationToolVerified} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'isAnonymizationToolVerified')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>;
      let areThereDataAnnotationsDropdown = <Dropdown key='areThereDataAnnotationsDropdown' defaultValue={this.state.areThereDataAnnotations} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'areThereDataAnnotations')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>;
      let dataAnnotationsThreatLevelDropdown = <Dropdown key='dataAnnotationsThreatLevelDropdown' defaultValue={this.state.dataAnnotationsThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAnnotationsThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let isDataTransferSecureDropdown = <Dropdown key='isDataTransferSecureDropdown' defaultValue={this.state.dataTransferSecure} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataTransferSecure')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>
      let isDataTransferSecureThreatLevelDropdown = <Dropdown key='isDataTransferSecureThreatLevelDropdown' defaultValue={this.state.dataTransferThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataTransferThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let dataAccessProtectionDropdown = <Dropdown key='dataAccessProtectionDropdown' defaultValue={this.state.dataAccessProtection} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAccessProtection')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let dataAccessProtectionThreatLevelDropdown = <Dropdown key='dataAccessProtectionThreatLevelDropdown' defaultValue={this.state.dataAccessProtectionThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAccessProtectionThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;

      let commentForTechnologicalThreats1 = <><p>Data access must be restricted to only the appropriate personel.</p><p>Access to specialists should be renewed at certain times per year for example, logged activites should be observed occasionally. </p><p>In some cases, specialists have too great access rights and this is unnecessary for their tasks, thus they can access more data than they should. Also, sometimes one specialist lets the other to use their account etc.</p></>
      let dataForTechnologicalThreats = [
        {'technologicalthreats': 'Are the datasets anonymized?', 'answer': isDataAnonymizedDropDown, 'comments': 'Personal data must be protected accorind to GDPR therefore anonymization is strongly recommended when sharing personal data.'},
        //{'technologicalthreats': 'Is the success rate 100%', 'answer': yesNoDropDown, 'comments': 'Is the annonymization tool was not able to anonymize all instances of personal data, there may be identifiable personal data still in the dataset.'},
        {'technologicalthreats': 'What is the percentage of anonymization success?', 'answer': anonymizationSuccessPercentageDropDown, 'comments': 'The percentage of success defines how many instances of personal data were anonymized successfully. If the annonymization tool was not able to anonymize all instances of personal data, there may be identifiable personal data still in the dataset.'},
        {'technologicalthreats': 'Is the anonymization tool verfified by a third party?', 'answer': isAnonymizationToolVerified, 'comments': 'Anonymization tools play an important role in masking personal data. A verification of the anonymization tool by an independent third party will ensure that the tool performs the job as it should.'},
        {'technologicalthreats': 'Are there any data annotations?', 'answer': areThereDataAnnotationsDropdown, 'comments': 'Data annotations can reveal sensitive information about the dataset. We should make sure that the annotations do not contain sensitive data.'},
        {'technologicalthreats': 'Data annotations threat level: ', 'answer': dataAnnotationsThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
        {'technologicalthreats': 'Is the data transfer method secure?', 'answer': isDataTransferSecureDropdown, 'comments': 'A secure data transfer method with end-to-end encryption provides the most secure way to transfer data. Make sure the chosen method of transfer is encrypted from end-to-end.'},
        {'technologicalthreats': 'Transfer method threat level: ', 'answer': isDataTransferSecureThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
        {'technologicalthreats': 'Does the requester protect data by restricting access to it?', 'answer': dataAccessProtectionDropdown, 'comments': commentForTechnologicalThreats1},
        {'technologicalthreats': 'Data access protection threat level: ', 'answer': dataAccessProtectionThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
      ];
      let columnsForPeopleThreats = [
        { Header: 'Parties/People Threats', accessor: 'peoplethreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let peopleEducationDropdown = <Dropdown key='peopleEducationDropdown' defaultValue={this.state.peopleEducation} onChange={(event) => this.handlePeopleThreatsAnswers(event, 'peopleEducation')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let peopleEducationThreatLevelDropdown = <Dropdown key='peopleEducationThreatLevelDropdown' defaultValue={this.state.peopleEducationThreatLevel} onChange={(event) => this.handlePeopleThreatsAnswers(event, 'peopleEducationThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let peopleEducationComment1 = <><p>Sensitive data must be handled with care and the persons that handle these data should have received proper education/guidance.</p><p>Sometimes, People are unaware of what they are allowed to do and use, they attach their computers to open Wi-Fi etc. \nPeople used social media and channels without end-to-end encryption. People keep the data on their personal accounts or clouds that are not considered safe.</p><p> A specialist doesn't use appropriate secrity measures (for example leaves their work computer open and accessible), accidentally sends information without encryption or to a wrong channel (e-mail, system ets), does not know legal basis for data sharing and does not confirm</p></>
      let dataForPeopleThreats = [
        { 'peoplethreats': 'Do the people handling the data have experience in handling sensitive data?', 'answer': peopleEducationDropdown, 'comments': peopleEducationComment1},
        { 'peoplethreats': 'Threat level related to people: ', 'answer': peopleEducationThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
      ];
      let columnsForInstitutionalThreats = [
        { Header: 'Institutional Threats', accessor: 'institutionalthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let institutionCybersecurityDropdown = <Dropdown key='institutionCybersecurityDropdown' defaultValue={this.state.institutionCybersecurity} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionCybersecurity')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let institutionCybersecurityThreatLevelDropdown = <Dropdown key='institutionCybersecurityThreatLevelDropdown' defaultValue={this.state.institutionCybersecurityThreatLevel} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionCybersecurityThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let institutionGovernanceDropdown = <Dropdown key='institutionGovernanceDropdown' defaultValue={this.state.institutionGovernance} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionGovernance')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let institutionGovernanceThreatLevelDropdown = <Dropdown key='institutionGovernanceThreatLevelDropdown' defaultValue={this.state.institutionGovernanceThreatLevel} onChange={(event) => this.handleInstitutionalThreatsAnswers(event, 'institutionGovernanceThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>;
      let commentForCybersecurity1 = <><p>Cybersecurity is imperative for any organization that handles sensitive data. Internal systems must be constantly monitored for attacks.</p><p>Organizations should be audited by external cybersecurity experts. </p><p>Sometimes, people work from home or open offices where not anyone should be able to access data, programs and systems, other people can get access to their computers and workspace, there are no set rules for working outside the regular workspace, VPN or other security measures are not used, Wi-Fi channel is not secure. </p><p>Has the requester been attacked the last two years? If yes, there is a strong chance the necessary changes to strengthen the security of their organization have not been implemented yet.</p></>
      let dataForInstitutionalThreats = [
        { 'institutionalthreats': 'Does the requester have strong cybersecurity protocols in their premises?', 'answer': institutionCybersecurityDropdown, 'comments': commentForCybersecurity1},
        { 'institutionalthreats': 'Cybersecurity threat level: ', 'answer': institutionCybersecurityThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
        
        { 'institutionalthreats': 'Does the requester implement a data governance scheme on their premises?', 'answer': institutionGovernanceDropdown, 'comments': "When handling sensitive data it is important to maintain limits to who can access which data at any time. Data governance refers to the policies and standards an organization creates around how their data is collected, stored, activated, and deleted. Having control over the data's lifecycle is important for sensitive FCT data."},
        { 'institutionalthreats': 'Data governance threat level: ', 'answer': institutionGovernanceThreatLevelDropdown, 'comments': 'What is the probability of this threat to materialize?'},
      
      ];
      let columnsForLegalThreats = [
        { Header: 'Legal & Ethical Threats', accessor: 'legalthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let legalLicenseDropdown = <Dropdown key='legalLicenseDropdown' defaultValue={this.state.legalLicense} onChange={(event) => this.handleLegalAnswers(event, 'legalLicense')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalDistributionDropdown = <Dropdown key='legalDistributionDropdown' defaultValue={this.state.legalDistribution} onChange={(event) => this.handleLegalAnswers(event, 'legalDistribution')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalDistributionModifiedDropdown = <Dropdown key='legalDistributionModifiedDropdown' defaultValue={this.state.legalDistributionModified}  onChange={(event) => this.handleLegalAnswers(event, 'legalDistributionModified')}buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalPublicationsDropdown = <Dropdown key='legalPublicationsDropdown' defaultValue={this.state.legalPublications} onChange={(event) => this.handleLegalAnswers(event, 'legalPublications')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalPermenantDropdown = <Dropdown key='legalPermenantDropdown' defaultValue={this.state.legalPermenant} onChange={(event) => this.handleLegalAnswers(event, 'legalPermenant')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalEvidenceOfDeletionDropdown = <Dropdown key='legalEvidenceOfDeletionDropdown' defaultValue={this.state.legalEvidenceOfDeletion} onChange={(event) => this.handleLegalAnswers(event, 'legalEvidenceOfDeletion')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalAnyDomainDropdown = <Dropdown key='legalAnyDomainDropdown' defaultValue={this.state.legalAnyDomain} onChange={(event) => this.handleLegalAnswers(event, 'legalAnyDomain')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalSubpopulationsDropdown = <Dropdown key='legalSubpopulationsDropdown' defaultValue={this.state.legalSubpopulations} onChange={(event) => this.handleLegalAnswers(event, 'legalSubpopulations')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalEthicalReviewDropdown = <Dropdown key='legalEthicalReviewDropdown' defaultValue={this.state.legalEthicalReview} onChange={(event) => this.handleLegalAnswers(event, 'legalEthicalReview')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;
      let legalLegalReviewDropdown = <Dropdown key='legalAnyDomainDroplegalLegalReviewDropdowndown' defaultValue={this.state.legalLegalReview} onChange={(event) => this.handleLegalAnswers(event, 'legalLegalReview')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>;

      let dataForLegalThreats = [
        { 'legalthreats': 'Do you have a license agreement with the requester?', 'answer': legalLicenseDropdown, 'comments': 'FCT research may involve sensitive data that is subject to legal and ethical regulations. Failure to comply with these regulations can lead to reputational damage, legal penalties, and loss of funding'},
        { 'legalthreats': 'Does the license allow distribution of the dataset to other parties?', 'answer': legalDistributionDropdown, 'comments': ''},
        { 'legalthreats': 'May the licensee distribute the modified and adapted work?', 'answer': legalDistributionModifiedDropdown, 'comments': ''},
        { 'legalthreats': 'Can parts of the dataset be published in scientific publications?', 'answer': legalPublicationsDropdown, 'comments': ''},
        { 'legalthreats': 'Is the license permanent?', 'answer': legalPermenantDropdown, 'comments': ''},
        { 'legalthreats': 'In the event that the license is terminated, shall the Licensee provide evidence of the deletion?', 'answer': legalEvidenceOfDeletionDropdown, 'comments': ''},
        { 'legalthreats': 'Can the dataset be used in any domain/industry?', 'answer': legalAnyDomainDropdown, 'comments': ''},
        { 'legalthreats': 'Does the dataset identify any subpopulations?', 'answer': legalSubpopulationsDropdown, 'comments': ''},
        { 'legalthreats': 'Has the dataset undergo an ethical review?', 'answer': legalEthicalReviewDropdown, 'comments': ''},
        { 'legalthreats': 'Has the dataset undergo a legal review? (GDPR, AI use, etc)?', 'answer': legalLegalReviewDropdown, 'comments': ''},
      ];
      let columnsForOtherThreats = [
        { Header: 'Other Threats', accessor: 'otherthreats'},
        { Header: 'Answer', accessor: 'answer'},
        { Header: 'Comments', accessor: 'comments'}
      ];
      let dataForOtherThreats = [
      ];
      let columnsForThreatLevelFinal = [
        { Header: 'Assessment Area', accessor: 'assessmentarea'},
        { Header: 'Probability Level', accessor: 'probabilitylevel'},
        
      ];
      let technologyThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalTechnologyThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'technological')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      let peopleThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalPeopleThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'people')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      let institutionalThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalInstitutionalThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'institutional')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      let legalThreatFinal = <Dropdown key='entity' defaultValue={this.state.finalLegalThreatLevel} onChange={(event) => this.handleFinalThreatChange(event, 'legal')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>;
      

      let scoreOfTechnologicalThreats = this.mapLevelToScore(this.state.finalTechnologyThreatLevel);
      let dataForThreatLevelFinal = [
        {"assessmentarea": 'Technology threats', "probabilitylevel": technologyThreatFinal},
        {"assessmentarea": 'People/Parties involved', "probabilitylevel": peopleThreatFinal},
        {"assessmentarea": 'Institutional related threats', "probabilitylevel": institutionalThreatFinal},
        {"assessmentarea": 'Legal threats', "probabilitylevel": legalThreatFinal},
      ];
      let finalRiskCalculation = this.state.finalRiskScoreEvaluation;
      
      

    return(
      <>
      <S.Row>
        <S.Col12>
          
          <S.Card>
            <S.CardBody>
            <S.CardSectionFlexStretched>
              <button type="button" style={{paddingTop:20, paddingBottom:20, paddingLeft:50, paddingRight:50, marginBottom:0, marginTop: 0, marginRight: 30, color: this.state.sectionGeneralActive ? '#fff' : '#505D84',backgroundColor: this.state.sectionGeneralActive ? '#556EE6' : '#fff'}} 
                className="button" onClick={() => this.nextStep('generalsub1')}>General</button> 
              <BsFillArrowRightCircleFill style={{marginRight: 30}}/>
              <button type="button" style={{ paddingTop:20, paddingBottom:20, paddingLeft:50, paddingRight:50, marginBottom:0, marginTop: 0, marginRight: 30, color: this.state.sectionImpactActive ? '#fff' : '#505D84',backgroundColor: this.state.sectionImpactActive ? '#556EE6' : '#fff'}} 
                className="button" onClick={() => this.nextStep('impactsub1')}>Impact Levels</button> 
              <BsFillArrowRightCircleFill style={{marginRight: 30}}/>
              <button type="button" style={{ paddingTop:20, paddingBottom:20, paddingLeft:50, paddingRight:50, marginBottom:0, marginTop: 0, marginRight: 30, color: this.state.sectionThreatsActive ? '#fff' : '#505D84',backgroundColor: this.state.sectionThreatsActive ? '#556EE6' : '#fff'}} 
                className="button" onClick={() => this.nextStep('threatssub1')}>Threats</button> 
              <BsFillArrowRightCircleFill style={{marginRight: 30}}/>
              <button type="button" style={{ paddingTop:20, paddingBottom:20, paddingLeft:50, paddingRight:50, marginBottom:0, marginTop: 0, marginRight: 30, color: this.state.sectionEvaluationActive ? '#fff' : '#505D84',backgroundColor: this.state.sectionEvaluationActive ? '#556EE6' : '#fff'}} 
                className="button" onClick={() => this.nextStep('evaluationsub1')}>Evaluation</button> 
            </S.CardSectionFlexStretched>    
            </S.CardBody>
          </S.Card>
          
        </S.Col12>
      </S.Row>
      
      

      {this.state.sectionGeneralSub1Active && <>
      <S.Row>
        <S.Col6FlexCondensed>
          <S.Card>
            <S.CardBody>
              <S.CardTitle><h4>General Info - General (1/2)</h4></S.CardTitle>
                <p>Please fill in some general information about this risk assessment</p>
                {generalInfoForm}
            </S.CardBody>
          </S.Card>
          <S.ButtonFlexRight>
            <button type="button" style={{width:'45%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('generalsub2')}>Next</button> 
          </S.ButtonFlexRight>
        </S.Col6FlexCondensed>
      </S.Row>
      </>}
      {this.state.sectionGeneralSub2Active && <>
      <S.Row>
        <S.Col6FlexCondensed>
          <S.Card>
            <S.CardBody>
              <S.CardTitle><h4>Sharing Details - General (2/2)</h4></S.CardTitle>
                {sharingInfoForm}
            </S.CardBody>
          </S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'45%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('generalsub1')}>Previous</button> 
            <button type="button" style={{width:'45%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub1')}>Next</button> 
          </S.ButtonFlex>
        </S.Col6FlexCondensed>
        
      </S.Row></>}
      
      {this.state.sectionImpactSub1Active && <>
        { this.state.showOldImpactTable && <>
      <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody><S.CardTitle><h4>Impact Levels</h4></S.CardTitle>
          <Styles>
            <Table columns={columnsForImpactTablePersonal} data={dataForImpactTablePersonal} />
          </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
          </S.Row></>
        }
  
      <S.Row>
        <S.Col6FlexCondensed>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Dataset Information - Impact Levels (1/9)</h4></S.CardTitle>
            <p>Please fill in the type of data present in the dataset and the nature of the dataset</p>
            
            {this.state.selectedDatasetModel === 'Dataset' && 
            <>
            <S.CardSection>
                <MySelect key='data-types' label='Data types'  onChange={this.handleDataTypeChange} options={this.state.dataTypeOptions} />
                
            </S.CardSection>

            <S.CardSection>
            <MySelect key='special-data-types' label='Special Data types' onChange={this.handleSpecialDataTypeChange} options={this.state.specialDataTypeOptions} />  
            </S.CardSection>

            <S.CardSectionFlex>
              <S.CardHalfWidth>
                <MySelect key='data-formats' label="Data Formats"  onChange={this.handleDataFormatSelection} options={this.state.dataFormatOptions}/>
              </S.CardHalfWidth>
              <S.CardHalfWidth>
                <Dropdown key='dataset-nature' formLabel="Dataset Nature" buttonText="" onChange={this.handleDatasetNatureSelection}> {this.state.datasetNatureOptions} </Dropdown>
                </S.CardHalfWidth>
            </S.CardSectionFlex>
            </>
          } 
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'45%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" >Previous</button> 
            <button type="button" style={{width:'45%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub2')}>Next</button> 
          </S.ButtonFlex>
        </S.Col6FlexCondensed>
      </S.Row>
          {/*
      <S.Col12>
          <button type="button" style={{width:220, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={this.calculateImpactLevel}>Calculate Impact Level</button> 
        </S.Col12>
         */}
      </>}
      
      {this.state.sectionImpactSub2Active && <>
      <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact on individuals - Impact Levels (2/9)</h4></S.CardTitle>
            <p>Based on the table of effects what would be the impact level in case the dataset is leaked?</p>
            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any impact to individuals?</S.GeneralFormFieldLabel>
              <Dropdown key='impactlevelonindividuals' defaultValue={this.state.impactOnIndividuals} onChange={(event) => this.handleImpactSelection(event, 'individual')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </>}
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any impact to individuals? If yes, what is the impact level?</S.GeneralFormFieldLabel>
              <Dropdown key='impactlevelonindividuals' defaultValue={this.impactLevelOnIndividuals} onChange={(event) => this.handleImpactLevelSelection(event, 'individual')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Does the dataset contain personal data?</S.GeneralFormFieldLabel>
              <Dropdown key='containpersonaldata' defaultValue={this.state.datasetContainsPersonalData} onChange={this.handleDatasetContainsPersonalData} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Does the dataset contain sensitive data?</S.GeneralFormFieldLabel>
              <Dropdown key='containsensitivedata' defaultValue={this.state.datasetContainsSensitiveData} onChange={this.handleDatasetContainsSensitiveData} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            <S.ButtonFlex>
            <button type="button" style={{width:'45%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub1')}>Previous</button> 
            <button type="button" style={{width:'45%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub3')}>Next</button> 
          </S.ButtonFlex>

            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Will they be able to overcome the consequences?</S.GeneralFormFieldLabel>
              <Dropdown key='entity' defaultValue='No' onChange={(event) => this.hanldeIndividualsOvercome(event)} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            {this.state.individualsOvercomeOld === 'Yes' && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>What effort will be required to overcome the consequences?</S.GeneralFormFieldLabel>
              <Dropdown key='entity' defaultValue='Minor' onChange={(event) => this.handleLevelOfImpactOnIndividual(event)} buttonText=""> {this.state.effortDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </> }
            
            </>}
            
          </S.CardBody></S.Card>
        </S.Col6Flex>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact Table</h4></S.CardTitle>
            <Styles>
              <Table columns={columnsForImpactTablePersonal} data={dataForImpactTablePersonal} />
            </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
        </S.Row>
        </>}

        {this.state.sectionImpactSub3Active && <>
        <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Institutional Impact (reputation/brand) - Impact Levels (3/9)</h4></S.CardTitle>
            <p>Please answer a few questions to determine the impact level of an unauthorized access to the dataset</p>
            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any impact for the organization in terms of its reputation or brand?</S.GeneralFormFieldLabel>
              <Dropdown key='impactoninstitutionrep' defaultValue={this.state.impactOnInstitutionalReputation} onChange={(event) => this.handleImpactSelection(event, 'institutionalrep')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </>}
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any impact for the organization in terms of its reputation or brand? If yes, what is the impact level?</S.GeneralFormFieldLabel>
              <Dropdown key='impactlevelforinstitutionrep' defaultValue={this.impactLevelOnInstitutionalRep} onChange={(event) => this.handleImpactLevelSelection(event, 'institutionalrep')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub2')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub4')}>Next</button> 
          </S.ButtonFlex>
        </S.Col6Flex>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact Table</h4></S.CardTitle>
            <Styles>
              <Table columns={columnsForImpactTableInstitutionalRep} data={dataForImpactTableInstitutionalReputation} />
            </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
        </S.Row></> }

        {this.state.sectionImpactSub4Active && <>
        <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Institutional Impact (legal) - Impact Levels (4/9)</h4></S.CardTitle>
            <p>Please answer a few questions to determine the impact level of an unauthorized access to the dataset</p>
            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any legal impact for the organization?</S.GeneralFormFieldLabel>
              <Dropdown key='impactonlegal' defaultValue={this.state.impactOnInstitutionalLegal} onChange={(event) => this.handleImpactSelection(event, 'institutionallegal')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </>}
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any legal impact for the organization? If yes, what is the impact level?</S.GeneralFormFieldLabel>
              <Dropdown key='impactlevelonlegal' defaultValue={this.impactLevelOnInstitutionalLegal} onChange={(event) => this.handleImpactLevelSelection(event, 'institutionallegal')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub3')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub5')}>Next</button> 
          </S.ButtonFlex>
          </S.CardBody></S.Card>
          
        </S.Col6Flex>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact Table</h4></S.CardTitle>
            <Styles>
              <Table columns={columnsForImpactTableInstitutionalLegal} data={dataForImpactTableInstitutionalLegal} />
            </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
        </S.Row></> }

        {this.state.sectionImpactSub5Active && <>
        <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Societal Impact - Impact Levels (5/9)</h4></S.CardTitle>
            <p>Please answer a few questions to determine the impact level of an unauthorized access to the dataset</p>
            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any societal impact?</S.GeneralFormFieldLabel>
              <Dropdown key='impactonsocietal' defaultValue={this.state.impactLevelOnSocietal} onChange={(event) => this.handleImpactSelection(event, 'societal')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </>}
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any societal impact? If yes, what is the impact level?</S.GeneralFormFieldLabel>
              <Dropdown key='impactlevelonsocietal' defaultValue={this.impactLevelOnSocietal} onChange={(event) => this.handleImpactLevelSelection(event, 'societal')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub4')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub6')}>Next</button> 
          </S.ButtonFlex>
        </S.Col6Flex>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact Table</h4></S.CardTitle>
            <Styles>
              <Table columns={columnsForImpactTableSocietal} data={dataForImpactTableSocietal} />
            </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
        </S.Row></> }

        {this.state.sectionImpactSub6Active && <>
        <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Ethical Impact - Impact Levels (6/9)</h4></S.CardTitle>
            <p>Please answer a few questions to determine the impact level of an unauthorized access to the dataset</p>
            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any ethical impact?</S.GeneralFormFieldLabel>
              <Dropdown key='impactonethical' defaultValue={this.state.impactOnEthical} onChange={(event) => this.handleImpactSelection(event, 'ethical')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </>}
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any ethical impact? If yes, what is the impact level?</S.GeneralFormFieldLabel>
              <Dropdown key='impactlevelonethical' defaultValue={this.impactLevelOnEthical} onChange={(event) => this.handleImpactLevelSelection(event, 'ethical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub5')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub7')}>Next</button> 
          </S.ButtonFlex>
        </S.Col6Flex>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact Table</h4></S.CardTitle>
            <Styles>
              <Table columns={columnsForImpactTableEthical} data={dataForImpactTableEthical} />
            </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
        </S.Row></> }

        {this.state.sectionImpactSub7Active && <>
        <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Economical Impact - Impact Levels (7/9)</h4></S.CardTitle>
            <p>Please answer a few questions to determine the impact level of an unauthorized access to the dataset</p>
            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any economical impact?</S.GeneralFormFieldLabel>
              <Dropdown key='impactonsocietal' defaultValue={this.state.impactOnEconomical} onChange={(event) => this.handleImpactSelection(event, 'economical')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </>}
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any economical impact? If yes, what is the impact level?</S.GeneralFormFieldLabel>
              <Dropdown key='impactleveloneconomical' defaultValue={this.impactLevelOnEconomical} onChange={(event) => this.handleImpactLevelSelection(event, 'economical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub6')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub8')}>Next</button> 
          </S.ButtonFlex>
        </S.Col6Flex>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact Table</h4></S.CardTitle>
            <Styles>
              <Table columns={columnsForImpactTableEconomical} data={dataForImpactTableEconomical} />
            </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
        </S.Row></> }

        {this.state.sectionImpactSub8Active && <>
        <S.Row>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Technical Impact - Impact Levels (8/9)</h4></S.CardTitle>
            <p>Please answer a few questions to determine the impact level of an unauthorized access to the dataset</p>
            {this.state.impactOnIndividualsOld && <>
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any technical impact?</S.GeneralFormFieldLabel>
              <Dropdown key='impactontechnical' defaultValue={this.state.impactOnTechnical} onChange={(event) => this.handleImpactSelection(event, 'technical')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            </>}
            
            <S.GeneralFormField>
              <S.GeneralFormFieldLabel>Is there any technical impact? If yes, what is the impact level?</S.GeneralFormFieldLabel>
              <Dropdown key='impactlevelontechnical' defaultValue={this.impactLevelOnTechnical} onChange={(event) => this.handleImpactLevelSelection(event, 'technical')} buttonText=""> {this.state.impactDropdownOptions} </Dropdown>
            </S.GeneralFormField>
            
            
            
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub7')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub10')}>Next</button> 
          </S.ButtonFlex>
        </S.Col6Flex>
        <S.Col6Flex>
          <S.Card><S.CardBody>
            <S.CardTitle><h4>Impact Table</h4></S.CardTitle>
            <Styles>
              <Table columns={columnsForImpactTableTechnical} data={dataForImpactTableTechnical} />
            </Styles>
          </S.CardBody></S.Card>
        </S.Col6Flex>
        </S.Row></> }

        
        {this.state.sectionImpactSub10Active && <>
          <S.Col12>
          <S.Card><S.CardBody><S.CardTitle><h4>Assessed Impact Levels - Impact Levels (9/9)</h4></S.CardTitle>
          <p>Please verify the impact levels of unauthorized access to the dataset</p>
          <Styles>
            <Table style={{width:'100%'}} columns={columnsForImpactLevelFinal} data={dataForImpactLevelFinal} />
          </Styles>
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub8')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub1')}>Next</button> 
          </S.ButtonFlex>
        </S.Col12>
        </>}
        
        {this.state.impactLevelCalculated && 
          <S.Col12>
            <button type="button" style={{width:120, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impact', 'threats')}>Next</button> 
          </S.Col12>
        }
      
      

      {this.state.sectionThreatsSub1Active && <>
        <S.Row><S.Col12>
            <S.Card><S.CardBody><S.CardTitle><h4>Technological threats - Threats (1/5)</h4></S.CardTitle>
              <Styles>
                <Table columns={columnsForTechnologicalThreats} data={dataForTechnologicalThreats} />
              </Styles>
            </S.CardBody></S.Card>
            <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub10')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub2')}>Next</button> 
          </S.ButtonFlex>
        </S.Col12></S.Row>
      </>}
      {this.state.sectionThreatsSub1ActiveOld && <>
        <S.Row>
          <S.Col12>
            <S.TitleCard>
              <S.CardBody>
                <S.TitleCardText>Technological threats - Threats (1/5)</S.TitleCardText>
              </S.CardBody>
            </S.TitleCard>
          </S.Col12>
        </S.Row>
        <S.GridRow>
          <S.Col6Flex>
            <S.GridCard>
              <S.CardBody>
                <S.CardTitle style={{backgroundColor:'#3D5A80', color:'#fff'}}><h4>Data Anonymization</h4></S.CardTitle>
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Are the datasets anonymized?</S.GeneralFormFieldLabel>
                  <Dropdown key='areDataAnonymized' defaultValue={this.state.isDataAnonymized} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'isDataAnonymized')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
                </S.GeneralFormField>
                {this.state.isDataAnonymized === 'Yes' && <>
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Is the anonymization tool verfified by a third party?</S.GeneralFormFieldLabel>
                  <Dropdown key='isAnonymizationToolVerified' defaultValue={this.state.isAnonymizationToolVerified} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'isAnonymizationToolVerified')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
                </S.GeneralFormField>
                </>}
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Anonymization threat level:</S.GeneralFormFieldLabel>
                  <Dropdown key='anonymizationthreatlevel' defaultValue={this.state.anonymizationThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'anonymizationThreatLevel')} buttonText=""> {this.state.threatLevelOptions} </Dropdown>
                </S.GeneralFormField>
              </S.CardBody>
            </S.GridCard>
            
          </S.Col6Flex>
          <S.Col6Flex>
            <S.GridCard>
            <S.CardBody>
                <S.CardTitle><h5>Comments</h5></S.CardTitle>
                  <p>Personal data must be protected accorind to GDPR therefore anonymization is strongly recommended when sharing personal data.</p>
                  <p>The percentage of success defines how many instances of personal data were anonymized successfully. If the annonymization tool was not able to anonymize all instances of personal data, there may be identifiable personal data still in the dataset.</p>
                  <p>Anonymization tools play an important role in masking personal data. A verification of the anonymization tool by an independent third party will ensure that the tool performs the job as it should.</p>
              </S.CardBody>
            </S.GridCard>
          </S.Col6Flex>
        </S.GridRow>

        <S.GridRow>
          <S.Col6Flex>
          
            <S.GridCard>
              <S.CardBody>
                <S.CardTitle><h4>Data Annotations</h4></S.CardTitle>      
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Are there any data annotations?</S.GeneralFormFieldLabel>
                  <Dropdown key='areThereDataAnnotationsDropdown' defaultValue={this.state.areThereDataAnnotations} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'areThereDataAnnotations')} buttonText=""> {this.state.basicDropdownOptions} </Dropdown>
                </S.GeneralFormField>
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Data annotations threat level:</S.GeneralFormFieldLabel>
                  <Dropdown key='dataAnnotationsThreatLevelDropdown' defaultValue={this.state.dataAnnotationsThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAnnotationsThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>
                </S.GeneralFormField>
              </S.CardBody>
            </S.GridCard>
            
          </S.Col6Flex>
          <S.Col6Flex>
            <S.GridCard>
            <S.CardBody>
                <S.CardTitle><h5>Comments</h5></S.CardTitle>
                  <p>Data annotations can reveal sensitive information about the dataset. We should make sure that the annotations do not contain sensitive data.</p>
                  <p>What is the probability of this threat to materialize?</p>
                  
              </S.CardBody>
            </S.GridCard>
          </S.Col6Flex>
        </S.GridRow>
        <S.GridRow>
          <S.Col6Flex>
          
            <S.GridCard>
              <S.CardBody>
                <S.CardTitle><h4>Data Transfer</h4></S.CardTitle>      
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Is the data transfer method secure?</S.GeneralFormFieldLabel>
                  <Dropdown key='isDataTransferSecureDropdown' defaultValue={this.state.dataTransferSecure} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataTransferSecure')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>
                </S.GeneralFormField>
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Transfer method threat level:</S.GeneralFormFieldLabel>
                  <Dropdown key='isDataTransferSecureThreatLevelDropdown' defaultValue={this.state.dataTransferThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataTransferThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>
                </S.GeneralFormField>
              </S.CardBody>
            </S.GridCard>
            
          </S.Col6Flex>
          <S.Col6Flex>
            <S.GridCard>
            <S.CardBody>
                <S.CardTitle><h5>Comments</h5></S.CardTitle>
                  <p>A secure data transfer method with end-to-end encryption provides the most secure way to transfer data. Make sure the chosen method of transfer is encrypted from end-to-end.</p>
                  <p>What is the probability of this threat to materialize?</p>
                  
              </S.CardBody>
            </S.GridCard>
          </S.Col6Flex>
        </S.GridRow>
        <S.GridRow>
          <S.Col6Flex>
          
            <S.GridCard>
              <S.CardBody>
                <S.CardTitle><h4>Data Access Protection</h4></S.CardTitle>      
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Does the requester protect data by restricting access to it?</S.GeneralFormFieldLabel>
                  <Dropdown key='dataAccessProtectionDropdown' defaultValue={this.state.dataAccessProtection} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAccessProtection')} buttonText="">{this.state.basicDropdownOptions}</Dropdown>
                </S.GeneralFormField>
                <S.GeneralFormField>
                  <S.GeneralFormFieldLabel>Data access protection threat level:</S.GeneralFormFieldLabel>
                  <Dropdown key='dataAccessProtectionThreatLevelDropdown' defaultValue={this.state.dataAccessProtectionThreatLevel} onChange={(event) => this.handleTechnologyThreatsAnswers(event, 'dataAccessProtectionThreatLevel')} buttonText="">{this.state.threatLevelOptions}</Dropdown>
                </S.GeneralFormField>
              </S.CardBody>
            </S.GridCard>
            
          </S.Col6Flex>
          <S.Col6Flex>
            <S.GridCard>
            <S.CardBody>
                <S.CardTitle><h5>Comments</h5></S.CardTitle>
                  <p>Data access must be restricted to only the appropriate personel.</p>
                  <p>Access to specialists should be renewed at certain times per year for example, logged activites should be observed occasionally.</p>
                  <p>In some cases, specialists have too great access rights and this is unnecessary for their tasks, thus they can access more data than they should. Also, sometimes one specialist lets the other to use their account etc.</p>
                  <p>What is the probability of this threat to materialize?</p>
                  
              </S.CardBody>
            </S.GridCard>
          </S.Col6Flex>
        </S.GridRow>
        
        <S.Row style={{marginBottom:200}}><S.Col12><S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('impactsub6')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub2')}>Next</button> 
          </S.ButtonFlex></S.Col12></S.Row>
        </>
        }

        {this.state.sectionThreatsSub2Active && <>
        <S.Row><S.Col12>
            <S.Card><S.CardBody><S.CardTitle><h4>Parties/People involved - Threats (2/5)</h4></S.CardTitle>
              <Styles>
                <Table columns={columnsForPeopleThreats} data={dataForPeopleThreats} />
              </Styles>
            </S.CardBody></S.Card>
            <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub1')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub3')}>Next</button> 
          </S.ButtonFlex>
        </S.Col12></S.Row></>}
        {this.state.sectionThreatsSub3Active && <>
        <S.Row><S.Col12>
            <S.Card><S.CardBody><S.CardTitle><h4>Institutional threats - Threats (3/5)</h4></S.CardTitle>
              <Styles>
                <Table columns={columnsForInstitutionalThreats} data={dataForInstitutionalThreats} />
              </Styles>
            </S.CardBody></S.Card>
            <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub2')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub4')}>Next</button> 
          </S.ButtonFlex>
            </S.Col12></S.Row></>}
        {this.state.sectionThreatsSub4Active && <>
            <S.Row><S.Col12>
            <S.Card><S.CardBody><S.CardTitle><h4>Legal threats - Threats (4/5)</h4></S.CardTitle>
              <Styles>
                <Table columns={columnsForLegalThreats} data={dataForLegalThreats} />
              </Styles>
            </S.CardBody></S.Card>
            <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub3')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub5')}>Next</button> 
          </S.ButtonFlex>
            </S.Col12></S.Row></>}
        {this.state.sectionThreatsSub5Active && <>
          <S.Row><S.Col12>
           {/* <button type="button" style={{width:220, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={this.calculateThreatLevel}>Calculate Threat Probability</button>   */}
            
          <S.Card><S.CardBody><S.CardTitle><h4>Assessed Threat Probability Levels - Threats (5/5)</h4></S.CardTitle>
          <p>Based on the previous answers the threat occurance probability level is assessed as follows. Please verify the probability levels of unauthorized access to the dataset and change the options as you feel necessary</p>
          <Styles>
            <Table columns={columnsForThreatLevelFinal} data={dataForThreatLevelFinal} />
          </Styles>
          </S.CardBody></S.Card>
          <S.ButtonFlex>
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('threatssub4')}>Previous</button> 
            <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.nextStep('evaluationsub1')}>Next</button> 
          </S.ButtonFlex>
          </S.Col12></S.Row></>}

        

      {this.state.sectionEvaluationActive && <>
        
        <S.Row>
          <S.Col6Flex>
            <S.Card><S.CardBody>
              
              <S.CardTitle><h4>Final Risk Evaluation</h4></S.CardTitle>
              <S.CardTitle><h5>Assessed Overall Impact Level: {this.state.finalImpactEvaluation}</h5></S.CardTitle>
              <S.CardTitle><h5>Assessed Overall Threat Probability: {this.state.finalThreatEvaluation}</h5></S.CardTitle>
              <S.CardTitle></S.CardTitle>
                {finalRiskCalculation === 'Low' && <h5>The estimated risk is: <span style={{fontWeight:'bold', fontSize:24}}>Low Risk</span></h5>}
                {finalRiskCalculation === 'Medium' && <h5>The estimated risk is: <span style={{fontWeight:'bold', fontSize:24, color:'orange'}}>Medium Risk</span></h5>}
                {finalRiskCalculation === 'High' && <h5>The estimated risk is: <span style={{fontWeight:'bold', fontSize:24, color:'red'}}>High Risk</span></h5>}
              <S.CardTitle></S.CardTitle>
                {<p>The final risk evaluation was based on the following considerations:</p> }
                {<p>{this.state.finalRiskTable}</p>}
            </S.CardBody></S.Card>
          </S.Col6Flex>
          <S.Col6Flex>
            
            <S.Card><S.CardBody>
              {this.state.finalImpactEvaluation === 'Low' && this.state.finalThreatEvaluation === 'Low' &&
                <S.BrandImage src={LowLowEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Low' && this.state.finalThreatEvaluation === 'Medium' &&
                <S.BrandImage src={LowMediumEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Low' && this.state.finalThreatEvaluation === 'High' &&
                <S.BrandImage src={LowHighEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Medium' && this.state.finalThreatEvaluation === 'Low' &&
                <S.BrandImage src={MediumLowEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Medium' && this.state.finalThreatEvaluation === 'Medium' &&
                <S.BrandImage src={MediumMediumEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Medium' && this.state.finalThreatEvaluation === 'High' &&
                <S.BrandImage src={MediumHighEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'High' && this.state.finalThreatEvaluation === 'Low' &&
                <S.BrandImage src={HighLowEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'High' && this.state.finalThreatEvaluation === 'Medium' &&
                <S.BrandImage src={HighMediumEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'High' && this.state.finalThreatEvaluation === 'High' &&
                <S.BrandImage src={HighHighEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Very High' && this.state.finalThreatEvaluation === 'Low' &&
                <S.BrandImage src={HighLowEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Very High' && this.state.finalThreatEvaluation === 'Medium' &&
                <S.BrandImage src={HighMediumEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
              {this.state.finalImpactEvaluation === 'Very High' && this.state.finalThreatEvaluation === 'High' &&
                <S.BrandImage src={HighHighEvaluation} alt='risk-evaluation-example' className='risk-example'></S.BrandImage>    
              }
            </S.CardBody></S.Card>
            
              <S.Card>
                <S.CardBody>
                  <h4>Mitigation Actions</h4>
                  <S.CardTitle></S.CardTitle> 
                  {<h5>Recommended Mitigations Actions</h5>}
                  <S.CardTitle></S.CardTitle>
                  {<p>{this.state.finalMitigationTable}</p>}
                </S.CardBody>
              </S.Card>
      
    
          </S.Col6Flex>
        </S.Row>
        <S.Row><S.Col6Flex>
        <button type="button" style={{width:'40%', paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.exportRiskAssessmentDataToJson()}>Export to JSON</button> 
        
        </S.Col6Flex></S.Row>
      </>}














      {this.state.sectionLoginActive === true && 
      <div>
      <S.ContainerFluid>
        <S.Row>
          <S.LogInOneLoginColumnWrapper className='login-column-wrapper'>
            <S.LoginOneLoginContentWrapper className='login-content-wrapper'>
              <S.LoginOneHeaderWrapper>
                
                <S.LoginOneHeaderTitle>Welcome Back!</S.LoginOneHeaderTitle>
                <S.LoginOneHeaderSubTitle>Sign in to access all available tools</S.LoginOneHeaderSubTitle>
              </S.LoginOneHeaderWrapper>
              <S.LoginOneFormWrapper>
                <S.LoginOneForm >
                  <S.LogInOneFormField>
                    
                    <S.LoginOneFormFieldLabel htmlFor="username">Username</S.LoginOneFormFieldLabel>
                    <S.LoginOneFormFieldInput 
                      type="text" 
                      placeholder="Enter your username" 
                    ></S.LoginOneFormFieldInput>
                  </S.LogInOneFormField>
                  <S.LogInOneFormField>
                    
                    <S.LoginOneFormFieldLabel htmlFor="password">Password</S.LoginOneFormFieldLabel>
                    <S.LoginOneFormFieldInput type="password" placeholder="Enter your password" ></S.LoginOneFormFieldInput>
                  </S.LogInOneFormField>
                  <S.LoginOneFormSubmitButtonWrapper>
                    <S.LoginOneFormSubmitButton>
                      
                      Log In
                      </S.LoginOneFormSubmitButton>
                    
                  </S.LoginOneFormSubmitButtonWrapper>
                  
                </S.LoginOneForm>
              </S.LoginOneFormWrapper>
              <S.LoginOneFooterWrapper>
                <p>Don't have an account? Please <S.PrimaryLink href='mailto:cmpalouk@gmail.com' >contact your administrator</S.PrimaryLink>.</p>
                <p>© 2023 Risk Assessment Prototype - LAGO - ICCS</p>
              </S.LoginOneFooterWrapper>
              

            </S.LoginOneLoginContentWrapper>
          </S.LogInOneLoginColumnWrapper>
          
        </S.Row>
      </S.ContainerFluid>

    </div>
  }
      {this.state.datasetActive && <S.Row>
      <S.Col6Flex>
        <S.Card>
          <S.CardBody>
            <S.CardTitle><h4>Dataset Information - Technologies Involved</h4></S.CardTitle>
            
            <p>Please fill in the type of data present in the dataset, the nature of the dataset and the anonymization status</p>


            <S.CardSection>
              
              <Dropdown key='datasetModel' formLabel="Dataset/Model" defaultValue={this.state.selectedDatasetModel} buttonText="" onChange={this.handleDatasetModelChange}> {this.state.datasetModelOptions} </Dropdown>
            </S.CardSection>

            {this.state.selectedDatasetModel === 'Dataset' && 
            <>
            <S.CardSection>
                <MySelect key='data-types' label='Data types'  onChange={this.handleDataTypeChange} options={this.state.dataTypeOptions} />
                
            </S.CardSection>

            <S.CardSection>
            <MySelect key='special-data-types' label='Special Data types' onChange={this.handleSpecialDataTypeChange} options={this.state.specialDataTypeOptions} />  
            </S.CardSection>

            <S.CardSectionFlex>
              <S.CardHalfWidth>
                <MySelect key='data-formats' label="Data Formats"  onChange={this.handleDataFormatSelection} options={this.state.dataFormatOptions}/>
              </S.CardHalfWidth>
              <S.CardHalfWidth>
                <Dropdown key='dataset-nature' formLabel="Dataset Nature" buttonText="" onChange={this.handleDatasetNatureSelection}> {this.state.datasetNatureOptions} </Dropdown>
                </S.CardHalfWidth>
            </S.CardSectionFlex>
            <S.CardSection>
              
            </S.CardSection>
            
          {this.state.selectedDatasetNature !== 'Synthetic' && 
            <S.CardSectionFlex>
              <S.CardHalfWidth>
                <Dropdown key='anonymization' formLabel="Anonymization" buttonText="" onChange={this.handleDatasetAnonymizationSelection}> {this.state.datasetAnonymizationOptions} </Dropdown>
                
              </S.CardHalfWidth>
              <S.CardHalfWidth>
              {this.state.selectedDatasetAnonymizationOption === 'Yes' && 
                  <Dropdown key='anonymization-trl' formLabel="TRL of Anonymization Tool" defaultValue={this.state.selectedAnonymizationTrl} buttonText="" onChange={this.handleAnonymizationTrlSelection}> {this.state.anonymizationToolTrlOptions} </Dropdown>
                }
              </S.CardHalfWidth>
            </S.CardSectionFlex>
          }
          {this.state.selectedDatasetAnonymizationOption === 'Yes' && 
                  <S.CardSection>
                  <Dropdown key='anonymization-tool' formLabel="Anonymization Tool" buttonText="" onChange={this.handleAnonymizationToolSelection}> {this.state.anonymizationToolOptions} </Dropdown>
                  </S.CardSection>
                }
          {this.state.selectedAnonymizationToolOptions === 'License plates - VICOM' &&
            <S.CardSection>
            <Dropdown key='license-plates-percentage' formLabel="Were all the license plates anonymized successfully?" buttonText="" onChange={this.handleLicensePlatesSelection}> {this.state.basicDropdownOptions} </Dropdown>
            </S.CardSection>
          }
          </>
          }
          {this.state.selectedDatasetModel === 'Model' && 
            <S.CardSection>
              <Dropdown key='federated-learning' formLabel="Federated Learning" buttonText="" onChange={this.handleFederatedLearningSelection}> {this.state.federatedLearningOptions} </Dropdown>
            </S.CardSection>
          }
          
          </S.CardBody>
        </S.Card>
        
      </S.Col6Flex>
      
      <S.Col6Flex>
      <S.Card>
          <S.CardBody>
            <h4>Usage Policies / License Agreements</h4>
            <S.CardSection>
              <p>Please answer the following questions regarding the usage policies and license agreements</p>
            </S.CardSection>
            <S.CardSubSectionHeader>Distribution of the dataset</S.CardSubSectionHeader>
            <S.CardSection>
              {this.state.selectedDatasetModel === 'Dataset' && 
                <Dropdown key='distribution-restrictions' formLabel="Does the data have any distribution restrictions?" buttonText="" onChange={this.handleDistributionRestrictions}> {this.state.basicDropdownOptions} </Dropdown>
              }
            </S.CardSection>
            <S.CardSection>
              {this.state.selectedDatasetModel === 'Dataset' && 
               this.state.selectedDistributionRestrictions === 'No' && <>
               <S.CardSection>
               
                <Dropdown key='distribution-modifications' formLabel="May the licensee distribute the  modified and adapted  work?" buttonText="" onChange={this.handleDistributionModifications}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              <S.CardSection>
                <Dropdown key='license-modifications' formLabel="May the licensee change the original license type?" buttonText="" onChange={this.handleLicenseModifications}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              </>
              }
              {this.state.selectedDatasetModel === 'Model' && 
              <S.CardSection>
                <Dropdown key='model-training' formLabel="Was the model (or the pre-trained model) trained on any personal data?" buttonText="" onChange={this.handleModelTrainingOptions}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              }
              <S.CardSubSectionHeader>Publication of partial data or results</S.CardSubSectionHeader>
              {this.state.selectedDatasetModel === 'Dataset' && 
              <>
              <S.CardSection>
              <Dropdown key='scientific-publications' formLabel="Can parts of the dataset be published in scientific publications?" buttonText="" onChange={this.handleScientificPublicationOptions}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              <S.CardSubSectionHeader>License expiration and data handling</S.CardSubSectionHeader>
              <S.CardSection>
              <Dropdown key='licese-expiration' formLabel="Is the license permanent" buttonText="" onChange={this.handleLicensePermenantSelection}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              <S.CardSection>
              <Dropdown key='evidence-deletion' formLabel="In the event that the license is terminated, shall the Licensee provide evidence of the deletion?" buttonText="" onChange={this.handleEvidenceDeletionOptions}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              <S.CardSubSectionHeader>Usage Restrictions</S.CardSubSectionHeader>
              <S.CardSection>
              <Dropdown key='domain-usage' formLabel="Can the dataset be used in any domain/industry?" buttonText="" onChange={this.handleSensitiveContextOptions}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              <S.CardSubSectionHeader>Technical / Ethical Review </S.CardSubSectionHeader>
              <S.CardSection>
                <Dropdown key='technical-review' formLabel="Has the data been reviewed for technical quality?" buttonText="" onChange={this.handleSensitiveContextOptions}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              <S.CardSection>
                <Dropdown key='technical-review' formLabel="Does the dataset identify any subpopulations?" buttonText="" onChange={this.handleSensitiveContextOptions}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              <S.CardSection>
                <Dropdown key='technical-review' formLabel="Were any ethical review processes conducted? " buttonText="" onChange={this.handleSensitiveContextOptions}> {this.state.basicDropdownOptions} </Dropdown>
              </S.CardSection>
              </>}

              
            </S.CardSection>
            
            
            {this.state.selectedLicensePermenant === 'No' && 
              <S.CardSection>
                <label>Specify the duration the data may be used:  
                  <input type="text" name="data-duration" />
                </label>
              </S.CardSection>
            
            }
          </S.CardBody>
        </S.Card>
        <S.Card>
          <S.CardBody>
            <h4>Sharing Entities</h4>
            <p>In this section, please select the entity type of your organization as well as the entity of the requester's organization</p>
            <S.CardSectionFlex>
            <S.CardHalfWidth>
              <Dropdown key='sharing-entity-from' formLabel="Owner Entity" buttonText="" onChange={this.handleSharingFromSelection}> {this.state.entityOptions} </Dropdown>
            </S.CardHalfWidth>
            <S.CardHalfWidth>
              <Dropdown key='sharing-entity-to' formLabel="Requesting Entity" buttonText="" onChange={this.hanldeSharingToSelection}> {this.state.entityOptions} </Dropdown>
            </S.CardHalfWidth>
            </S.CardSectionFlex>
            <S.CardSection>
            <Dropdown key='entity-risk-management' formLabel="Does the requesting entity implement a risk management framework at their premises?" buttonText="" onChange={this.handleDistributionRestrictions}> {this.state.basicDropdownOptions} </Dropdown>
            </S.CardSection>
          </S.CardBody>
        </S.Card>
      </S.Col6Flex>
      
      
      <S.Col12>
      <button type="button" style={{width:250, paddingTop:20, paddingBottom:20, marginBottom:30, marginTop: 30, backgroundColor: '#556EE6'}} className="button" onClick={() => this.calculateRisk()}>Calculate Risk</button> 
      </S.Col12>

  {this.state.riskCalculated && 
    <S.Col6Flex>
      
        
      
      <S.Card>
        <S.CardBody>
        
          <h4>Risk Evaluation</h4>
          
          <S.CardTitle></S.CardTitle>
          {this.state.riskCalculated && this.state.finalRiskCalculation === 'Low Risk' && <h5>The estimated risk is: <span style={{fontWeight:'bold', fontSize:24}}>{this.state.finalRiskCalculation}</span></h5>}
          {this.state.riskCalculated && this.state.finalRiskCalculation === 'Medium Risk' && <h5>The estimated risk is: <span style={{fontWeight:'bold', fontSize:24, color:'orange'}}>{this.state.finalRiskCalculation}</span></h5>}
          {this.state.riskCalculated && this.state.finalRiskCalculation === 'High Risk' && <h5>The estimated risk is: <span style={{fontWeight:'bold', fontSize:24, color:'red'}}>{this.state.finalRiskCalculation}</span></h5>}
          <S.CardTitle></S.CardTitle>
          {this.state.riskCalculated && <p>The explanation for the final risk evaluation is the following:</p> }
          {this.state.riskCalculated && <p>{this.state.finalRiskDetails}</p>}

          
          
        </S.CardBody>
        
      </S.Card>
  
    </S.Col6Flex>
  }
  {this.state.riskCalculated && 
    <S.Col6Flex>
      <S.Card>
        <S.CardBody>
          <h4>Mitigation Actions</h4>
          <S.CardTitle></S.CardTitle> 
          {this.state.riskCalculated && <h5>Recommended Mitigations Actions</h5>}
          <S.CardTitle></S.CardTitle>
          {this.state.riskCalculated && <p>{this.state.mitigationActions}</p>}
        </S.CardBody>
      </S.Card>
      
    </S.Col6Flex>
  }
    <S.Col12 style={{marginTop: 100}}><h2>Help</h2></S.Col12>
    <S.Col6Flex>
      <S.Card>
          <S.CardBody>
            <h4>Personal data definition</h4>
                <p>According to Article 4 of the General Data Protection Regulation (GDPR), "personal data" means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person;</p>
                <p>Find more information <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2793-1-1" target="_blank" style={{textDecoration: 'underline'}}><b>here</b></a></p>
                <h5>Examples</h5>
                <p><b>Personal Info:</b> name, address, identity card etc</p>
                <p><b>Social Activity:</b> username, followers, friends, etc</p>
                <p><b>Location Data:</b> gps tracking, frequently visited locations, etc</p>
                <p><b>Online Activity:</b>email address, ip address, etc</p>
                <p><b>Financial Info:</b>banking accounts, credit cards, bank statements, etc</p>
                <p><b>Health Data:</b>medical history, examination reports, tests etc</p>
                </S.CardBody>
        </S.Card>
        <S.Card>
          <S.CardBody>
          <h4>Special cases of personal data</h4>
                <p>According to article 9 of GDPR, "personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation" are considered special cases of personal data which merit higher protection</p>
                <p>Find more information <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679#d1e2793-1-1" target="_blank" style={{textDecoration: 'underline'}}><b>here</b></a></p>
          </S.CardBody>
        </S.Card>
    </S.Col6Flex>
    <S.Col6Flex>
        <S.Card>
          <S.CardBody>
          <h4>Choosing the data format:</h4>
                <p><b>Text:</b>Text stand for any text based dataset like tabular data etc</p>
                <p><b>Images:</b>Datasets that contain images of persons or objects</p>
                <p><b>Video:</b>Datasets that contain videos like feed from CCTV cameras etc</p>
                <p><b>Audio:</b>Datasets containing audio of people talking, noises etc</p>
          </S.CardBody>
        </S.Card>
        <S.Card>
          <S.CardBody>
          <h4>Choosing the datsets nature:</h4>
                <p><b>Real:</b>Dataset that contains real-world information.</p>
                <p><b>Synthetic:</b>Dataset that contains information that's artificially manufactured rather than generated by real-world events.</p>
                <p><b>Hybrid:</b>Dataset that contains both real and synthetic information.</p>
          </S.CardBody>
        </S.Card>
        <S.Card>
          <S.CardBody>
          <h4>Choosing the anonymization options:</h4>
                <p>If the dataset has been anonymized please select "Yes", otherwise select "No".</p>
                <p>For the TRL option, if multiple anonymization tools have worked on the dataset, please use the lowest TRL value of all tools</p>
          </S.CardBody>
        </S.Card>
        
        </S.Col6Flex>
        
          
      <S.Col12>
      </S.Col12>
        </S.Row>
      }
      </>
    )
}
}