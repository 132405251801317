import React from 'react'
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

const notations = {
  0: "None",
  1: "Low",
  2: "Medium",
  3: "High",
}

export const options = {
  responsive: true,
  
  plugins: {
    legend: {
      labels: {
        font: {
          family: 'ClearSans',
          weight: '700',
          size: 14,
        },
      },
      position: 'top',
      
    },
    title: {
      display: true,
      text: 'Risk Levels',
      font: {
        family: 'ClearSans',
        weight: '700',
        size: 16
      }
    },
  },
  scales: {
    r: {
      backgroundColor: '#eee',
      max: 3,
      min: 0,
      grid: {
        circular: true,
      },
      pointLabels: {
        font: {
          size: 16,
          family: 'ClearSans',
          weight: '700'
        }
      },
      // This more specific font property overrides the global property
      ticks: { 
        font: {
          family: 'ClearSans',
          size: 14,
          weight: '400'
        },
        beginAtZero: true,
        stepSize: 1,
        callback: function (value, index, values) {
          return notations[value];
        },
      },
    },
  },
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [23, 43, 45 ,456, 44],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    
  ],
};


const RiskRadarChart = (props) => {
  
  return (
    <div>
      <Radar data={props.data} options={options}/>
      
    </div>
  )
}

export default RiskRadarChart;